<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Trunks
      </v-toolbar-title>
      <v-autocomplete
        v-model="accountFilter"
        :items="accounts"
        item-text="name"
        item-value="account_id"
        label="Account filter"
        multiple
        clearable
        flat
        hide-details
        single-line
        style="max-width: 20rem;"
      />
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <tableDownload
        :table-data="filteredTrunks"
        report-name="Trunk Management"
        color="primary"
      />
    </v-toolbar>
    <v-container>
      <v-alert
        :value="callCountWarning"
        type="warning"
        dismissible
      >
        Call Counts might not be accurate
      </v-alert>

      <v-alert
        :value="callCountError"
        type="error"
      >
        Call Counts system in error and cant be relied upon
      </v-alert>

      <v-layout class="mt-1 px-2">
        <v-flex grow>
          <v-data-table
            :headers="headers"
            :options="tableOptions"
            :items="filteredTrunks"
            :loading="loading"
            :search="$store.getters.searchQuery"
            fixed-header
            height="calc(100vh - 210px)"
            @update:options="handleTableOptionsUpdate"
          >
            <template #item="props">
              <tr>
                <td>
                  <a
                    v-intersect="(entries, observer, isIntersecting) => { toggleTrunkVisible(props.item.TrunkID, isIntersecting) }"
                    @click="viewTrunk(props.item)"
                  >{{ props.item.Description }}</a>
                </td>
                <td>
                  {{ props.item.accountName }}
                </td>
                <td>
                  {{ props.item.expDate }}
                </td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.Live"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.Failover"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.Active"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
                <td
                  v-if="callCountsPermitted"
                  class="text-right"
                >
                  <call-counts
                    :all-total="props.item.allTotal"
                    :all-in="props.item.allIn"
                    :all-out="props.item.allOut"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { sortBy, without } from 'lodash';

import {
  reactive, computed, watch, onBeforeUnmount, toRefs, onMounted, set,
} from 'vue';
import config, { BTL_API_ACCOUNT_URL, long_date_time_format } from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import { useStore, useRouter } from '@/utils/vue';
import usePagination from '../../composables/tablePaginationViaQueryString';

import tableDownload from '../../components/download/tableDownload';
import CallCounts from '../../components/CallCounts';

export default {
  name: 'TrunkManagement',
  components: {
    tableDownload,
    CallCounts,
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    // composables
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 15, sortBy: 'accountName' });

    const state = reactive({

      timeZone: computed(() => store.getters.timeZone),

      loading: false,
      headers: [
        { text: 'Name', value: 'Description' },
        { text: 'Account', value: 'accountName' },
        { text: 'Expires', value: 'expDate' },
        { text: 'Connected', value: 'Live' },
        { text: 'Failover', value: 'Failover' },
        { text: 'Active', value: 'Active' },
      ],
      filteredTrunks: [],
      trunks: [],
      trunksInView: [],
      accounts: [],
      accountFilter: [],
      callCountIntervalPeriod: 5000,
      callCountInterval: null,
      callCountWarning: false,
      callCountError: false,
      callCountsPermitted: false,
    });

    const loadAccounts = async () => {
      try {
        const { data } = await axios.get(BTL_API_ACCOUNT_URL);
        state.accounts = sortBy(data, ['name']);
      } catch (error) {
        console.error('ERROR', error.response);
      }
    };
    const getTruncks = async () => {
      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/trunks`);
        return response.data;
      } catch (error) {
        return [];
      }
    };

    const loadAccountTrunks = (account, allTrunks) => {
      // const { account_id: accountId } = account;

      // return axios.get(`${BTL_API_ACCOUNT_URL}/${accountId}/trunks`)
      // .then(response => {
      const trunks = allTrunks.filter((item) => item.AccountId === account.account_id).map((trunk) => {
        trunk.accountName = account.name;
        trunk.accountId = account.account_id;
        trunk.detailsRoute = `/trunk/${trunk.TrunkID}`;
        trunk.expDate = trunk.Expires ? moment(trunk.Expires).tz(state.timeZone).format(long_date_time_format) : '';
        return trunk;
      });

      state.trunks = state.trunks.concat(trunks);
      // })
      // .catch( (error) => {
      //   console.error("ERROR", error.response);
      // });
    };

    const filterTrunks = () => {
      if (state.accountFilter.length === 0) {
        state.filteredTrunks = state.trunks;
      } else {
        state.filteredTrunks = state.trunks.filter((trunk) => state.accountFilter.includes(trunk.accountId));
      }
    };

    const getTrunkCallCounts = async () => {
      // /api/v4/tenant/<tenant_id>/trunks

      state.trunksInView.map((trunkId) => {
        const trunk = state.trunks.find((t) => t.TrunkID === trunkId);

        if (!trunk) {
          Promise.resolve();
        }

        return axios.get(`${BTL_API_ACCOUNT_URL}/${trunk.accountId}/trunks/${trunkId}/callcount`)
          .then((response) => {
            const callCounts = response.data.count;
            if (response.data.serviceStatus !== 'OK') {
              state.callCountError = true;
              set(trunk, 'allTotal', null);
              set(trunk, 'allIn', null);
              set(trunk, 'allOut', null);
            } else {
              set(trunk, 'allTotal', callCounts.allTotal ? Number(callCounts.allTotal) : 0);
              set(trunk, 'allIn', callCounts.allIn ? Number(callCounts.allIn) : 0);
              set(trunk, 'allOut', callCounts.allOut ? Number(callCounts.allOut) : 0);
            }

            if (response.data.nonAuthoritativeNetxGateways.length > 0) {
              state.callCountWarning = true;
            }
          })
          .catch((error) => {
            console.error(`Failed to fetch trunk ${trunk.TrunkID} callcount`, error);
          });
      });
    };

    const startCallCountInterval = () => {
      if (state.callCountInterval) {
        return;
      }
      setTimeout(state.getTrunkCallCounts, 350);
      state.callCountInterval = setInterval(() => {
        getTrunkCallCounts();
      }, state.callCountIntervalPeriod);
    };

    const loadData = async () => {
      state.loading = true;
      state.trunks = [];
      state.accounts = [];
      try {
        await loadAccounts();
        const trunks = await getTruncks();
        //  await Promise.all(
        state.accounts.map((account) => loadAccountTrunks(account, trunks));
        // );
        if (state.callCountsPermitted) {
          startCallCountInterval();
        }
        filterTrunks();
      } catch (err) {
        console.error('Failed To Load Trunks', err);
      }
      state.loading = false;
    };

    const viewTrunk = (trunk) => {
      store.dispatch('updateAccountId', trunk.AccountId);
      router.push(trunk.detailsRoute);
    };

    const toggleTrunkVisible = (trunkId, isVisible) => {
      if (isVisible) {
        if (!state.trunksInView.includes(trunkId)) {
          state.trunksInView.push(trunkId);
        }
      } else {
        state.trunksInView = without(state.trunksInView, trunkId);
      }
    };

    watch(() => state.accountFilter, filterTrunks);

    saveQueryParams();
    onMounted(async () => {
      state.callCountsPermitted = permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/trunks/<trunk_id>/callcount', 'GET');
      if (state.callCountsPermitted) {
        state.headers.push({ text: 'Current Calls', value: 'allTotal', sortable: false });
      }

      await loadData();
      processQueryParams();
    });

    onBeforeUnmount(() => {
      clearInterval(state.callCountInterval);
    });

    return {
      ...toRefs(state),
      loadData,
      viewTrunk,
      toggleTrunkVisible,
      handleTableOptionsUpdate,
      tableOptions,
      permittedFunction,
    };
  },
};
</script>
