<template>
  <div>
    <page-toolbar title="Send SMS" />
    <v-container>
      <v-form ref="form">
        <v-select
          v-model="userId"
          :items="smsUsersList"
          :loading="loadingUsers"
          label="User"
          :rules="generalRules"
          prepend-icon="person"
        />
        <v-select
          v-model="smsToSend.From"
          :items="accountNumbers"
          label="From"
          :rules="generalRules"
          item-value="SourceNumber"
          item-text="SourceNumber"
          prepend-icon="phone"
        />
        <v-text-field
          v-model="smsToSend.To"
          label="To"
          :rules="generalRules"
          prepend-icon="phone"
        />
        <v-textarea
          v-model="smsToSend.Message"
          :rules="generalRules"
          counter
          prepend-icon="sms"
        />
        <div class="mt-3">
          <v-btn
            primary
            color="primary"
            dark
            :loading="sending"
            @click="sendSms"
          >
            Send
          </v-btn>
          <v-btn
            class="ml-3"
            text
            color="grey"
            @click="reset"
          >
            Reset
          </v-btn>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import axios from 'axios';

import { useStore } from '@/utils/vue';
import PageToolbar from '@/components/PageToolbar';

import { bizvu_user_url, BTL_API_ACCOUNT_URL } from '@/config';
import { find } from 'lodash';

const store = useStore();

const generalRules = [
  (v) => !!v || 'Field is required',
];

const emit = defineEmits(['snack']);

const selectAccountId = computed(() => store.getters.selectedAccountId);

const accountNumbers = ref([]);
const form = ref(false);
const userId = ref(null);
const smsToSend = ref({ From: null, To: null, Message: null });

const sending = ref(false);

const loadAccountNumbers = async () => {
  try {
    const { data } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/numbers`);
    accountNumbers.value = data;
    smsToSend.value.From = data[0]?.SourceNumber;
  } catch (err) {
    console.error('ERROR', err.response);
  }
};

const loadingSmsUsers = ref(false);
const smsUsers = ref([]);

const loadSmsUsers = async () => {
  try {
    loadingSmsUsers.value = true;
    const { data } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/users`);
    smsUsers.value = data;
  } catch (err) {
    console.error('ERROR', err.response);
  } finally {
    loadingSmsUsers.value = false;
  }
};

const loadingUsers = ref(false);
const users = ref([]);

const loadUsers = async () => {
  loadingUsers.value = true;
  try {
    const { data } = await axios.get(`${bizvu_user_url}/account/${selectAccountId.value}/user`);
    users.value = data;
  } catch (error) {
    console.error('ERROR', error.response);
  } finally {
    loadingUsers.value = false;
  }
};

const smsUsersList = computed(() => smsUsers.value.map((u) => ({
  text: find(users.value, { user_id: u.UserId })?.fullname,
  value: u.UserId,
})));

const load = async () => {
  await Promise.all([
    loadUsers(),
    loadSmsUsers(),
    loadAccountNumbers(),
  ]);
};

const reset = () => {
  smsToSend.value = { From: null, To: null, Message: null };
  form.value.reset();
};

const sendSms = async () => {
  if (!form.value.validate()) return;
  sending.value = true;
  try {
    await axios.post(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/users/${userId.value}/messages`, [smsToSend.value]);
    emit('snack', 'SMS Sent');
    reset();
  } catch (err) {
    console.error('ERROR', err.response);
  } finally {
    sending.value = false;
  }
};

watch(selectAccountId, load);
onMounted(load);
</script>
