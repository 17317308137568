import { getCurrentInstance } from 'vue';

// HELPER FUNCTIONS BEFORE Vue3 Is IMPLED

export function useRoute() {
  const { proxy } = getCurrentInstance();
  return proxy.$route;
}

export function useRouter() {
  const { proxy } = getCurrentInstance();
  return proxy.$router;
}

export function useStore() {
  const { proxy } = getCurrentInstance();
  return proxy.$store;
}
