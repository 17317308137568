<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
    @resizing="onResize"
  >
    <v-layout
      ref="container"
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
          style="margin-top: -5px;"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            Longest Calls
          </v-toolbar-title>
          <v-spacer />
          <v-select
            v-model="period"
            dense
            required
            :items="timePeriods"
            style="width: 100px;"
            class="body-2 widget__select"
          />
          <v-spacer />
          <v-btn
            icon
            @click="loadData"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <table-download
            :table-data="records"
            :headers="columns"
            report-name="Longest Calls"
          />
        </v-toolbar>
      </v-container>
      <v-container style="background-color:#FFF;">
        <queryTable
          :records="records"
          :display-headers="columns"
          sort-by="duration"
          :sort-desc="true"
          :table-height="tableHeight"
          :loading="loading"
          :hide-selectors="true"
        />
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import ResizeContainer from '@/components/widgets/layout/ResizeContainer';
import queryTable from '@/components/query/components/queryTable/queryTable';
import tableDownload from '../download/tableDownload';

export default {
  name: 'Longestcall',
  components: {
    queryTable,
    'resize-container': ResizeContainer,
    tableDownload,
  },
  props: {
    id: String,
    layoutProps: Object,
    data: {
      type: Object,
      default() {
        return {
          period: '30DAYS',
        };
      },
    },
  },
  data() {
    return {
      period: this.data.period,
      loading: false,
      records: [],
      sort: { on: 'duration', direction: 'desc', type: 'string' },
      columns: [
        { text: 'Source', value: 'source_e164' },
        { text: 'Destination', value: 'destination_e164' },
        { text: 'Duration', value: 'duration' },
      ],
      timePeriods: [
        { text: 'Today', value: 'TODAY' },
        { text: 'last 10 days', value: '10DAYS' },
        { text: 'last 30 days', value: '30DAYS' },
      ],
      tableHeight: 0,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'authentication',
      'timeZone',
    ]),
  },
  watch: {
    period() {
      this.loadData();
      this.$emit('saveWidgetData', this.id, { period: this.period });
    },
    authentication: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.tableHeight = this.layoutProps.h - 100;
  },
  methods: {
    loadData() {
      let startDate = moment().tz(this.timeZone).startOf('day');
      const endDate = moment().tz(this.timeZone).endOf('day').toISOString();
      if (this.period === 'TODAY') {
        startDate = startDate.toISOString();
      } else if (this.period === '10DAYS') {
        startDate = startDate.subtract(10, 'days').toISOString();
      } else if (this.period === '30DAYS') {
        startDate = startDate.subtract(30, 'days').toISOString();
      }

      this.loading = true;
      axios.get(`${config.bizvu_analytics_url}/account/${this.selectedAccountId}/longestcalls`, {
        params: {
          startdate: startDate,
          enddate: endDate,
        },
      })
        .then((response) => {
          this.records = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    onResize() {
      this.tableHeight = this.$refs.container.clientHeight - 100;
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
  },
};
</script>
