<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Sites
      </v-toolbar-title>
      <v-btn
        v-if="permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/sites', 'POST')"
        text
        color="blue"
        @click="$router.push('/newsite')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create Site
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadSites"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <tableDownload
        color="primary"
        :table-data="sites"
        report-name="Sites"
      />
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-layout class="mt-1 px-2">
        <v-flex>
          <v-data-table
            :headers="headers"
            :options="tableOptions"
            :items="sites"
            :loading="loading"
            :search="$store.getters.searchQuery"
            @update:options="handleTableOptionsUpdate"
          >
            <template #item="props">
              <tr :class="{ 'blue lighten-5': props.item.Default }">
                <td>
                  <router-link
                    v-if="permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/sites/<site_id>', 'GET')"
                    :to="props.item.detailsRoute"
                  >
                    {{ props.item.SiteName }}
                  </router-link>
                  <p v-else>
                    {{ props.item.SiteName }}
                  </p>
                </td>
                <td>{{ props.item.SiteType }}</td>
                <td>
                  <v-icon
                    v-if="props.item.Default"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
                <td>
                  <v-icon
                    v-if="props.item.InDRMode"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
                <td>{{ props.item.City }}</td>
                <td>{{ props.item.PostCode }}</td>
                <td>{{ props.item.MainNumber }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import {
  reactive, computed, watch, toRefs, onMounted,
} from 'vue';

import config from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import { useStore } from '@/utils/vue';
import tableDownload from '../../components/download/tableDownload';
import usePagination from '../../composables/tablePaginationViaQueryString';

export default {
  name: 'Sites',
  components: {
    tableDownload,
  },
  setup(props, context) {
    const store = useStore();
    // composables
    // for some reason pagination on appliances and sites doesn't seem to be working, looks like a vuetify issue but we're keeping the code in hope they fix it in future releases
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 15, sortBy: 'SiteName' });

    const state = reactive({
      selectedAccountId: computed(() => store.getters.selectedAccountId),
      headers: [
        { text: 'Name', value: 'SiteName' },
        { text: 'Type', value: 'SiteType' },
        { text: 'Default', value: 'Default' },
        { text: 'In DR mode', value: 'InDRMode' },
        { text: 'City', value: 'City' },
        { text: 'Postcode', value: 'PostCode' },
        { text: 'Main Number', value: 'MainNumber' },
      ],
      loading: false,
      sites: [],
    });

    const loadSites = async () => {
      state.loading = true;
      try {
        const { data } = await axios.get(`${config.bizvu_site_url}/account/${state.selectedAccountId}/site`);
        state.sites = data;
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        state.loading = false;
      }
    };

    watch(() => state.selectedAccountId, loadSites);

    saveQueryParams();
    onMounted(async () => {
      await loadSites();
      processQueryParams();
    });

    return {
      ...toRefs(state),
      loadSites,
      tableOptions,
      handleTableOptionsUpdate,
      permittedFunction,
    };
  },
};
</script>

<style scoped>
</style>
