<template>
  <div
    v-if="grid"
    class="grid-container"
  >
    <v-row>
      <v-col
        class="pa-5 mt-3"
        md="9"
      >
        <v-btn-toggle
          v-if="intervals"
          v-model="interval"
          color="deep-purple accent-3"
          @change="renderChart(grid)"
        >
          <template v-for="(item, key) in intervals">
            <v-btn
              :key="key"
              :value="key"
              small
            >
              {{ item.text }}
            </v-btn>
          </template>
        </v-btn-toggle>
      </v-col>
      <v-col>
        <v-select
          v-model="field"
          :items="fields"
          label="Group By"
          item-text="text"
          item-value="value"
          @change="renderChart(grid)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xs="6"
        style="width: 600px"
      >
        <div
          class="chart-container"
          :style="style"
        >
          <bar-chart
            v-if="id && chartData && style && !loading && grid.type == 'bar'"
            :key="id"
            :chart-data="chartData"
            :options="chartOptions"
            class="bar-chart-container"
          />
          <line-chart
            v-if="id && chartData && style && !loading && grid.type == 'line'"
            :key="id"
            :chart-data="chartData"
            :options="chartOptions"
            class="bar-chart-container"
          />
          <doughnut-chart
            v-if="id && chartData && style && !loading && grid.type == 'doughnut'"
            :key="id"
            :chart-data="chartData"
            :options="chartOptions"
            class="bar-chart-container"
          />
          <div
            v-if="loading"
            class="mx-auto text-center loading-container"
          >
            <v-progress-circular
              class="mx-auto"
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import {
  getData, getPieData, intervals, fields,
  build,
} from '@/utils/analytics/query-builder';
import {
  bizvu_analytics_url,
  analytics_query_size,
} from '@/config.js';
import BarChart from './BarChart';
import LineChart from './LineChart';
import DoughnutChart from './DoughnutChart';

export default {
  name: 'CharContainerComponent',
  components: {
    BarChart,
    LineChart,
    DoughnutChart,
  },
  props: {
    queryOptions: Object,
    gridOptions: Object,
    grid: Object,
  },
  data() {
    return {
      id: null,
      chartData: null,
      style: {},
      chartOptions: {
      },
      labelField: 'call_direction.keyword',
      yField: 'call_datetime_iso',
      intervals,
      interval: null,
      field: null,
      fields,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['selectedAccountId', 'authentication', 'timeZone']),
  },
  watch: {
    eventLog() {
      const { eventsDiv } = this.$refs;
      eventsDiv.scrollTop = eventsDiv.scrollHeight;
    },
  },

  mounted() {
    this.interval = this.grid.interval;
    this.field = this.grid.labelField;
    this.renderChart(this.grid);
  },
  updated() {
  },
  methods: {
    renderChart(grid) {
      const localGrid = { ...grid };
      if (this.field) {
        localGrid.labelField = this.field;
      }
      if (this.interval) {
        localGrid.interval = this.interval;
      }
      this.setStyle();
      this.executeQuery(localGrid);
    },
    setStyle() {
      this.style = {
        width: `${this.$parent.$el.offsetWidth}-80px`,
        height: '300px',
      };
    },
    setId(grid) {
      this.id = `i${this.grid.i}f${grid.labelField}iv${grid.interval}`;
    },
    async executeQuery(grid) {
      const query = build(grid);
      query.from = 0;
      query.size = analytics_query_size;
      const params = {
        userquery: JSON.stringify(query),
        userId: this.authentication.userId,
        accountId: this.authentication.accountId,
        reportType: 'querybuilder',
      };
      this.loading = true;
      try {
        const response = await axios.get(
          `${bizvu_analytics_url}/account/${this.selectedAccountId}/userquery`,
          {
            params,
          },
        );
        const data = this.grid.type === 'doughnut' ? getPieData(grid, response.data, 'label') : getData(grid, response.data, 'label', 'y');
        this.chartData = { ...data };
        this.loading = false;
        this.setId(grid);
      } catch (error) {
        this.loading = false;
        this.setId(grid);
        // console.error("failed to execute analytics query", error);
      }
    },
  },
};
</script>

<style scoped>
</style>
