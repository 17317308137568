<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Trunks
      </v-toolbar-title>
      <v-btn
        v-if="permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/trunks', 'POST')"
        text
        color="blue"
        @click="$router.push('/newtrunk')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create Trunk
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadTrunks"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <tableDownload
        :table-data="trunks"
        color="primary"
        report-name="Trunks"
      />
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-alert
        :value="callCountWarning"
        type="warning"
        dismissible
      >
        Call Counts might not be accurate
      </v-alert>

      <v-alert
        :value="callCountError"
        type="error"
      >
        Call Counts system in error and cant be relied upon
      </v-alert>
      <v-layout class="mt-1 px-2">
        <v-flex>
          <v-data-table
            :headers="headers"
            :options="tableOptions"
            :items="trunks"
            :loading="loading"
            :search="$store.getters.searchQuery"
            @update:options="handleTableOptionsUpdate"
          >
            <template #item="props">
              <tr>
                <td>
                  <router-link
                    v-if="permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/trunks/<trunkid>', 'GET')"
                    v-intersect="(entries, observer, isIntersecting) => { toggleTrunkVisible(props.item.TrunkID, isIntersecting) }"
                    :to="`${props.item.detailsRoute}`"
                  >
                    {{ props.item.Description }}
                  </router-link>
                  <p
                    v-else
                    v-intersect="(entries, observer, isIntersecting) => { toggleTrunkVisible(props.item.TrunkID, isIntersecting) }"
                  >
                    {{ props.item.Description }}
                  </p>
                </td>
                <td>
                  {{ props.item.expDate }}
                </td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.Live"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.Failover"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
                <td class="text-center">
                  <v-icon
                    v-if="props.item.Active"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
                <td
                  v-if="callCountsPermitted"
                  class="text-right"
                >
                  <call-counts
                    :all-total="props.item.allTotal"
                    :all-in="props.item.allIn"
                    :all-out="props.item.allOut"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import moment from 'moment';
import { without } from 'lodash';

import {
  reactive, computed, watch, onMounted, onBeforeUnmount, toRefs, set, nextTick,
} from 'vue';
import { BTL_API_ACCOUNT_URL, BTL_API_TENANT_URL, long_date_time_format } from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import { useStore } from '@/utils/vue';
import usePagination from '../../composables/tablePaginationViaQueryString';

import tableDownload from '../../components/download/tableDownload';
import CallCounts from '../../components/CallCounts';

export default {
  name: 'Trunks',
  components: {
    tableDownload,
    CallCounts,
  },
  setup(props, context) {
    const store = useStore();
    // composables
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 10, sortBy: 'Description' });

    const state = reactive({

      selectedAccountId: computed(() => store.getters.selectedAccountId),
      timeZone: computed(() => store.getters.timeZone),

      headers: [
        { text: 'Name', value: 'Description' },
        { text: 'Expires', value: 'expDate' },
        { text: 'Connected', value: 'Live', align: 'center' },
        { text: 'Failover', value: 'Failover', align: 'center' },
        { text: 'Active', value: 'Active', align: 'center' },
      ],
      trunks: [],
      trunksInView: [],
      callCountIntervalPeriod: 5000,
      callCountInterval: null,
      loading: false,
      callCountWarning: false,
      callCountError: false,
      callCountsPermitted: false,
    });
    watch(() => tableOptions.page, () => {
      state.trunksInView = state.trunks
        .slice(tableOptions.itemsPerPage * (tableOptions.page - 1), tableOptions.itemsPerPage * tableOptions.page)
        .map(trunk => trunk.TrunkID);
      getTrunkCallCounts();
    });

    const getTrunkCallCounts = async () => {
  if (state.trunksInView.length === 0) {
    return; // No trunks to query
  }

  try {
    const url = `${BTL_API_ACCOUNT_URL}/${state.selectedAccountId}/trunk_list/${encodeURIComponent(JSON.stringify(state.trunksInView))}/callcount`;
    const response = await axios.get(url);
    const callCountsData = response.data;

    if (response.data.serviceStatus !== 'OK') {
      state.callCountError = true;
      state.trunksInView.forEach((trunkId) => {
        const trunk = state.trunks.find((t) => t.TrunkID === trunkId);
        if (trunk) {
          // Set default values if call count data is not available
          set(trunk, 'allTotal', 0);
          set(trunk, 'allIn', 0);
          set(trunk, 'allOut', 0);
        }
      });
    } else {
      // Map the call counts data by ID for quick lookup
      const callCountsMap = new Map();
      callCountsData.count.forEach((entry) => {
        callCountsMap.set(entry.id, {
          allTotal: entry.allTotal ? Number(entry.allTotal) : 0,
          allIn: entry.allIn ? Number(entry.allIn) : 0,
          allOut: entry.allOut ? Number(entry.allOut) : 0
        });
      });

      state.trunksInView.forEach((trunkId) => {
        const trunk = state.trunks.find((t) => t.TrunkID === trunkId);
        if (trunk) {
          const counts = callCountsMap.get(trunkId) || {};
          set(trunk, 'allTotal', counts.allTotal ?? 0);
          set(trunk, 'allIn', counts.allIn ?? 0);
          set(trunk, 'allOut', counts.allOut ?? 0);
        }
      });

      if (response.data.nonAuthoritativeNetxGateways.length > 0) {
        state.callCountWarning = true;
      }
    }
  } catch (error) {
    console.error('Failed to fetch trunk call counts:', error);
    state.callCountError = true;
    state.trunksInView.forEach((trunkId) => {
      const trunk = state.trunks.find((t) => t.TrunkID === trunkId);
      if (trunk) {
        // Set default values on error
        set(trunk, 'allTotal', 0);
        set(trunk, 'allIn', 0);
        set(trunk, 'allOut', 0);
      }
    });
  }
};

    const startCallCountInterval = () => {
      if (state.callCountInterval) {
        return;
      }
      // Call function after initial rendering
      setTimeout(getTrunkCallCounts, 350);
      state.callCountInterval = setInterval(() => {
        getTrunkCallCounts();
      }, state.callCountIntervalPeriod);
    };
    const loadTrunks = async () => {
      state.loading = true;

      try {
        const { data } = await axios.get(`${BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/trunks`);
        state.trunks = data;
        state.trunks.forEach((row) => {
          row.detailsRoute = `/trunk/${row.TrunkID}`;
          row.expDate = row.Expires ? moment(row.Expires).tz(state.timeZone).format(long_date_time_format) : '';
        });

        if (state.callCountsPermitted) {
          startCallCountInterval();
        }
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        state.loading = false;
      }
    };

    const loadData = async () => {
      state.loading = true;
      await loadTrunks();

      nextTick(async () => {
        if (state.callCountsPermitted) {
          await getTrunkCallCounts();
        }
      });
    };

    const toggleTrunkVisible = (trunkId, isVisible) => {
      if (isVisible) {
        if (!state.trunksInView.includes(trunkId)) {
          state.trunksInView.push(trunkId);
        }
      } else {
        state.trunksInView = without(state.trunksInView, trunkId);
      }
    };

    watch(() => state.selectedAccountId, () => {
      clearInterval(state.callCountInterval);
      state.callCountInterval = null;
      state.trunksInView = [];
      loadData();
    });

    saveQueryParams();
    onMounted(async () => {
      state.callCountsPermitted = permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/trunks/<trunk_id>/callcount', 'GET');
      if (state.callCountsPermitted) {
        state.headers.push({
          text: 'Current Calls', value: 'allTotal', align: 'right', sortable: false,
        });
      }

      await loadData();
      processQueryParams();
    });

    onBeforeUnmount(() => {
      clearInterval(state.callCountInterval);
    });

    return {
      ...toRefs(state),
      loadTrunks,
      toggleTrunkVisible,
      handleTableOptionsUpdate,
      tableOptions,
      permittedFunction,
    };
  },
};
</script>

<style scoped>
</style>
