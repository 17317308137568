<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-spacer class="pt-8" />
        <v-card-text>
          <label class="subtitle-1">{{ item.text }} (seconds)</label>
          <v-container>
            <v-row no-gutters>
              <v-col>
                <div class="pa-2">
                  <v-text-field
                    v-model="duration[0]"
                    hide-details
                    single-line
                    type="number"
                  />
                </div>
              </v-col>
              <v-col cols="8">
                <div class="mt-6">
                  <v-range-slider
                    v-model="duration"
                    :max="max"
                    :min="min"
                  />
                </div>
              </v-col>
              <v-col>
                <div class="pa-2">
                  <v-text-field
                    v-model="duration[1]"
                    hide-details
                    single-line
                    type="number"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'QueryBuilderAnswerTime',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      duration: [0, 30],
      min: 0,
      max: 30,
    };
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.duration = [...this.item.defaultValue];
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },

    onSubmit() {
      const item = { ...this.item };
      item.type = 'answerTime';
      const min = this.duration[0];
      const max = this.duration[1];
      item.defaultValue = [...this.duration];
      const query = {
        query: {
          bool: {
            filter: [
              {
                range: {
                  // not currently exposed as searchable field
                  answersec: {
                    gte: min,
                    lte: max,
                  },
                },
              },
            ],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        text: `${min} - ${max}`,
      });
      this.dialog = false;
      this.number = '';
    },
  },
};
</script>
