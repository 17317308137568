<template>
  <div class="lookup">
    <v-tooltip
      bottom
    >
      <template #activator="{on}">
        <v-btn
          :disabled="disabled"
          icon
          @click="lookupOpen = true"
          v-on="on"
        >
          <v-icon size="16">
            open_in_new
          </v-icon>
        </v-btn>
      </template>
      <span>{{ title }} Lookup</span>
    </v-tooltip>

    <v-dialog
      v-model="lookupOpen"
    >
      <v-card
        style="display: flex;
        flex-direction: column;"
      >
        <v-card-title>
          <span>{{ title }}</span>
          <v-spacer />
        </v-card-title>
        <slot />
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="error"
            @click="lookupOpen = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'TableLookup',
  props: {
    disabled: Boolean,
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lookupOpen: false,
    };
  },
};
</script>

<style scoped>
  .lookup {
    display: inline-block;
  }
</style>
