<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create Group
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs5>
            <v-text-field
              v-model="group.name"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex
            xs12
            sm10
          >
            <v-expansion-panels
              focusable
              class="my-3"
            >
              <Roles
                :accounts="accounts"
                :selected-roles="selectedRoles"
                :user="user"
                @selectedRolesChanged="updateSelectedRoles"
              />
              <Functions
                :accounts="accounts"
                :user="user"
                @selectedFunctionsChanged="updateSelectedFunctions"
              />
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="subtitle-1">
                    <v-icon class="mr-3">
                      build
                    </v-icon>Users
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card class="px-5 mb-3">
                    <v-data-table
                      v-model="selectedUsers"
                      :headers="usersHeaders"
                      :items="users"
                      item-key="user_id"
                      show-select
                      sort-by="fullname"
                      :search="$store.getters.searchQuery"
                      :loading="usersLoading"
                      @toggle-select-all="toggleAllUsers"
                    >
                      <template #item.data-table-select="{ isSelected, select }">
                        <v-simple-checkbox
                          color="primary"
                          :value="isSelected"
                          @input="select($event)"
                        />
                      </template>
                      <template #item.email="props">
                        <td class="text-left">
                          {{ props.item.emails[0].email }}
                        </td>
                      </template>
                      <template #item.active="props">
                        <td class="text-center">
                          <v-icon
                            v-if="props.item.active"
                            color="primary"
                          >
                            toggle_on
                          </v-icon>
                          <v-icon
                            v-else
                            color="grey"
                          >
                            toggle_off
                          </v-icon>
                        </td>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-layout>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createGroup"
          >
            Save
          </v-btn>
          <v-btn
            class="ml-2"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';
import Roles from '@/components/user/RolesPanel';
import Functions from '@/components/user/FunctionsPanel';

export default {
  name: 'Newgroup',
  components: {
    Roles,
    Functions,
  },
  data() {
    return {
      user: {},
      accounts: [],
      users: [],
      selectedRoles: [],
      selectedFunctions: [],
      selectedUsers: [],
      valid: false,
      group: {},
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      usersHeaders: [
        { text: 'Name', value: 'fullname' },
        { text: 'Email', value: 'email' },
        { text: 'Active', value: 'active' },
      ],
      usersLoading: false,
    };
  },
  computed: {
    isActive(active) {
      return active ? 'check_circle' : '';
    },
    filteredUsers() {
      // THIS MAY BREAK IF VUETIFY CHANGES DATATABLE INTERNAL STRUCTURE!
      return this.$refs.users.$children[0].filteredItems;
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  created() {
    this.loadAccounts();
    this.loadUsers();
  },
  methods: {
    changeSort(column, pagination) {
      if (pagination.sortBy === column) {
        pagination.descending = !pagination.descending;
      } else {
        pagination.sortBy = column;
        pagination.descending = false;
      }
    },
    getEntityTypes() {
      return [
        { name: 'Tenant', key: 'tenant' },
        { name: 'Account', key: 'account' },
      ];
    },
    getEntityIds(type) {
      if (type === 'tenant') {
        return ['btlcloud', 'abc'];
      } if (type === 'account') {
        return this.accounts;
      }
      return [];
    },
    loadAccounts() {
      axios.get(config.BTL_API_ACCOUNT_URL)
        .then((response) => {
          this.accounts = response.data;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },

    loadUsers() {
      this.usersLoading = true;
      axios.get(`${config.bizvu_user_url}/account/${this.selectedAccountId}/user`)
        .then((response) => {
          this.users = response.data;
          this.usersLoading = false;
        })
        .catch((error) => {
          this.usersLoading = false;
          console.error('ERROR', error);
        });
    },
    updateSelectedRoles(updatedRoles) {
      this.selectedRoles = updatedRoles.slice();
      this.user.updateEntitlements = true;
    },
    updateSelectedFunctions(functions) {
      this.selectedFunctions = functions.slice();
      this.user.updateEntitlements = true;
    },
    createGroup() {
      this.group.roles = this.selectedRoles;
      this.group.functions = this.selectedFunctions;
      this.group.users = this.selectedUsers.map((user) => user.user_id);
      axios.post(`${config.bizvu_user_url}/account/${this.selectedAccountId}/group`, this.group)
        .then(() => {
          this.$emit('snack', 'Group successfully created!');
          this.$router.push('/groups');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    toggleAllUsers(selectAllEvent) {
      if (selectAllEvent.value) {
        this.filteredUsers.forEach((func) => {
          const index = this.selectedUsers.indexOf(func);
          if (index === -1) {
            this.selectedUsers.push(func);
          }
        });
      } else {
        this.filteredUsers.forEach((func) => {
          const index = this.selectedUsers.indexOf(func);
          if (index !== -1) {
            this.$delete(this.selectedUsers, index);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
