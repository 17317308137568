<template>
  <v-container>
    <node-header
      icon="call_end"
      icon-color="red"
      title="Hang Up"
      :detail="cause ? cause.description : ''"
    />
    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="primary"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              edit
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Configure hang up</v-subheader>
          <v-list-item>
            <v-select
              v-model="cause"
              label="Specify Hang Up Cause"
              return-object
              required
              :items="causes"
              item-text="description"
            />
          </v-list-item>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="handleCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-spacer />
      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="menu_delete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleDelete"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>

import NodeHeader from './partials/Header';

export default {
  components: {
    NodeHeader,
  },
  props: {
    canedit: Boolean,
    node: Object,
  },
  data: () => ({
    menu: false,
    menu_delete: false,
    cause: null,
    causes: [
      {
        value: 'NORMAL_TEMPORARY_FAILURE',
        description: 'Normal Temporary Failure',
      },
      {
        value: 'USER_BUSY',
        description: 'Busy',
      },
    ],
  }),
  created() {
    this.cause = this.causes.find((cause) => cause.value === this.node.parameters.cause);
  },
  methods: {
    handleDelete() {
      this.$emit('deletenode');
    },
    handleSave() {
      this.menu = false;
      this.node.parameters.cause = this.cause.value;
    },
    handleCancel() {
      this.menu = false;
      this.cause = this.causes.find((cause) => cause.value === this.node.parameters.cause);
    },
  },
};
</script>
