<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create site
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs8>
            <v-text-field
              ref="SiteName"
              v-model="site.SiteName"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs8>
            <v-switch
              v-model="site.Default"
              label="Default Site"
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs4>
            <TimeZonePicker
                    :init-zone="timeZone"
                    @update="handleTimeZoneUpdate"
                />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs4>
            <v-combobox
              v-model="site.SiteType"
              flat
              :items="siteTypes"
              label="Site type"
            />
          </v-flex>
        </v-layout>
        <v-expansion-panels
          focusable
          class="my-3 mx-2"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  location_on
                </v-icon>Address
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs2>
                    <v-text-field
                      v-model="pcLookup"
                      label="Postcode"
                      :counter="10"
                    />
                  </v-flex>
                  <v-flex
                    xs2
                    class="ml-3"
                  >
                    <v-btn
                      class="mt-3"
                      color="primary"
                      text
                      @click="findAddresses"
                    >
                      Find Address
                    </v-btn>
                  </v-flex>
                  <v-flex
                    v-if="addresses && addresses.addresses.length>0"
                    xs8
                    class="ml-3"
                  >
                    <v-select
                      v-model="selectedAddress"
                      flat
                      :items="addresses.addresses"
                      item-text="addressString"
                      item-value="key"
                      label="Select Street Address"
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12>
                    <v-text-field
                      v-model="site.Address1"
                      label="Street address 1"
                      :rules="address1Rules"
                      :counter="120"
                      required
                    />
                    <v-text-field
                      v-model="site.Address2"
                      label="Street address 2"
                      :rules="address2Rules"
                      :counter="120"
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs5>
                    <v-layout class="pr-2">
                      <v-text-field
                        v-model="site.City"
                        label="City"
                        :rules="cityRules"
                        :counter="60"
                        required
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout class="px-2">
                      <v-text-field
                        v-model="site.PostCode"
                        label="Postcode"
                        :rules="postcodeRules"
                        :counter="10"
                        required
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex xs5>
                    <v-layout class="pl-2">
                      <v-text-field
                        v-model="site.Country"
                        label="Country"
                        :rules="countryRules"
                        :counter="30"
                        required
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  call
                </v-icon>Contact information
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex
                    xs6
                    class="pr-2"
                  >
                    <v-text-field
                      v-model="site.MainNumber"
                      label="Main Number"
                      :rules="mainnumberRules"
                      :counter="15"
                      required
                    />
                    <v-text-field
                      v-model="site.ContactName"
                      label="Contact Name"
                      :rules="contactnameRules"
                      :counter="60"
                      required
                    />
                    <v-text-field
                      v-model="site.ContactNumber"
                      label="Contact Number"
                      :rules="contactnumberRules"
                      :counter="15"
                      required
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    class="pl-2"
                  >
                    <v-text-field
                      v-model="site.EmergencyCLI"
                      label="Emergency CLI"
                      :rules="emergencycliRules"
                      :counter="15"
                      required
                    />
                    <v-text-field
                      v-model="site.MatchingCLI"
                      label="Matching CLI"
                      :rules="matchingcliRules"
                      :counter="15"
                      required
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  calendar_today
                </v-icon>Working hours
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs12>
                    <OpeningHoursEditor
                      v-if="site.Hours"
                      v-model="site.Hours"
                      :hours="site.Hours"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  calendar_today
                </v-icon>Working hours (Bank Holiday)
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <OpeningHourBankHoliday
                  :key="bankHolidaIndex"
                  v-model="site.BankHolidays"
                  @update="onUpdateBankHolidays"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createSite"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>

        <v-dialog
          v-model="dialog"
          width="500"
        >
          <v-card>
            <v-card-title
              class="headline"
              primary-title
            >
              Confirm Default Site
            </v-card-title>
            <v-card-text>Are you sure you want to make this site the default site?</v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click="commitSite"
              >
                Yes
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import TimeZonePicker from '@/components/TimeZonePicker';
import OpeningHourBankHoliday from '@/components/openinghourseditor/OpeningHourBankHolidayEditor';
import OpeningHoursEditor from '@/components/openinghourseditor/OpeningHoursEditor';

export default {
  name: 'Newsite',
  components: {
    OpeningHoursEditor,
    OpeningHourBankHoliday,
    TimeZonePicker,
  },
  data() {
    return {
      dialog: false,
      valid: false,
      bankHolidaIndex: 0,
      addresses: null,
      pcLookup: '',
      selectedAddress: null,
      site: {
        SiteName: '',
        SiteType: '',
        TimeZone: 'Europe/London',
        InDRMode: false,
        Default: false,
        Address1: '',
        Address2: '',
        City: '',
        PostCode: '',
        Country: '',
        MainNumber: '',
        ContactName: '',
        ContactNumber: '',
        EmergencyCLI: '',
        MatchingCLI: '',
        Hours: {
          WeekDay1: [],
          WeekDay2: [],
          WeekDay3: [],
          WeekDay4: [],
          WeekDay5: [],
          WeekDay6: [],
          WeekDay7: [],
        },
        BankHolidays: {
          year: [],
        },
      },
      siteTypes: ['Office', 'Home', 'Factory'],
      timeZone: null,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      address1Rules: [
        (v) => !!v || 'Street address 1 is required',
        (v) => (v && v.length <= 120) || 'Street Address 1 must not have more than 120 characters',
      ],
      address2Rules: [
        (v) => (v.length <= 120) || 'Street Address 2 must not have more than 120 characters',
      ],
      cityRules: [
        (v) => !!v || 'City is required',
        (v) => (v && v.length <= 60) || 'City must not have more than 60 characters',
      ],
      postcodeRules: [
        (v) => !!v || 'Post code is required',
        (v) => (v && v.length <= 10) || 'Post code must not have more than 10 characters',
      ],
      countryRules: [
        (v) => !!v || 'Country is required',
        (v) => (v && v.length <= 30) || 'Country must not have more than 30 characters',
      ],
      mainnumberRules: [
        (v) => !!v || 'Main numer is required',
        (v) => (v && v.length <= 15) || 'Main number must not have more than 15 characters',
      ],
      emergencycliRules: [
        (v) => !!v || 'Emergency CLI is required',
        (v) => (v && v.length <= 15) || 'Emergency CLI must not have more than 15 characters',
      ],
      matchingcliRules: [
        (v) => !!v || 'Matching CLI is required',
        (v) => (v && v.length <= 15) || 'Matching CLI must not have more than 15 characters',
      ],
      contactnameRules: [
        (v) => !!v || 'Contact name is required',
        (v) => (v && v.length <= 60) || 'Contact name must not have more than 60 characters',
      ],
      contactnumberRules: [
        (v) => !!v || 'Contact number is required',
        (v) => (v && v.length <= 15) || 'Contact number must not have more than 15 characters',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAddress() {
      this.site.Address1 = this.addresses.addresses[this.selectedAddress].line_1;
      this.site.Address2 = this.addresses.addresses[this.selectedAddress].line_2;
      this.site.City = this.addresses.addresses[this.selectedAddress].town_or_city;
      this.site.Country = this.addresses.addresses[this.selectedAddress].country;
      this.site.PostCode = this.addresses.postcode;

      if (this.addresses.addresses[this.selectedAddress].locality !== '') {
        this.site.Address2 = this.addresses.addresses[this.selectedAddress].locality;
      }
    },
    selectedAccountId() {
      this.$router.back();
    },
  },
  created() {
    this.loadAccountWorkingHours();
  },
  mounted() {
    this.valid = false;
    this.$refs.SiteName.focus();
  },
  methods: {
    handleTimeZoneUpdate(TimeZone) {
      this.timeZone = TimeZone;
      this.site.TimeZone = TimeZone;
    },
    validForm() {
      if (this.site.SiteName === '') {
        this.$emit('snack', 'Site Name is a required field!', true);
        return false;
      }
      if (this.site.Address1 === '') {
        this.$emit('snack', 'Street Address 1 is a required field!', true);
        return false;
      }
      if (this.site.PostCode === '') {
        this.$emit('snack', 'PostCode is a required field!', true);
        return false;
      }
      if (this.site.City === '') {
        this.$emit('snack', 'City is a required field!', true);
        return false;
      }
      if (this.site.Country === '') {
        this.$emit('snack', 'Country is a required field!', true);
        return false;
      }
      if (this.site.MainNumber === '') {
        this.$emit('snack', 'MainNumber is a required field!', true);
        return false;
      }
      if (this.site.ContactName === '') {
        this.$emit('snack', 'ContactName is a required field!', true);
        return false;
      }
      if (this.site.ContactNumber === '') {
        this.$emit('snack', 'ContactNumber is a required field!', true);
        return false;
      }
      if (this.site.EmergencyCLI === '') {
        this.$emit('snack', 'EmergencyCLI is a required field!', true);
        return false;
      }
      if (this.site.MatchingCLI === '') {
        this.$emit('snack', 'MatchingCLI is a required field!', true);
        return false;
      }

      // All good and valid
      return true;
    },
    findAddresses() {
      axios.get(`${config.getaddress_url}/${this.pcLookup}?expand=true&api-key=${config.getaddress_api_key}`)
        .then((response) => {
          this.addresses = response.data;
          this.addresses.addresses.forEach((a, i) => {
            a.addressString = `${a.formatted_address[0]}, ${a.formatted_address[3]}, ${a.formatted_address[4]}`;
            a.key = i;
          });
        })
        .catch(() => {
          this.$emit('snack', 'Invalid Postcode!');
          this.pcLookup = '';
          this.addresses = null;
        });
    },
    onUpdateBankHolidays(data) {
      this.site.BankHolidays = data;
    },
    loadAccountBankHolidays() {
      const self = this;
      axios.get(`${config.bizvu_account_url}/${this.selectedAccountId}/bankholidays`)
        .then((response) => {
          self.site.BankHolidays = response.data.Hours;
          self.bankHolidaIndex += 1;
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
    loadAccountWorkingHours() {
      const self = this;
      axios.get(`${config.bizvu_account_url}/${this.selectedAccountId}/workinghours`)
        .then((response) => {
          self.site.Hours = response.data.Hours;
          self.loadAccountBankHolidays();
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
    createSite() {
      if (!this.validForm()) {
        return;
      }

      if (this.site.Default) {
        this.dialog = true;
        return;
      }

      this.commitSite();
    },
    async commitSite() {
      try {
        await axios.post(`${config.bizvu_site_url}/account/${this.selectedAccountId}/site`, this.site);
        this.$emit('snack', 'Site created successfully');
        this.$router.back();
      } catch (error) {
        console.error('Error creating site', error.response);
        if (error.response.status === 400) {
          const fieldErrors = error.response.data.errors;
          Object.values(fieldErrors).forEach((errors) => {
            this.$emit('snack', errors[0], true);
          });
        } else {
          this.$emit('snack', 'Failed to create site', true);
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
