<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
    @resizing="onResize"
  >
    <v-layout
      ref="container"
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
          style="margin-top: -5px;"
        >
          <v-btn
            icon
            @click="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            {{ name }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="executeQuery"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <query-downloader
            :report-name="name"
            :query="query.body"
            :headers="headers.map(header => ({ text: header.text.text, value: header.value }))"
            progress-class="ml-3"
          />
        </v-toolbar>
      </v-container>
      <v-container style="background-color: #FFF;">
        <v-alert
          v-if="hitsExceedResultsLimit"
          class="pa-1 ma-0"
          type="info"
          dense
          text
        >
          Found <b>{{ totalHits }}</b> calls. Latest {{ analytics_query_size }} loaded, use download to view all data.
        </v-alert>
        <queryTable
          :display-headers="headers"
          :records="records"
          sort-by="call_datetime_iso"
          :sort-desc="true"
          :table-height="tableHeight"
          :loading="loading"
          :hide-selectors="true"
        />
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import moment from 'moment';
import { has, isNumber } from 'lodash';
import { bizvu_analytics_url, analytics_query_size } from '@/config.js';

import queryTable from '@/components/query/components/queryTable/queryTable';
import ResizeContainer from '@/components/widgets/layout/ResizeContainer';
import QueryDownloader from '../query/QueryDownloader';

export default {
  name: 'CustomQuery',
  components: {
    queryTable,
    'resize-container': ResizeContainer,
    QueryDownloader,
  },
  props: {
    id: String,
    name: String,
    layoutProps: Object,
    data: Object,
  },
  data() {
    return {
      dataLoaded: false,
      headers: [],
      query: {},
      records: [],
      totalHits: 0,
      parameters: [],
      loading: false,
      lStorageDataName: '',
      tableHeight: 0,
      analytics_query_size,
    };
  },
  computed: {
    hitsExceedResultsLimit() {
      return this.totalHits > analytics_query_size;
    },
    ...mapGetters([
      'selectedAccountId',
      'authentication',
      'timeZone',
    ]),
  },
  watch: {
    authentication: {
      handler() {
        this.dataLoaded = false;
        this.executeQuery();
      },
      deep: true,
    },
  },
  mounted() {
    this.headers = this.data.headers.filter((header) => header.value !== 'action');
    this.query = this.data.query;
    this.parameters = this.data.parameters;
    this.tableHeight = this.hitsExceedResultsLimit ? this.layoutProps.h - 132 : this.layoutProps.h - 100;
    this.processQueryParameters();
    this.executeQuery();
  },
  methods: {
    processQueryParameters() {
      if (this.parameters && this.parameters.length > 0) {
        this.parameters.forEach((parameter) => {
          const filter = this.query.body.query.bool.filter.find((f) => has(f, 'range.call_datetime_iso'));
          switch (parameter.type) {
            case 'DATE_TODAY':
              filter.range.call_datetime_iso.gte = moment().tz(this.timeZone).startOf('day').toISOString();
              filter.range.call_datetime_iso.lte = moment().tz(this.timeZone).endOf('day').toISOString();
              break;
            case 'DATE_PAST_DAYS':
              filter.range.call_datetime_iso.gte = moment().tz(this.timeZone).subtract(parameter.pastDays, 'days').startOf('day')
                .toISOString();
              filter.range.call_datetime_iso.lte = moment().tz(this.timeZone).endOf('day').toISOString();
              break;
            case 'DATE_LAST_MONTH':
              filter.range.call_datetime_iso.gte = moment().tz(this.timeZone).subtract(1, 'months').startOf('month')
                .toISOString();
              filter.range.call_datetime_iso.lte = moment().tz(this.timeZone).subtract(1, 'months').endOf('month')
                .toISOString();
              break;
            case 'DATE_CURRENT_MONTH':
              filter.range.call_datetime_iso.gte = moment().tz(this.timeZone).startOf('month').toISOString();
              filter.range.call_datetime_iso.lte = moment().tz(this.timeZone).endOf('month').toISOString();
              break;
            default:
              break;
          }
        });
      }
      if (!this.query.body.sort) {
        this.query.body.sort = [
          {
            call_datetime_iso: 'desc',
          },
        ];
      }
    },
    positionWidget() {
      this.w = this.layoutProps.w;
      this.h = this.layoutProps.h;
      this.x = this.layoutProps.x;
      this.y = this.layoutProps.y;
    },
    executeQuery() {
      const query = this.query.body;
      query.from = 0;
      query.size = analytics_query_size;
      const params = {
        userquery: JSON.stringify(this.query.body),
        userId: this.authentication.userId,
        accountId: this.authentication.accountId,
        reportType: 'widget',
      };
      this.loading = true;
      axios.get(`${bizvu_analytics_url}/account/${this.selectedAccountId}/userquery`, {
        params,
      })
        .then((response) => {
          this.loading = false;
          this.dataLoaded = true;
          this.records = [];
          this.totalHits = isNumber(response.data.hits.total) ? response.data.hits.total : response.data.hits.total.value; // ES v6 or ES v7
          response.data.hits.hits.forEach((r) => {
            r._source.biz_ring_time = r._source.duration - r._source.billsec;
            this.records.push(r._source);
          });
          this.records.sort((a, b) => b.duration - a.duration);
          this.onResize();
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error querying for calls', error);
        });
    },
    close() {
      this.$emit('deleteWidget', this.id);
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
    onResize() {
      this.tableHeight = this.hitsExceedResultsLimit ? this.$refs.container.clientHeight - 130 : this.$refs.container.clientHeight - 100;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
