<template>
  <div>
    <v-toolbar>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-3">
        Create Appliance
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs8>
            <v-text-field
              v-model="appliance.Name"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs5>
            <v-select
              v-if="sites.length>1"
              v-model="SiteId"
              flat
              :items="sites"
              item-text="SiteName"
              item-value="SiteId"
              label="Site"
            />
          </v-flex>
        </v-layout>
        <v-menu
          v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/devices', 'POST')"
          offset-y
        >
          <template #activator="{on}">
            <v-btn
              text
              color="primary"
              dark
              v-on="on"
            >
              <v-icon class="mr-2">
                add_box
              </v-icon>Create Device
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="hasSBC()"
              @click="addSBC"
            >
              <v-list-item-title>SBC</v-list-item-title>
            </v-list-item>
            <v-list-item @click="addVPN">
              <v-list-item-title>VPN</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-layout class="mt-2" />
        <v-expansion-panels
          v-if="devices.length>0"
          v-model="panel"
          multiple
          focusable
          class="my-3 mx-2"
        >
          <v-expansion-panel
            v-for="(d,i) in devices"
            :key="i"
          >
            <v-expansion-panel-header>
              <div
                v-if="d.DeviceType==='VPN'"
                class="subtitle-1"
              >
                <v-icon class="mr-3">
                  vpn_lock
                </v-icon>VPN
              </div>
              <div
                v-if="d.DeviceType==='SBC'"
                class="subtitle-1"
              >
                <v-icon class="mr-3">
                  developer_board
                </v-icon>SBC
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card
                v-if="d.DeviceType==='VPN'"
                class="px-5 mb-3"
              >
                <v-layout>
                  <v-flex
                    xs6
                    class="pr-2"
                  >
                    <v-text-field
                      v-model="d.Description"
                      label="Name"
                      :rules="nameRules"
                      :counter="60"
                      required
                    />
                    <v-text-field
                      v-model="d.LANIP"
                      label="LAN IP"
                      :rules="ipRules"
                      :counter="15"
                      required
                    />
                    <v-text-field
                      v-model="d.LanIface"
                      label="LAN Interface"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
              <v-card
                v-if="d.DeviceType==='SBC'"
                class="px-5 mb-3"
              >
                <v-layout>
                  <v-flex
                    xs6
                    class="pr-2"
                  >
                    <v-text-field
                      v-model="d.Description"
                      label="Name"
                      :rules="nameRules"
                      :counter="80"
                    />
                    <v-select
                      v-if="trunks.length>0"
                      v-model="d.TrunkId"
                      flat
                      :items="trunks"
                      item-text="Description"
                      item-value="TrunkID"
                      label="Trunk"
                    />
                    <v-select
                      v-model="d.SBCType"
                      flat
                      :items="sbcTypes"
                      :rules="sbcTypeRules"
                      label="Type"
                    />
                    <v-text-field
                      v-if="d.SBCType === 'DualLAN'"
                      v-model="d.LANIP"
                      label="External IP"
                      :counter="15"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-if="d.SBCType === 'DualLAN'"
                      v-model="d.DualLANIP"
                      label="Internal IP"
                      :counter="15"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-model="d.LIMIT_SPS"
                      label="Max Sessions Per Second"
                    />
                    <v-text-field
                      v-model="d.LIMIT_MAX_SESSIONS"
                      label="Max Concurrent Sessions"
                    />
                    <v-switch
                      v-model="d.SUPPORT_SEPARATE_DTMF_SSRC"
                      label="Support Separate DTMF SSRC"
                    />
                    <v-switch
                      v-model="d.ATMOSO"
                      label="Is Atmoso"
                    />
                    <v-switch
                      v-model="d.DISABLE_T38"
                      label="Disable T38"
                    />
                    <v-text-field
                      v-if="d.ATMOSO"
                      v-model="d.RPS_DEVICE_IP"
                      label="RPS DEVICE IP"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-if="d.ATMOSO"
                      v-model="d.RPS_GATEWAY_IP"
                      label="RPS GATEWAY IP"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-if="d.ATMOSO"
                      v-model="d.CCS_DEVICE_IP"
                      label="CCS DEVICE IP"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-if="d.ATMOSO"
                      v-model="d.CCS_GATEWAY_IP"
                      label="CCS GATEWAY IP"
                      :rules="ipRules"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createAppliance"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';

import config from '@/config.js';
import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'Newappliance',
  data() {
    return {
      valid: false,
      sites: [],
      trunks: [],
      panel: [],
      devices: [],
      SiteId: undefined,
      appliance: {
        Name: '',
      },
      siteTypes: [
        { value: 'OFFICE', text: 'Office' },
        { value: 'HOME', text: 'Home' },
      ],
      sbcTypes: [
        { value: 'Standard', text: 'Standard' },
        { value: 'DualLAN', text: 'Dual LAN' },
      ],
      timeZones: [
        'Europe/London',
        'US/Pacific',
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      sbcTypeRules: [
        (v) => !!v || 'SBC Type is required',
      ],
      ipRules: [
        (v) => (v && /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v)) || 'Not a valid IP address format',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  created() {
    this.loadSites();
    this.loadTrunks();
  },
  methods: {
    createAppliance() {
      if (!this.$refs.form.validate()) {
        this.$emit('snack', 'Please fix validation errors before continuing', true);
        return;
      }
      axios.post(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/appliances`, this.appliance)
        .then((response) => Promise.all(this.devices.map((device) => {
          device.SiteId = this.SiteId;
          device.ApplianceId = response.data.ApplianceId;

          let options = {
            LIMIT_SPS: device.LIMIT_SPS,
            LIMIT_MAX_SESSIONS: device.LIMIT_MAX_SESSIONS,
          };

          if (device.ATMOSO) {
            const {
              RPS_DEVICE_IP,
              RPS_GATEWAY_IP,
              CCS_DEVICE_IP,
              CCS_GATEWAY_IP,
            } = device;

            options = {
              ...options,
              ATMOSO: true,
              RPS_DEVICE_IP,
              RPS_GATEWAY_IP,
              CCS_DEVICE_IP,
              CCS_GATEWAY_IP,
            };
          }

          if (device.SUPPORT_SEPARATE_DTMF_SSRC) {
            options = {
              ...options,
              SUPPORT_SEPARATE_DTMF_SSRC: true,
            };
          }

          if (device.DISABLE_T38) {
            options = {
              ...options,
              DISABLE_T38: true,
            };
          }

          device.Options = JSON.stringify(options);

          delete device.SUPPORT_SEPARATE_DTMF_SSRC;
          delete device.ATMOSO;
          delete device.RPS_DEVICE_IP;
          delete device.RPS_GATEWAY_IP;
          delete device.CCS_DEVICE_IP;
          delete device.CCS_GATEWAY_IP;
          delete device.DISABLE_T38;
          delete device.LIMIT_SPS;
          delete device.LIMIT_MAX_SESSIONS;

          return axios.post(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/devices`, device);
        }))
          .then(() => {
            this.$emit('snack', 'Appliance successfully created!');
            this.$router.back();
          }))
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    addSBC() {
      const device = {
        DeviceType: 'SBC',
        Description: this.appliance.Name,
        SiteId: undefined,
        TrunkId: undefined,
        ApplianceId: undefined,
        SBCType: 'Standard',
        DualLANIP: null,
        LANIP: null,
        LIMIT_SPS: 30,
        LIMIT_MAX_SESSIONS: 1000,
      };
      this.panel.push(this.devices.length);
      this.devices.push(device);
    },
    addVPN() {
      const device = {
        DeviceType: 'VPN',
        LANIP: '',
        SiteId: undefined,
        ApplianceId: undefined,
      };
      this.panel.push(this.devices.length);
      this.devices.push(device);
    },
    loadSites() {
      const self = this;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/sites`)
        .then((response) => {
          self.sites = response.data;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    loadTrunks() {
      const self = this;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/trunks`)
        .then((response) => {
          self.trunks = sortBy(response.data, (trunk) => trunk.Description.toLowerCase());
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    hasSBC() {
      return (this.devices.filter((d) => d.DeviceType === 'SBC').length > 0);
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>
</style>
