<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Bundle ChargeBand
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/bundlechargebands/<bcbid>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-layout>
              <v-select
                v-model="bundleChargeband.BundleId"
                :disabled="!createMode"
                flat
                :items="callBundles"
                item-text="BundleName"
                item-value="BundleId"
                label="Bundle"
                :rules="rules.Bundle"
                @change="loadChargeBands"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-select
                v-model="bundleChargeband.ChargeBand"
                :disabled="!createMode || !chargeBands.length"
                flat
                :loading="loadingChargebands"
                :items="chargeBands"
                label="ChargeBand"
                :rules="rules.ChargeBand"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-layout>
          <v-flex class="pa-2">
            <v-row>
              <v-btn
                v-if="createMode && isPermitted('/bundlechargebands', 'POST')"
                primary
                color="primary"
                dark
                @click="saveBundleChargeBand"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this Bundle ChargeBand?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteBundleChargeband"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'BundleChargeband',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      loadingChargebands: false,
      bundleChargebandId: this.$route.params.bundleChargebandId,
      callBundles: [],
      chargeBands: [],
      bundleChargeband: {},
      rules: {
        Bundle: [
          (v) => (v != null && String(v).length > 0) || 'Bundle is required',
        ],
        ChargeBand: [
          (v) => (v != null && String(v).length > 0) || 'ChargeBand is required',
        ],
      },
    };
  },
  created() {
    if (this.bundleChargebandId === 'new') {
      this.createMode = true;
    } else {
      this.loadBundleChargeband();
    }
  },
  mounted() {
    this.loadBundles();
  },
  methods: {
    async loadBundles() {
      this.loading = true;

      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/callbundles`);
        this.callBundles = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve call bundles.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadChargeBands() {
      try {
        this.chargeBands = [];
        this.loadingChargebands = true;

        const { BundleType } = this.callBundles.find((cb) => cb.BundleId === this.bundleChargeband.BundleId);

        let url;

        if (BundleType === 'NATIONAL') {
          url = `${config.BTL_API_TENANT_URL}/chargebands`;
        } else if (BundleType === 'INTERNATIONAL') {
          url = `${config.BTL_API_TENANT_URL}/countries`;
        } else if (BundleType === 'NONGEO') {
          url = `${config.BTL_API_TENANT_URL}/chargebands`;
        } else {
          throw new Error(`Unsupported bundle type ${BundleType}`);
        }

        const params = {
          filters: [
            {
              name: 'AccountId',
              op: 'is_null',
            },
          ],
        };

        url += `?q=${JSON.stringify(params)}`;
        const { data: chargeBands } = await axios.get(url);

        let mappedChargeBands;
        if (BundleType === 'NATIONAL') {
          mappedChargeBands = chargeBands.map(({ Name }) => ({
            text: Name,
            value: Name,
          }));
        } else if (BundleType === 'INTERNATIONAL') {
          mappedChargeBands = chargeBands.map(({ CountryName }) => ({
            text: CountryName,
            value: CountryName,
          }));
        } else if (BundleType === 'NONGEO') {
          mappedChargeBands = chargeBands.map(({ Name }) => ({
            text: Name,
            value: Name,
          }));
        } else {
          throw new Error(`Unsupported bundle type ${BundleType}`);
        }

        this.chargeBands = sortBy(mappedChargeBands, [(cb) => cb.text.toLowerCase()]).filter(({ text, value }) => !!text && !!value);
      } catch (error) {
        console.error('Error loading ChargeBands', error);
        this.$emit('snack', 'Failed to load ChargeBands.', true);
      } finally {
        this.loadingChargebands = false;
      }
    },
    async saveBundleChargeBand() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Bundle chargeband successfully updated.';

        if (this.createMode) {
          await axios.post(`${config.BTL_API_TENANT_URL}/bundlechargebands`, this.bundleChargeband);
          snackMessage = 'Bundle chargeband successfully created.';
        } else {
          throw new Error('Cannot update bundle chargebands');
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating bundle chargeband', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} bundle chargeband.`, true);
      }
    },
    async loadBundleChargeband() {
      try {
        const { data: bundleChargeband } = await axios.get(`${config.BTL_API_TENANT_URL}/bundlechargebands/${this.bundleChargebandId}`);
        this.bundleChargeband = bundleChargeband;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve bundle chargeband.', true);
        console.error('Failed to retrieve bundle chargeband', error);
      }
    },
    async deleteBundleChargeband() {
      try {
        await axios.delete(`${config.BTL_API_TENANT_URL}/bundlechargebands/${this.bundleChargebandId}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete bundle chargeband.', true);
        console.error('Failed to delete bundle chargeband', error);
      }
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
