<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Tenant Service Charge
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/servicecharges/<servicecharge_id>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="serviceCharge.Name"
                label="Name"
                :rules="rules.Name"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="serviceCharge.Description"
                label="Description"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-select
                v-model="serviceCharge.ServiceId"
                flat
                :items="tenantServices"
                item-text="Name"
                item-value="service_id"
                label="Service"
                :rules="rules.Service"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="serviceCharge.Units"
                label="Units"
                :rules="rules.Units"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="serviceCharge.UnitCost"
                label="Unit Cost"
                :rules="rules.UnitCost"
              />
            </v-layout>
            <v-layout>
              <v-select
                v-model="serviceCharge.UnitType"
                flat
                :items="unitTypes"
                label="Unit Type"
                :rules="rules.UnitType"
              />
            </v-layout>
            <v-layout>
              <v-select
                v-model="serviceCharge.ChargeFrequency"
                flat
                :items="frequencies"
                label="Charge Frequency"
                :rules="rules.ChargeFrequency"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-layout>
          <v-flex class="pa-2">
            <v-row>
              <v-btn
                v-if="createMode ? isPermitted('/servicecharges', 'POST') : isPermitted('/servicecharges/<servicecharge_id>', 'PUT')"
                primary
                color="primary"
                dark
                @click="updateServiceCharge"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this Service Charge?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteServiceCharge"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'TenantServiceCharge',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      chargeId: this.$route.params.chargeId,
      tenantServices: [],
      serviceCharge: {},
      frequencies: [
        {
          text: 'Daily',
          value: 'DAILY',
        },
        {
          text: 'Weekly',
          value: 'WEEKLY',
        },
        {
          text: 'Monthly',
          value: 'MONTHLY',
        },
        {
          text: 'Quarterly',
          value: 'QUARTERLY',
        },
        {
          text: 'Annually',
          value: 'ANNUALLY',
        },
      ],
      unitTypes: [
        {
          text: 'Call',
          value: 'CALL',
        },
        {
          text: 'GB',
          value: 'GB',
        },
        {
          text: 'MB',
          value: 'MB',
        },
        {
          text: 'Transaction',
          value: 'TRANSACTION',
        },
        {
          text: 'Report',
          value: 'REPORT',
        },
        {
          text: 'Day',
          value: 'DAY',
        },
      ],
      rules: {
        Name: [
          (v) => (v != null && String(v).length > 0) || 'Name is required',
        ],
        Service: [
          (v) => (v != null && String(v).length > 0) || 'Service is required',
        ],
        ChargeFrequency: [
          (v) => (v != null && String(v).length > 0) || 'Charge Frequency is required',
        ],
        Units: [
          (v) => (v != null && String(v).length > 0) || 'Units are required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        UnitCost: [
          (v) => (v != null && String(v).length > 0) || 'Unit Cost is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        UnitType: [
          (v) => (v != null && String(v).length > 0) || 'Unit Type is required',
        ],
      },
    };
  },
  created() {
    if (this.chargeId === 'new') {
      this.createMode = true;
    } else {
      this.loadServiceCharge();
    }
  },
  mounted() {
    this.loadServices();
  },
  methods: {
    async loadServices() {
      this.loading = true;

      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/services`);
        this.tenantServices = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve tenant services.', true);
      } finally {
        this.loading = false;
      }
    },
    async updateServiceCharge() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Service charge successfully updated.';

        if (this.createMode) {
          await axios.post(`${config.BTL_API_TENANT_URL}/servicecharges`, this.serviceCharge);
          snackMessage = 'Service charge successfully created.';
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/servicecharges/${this.chargeId}`, this.serviceCharge);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating service charge', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} service charge.`, true);
      }
    },
    async loadServiceCharge() {
      try {
        const { data: serviceCharge } = await axios.get(`${config.BTL_API_TENANT_URL}/servicecharges/${this.chargeId}`);
        this.serviceCharge = serviceCharge;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve service charge.', true);
        console.error('Failed to retrieve service charge', error);
      }
    },
    async deleteServiceCharge() {
      try {
        await axios.delete(`${config.BTL_API_TENANT_URL}/servicecharges/${this.chargeId}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete service charge.', true);
        console.error('Failed to delete service charge', error);
      }
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
