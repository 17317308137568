<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Devices
      </v-toolbar-title>

      <v-autocomplete
        v-model="accountFilter"
        :items="accounts"
        :loading="accountsLoading"
        item-text="name"
        item-value="account_id"
        label="Account filter"
        dense
        clearable
        single-line
        style="max-width: 20rem;"
        hide-details
      />

      <v-btn
        text
        color="blue"
        icon
        @click="loadDevices"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>
      </v-btn>
      <tableDownload
        color="primary"
        :table-data="devices"
        report-name="Devices"
      />
      <v-spacer />
    </v-toolbar>

    <v-container>
      <v-card rounded>
        <v-tabs
          v-model="tab"
          background-color="primary lighten-2"
          center-active
          grow
          dark
          active-class="activeTab"
        >
          <v-tabs-slider color="green" />

          <v-tab
            key="sbcs"
            class="text-capitalize"
          >
            SBC's
          </v-tab>
          <v-tab
            key="vpns"
            class="text-capitalize"
          >
            VPN's
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            key="sbcs"
          >
            <v-data-table
              :headers="sbcHeaders"
              :options="tableOptions"
              :items="sbcs"
              :loading="devicesLoading"
              :search="$store.getters.searchQuery"
              fixed-header
              height="calc(100vh - 260px)"
              @update:options="handleTableOptionsUpdate"
            >
              <template #item.ip="{ item:device }">
                <v-chip
                  v-if="device.ip"
                  color="green"
                  outlined
                  small
                >
                  External: {{ device.ip }}
                </v-chip>
                <v-chip
                  v-if="device.DualLANIP"
                  class="ml-2"
                  color="blue"
                  outlined
                  small
                >
                  Internal: {{ device.DualLANIP }}
                </v-chip>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item
            key="vpns"
          >
            <v-data-table
              :headers="vpnHeaders"
              :options="tableOptions"
              :items="vpns"
              :loading="devicesLoading"
              :search="$store.getters.searchQuery"
              fixed-header
              height="calc(100vh - 260px)"
              @update:options="handleTableOptionsUpdate"
            >
              <template #item.ips="{ item:device }">
                <v-chip
                  v-if="device.VPNIP"
                  color="green"
                  outlined
                  small
                >
                  VPN: {{ device.VPNIP }}
                </v-chip>
                <v-chip
                  v-if="device.LANIP"
                  class="ml-2"
                  color="blue"
                  outlined
                  small
                >
                  LAN: {{ device.LANIP }}
                </v-chip>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import axios from 'axios';
import {
  reactive, toRefs, onMounted, watch, computed,
} from 'vue';
import { BTL_API_ACCOUNT_URL, BTL_API_TENANT_URL } from '@/config.js';

import permittedFunction from '@/permittedFunction.js';
import usePagination from '../../composables/tablePaginationViaQueryString';

import tableDownload from '../../components/download/tableDownload';

export default {
  name: 'TenantDevices',
  components: {
    tableDownload,
  },
  setup(props, context) {
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 15, sortBy: 'AccountName' });

    const state = reactive({
      tab: 'sbc',
      accounts: [],
      accountsLoading: false,
      accountFilter: null,

      devices: [],
      devicesLoading: false,

      sbcHeaders: [
        { text: 'Account', value: 'AccountName' },
        { text: 'Type', value: 'SBCType' },
        { text: 'Version', value: 'Version' },
        { text: 'IP(s)', value: 'ip', align: 'center' },
        { text: 'Description', value: 'Description' },
      ],
      vpnHeaders: [
        { text: 'Account', value: 'AccountName' },
        { text: 'Version', value: 'Version' },
        { text: 'IP(s)', value: 'ips', align: 'center' },
        { text: 'LAN Interface', value: 'LanIface' },
        { text: 'Description', value: 'Description' },
      ],
    });

    const sbcs = computed(() => state.devices.filter((device) => device.DeviceType === 'SBC'));
    const vpns = computed(() => state.devices.filter((device) => device.DeviceType === 'VPN'));

    const loadAccounts = async () => {
      state.accountsLoading = true;
      try {
        const { data: accounts } = await axios.get(`${BTL_API_ACCOUNT_URL}`);
        state.accounts = sortBy(accounts, ['name']);
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        state.accountsLoading = false;
      }
    };

    const loadDevices = async () => {
      state.devicesLoading = true;
      try {
        const query = state.accountFilter ? `?q={"filters":[{"name":"AccountId","op":"eq","val":"${state.accountFilter}"}]}` : '';
        const { data: devices } = await axios.get(`${BTL_API_TENANT_URL}/devices${query}`);
        state.devices = devices.map((device) => {
          const dev = { ...device };
          // eslint-disable-next-line no-nested-ternary
          dev.ip = device.DeviceType === 'SBC' ? device.TrunkData ? device.TrunkData.Location : '' : device.LANIP;
          return dev;
        });
      } catch (err) {
        console.error('Failed to load devices', err);
      } finally {
        state.devicesLoading = false;
      }
    };

    watch(() => state.accountFilter, () => {
      loadDevices();
    });

    saveQueryParams();
    onMounted(async () => {
      await Promise.all([loadAccounts(), loadDevices()]);
      processQueryParams();
    });

    return {
      ...toRefs(state),
      loadAccounts,
      loadDevices,
      sbcs,
      vpns,
      permittedFunction,
      tableOptions,
      handleTableOptionsUpdate,
    };
  },
};
</script>

<style>
.activeTab {
  background-color: #1976D2;
}
</style>
