<template>
  <div>
    <page-toolbar title="SMS Users">
      <v-btn
        text
        color="green"
        @click="createDialogue = true"
      >
        <v-icon left>
          add_box
        </v-icon>Create
      </v-btn>

      <v-btn
        v-if="canDelete"
        text
        color="error"
        @click="deleteDialogue = true"
      >
        <v-icon left>
          delete
        </v-icon>Delete
      </v-btn>

      <v-btn
        text
        color="blue"
        :loading="loadingSmsUsers"
        @click="loadSmsUsers"
      >
        <v-icon left>
          refresh
        </v-icon>Refresh
      </v-btn>
    </page-toolbar>

    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="smsUsers"
            show-select
            :loading="loadingSmsUsers"
            item-key="UserId"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 205px)"
            :search="$store.getters.searchQuery"
          >
            <template #item.ModifiedOn="{item}">
              {{ formatDateTime(item.ModifiedOn) }}
            </template>
            <template #item.UserId="props">
              {{ userIdToName(props.item.UserId) }}
            </template>
            <template #item.actions="props">
              <div>
                <v-tooltip
                  bottom
                >
                  <template #activator="{on}">
                    <v-btn
                      icon
                      @click="openEditDialogue({ user: props.item })"
                      v-on="on"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Number</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="createDialogue"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Create User
        </v-card-title>
        <v-card-text>
          <v-form v-model="createForm">
            <v-select
              v-model="userToCreate.UserId"
              :items="users"
              label="User"
              :rules="generalRules"
              item-value="user_id"
              item-text="fullname"
            />
            <v-select
              v-model="userToCreate.DefaultSource"
              :items="accountNumbers"
              label="Default Source"
              :rules="generalRules"
              item-value="SourceNumber"
              item-text="SourceNumber"
            />
            <v-text-field
              v-model="userToCreate.Notes"
              label="Notes"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="green"
            dark
            :loading="creating"
            @click="createUser"
          >
            Create
          </v-btn>
          <v-btn
            text
            color="secondary"
            @click="createDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editDialogue"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Edit User
        </v-card-title>
        <v-card-text>
          <v-form v-model="editForm">
            <v-select
              v-model="userToEdit.DefaultSource"
              :items="accountNumbers"
              label="Default Source"
              :rules="generalRules"
              item-value="SourceNumber"
              item-text="SourceNumber"
            />
            <v-text-field
              v-model="userToEdit.Notes"
              label="Notes"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            :loading="editing"
            @click="editUser"
          >
            Edit
          </v-btn>
          <v-btn
            text
            color="secondary"
            @click="editDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialogue"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Delete User(s)
        </v-card-title>
        <v-card-text>Do you want to delete these Users?</v-card-text>
        <div class="text-center">
          <v-chip
            v-for="u in selected"
            :key="u.UserId"
            class="ma-1"
            color="secondary"
            text-color="white"
          >
            <v-icon left>
              person
            </v-icon>
            {{ userIdToName(u.UserId) }}
          </v-chip>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="error"
            dark
            :loading="deleting"
            @click="deleteUsers"
          >
            Delete
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="deleteDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import {
  onMounted, ref, computed, watch,
} from 'vue';
import axios from 'axios';
import { useStore } from '@/utils/vue';
import { find, sortBy } from 'lodash';

import PageToolbar from '@/components/PageToolbar';

import { bizvu_user_url, BTL_API_ACCOUNT_URL } from '@/config';
import { formatDateTime } from '@/utils/DateTimeFormatter';

const headers = [
  { text: 'User', value: 'UserId' },
  { text: 'Default Source', value: 'DefaultSource' },
  { text: 'Notes', value: 'Notes' },
  { text: 'Modified On', value: 'ModifiedOn' },
  { text: 'Modified By', value: 'ModifiedBy' },
  { text: '', value: 'actions', align: 'right' },
];

const generalRules = [
  (v) => !!v || 'Field is required',
];

const emit = defineEmits(['snack']);
const store = useStore();

const selectAccountId = computed(() => store.getters.selectedAccountId);

const selected = ref([]);

const loadingSmsUsers = ref(false);
const smsUsers = ref([]);

const loadSmsUsers = async () => {
  try {
    loadingSmsUsers.value = true;
    const { data } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/users`);
    smsUsers.value = sortBy(data, ['fullname']);
  } catch (err) {
    console.error('ERROR', err.response);
  } finally {
    loadingSmsUsers.value = false;
  }
};

const loadingUsers = ref(false);
const users = ref([]);

const loadUsers = async () => {
  loadingUsers.value = true;
  try {
    const { data } = await axios.get(`${bizvu_user_url}/account/${selectAccountId.value}/user`);
    users.value = data.map((user) => {
      const usr = { ...user };
      usr.email = user.emails.length > 0 ? user.emails[0].email : '';
      return usr;
    });
  } catch (error) {
    console.error('ERROR', error.response);
  } finally {
    loadingUsers.value = true;
  }
};

const accountNumbers = ref([]);

const loadAccountNumbers = async () => {
  try {
    const { data } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/numbers`);
    accountNumbers.value = data;
  } catch (err) {
    console.error('ERROR', err.response);
  }
};

const userIdToName = (userId) => {
  const user = find(users.value, { user_id: userId });
  return user?.fullname;
};

const createDialogue = ref(false);
const userToCreate = ref({});
const creating = ref(false);
const createForm = ref(false);

const createUser = async () => {
  if (!createForm.value) return;

  creating.value = true;
  try {
    await axios.post(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/users`, userToCreate.value);
    createDialogue.value = false;
    emit('snack', 'User Created');
    await loadSmsUsers();
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    creating.value = false;
  }
};

const editDialogue = ref(false);
const userToEdit = ref({ DefaultSource: null, Notes: null });
const editing = ref(false);
const editForm = ref(false);

const openEditDialogue = ({ user }) => {
  userToEdit.value = user;
  editDialogue.value = true;
};

const editUser = async () => {
  editing.value = true;
  try {
    await axios.put(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/users/${userToEdit.value.UserId}`, userToEdit.value);
    editDialogue.value = false;
    emit('snack', 'User Updated');
    await loadSmsUsers();
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    editing.value = false;
  }
};

const canDelete = computed(() => selected.value.length > 0);

const deleteDialogue = ref(false);
const deleting = ref(false);

const deleteUsers = async () => {
  deleting.value = true;
  try {
    await Promise.all(selected.value.map(async (user) => {
      await axios.delete(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/users/${user.UserId}`);
    }));
    deleteDialogue.value = false;
    emit('snack', 'User(s) Deleted');
    selected.value = [];
    await loadSmsUsers();
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    deleting.value = false;
  }
};

const load = async () => {
  await Promise.all([
    loadUsers(),
    loadSmsUsers(),
    loadAccountNumbers(),
  ]);
};

watch(selectAccountId, load);
onMounted(async () => await load());
</script>
