<template>
  <vue-draggable-resizable
    v-if="node.action !== 'Finish'"
    :x="node.layout.x"
    :y="node.layout.y"
    :w="nodeWidth"
    :h="nodeHeight"
    :resizable="false"
    :parent="true"
    :grid="[25,25]"
    :maximize="nodeType !== 'Start'"
    :draggable="canEdit"
    @dragging="onDrag"
  >
    <div v-if="nodeType === 'Start'">
      <v-chip
        color="blue-grey lighten-4"
        class="smallnode elevation-6"
      >
        <start
          :canedit="canEdit"
          @newnode="handleNewNode"
        />
      </v-chip>
    </div>
    <div v-else>
      <v-container
        elevation-6
        class="callflow-node"
      >
        <v-card
          flat
          color="blue-grey lighten-4"
          class="callflow-node__card"
        >
          <dial-trunk
            v-if="nodeType === 'DialTrunk'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
          <dial-trunk-with-number
            v-if="nodeType === 'DialTrunkWithNumber'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
          <dial-number
            v-if="nodeType === 'DialNumber'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
          <test-workinghours
            v-if="nodeType === 'CheckSiteWorkinghours'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
          <test-dr
            v-if="nodeType === 'SiteDR'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
          <callflow-limit
            v-if="nodeType === 'CheckDCL'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
          <dynamic-dial-trunk
            v-if="nodeType === 'DynamicDialTrunk'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
          <receive-fax
            v-if="nodeType === 'ReceiveFax'"
            :node="node"
            :canedit="canEdit"
            @deletenode="handleDelete"
          />
          <hang-up
            v-if="nodeType === 'HangUp'"
            :node="node"
            :canedit="canEdit"
            @deletenode="handleDelete"
          />
          <set-s-i-p-header
            v-if="nodeType === 'SetSIPHeader'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
          <set-channel-variables
            v-if="nodeType === 'SetChannelVariables'"
            :node="node"
            :dialplan="callflow"
            :canedit="canEdit"
            @deletenode="handleDelete"
            @drawconnectors="drawConnectors"
            @newnode="handleNewNode"
          />
        </v-card>
      </v-container>
    </div>
  </vue-draggable-resizable>
</template>

<script>
/* eslint-disable */
import VueDraggableResizable from 'vue-draggable-resizable';

import permittedFunction from '@/permittedFunction.js';

import Start from './nodetypes/Start';
import HangUp from './nodetypes/HangUp';
import DialTrunk from './nodetypes/DialTrunk';
import DialTrunkWithNumber from './nodetypes/DialTrunkWithNumber';
import DialNumber from './nodetypes/DialNumber';
import TestWorkinghours from './nodetypes/TestWorkinghours';
import TestDr from './nodetypes/TestDR';
import CallflowLimit from './nodetypes/CallflowLimit';
import DynamicDialTrunk from './nodetypes/DynamicDialTrunk';
import ReceiveFax from './nodetypes/ReceiveFax';
import SetSIPHeader from './nodetypes/SetSIPHeader';
import SetChannelVariables from './nodetypes/SetChannelVariables';

export default {
  components: {
    VueDraggableResizable,
    Start,
    HangUp,
    DialTrunk,
    DialTrunkWithNumber,
    DialNumber,
    TestWorkinghours,
    TestDr,
    CallflowLimit,
    DynamicDialTrunk,
    ReceiveFax,
    SetSIPHeader,
    SetChannelVariables,
  },
  props: {
    node: Object,
    callflow: Array,
  },
  data: () => ({
    nodeType: '',
    nodeWidth: 160,
    nodeHeight: 120,
    canEdit: false,
  }),
  mounted() {
    this.nodeType = this.node.action;

    this.canEdit = this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/callflows/<callflowid>', 'PUT');
  },
  methods: {
    handleDelete() {
      this.$emit('deletenode', this.node);
    },
    onDrag(x, y) {
      this.node.layout.x = x;
      this.node.layout.y = y;
      this.$emit('drawconnectors');
    },
    drawConnectors() {
      this.$emit('drawconnectors');
    },
    handleNewNode(type, param) {
      this.$emit('newnode', this.node, type, param);
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style>
  .callflow-node {
    padding: 0;
    border-radius: 7px;
    border-color: #90A4AE;
  }

  .callflow-node__card {
    border-radius: 7px;
  }

  .callflow-node__card > .container {
    padding: 8px;
  }

  .callflow-node__menu {
    width: 0;
    height: 30px;
  }

  .smallnode {
    width: 55px;
  }

  .cornerbtn {
    height: 18px;
    width: 18px;
    position: relative;
    top: -14px;
    left: 6px;
    z-index: 10;
  }
</style>
