<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Users
      </v-toolbar-title>
      <v-btn
        v-if="canCreateUser"
        text
        color="blue"
        @click="$router.push('/newuser')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create User
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadUsers"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <tableDownload
        :table-data="users"
        color="primary"
        report-name="Users"
      />
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-layout class="mt-1 px-2">
        <v-flex>
          <v-data-table
            :headers="headers"
            :options="tableOptions"
            :items="users"
            :loading="loading"
            :search="$store.getters.searchQuery"
            @update:options="handleTableOptionsUpdate"
          >
            <template #item="props">
              <tr>
                <td>
                  <router-link
                    v-if="canViewUser"
                    :to="`/user/${props.item.user_id}`"
                  >
                    {{ props.item.fullname }}
                  </router-link>
                  <p v-else>
                    {{ props.item.fullname }}
                  </p>
                </td>
                <td class="text-xs">
                  {{ props.item.email }}
                </td>
                <td class="text-right">
                  <v-icon
                    v-if="props.item.active"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import {
  reactive, computed, watch, toRefs, onMounted,
} from 'vue';
import { bizvu_user_url } from '@/config.js';

import { useStore } from '@/utils/vue';
import usePagination from '../../composables/tablePaginationViaQueryString';
import permittedFunction from '../../permittedFunction';
import tableDownload from '../../components/download/tableDownload';

export default {
  name: 'Users',
  components: {
    tableDownload,
  },
  setup(props, context) {
    const store = useStore();

    // composables
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 15, sortBy: 'fullname' });

    const state = reactive({
      selectedAccountId: computed(() => store.getters.selectedAccountId),
      canViewUser: computed(() => permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/users/<user_id>', 'GET')),
      canCreateUser: computed(() => permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/users', 'POST')),

      headers: [
        { text: 'Name', value: 'fullname' },
        { text: 'Email', value: 'email' },
        { text: 'Active', value: 'active', align: 'right' },
      ],
      users: [],
      loading: false,
    });

    const loadUsers = async () => {
      state.loading = true;

      try {
        const { data: users } = await axios.get(`${bizvu_user_url}/account/${state.selectedAccountId}/user`);
        state.users = users.map((user) => {
          const usr = { ...user };
          usr.email = user.emails.length > 0 ? user.emails[0].email : '';
          return usr;
        });
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        state.loading = false;
      }
    };

    watch(() => state.selectedAccountId, loadUsers);

    saveQueryParams();
    onMounted(async () => {
      await loadUsers();
      processQueryParams();
    });

    return {
      ...toRefs(state),
      loadUsers,
      tableOptions,
      handleTableOptionsUpdate,
    };
  },
};
</script>

<style scoped>
</style>
