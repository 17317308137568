<template>
  <div class="ipinput">
    <v-layout
      row
      wrap
    >
      <v-flex xs11>
        <v-layout
          row
          wrap
        >
          <v-flex xs5>
            <v-text-field
              v-model="ip.ip"
              solo
              single-line
              flat
              :rules="ipRules"
              placeholder="0.0.0.0"
              required
            />
          </v-flex>
          <v-flex xs1>
            <p class="subtitle-1 text-center mt-2">
              :
            </p>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="ip.port"
              solo
              single-line
              flat
              mask="####"
              placeholder="1234"
              required
            />
          </v-flex>
          <v-flex xs1>
            <p class="subtitle-1 text-center mt-2">
              :
            </p>
          </v-flex>
          <v-flex xs2>
            <v-select
              v-model="ip.protocol"
              class="pt-0 mt0"
              dense
              flat
              :items="protArray"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs1>
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <v-btn
              icon
              small
              primary
              @click="removeIP"
            >
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'Ipinput',
  components: {
  },
  props: {
    ip: Object,
    idx: Number,
  },
  data: () => ({
    protArray: ['udp', 'tcp'],
    ipRules: [
      (v) => !!v || 'IP is required',
      (v) => (v && v.length <= 15) || 'IP must not have more than 15 characters',
    ],
  }),
  methods: {
    removeIP() {
      this.$emit('removeIP', this.idx);
    },
  },
};
</script>
<style>
</style>
