<template>
  <v-list-item @click.stop="onShow">
    <span v-if="!editMode">
      {{ item.text }}
    </span>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-spacer class="pt-8" />
        <p class="px-6 pb-4">
          Please type in one or more values and press enter to confirm
        </p>
        <v-card-text>
          <v-combobox
            v-model="destination_region"
            :label="`${item.text} - Region`"
            multiple
            chips
            deletable-chips
            dense
          />
          <v-combobox
            v-model="destination_country_name"
            :label="`${item.text} - Country`"
            multiple
            chips
            deletable-chips
            dense
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'QueryBuilderDestination',
  props: {
    item: Object,
    query: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      destination_region: [],
      destination_country_name: [],
    };
  },
  mounted() {
    if (this.editMode) {
      this.onShow();
      this.destination_region = this.query.query.bool.filter[0].bool.should.filter((filter) => Object.keys(filter.fuzzy)[0] === 'destination_region').map((filter) => filter.fuzzy.destination_region);
      this.destination_country_name = this.query.query.bool.filter[0].bool.should.filter((filter) => Object.keys(filter.fuzzy)[0] === 'destination_country_name').map((filter) => filter.fuzzy.destination_country_name);
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'destination';
      const query = {
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: [],
                },
              },
            ],
          },
        },
      };

      let textHolder = '';

      if (this.destination_region.length) {
        query.query.bool.filter[0].bool.should.push(...this.destination_region.map((region) => ({
          fuzzy: {
            destination_region: region,
          },
        })));
        textHolder = this.destination_region.join(', ');
      }

      if (this.destination_country_name.length) {
        query.query.bool.filter[0].bool.should.push(...this.destination_country_name.map((country) => ({
          fuzzy: {
            destination_country_name: country,
          },
        })));
        textHolder = textHolder === '' ? this.destination_country_name : textHolder += `, ${this.destination_country_name.join(', ')}`;
      }

      if (!query.query.bool.filter[0].bool.should.length) {
        query.query.bool.filter[0].bool.should.push({
          fuzzy: {
            destination_region: '', // this is so that it does not display results if there aren't any filters
          },
        });
      }

      this.$emit('submit', {
        item,
        query,
        text: textHolder,
      });

      this.dialog = false;
      this.destination_region = [];
      this.destination_country_name = [];
    },
  },
};
</script>

<style scoped>
 ::v-deep div[role=combobox] .v-input__append-inner {
   display: none;
 }
</style>
