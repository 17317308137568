<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Report Download
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-container fluid>
      <v-card>
        <v-row
          class="fill-height"
          align-content="center"
          justify="center"
        >
          <v-col
            class="subtitle-1 text-center"
            cols="12"
          >
            {{ downloading ? 'Downloading Report' : 'Report Downloaded' }}
          </v-col>
          <v-col
            v-if="downloading"
            cols="6"
          >
            <v-progress-linear
              color="deep-purple accent-4"
              :value="downloadProgress"
              rounded
              height="6"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="!downloading"
        >
          <v-col class="text-center">
            <v-btn
              color="primary"
              @click="downloadReport"
            >
              Download Again
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

import { bizvu_schedule_url } from '../config.js';

const fileNameRegex = /.*filename="(.*)".*/;

export default {
  name: 'Report',
  data() {
    return {
      accountId: null,
      reportId: null,

      downloading: true,
      downloadProgress: 0,
    };
  },
  mounted() {
    this.extractReport();
    this.downloadReport();
  },
  methods: {
    extractReport() {
      const { accountId, reportId } = this.$route.query;
      this.accountId = accountId;
      this.reportId = reportId;
    },
    async downloadReport() {
      this.downloading = true;
      this.onDownloadProgress = 0;
      try {
        const { data, headers } = await axios.get(
          `${bizvu_schedule_url}/account/${this.accountId}/report/${this.reportId}`,
          {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              this.downloadProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
            },
          },
        );

        const reportName = fileNameRegex.exec(headers['content-disposition'])[1];

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${decodeURIComponent(reportName)}`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error('Failed to retrieve report', err);
      }
      this.downloading = false;
    },
  },
};
</script>
