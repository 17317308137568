<template>
  <vue-draggable-resizeable
    class="widget"
    :grid="[20,20]"
    :w="w"
    :h="h"
    :x="x"
    :y="y"
    :z="z"
    @resizestop="layoutChangeHandler"
    @dragstop="layoutChangeHandler"
    @resizing="onResize"
    @activated="activated"
    @deactivated="deactivated"
  >
    <slot />
  </vue-draggable-resizeable>
</template>
<script>
import { isMatch } from 'lodash';

import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

export default {
  name: 'ResizeContainer',
  components: {
    'vue-draggable-resizeable': VueDraggableResizable,
  },
  props: {
    id: String,
    layoutProps: Object,
  },
  data() {
    return {
      w: 300,
      h: 200,
      x: 400,
      y: 100,
      layout: {},
      z: 0,
    };
  },
  beforeMount() {
    this.positionWidget();
  },
  methods: {
    positionWidget() {
      this.w = this.layoutProps.w || this.w;
      this.h = this.layoutProps.h || this.h;
      this.x = this.layoutProps.x || this.x;
      this.y = this.layoutProps.y || this.y;
    },
    layoutChangeHandler(top, left, width, height) {
      const newLayout = {
        x: top,
        y: left,
        w: width || this.w,
        h: height || this.h,
      };

      if (isMatch(this.layout, newLayout)) {
        return;
      }

      this.layout = newLayout;
      this.saveLayout();
    },
    saveLayout() {
      this.layout.id = this.id;
      this.$emit('saveLayout', this.layout);
    },
    onResize(f) {
      this.$emit('resizing', f);
    },
    activated() {
      this.z = 1;
    },
    deactivated() {
      this.z = 0;
    },
  },
};
</script>

<style>
  .widget .v-toolbar__content {
    padding: 0 9px;
  }
  .widget__select {
    margin-top: 4px !important;
    height: 36px;
  }
  .widget .container {
    padding: 0;
  }
  .widget td {
    padding: 2px !important;
  }
  .widget th {
    padding: 0 12px !important;
  }

  .vdr {
    border: none;
  }
</style>
