<template>
  <div style="width: 100%; height: 100%;">
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Dashboard
      </v-toolbar-title>
      <v-menu offset-y>
        <template #activator="{on}">
          <v-btn
            text
            color="blue"
            v-on="on"
          >
            <v-icon>add_box</v-icon>Add Widget
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="widget in availableWidgets"
            :key="widget.type"
            @click="addWidget(widget)"
          >
            <v-list-item-title>{{ widget.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        text
        color="blue"
      >
        <v-icon>refresh</v-icon>Refresh
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container
      fluid
      grid-list-md
      style="width: 100%; height: 100%;"
    >
      <v-layout
        :key="idx"
        ref="widgetContainer"
        v-resize="onResize"
        row
        wrap
        fill-height
      >
        <component
          :is="w.type"
          v-for="w in widgets"
          :id="w.id"
          :key="w.id"
          :data="w.data"
          :name="w.name"
          :layout-props="{
            w: w.w,
            h: w.h,
            x: w.x,
            y: w.y
          }"
          @saveLayout="updateWidgetLayout"
          @saveWidgetData="saveWidgetData"
          @deleteWidget="deleteWidget"
        />
      </v-layout>
    </v-container>
  </div>
</template>
<script>

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import RBush from 'rbush';
import { cloneDeep, sortBy } from 'lodash';
import { mapGetters } from 'vuex';

import NumberTypes from '@/components/widgets/NumberTypes';
import OutgoingCalls from '@/components/widgets/OutgoingCalls';
import RecentChanges from '@/components/widgets/RecentChanges';
import LongestCalls from '@/components/widgets/LongestCalls';
import ExpensiveCalls from '@/components/widgets/ExpensiveCalls';
import InboundTraffic from '@/components/widgets/InboundTraffic';
import AllTraffic from '@/components/widgets/AllTraffic';
import CallData from '@/components/widgets/CDRList';
import CallCount from '@/components/widgets/CallCount';
import CustomQuery from '@/components/widgets/CustomQuery';
import ConcurrentCalls from '@/components/widgets/ConcurrentCalls';
import ReleaseNotes from '@/components/widgets/ReleaseNotes';

import permittedFunction from '@/permittedFunction.js';
import { bizvu_user_url } from '../config.js';

export default {
  name: 'Dashboard',
  components: {
    NumberTypes,
    OutgoingCalls,
    RecentChanges,
    LongestCalls,
    ExpensiveCalls,
    InboundTraffic,
    CallData,
    CustomQuery,
    CallCount,
    ConcurrentCalls,
    AllTraffic,
    ReleaseNotes,
  },
  data() {
    let availableWidgets = [
      {
        name: 'Release Notes', type: 'ReleaseNotes', defaultWidth: 540, defaultHeight: 680,
      },
    ];

    if (this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/analytics', 'POST')) {
      availableWidgets = availableWidgets.concat([
        {
          name: 'Number Types', type: 'NumberTypes', defaultWidth: 280, defaultHeight: 330,
        },
        {
          name: 'Outgoing Calls', type: 'OutgoingCalls', defaultWidth: 400, defaultHeight: 440,
        },
        {
          name: 'Concurrent Calls', type: 'ConcurrentCalls', defaultWidth: 620, defaultHeight: 460,
        },
        {
          name: 'Recent Changes', type: 'RecentChanges', defaultWidth: 320, defaultHeight: 480,
        },
        {
          name: 'Call Data', type: 'CallData', defaultWidth: 660, defaultHeight: 460,
        },
        {
          name: 'Longest Calls', type: 'LongestCalls', defaultWidth: 500, defaultHeight: 460,
        },
        {
          name: 'Most Expensive Calls', type: 'ExpensiveCalls', defaultWidth: 750, defaultHeight: 520,
        },
        {
          name: 'Inbound Traffic', type: 'InboundTraffic', defaultWidth: 540, defaultHeight: 300,
        },
        {
          name: 'All Traffic', type: 'AllTraffic', defaultWidth: 540, defaultHeight: 300,
        },
      ]);
    }

    if (
      this.isPermitted('/tenants/<tenant_name>/callcount/system', 'GET')
      || this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/callcount', 'GET')
      || this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/trunks/<trunk_id>/callcount', 'GET')
      || this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/phonenumbers/<phonenumber>/callcount', 'GET')
    ) {
      availableWidgets.push({
        name: 'Call Count', type: 'CallCount', defaultWidth: 420, defaultHeight: 300,
      });
    }

    availableWidgets = sortBy(availableWidgets, ['name']);

    return {
      availableWidgets,
      widgets: [],
      idx: 1,
      container: null,
      containerMaxX: null,
      containerMaxY: null,
    };
  },
  computed: {
    userPreferences() {
      return this.$store.state.userPreferences;
    },
    ...mapGetters([
      'selectedAccountId',
      'authentication',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.idx += 1; // force reload of all widgets
    },
  },
  mounted() {
    this.loadWidgets();
  },
  beforeDestroy() {
    this.saveWidgets();
  },
  methods: {
    loadWidgets() {
      return axios.get(`${bizvu_user_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/preferences`)
        .then((response) => {
          this.widgets = response.data.dashboard.widgets || [];

          this.widgets.map((widget) => {
            if (widget.type === 'CallTypes') { // Migrating widget type
              widget.type = 'OutgoingCalls';
            }

            if (!widget.x) { // Deals with widgets added from other screens - Custom Queries
              const pos = this.findSpaceForWidget(620, 460);
              widget.x = pos.x;
              widget.y = pos.y;
              widget.w = 620;
              widget.h = 460;
            }
            return widget;
          });
        })
        .catch((error) => {
          console.error('failed to load widgets', error);
        });
    },
    saveWidgets() {
      const userPreferences = {
        ...this.userPreferences,
        dashboard: {
          widgets: cloneDeep(this.widgets),
        },
      };

      return this.$store.dispatch('saveUserPreferences', userPreferences)
        .catch((error) => {
          console.error('failed to save widgets', error);
        });
    },
    onResize() {
      this.containerMaxX = this.$refs.widgetContainer.offsetWidth;
      this.containerMaxY = this.$refs.widgetContainer.offsetHeight;
    },
    findSpaceForWidget(width = 300, height = 200) {
      const startingPoint = 20;
      const startingPointY = 80;
      const increment = 20;
      const widgetToPlace = {
        minX: startingPoint,
        minY: startingPointY,
        maxX: startingPoint + width,
        maxY: startingPointY + height,
      };

      const tree = new RBush();
      tree.load(this.widgets.filter((widget) => widget.x).map((widget) => ({
        minX: widget.x, minY: widget.y, maxX: widget.x + widget.w, maxY: widget.y + widget.h,
      })));

      let collision = tree.collides(widgetToPlace);

      while (collision) {
        if (widgetToPlace.maxX + increment < this.containerMaxX) {
          widgetToPlace.minX += increment;
          widgetToPlace.maxX += increment;
        } else {
          widgetToPlace.minX = startingPoint;
          widgetToPlace.maxX = startingPoint + width;
          widgetToPlace.minY += increment;
          widgetToPlace.maxY += increment;
        }

        collision = tree.collides(widgetToPlace);
      }

      return {
        x: widgetToPlace.minX, y: widgetToPlace.minY, w: width, h: height,
      };
    },
    addWidget(widget) {
      this.widgets.push({ type: widget.type, id: uuidv4(), ...this.findSpaceForWidget(widget.defaultWidth, widget.defaultHeight) });
      this.saveWidgets();
    },
    deleteWidget(id) {
      this.widgets = this.widgets.filter((widget) => widget.id !== id);
      this.saveWidgets();
    },
    updateWidgetLayout(layout) {
      this.widgets.forEach((widget) => {
        if (widget.id === layout.id) {
          widget.x = layout.x;
          widget.y = layout.y;
          widget.w = layout.w;
          widget.h = layout.h;
        }
      });
      this.saveWidgets();
    },
    saveWidgetData(id, widgetData) {
      const widget = this.widgets.find((w) => w.id === id);
      widget.data = widgetData;
      this.saveWidgets();
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>
</style>
