import { reactive } from 'vue';
import { isEqual } from 'lodash';

import { useRoute, useRouter } from '@/utils/vue';

export default function usePagination(context, options = {}) {
  const route = useRoute();
  const router = useRouter();

  let queryOptions = null;

  const tableOptions = reactive({
    page: options.page || 1,
    itemsPerPage: options.itemsPerPage || 100,
    sortBy: options.sortBy ? [options.sortBy] : [],
    sortDesc: options.sortDesc ? [options.sortDesc] : [],
  });

  const saveQueryParams = () => {
    const {
      page, itemsPerPage, sortBy, sortDesc,
    } = route.query;
    // eslint-disable-next-line no-shadow
    const options = {};

    if (page) options.page = +page;
    if (itemsPerPage) options.itemsPerPage = +itemsPerPage;
    if (sortBy) options.sortBy = [sortBy];
    if (sortDesc) options.sortDesc = [sortDesc === 'true']; // because it's a string, not a boolean when extracted from query params

    if (Object.keys(options).length) queryOptions = options;
  };

  const handleTableOptionsUpdate = (emittedOptions) => {
    const parsedOptions = JSON.parse(JSON.stringify(emittedOptions));

    const {
      page, itemsPerPage, sortBy, sortDesc,
    } = parsedOptions;

    const standardisedOptions = {
      page: Number(page),
      itemsPerPage: Number(itemsPerPage),
    };

    if (Array.isArray(sortBy) && sortBy.length) standardisedOptions.sortBy = sortBy;
    if (Array.isArray(sortDesc) && sortDesc.length) standardisedOptions.sortDesc = sortDesc;

    tableOptions.page = standardisedOptions.page;
    tableOptions.itemsPerPage = standardisedOptions.itemsPerPage;
    if (standardisedOptions.sortBy && standardisedOptions.sortBy.length) {
      tableOptions.sortBy = standardisedOptions.sortBy;
    }
    if (tableOptions.sortDesc && tableOptions.sortDesc.sortDesc) {
      tableOptions.sortDesc = standardisedOptions.sortDesc;
    }

    // eslint-disable-next-line no-shadow
    const queryOptions = { ...standardisedOptions };

    if (queryOptions.sortBy && queryOptions.sortBy.length) queryOptions.sortBy = sortBy[0];
    if (queryOptions.sortDesc && queryOptions.sortDesc.length) queryOptions.sortDesc = sortDesc[0];
    if (isEqual(queryOptions, route.query)) return;
    router.replace({ query: queryOptions });
  };

  const processQueryParams = () => {
    if (queryOptions) {
      handleTableOptionsUpdate(queryOptions);
      queryOptions = null; // this ensures that the query only runs once
    }
  };

  return {
    tableOptions,
    processQueryParams,
    saveQueryParams,
    handleTableOptionsUpdate,
  };
}
