<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Unbilled Usage
      </v-toolbar-title>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-expansion-panels
        focusable
        class="my-3 mx-2"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="subtitle-1">
              <v-icon class="mr-3">
                donut_small
              </v-icon>Your bucket consumption
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="ml-5">
              <p>Mobile Calls Usage</p>
            </v-row>
            <v-row class="col-md-8 ml-2">
              <v-progress-linear
                v-model="mobilePercent"
                color="primary"
                height="25"
                width="80"
              >
                <template #value>
                  <strong>{{ `${mobileUsed}/${mobileTotal}` }}</strong>
                </template>
              </v-progress-linear>
            </v-row>
            <v-row class="ml-5 mt-5">
              <p>National Calls Usage</p>
            </v-row>
            <v-row class="col-md-8 ml-2">
              <v-progress-linear
                v-model="nationalPercent"
                color="primary"
                height="25"
                width="80"
              >
                <template #value>
                  <strong>{{ `${nationalUsed}/${nationalTotal}` }}</strong>
                </template>
              </v-progress-linear>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="subtitle-1">
              <v-icon class="mr-3">
                subject
              </v-icon>Your unbilled usage
            </div>
          </v-expansion-panel-header>
          <UnbilledUsage
            ref="unbilled"
          />
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="subtitle-1">
              <v-icon class="mr-3">
                receipt
              </v-icon>Your Invoices
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content />
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="subtitle-1">
              <v-icon class="mr-3">
                euro_symbol
              </v-icon>Your Payments
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content />
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { bizvu_billing_url } from '@/config.js';

import UnbilledUsage from '../../components/billing/UnbilledUsage';

export default {
  name: 'Billing',
  components: {
    UnbilledUsage,
  },
  data() {
    return {
      bundleUsage: {},
      mobileTotal: 0,
      mobileUsed: 0,
      mobilePercent: 0,
      nationalTotal: 0,
      nationalUsed: 0,
      nationalPercent: 0,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadBundleUsage();
      this.$refs.unbilled.loadData();
    },
    loadBundleUsage() {
      axios.get(`${bizvu_billing_url}/tenant/btlcloud/account/${this.selectedAccountId}/bucket`)
        .then((response) => {
          this.bundleUsage = response.data._embedded;
          this.mobileTotal = this.bundleUsage.item[0].bucketVolume;
          this.mobileUsed = this.bundleUsage.item[0].bucketUsed;
          this.mobilePercent = (this.mobileUsed / this.mobileTotal) * 100;
          this.nationalTotal = this.bundleUsage.item[1].bucketVolume;
          this.nationalUsed = this.bundleUsage.item[1].bucketUsed;
          this.nationalPercent = (this.nationalUsed / this.nationalTotal) * 100;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
  },
};
</script>

<style scoped>
</style>
