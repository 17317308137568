import moment from 'moment';

const label = '2 years - monthly';
const text = '2 years';
const limit = 12;

const createFilter = () => {
  const now = moment(); // Get the current date and time
  const twoYearsAgo = now.clone().subtract(2, 'years'); // Get the date 2 years ago

  const timeRangeFilter = {
    range: {
      call_datetime_iso: {
        gte: twoYearsAgo.toISOString(),
        lte: now.toISOString(),
      },
    },
  };
  return [timeRangeFilter];
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  calendar_interval: 'month',
  min_doc_count: 0,
});

const dateFormat = (date) => moment(date).format('MMM YY');

export default {
  label,
  limit,
  text,
  createFilter,
  dateHistogram,
  dateFormat,
};
