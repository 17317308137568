import moment from 'moment';

const label = '1 month - 1 day interval';
const limit = 12;
const text = '1 Month';

const createFilter = () => {
  const now = moment(); // Get the current date and time
  const startOfMonth = now.clone().startOf('month'); // Get the start of the current month
  const endOfMonth = now.clone().endOf('month'); // Get the end of the current month

  const timeRangeFilter = {
    range: {
      call_datetime_iso: {
        gte: startOfMonth.toISOString(),
        lte: endOfMonth.toISOString(),
      },
    },
  };
  return [timeRangeFilter];
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  fixed_interval: '1d',
  min_doc_count: 0,
});

const dateFormat = (date) => moment(date).format('DD');

export default {
  label,
  limit,
  text,
  createFilter,
  dateHistogram,
  dateFormat,
};
