<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Groups
      </v-toolbar-title>
      <v-btn
        v-if="canCreateGroup"
        text
        color="blue"
        @click="$router.push('/newgroup')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create Group
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadGroups"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <tableDownload
        :table-data="groups"
        color="primary"
        report-name="Groups"
      />
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-layout row>
        <v-flex grow>
          <v-data-table
            :headers="headers"
            :items="groups"
            :loading="loading"
            sort-by="name"
            :items-per-page="15"
          >
            <template #item="props">
              <tr>
                <td
                  :key="props.item.name"
                  class="text-xs"
                >
                  <router-link
                    v-if="canViewGroup"
                    :to="{ name: 'group', params: { groupId: props.item.group_id }}"
                  >
                    {{ props.item.name }}
                  </router-link>
                  <p v-else>
                    {{ props.item.name }}
                  </p>
                </td>
                <td
                  :key="props.item.active"
                  class="text-right"
                >
                  <v-layout align-right>
                    <v-tooltip
                      v-if="canDeleteGroup"
                      bottom
                    >
                      <template #activator="{on}">
                        <v-btn
                          icon
                          @click="deleteGroup(props.item)"
                          v-on="on"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Group</span>
                    </v-tooltip>
                  </v-layout>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { bizvu_user_url } from '@/config.js';

import permittedFunction from '../../permittedFunction';

import tableDownload from '../../components/download/tableDownload';

export default {
  name: 'Groups',
  components: {
    tableDownload,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: 'Name', value: 'name' },
        {
          text: '', value: 'active', width: '5%', align: 'end',
        },
      ],
      groups: [],
    };
  },
  computed: {
    canViewGroup() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>', 'GET');
    },
    canCreateGroup() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups', 'POST');
    },
    canDeleteGroup() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>', 'DELETE');
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.loadGroups();
    },
  },
  mounted() {
    this.loadGroups();
  },
  methods: {
    loadGroups() {
      this.loading = true;
      const self = this;
      axios.get(`${bizvu_user_url}/account/${this.selectedAccountId}/group`)
        .then((response) => {
          self.groups = response.data;
          self.loading = false;
        })
        .catch((error) => {
          self.loading = false;
          console.error('ERROR', error.response);
        });
    },
    deleteGroup(group) {
      const groupId = group.group_id;
      axios.delete(`${bizvu_user_url}/account/${this.selectedAccountId}/group/${groupId}`)
        .then(() => {
          this.loadGroups();
        });
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>
</style>
