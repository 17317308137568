<template>
  <v-container>
    <node-header
      icon="business"
      :title="name"
      :detail="siteName"
      :color-a="routeConfig.trueAction ==='none'"
      :color-b="routeConfig.falseAction ==='none'"
      chip-a="TRUE"
      chip-b="FALSE"
    />

    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="primary"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              edit
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Configure name</v-subheader>
          <v-list-item>
            <v-text-field
              v-model="name"
              label="Node Name"
            />
          </v-list-item>
          <v-subheader>Configure test</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-select
                v-model="siteId"
                label="Site"
                required
                :items="sites"
                item-text="SiteName"
                item-value="SiteId"
              />
            </v-list-item-action>
          </v-list-item>
          <v-subheader>Add next step</v-subheader>
          <v-list-item class="mb-2">
            <v-select
              v-model="trueAction"
              label="On TRUE"
              :items="actions"
            />
          </v-list-item>
          <v-list-item class="mb-2">
            <v-select
              v-model="falseAction"
              label="On FALSE"
              :items="actions"
            />
          </v-list-item>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="handleCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="menu_delete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleDelete"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import config from '@/config.js';

import NodeHeader from './partials/Header';

import { typesForSelect } from './nodeTypes';

export default {
  components: {
    NodeHeader,
  },
  props: {
    node: Object,
    dialplan: Array,
    canedit: Boolean,
  },
  data: () => ({
    valid: false,
    menu: false,
    menu_delete: false,
    width: 0,
    height: 0,
    sites: null,
    siteName: 'none',
    siteId: '',
    name: null,
    trueAction: 'none',
    falseAction: 'none',
    actions: typesForSelect,
    routeConfig: {
      siteId: '',
      trueAction: 'none',
      falseAction: 'none',
    },
  }),
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    siteId() {
      const site = this.sites.find((t) => t.SiteId === this.siteId);
      if (site) {
        this.siteName = site.SiteName;
      } else {
        this.siteName = 'none';
      }
    },
  },
  mounted() {
    this.loadSites();
    this.name = this.node.name;
    this.falseAction = this.getLabel(this.node.conditions.SiteDR.False);
    this.routeConfig.falseAction = this.getLabel(this.node.conditions.SiteDR.False);
    this.trueAction = this.getLabel(this.node.conditions.SiteDR.True);
    this.routeConfig.trueAction = this.getLabel(this.node.conditions.SiteDR.True);
  },
  methods: {
    getLabel(nodeId) {
      const node = this.dialplan.find((n) => n.id === nodeId);
      if (node) {
        return node.action;
      }
      return 'none';
    },
    handleDelete() {
      this.$emit('deletenode');
    },
    handleSave() {
      this.menu = false;
      this.node.parameters.siteid = this.siteId;
      this.node.routeConfig = this.routeConfig;
      this.node.name = this.name;

      if (this.routeConfig.trueAction !== this.trueAction) {
        this.routeConfig.trueAction = this.trueAction;
        if (this.trueAction === 'none') {
          this.node.conditions.SiteDR.True = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.trueAction, 'DRTRUE');
        }
      }
      if (this.routeConfig.falseAction !== this.falseAction) {
        this.routeConfig.falseAction = this.falseAction;
        if (this.falseAction === 'none') {
          this.node.conditions.SiteDR.False = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.falseAction, 'DRFALSE');
        }
      }
    },
    handleCancel() {
      this.menu = false;
      this.siteId = this.node.parameters.siteid;
      this.name = this.node.name;
    },
    loadSites() {
      const self = this;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${self.selectedAccountId}/sites`)
        .then((response) => {
          self.sites = response.data;
          if (self.node.parameters && self.node.parameters.siteid) {
            self.siteId = self.node.parameters.siteid;
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
  },
};
</script>
