<template>
  <div class="simpletimeperiodpicker">
    <v-layout row>
      <v-flex
        xs12
        sm12
      >
        <v-select
          v-model="date.date"
          :items="optionsYears"
          label="Select year"
          item-value="value"
        />
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-card>
          <v-list>
            <v-layout row>
              <v-flex
                xs10
                sm10
              >
                <v-subheader>Bank Holidays of year {{ dateFormatted }}</v-subheader>
              </v-flex>
              <v-flex
                xs2
                sm2
              >
                <v-layout
                  row
                  justify-center
                >
                  <v-dialog
                    v-model="holiday.dialog"
                    persistent
                    max-width="600px"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        color="primary"
                        dark
                        v-on="on"
                      >
                        <v-icon>add</v-icon>New holiday
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Holiday Details</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container grid-list-md>
                          <v-layout wrap>
                            <v-flex xs12>
                              <v-text-field
                                v-model="holiday.summary"
                                label="Name"
                                required
                              />
                            </v-flex>
                            <v-flex
                              xs12
                              sm12
                              md12
                            >
                              <!-- <v-flex xs6 sm6 md6> -->
                              <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template #activator="{ on }">
                                  <v-text-field
                                    :value="holiday.start.date | formatDate"
                                    label="Select date"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="holiday.start.date"
                                  @input="menu2 = false"
                                />
                              </v-menu>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="holiday.dialog = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="addItem"
                        >
                          Add
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout
              row
              justify-center
              align-center
            >
              <v-flex
                v-if="loading"
                xs12
                sm12
                style="text-align:center;"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </v-flex>
            </v-layout>
            <v-list-group
              v-for="(item, i) in bankHolidays"
              :key="i"
              v-model="item.active"
              :prepend-icon="item.action"
              no-action
            >
              <template #activator>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="item.is_relevant" />
                  </v-list-item-action>
                  <v-list-item-content style="position:relative;">
                    <v-list-item-title :class="{'custom-disabled':(!item.is_relevant)}">
                      {{ item.summary }}<span style="position:absolute; right:0;">{{ item.start.date | formatDate }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>{{ item.action }}</v-list-item-action-text>
                    <v-container
                      grid-list-md
                      style="padding: 5px 0 0 14px;"
                    >
                      <v-layout wrap>
                        <v-flex
                          xs6
                          sm6
                          md6
                        >
                          <v-layout
                            row
                            justify-center
                          >
                            <v-dialog
                              v-model="item.dialog"
                              style="margin-left:10px;margin-right:10px;"
                              persistent
                              max-width="600px"
                            >
                              <template #activator="{on}">
                                <v-icon
                                  color="grey darken-1"
                                  v-on="on"
                                >
                                  edit
                                </v-icon>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="headline">Holiday Details</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container grid-list-md>
                                    <v-layout wrap>
                                      <v-flex xs12>
                                        <v-text-field
                                          v-model="item.summary"
                                          label="Name*"
                                          required
                                        />
                                      </v-flex>
                                      <!-- <v-flex xs6 sm6 md6> -->
                                      <v-flex
                                        xs12
                                        sm12
                                        md12
                                      >
                                        <v-menu
                                          v-model="item.menustart"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="290px"
                                        >
                                          <template #activator="{on}">
                                            <v-text-field
                                              :value="item.start.date | formatDate"
                                              label="Select date"
                                              prepend-icon="event"
                                              readonly
                                              v-on="on"
                                            />
                                          </template>
                                          <v-date-picker
                                            v-model="item.start.date"
                                            @input="item.menustart = false"
                                          />
                                        </v-menu>
                                      </v-flex>
                                    </v-layout>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer />
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="item.dialog = false"
                                  >
                                    Close
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="editItem(item)"
                                  >
                                    Done
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-layout>
                        </v-flex>
                        <v-flex
                          xs6
                          sm6
                          md6
                        >
                          <v-layout
                            row
                            justify-center
                          >
                            <v-dialog
                              v-model="item.dialog_delete"
                              persistent
                              max-width="290"
                            >
                              <template #activator="{on}">
                                <v-icon
                                  color="grey darken-1"
                                  v-on="on"
                                >
                                  delete
                                </v-icon>
                              </template>
                              <v-card>
                                <v-card-title class="headline">
                                  Are you sure you want to delete {{ item.summary }}?
                                </v-card-title>
                                <v-card-text>Please confirm to delete {{ item.summary }}!</v-card-text>
                                <v-card-actions>
                                  <v-spacer />
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="item.dialog_delete = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="removeItem(item)"
                                  >
                                    Agree
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list-item
                v-if="item.is_relevant"
                class="custom-list"
              >
                <v-list-item-content>
                  <TimePeriodAndPopulate
                    :id="item.id"
                    :key="item.key"
                    v-model="item.hours"
                    :index="i"
                    :days="bankHolidaysDays"
                    @update="onUpdateHours"
                    @populate="onPopulateHours"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios';
import lodash from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import config from '@/config.js';
import TimePeriodAndPopulate from '@/components/openinghourseditor/TimePeriodAndPopulateEditor';

const dateFormatter = (date) => moment(date, 'YYYY-MM-DD').format(config.date_format);

export default {
  name: 'Timeperiodandpopulate',
  components: {
    TimePeriodAndPopulate,
  },
  filters: {
    formatDate(date) {
      if (!date) return null;
      return dateFormatter(date);
    },
  },
  props: {
    value: Object,
    loadDefaults: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      originData: null,
      bankHolidays: {},
      bankHolidaysDays: [],
      optionsYears: [
        { text: (Number(new Date().getFullYear()) + 1).toString(), value: (Number(new Date().getFullYear()) + 1).toString() },
        { text: (Number(new Date().getFullYear())).toString(), value: (Number(new Date().getFullYear())).toString() },
        { text: (Number(new Date().getFullYear()) - 1).toString(), value: (Number(new Date().getFullYear()) - 1).toString() },
      ],
      dateFormatted: new Date().getFullYear().toString(),
      date: {
        min: `${Number(new Date().getFullYear()) - 1}-01-01`,
        max: `${Number(new Date().getFullYear()) + 1}-01-01`,
        date: new Date().getFullYear().toString(),
      },
      holiday: {
        dialog: false,
        start: { date: new Date().toISOString().substr(0, 10) },
        end: { date: new Date().toISOString().substr(0, 10) },
        summary: '',
      },
      menu: false,
      menu2: false,
      menu3: false,
    };
  },
  watch: {
    bankHolidays: {
      handler() {
        this.update();
      },
      deep: true,
    },
    // holiday: {
    //   handler(after, before){
    //     this.holiday.start.date = moment(this.holiday.start.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    //   },
    //   deep: true
    // },
    date: {
      handler() {
        this.dateFormatted = this.date.date;
        this.saveDate(this.date.date);
      },
      deep: true,
    },
    menu(val) {
      // eslint-disable-next-line no-unused-expressions
      val && this.$nextTick(() => { this.$refs.picker.activePicker = 'YEAR'; });
    },
  },
  mounted() {
    this.originData = lodash.cloneDeep(this.value);
    if (this.originData !== undefined) this.buildBankHolidays();
  },
  methods: {
    addItem() {
      this.holiday.dialog = false;
      this.bankHolidays.push({
        is_deleted: this.holiday.is_deleted,
        end: { ...this.holiday.end },
        hours: [],
        id: uuidv4(),
        is_relevant: true,
        key: uuidv4(),
        start: { ...this.holiday.start },
        summary: this.holiday.summary,
        dialog: false,
        menustart: false,
        menuend: false,
        dialog_delete: false,
        origin: 'new',
      });
      this.holiday.start = { date: new Date().toISOString().substr(0, 10) };
      this.holiday.end = { date: new Date().toISOString().substr(0, 10) };
      this.holiday.summary = '';
      this.bankHolidays.sort((a, b) => (moment.utc(a.start.date, 'DD-MM-YYYY').diff(moment.utc(b.start.date, 'DD-MM-YYYY'))));
    },
    editItem(item) {
      item.dialog = false;
    },
    removeItem(item) {
      item.dialog_delete = false;
      item.is_deleted = true;
      this.update();
    },
    update() {
      const bankHolydayAux = this.originData.year.find((x) => x.year === this.dateFormatted);
      this.bankHolidays.forEach((item) => {
        const itemAux = {
          is_deleted: item.is_deleted,
          end: item.end,
          hours: item.hours,
          id: item.id,
          is_relevant: item.is_relevant,
          key: item.key,
          start: item.start,
          summary: item.summary,
          origin: item.origin,
        };
        const originItem = bankHolydayAux.data.find((x) => x.id === item.id);
        if (originItem !== undefined) {
          originItem.hours = item.hours;
          originItem.summary = item.summary;
          originItem.start = item.start;
          originItem.end = item.end;
          originItem.is_deleted = item.is_deleted;
          originItem.is_relevant = item.is_relevant === null ? false : item.is_relevant;
        } else {
          bankHolydayAux.data.push(itemAux);
        }
        if (item.origin !== 'google' && item.is_deleted) {
          this.bankHolidays = this.bankHolidays.filter((x) => x.id !== item.id);
          bankHolydayAux.data = bankHolydayAux.data.filter((x) => x.id !== item.id);
        } else if (item.is_deleted) {
          this.bankHolidays = this.bankHolidays.filter((x) => x.id !== item.id);
        }
      });

      this.$emit('update', this.originData);
    },
    onPopulateHours(data) {
      data.days.forEach((item) => {
        const originItem = this.bankHolidays.find((x) => x.id === item);
        originItem.hours = Object.assign(data.hours);
        originItem.key += 1;
      });
    },
    onUpdateHours(data) {
      const originItem = this.bankHolidays.find((x) => x.id === data.id);
      originItem.hours = data.hours;
    },
    saveDate() {
      this.buildBankHolidays(this.dateFormatted);
    },
    async buildBankHolidays(year) {
      year = year === undefined ? new Date().getFullYear().toString() : year;
      let bankHolydayAux = this.originData.year.find((x) => x.year === year);
      if (bankHolydayAux === undefined) {
        this.originData.year.push({ year: this.dateFormatted, data: [] });
      }
      bankHolydayAux = this.originData.year.find((x) => x.year === year);

      try {
        let data = { items: [] };
        if (this.loadDefaults) {
          data = (await axios.get(`${config.google_calendar_url}${config.google_calendar_id}/events?key=${config.google_calendarapi_key}&timeMax=${year}-12-31T10:00:00-07:00&timeMin=${year}-01-01T10:00:00-07:00`)).data;
        }

        data.items = data.items.filter((x) => x.summary.indexOf('Northern Ireland') === -1);
        data.items = data.items.filter((x) => x.summary.indexOf('Scotland') === -1);
        data.items = data.items.filter((x) => x.summary.indexOf('Wales') === -1);
        data.items = data.items.filter((x) => !x.summary.includes('Daylight'));
        data.items = data.items.filter((x) => !x.summary.includes("George's"));
        data.items = data.items.filter((x) => !x.summary.includes('Halloween'));
        data.items = data.items.filter((x) => !x.summary.includes('Fawkes'));
        data.items = data.items.filter((x) => !x.summary.includes("Queen's Birthday"));
        data.items = data.items.filter((x) => !x.summary.includes('Remembrance Sunday'));
        data.items = data.items.map(({
          summary, id, start, end,
        }) => ({
          summary, id, start, end,
        }));
        data.items.forEach((item) => {
          item.hours = [];
          item.key = 0;
          item.is_relevant = true;
          item.dialog = false;
          item.menustart = false;
          item.menuend = false;
          item.dialog_delete = false;
          item.is_deleted = false;
          item.origin = 'google';
          const originItem = bankHolydayAux.data.find((x) => x.id === item.id);
          if (originItem !== undefined) {
            item.is_relevant = originItem.is_relevant === null ? true : originItem.is_relevant;
            item.hours = originItem.hours;
            item.summary = originItem.summary;
            item.start = originItem.start;
            item.end = originItem.end;
            item.is_deleted = originItem.is_deleted;
          }
          this.bankHolidaysDays.push({ text: item.summary, id: item.id });
        });

        const savedHolidays = this.originData.year.find((x) => x.year === this.dateFormatted);
        // savedHolidays.data = savedHolidays.data.filter(x => x.is_deleted === true);
        savedHolidays.data.forEach((item) => {
          const bankHoliday = data.items.find((x) => x.id === item.id);
          if (bankHoliday === undefined) {
            const itemAux = {
              is_deleted: item.is_deleted,
              end: item.end,
              hours: item.hours,
              id: item.id,
              is_relevant: item.is_relevant,
              key: item.key,
              start: item.start,
              summary: item.summary,
              dialog: false,
              dialog_delete: false,
              menustart: false,
              menuend: false,
            };
            data.items.push(itemAux);
          }
        });
        data.items.sort((a, b) => (moment.utc(a.start.date, 'DD-MM-YYYY').diff(moment.utc(b.start.date, 'DD-MM-YYYY'))));
        this.bankHolidays = data.items;
        this.loading = false;
      } catch (error) {
        console.error('error fetching data, ', error);
      }
    },
  },
};
</script>
<style>
.custom-disabled{
  color:grey;
}
</style>
