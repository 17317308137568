<template>
  <div
    :key="ckey"
    class="floweditor"
  >
    <canvas
      id="cfe_canvas"
      ref="cfe_canvas"
      width="1500"
      height="1000"
    />

    <div
      id="cfe_vue"
      style="height: 1000px; width: 1500px; border: 1px solid grey; position: relative; z-index: 1;"
    >
      <call-flow-node
        v-for="h in callflow"
        :key="h.id"
        :node="h"
        :callflow="callflow"
        @drawconnectors="drawConnectors"
        @newnode="handleNewNode"
        @deletenode="handleDeleteNode"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import CallFlowNode from './CallFlowNode';

import { types } from './nodetypes/nodeTypes';

export default {
  components: {
    CallFlowNode,
  },
  props: {
    callflow: Array,
    version: String,
  },
  data: () => ({
    ckey: 1,
    nextId: 1,
    ctx: null,
  }),
  watch: {
    version() {
      this.ckey += 1; // force rerendering the callflow editor
      this.$nextTick(() => {
        this.rewriteRoutes();
      });
    },
  },
  created() {
  },
  mounted() {
    // Fix for earlier bug (removing nodes accidentally removed the finish node)
    if (this.callflow) {
      const finish = this.callflow.find((s) => s.id === 99);
      if (!finish) {
        this.callflow.push({
          action: 'Finish',
          layout: {
            y: 300,
            x: 300,
          },
          name: 'End',
          id: 99,
        });
      }
    }
    // Fix END
    this.rewriteRoutes();
  },
  methods: {
    rewriteRoutes() {
      const canvas = this.$refs.cfe_canvas;
      this.ctx = canvas.getContext('2d');
      this.ctx.lineWidth = 2.5;
      this.ctx.strokeStyle = '#1565C0';
      this.drawConnectors();
      // find next available id
      this.callflow.forEach((s) => {
        if (this.nextId <= s.id) { this.nextId = s.id + 1; }
      });
    },
    handleDeleteNode(node) {
      // console.log('here delete', node, type, param)
      this.ctx.clearRect(0, 0, this.$refs.cfe_canvas.width, this.$refs.cfe_canvas.height);
      this.removeNode(node);
      this.rewriteRoutes();
    },
    removeParentConnection(nodeId) {
      this.callflow.forEach((item) => {
        if (item.next === nodeId) {
          item.next = 99;
        }
        if (item.conditions) {
          if (item.conditions.DIALSTATUS && item.conditions.DIALSTATUS.FAILOVER === nodeId) {
            item.conditions.DIALSTATUS.FAILOVER = 99;
          } else if (item.conditions.workinghours && item.conditions.workinghours.False === nodeId) {
            item.conditions.workinghours.False = 99;
          } else if (item.conditions.workinghours && item.conditions.workinghours.True === nodeId) {
            item.conditions.workinghours.True = 99;
          } else if (item.conditions.SiteDR && item.conditions.SiteDR.True === nodeId) {
            item.conditions.SiteDR.True = 99;
          } else if (item.conditions.SiteDR && item.conditions.SiteDR.False === nodeId) {
            item.conditions.SiteDR.False = 99;
          } else if (item.conditions.CheckDCL && item.conditions.CheckDCL.next === nodeId) {
            item.conditions.CheckDCL.next = 99;
          } else if (item.conditions.CheckDCL && item.conditions.CheckDCL.overflow === nodeId) {
            item.conditions.CheckDCL.overflow = 99;
          }
        }
      });
    },
    removeNode(node) {
      let nodeToRemoveIds = [];
      this.removeParentConnection(node.id);
      nodeToRemoveIds.push(node.id);
      // eslint-disable-next-line no-use-before-define
      const tempArr = getAllChildNodeIdsToRemove(this.callflow, nodeToRemoveIds, nodeToRemoveIds, node.id);
      nodeToRemoveIds = tempArr.reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);
      nodeToRemoveIds.forEach((id) => {
        if (id !== 99 && id !== 0) {
          const index = this.callflow.findIndex((c) => c.id === id);
          if (index > 0) this.callflow.splice(index, 1);
        }
      });

      // eslint-disable-next-line consistent-return
      function getAllChildNodeIdsToRemove(arrNode, arrDeleteId, arrOrigin, nodeOriginId) {
        const tempArrDeleteId = [];
        try {
          arrNode.forEach((item) => {
            if (item.id !== 99 && item.id !== 0) {
              // eslint-disable-next-line consistent-return
              arrDeleteId.forEach((id) => {
                if (id === item.id) {
                  if (item.conditions !== undefined) {
                    Object.keys(item.conditions).forEach((key) => {
                      Object.keys(item.conditions[key]).forEach((key_aux) => {
                        tempArrDeleteId.push(item.conditions[key][key_aux]);
                        arrOrigin.push(item.conditions[key][key_aux]);
                      });
                    });
                  }
                  if (nodeOriginId && (nodeOriginId !== item.next)) {
                    tempArrDeleteId.push(item.next);
                    arrOrigin.push(item.next);
                  }
                  getAllChildNodeIdsToRemove(arrNode, tempArrDeleteId, arrOrigin);
                  return arrOrigin;
                }
              });
            }
          });
          return arrOrigin;
        } catch (error) {
          console.error(error);
        }
      }
    },
    handleNewNode(node, type, param) {
      this.injectNode(node, type, param);
    },
    injectNode(node, type, param) {
      const oldNode = this.callflow.find((c) => c.id === node.id);
      const newNode = cloneDeep(types.find((t) => t.action === type));

      // Calculate position of new node
      newNode.layout.x = node.layout.x + 220;

      const isTrue = param === 'NEXT'
          || param === 'WHTRUE'
          || param === 'DRTRUE'
          || param === 'CheckDCLNext';

      if (type === 'HangUp') {
        if (isTrue) {
          newNode.layout.y = node.layout.y + 20;
        } else {
          newNode.layout.y = node.layout.y + 70;
        }
      } else if (oldNode.action === 'Start') {
        newNode.layout.x = node.layout.x + 80;
        newNode.layout.y = node.layout.y - 40;
      } else if (isTrue) {
        newNode.layout.y = node.layout.y - 60;
      } else {
        newNode.layout.y = node.layout.y + 70;
      }

      newNode.id = this.nextId;
      if (!param || param === 'NEXT') {
        oldNode.next = newNode.id;
      } else if (param === 'FAILOVER') {
        oldNode.conditions.DIALSTATUS.FAILOVER = newNode.id;
      } else if (param === 'WHTRUE') {
        oldNode.conditions.workinghours.True = newNode.id;
      } else if (param === 'WHFALSE') {
        oldNode.conditions.workinghours.False = newNode.id;
      } else if (param === 'DRTRUE') {
        oldNode.conditions.SiteDR.True = newNode.id;
      } else if (param === 'DRFALSE') {
        oldNode.conditions.SiteDR.False = newNode.id;
      } else if (param === 'CheckDCLNext') {
        oldNode.conditions.CheckDCL.next = newNode.id;
      } else if (param === 'CheckDCLOverflow') {
        oldNode.conditions.CheckDCL.overflow = newNode.id;
      }
      this.callflow.push(newNode);
      this.nextId += 1;
      this.drawConnectors();
    },
    calcCoordinates(origin, target, offset) {
      const smallNodeHeight = 40;
      const smallNodeWidth = 55;
      const nodeHeight = 120;
      const nodeWidth = 160;

      if (target) {
        // ignore the "Finish" step
        if (target.action !== 'Finish') {
          const from = {};
          const to = {};

          // calculate start coordinates
          if (origin.action === 'Start') {
            // eslint-disable-next-line no-unused-expressions
            from.x = origin.layout.x + smallNodeWidth;
            from.y = origin.layout.y + smallNodeHeight / 2;
          } else {
            from.x = origin.layout.x + nodeWidth + 10; // to account for labels sticking out;
            from.y = origin.layout.y + offset;
          }

          // calculate end coordinates
          to.x = target.layout.x;
          to.y = target.layout.y + ((target.action === 'HangUp' ? smallNodeHeight : nodeHeight) / 2);

          // eslint-disable-next-line no-use-before-define
          drawArrow(this.ctx, from.x, from.y, to.x, to.y);
          this.ctx.stroke();
        }
      }
    },
    drawConnectors() {
      // wipe canvas
      this.ctx.clearRect(0, 0, this.$refs.cfe_canvas.width, this.$refs.cfe_canvas.height);
      this.ctx.beginPath();
      const arrowOffsetTop = 56;
      const arrowOffsetBottom = 76;
      this.callflow.forEach((callflow) => {
        // find taget node
        if (callflow.next) {
          const target = this.callflow.find((f) => callflow.next === f.id);
          this.calcCoordinates(callflow, target, arrowOffsetTop);
        }
        const { conditions } = callflow;
        if (conditions && conditions.DIALSTATUS && conditions.DIALSTATUS.FAILOVER) {
          const target = this.callflow.find((f) => conditions.DIALSTATUS.FAILOVER === f.id);
          this.calcCoordinates(callflow, target, arrowOffsetBottom);
        }
        if (conditions && conditions.workinghours && conditions.workinghours.False) {
          const target = this.callflow.find((f) => conditions.workinghours.False === f.id);
          this.calcCoordinates(callflow, target, arrowOffsetBottom);
        }
        if (conditions && conditions.workinghours && conditions.workinghours.True) {
          const target = this.callflow.find((f) => conditions.workinghours.True === f.id);
          this.calcCoordinates(callflow, target, arrowOffsetTop);
        }
        if (conditions && conditions.SiteDR && conditions.SiteDR.False) {
          const target = this.callflow.find((f) => conditions.SiteDR.False === f.id);
          this.calcCoordinates(callflow, target, arrowOffsetBottom);
        }
        if (conditions && conditions.SiteDR && conditions.SiteDR.True) {
          const target = this.callflow.find((f) => conditions.SiteDR.True === f.id);
          this.calcCoordinates(callflow, target, arrowOffsetTop);
        }
        if (conditions && conditions.CheckDCL && conditions.CheckDCL.next) {
          const target = this.callflow.find((f) => conditions.CheckDCL.next === f.id);
          this.calcCoordinates(callflow, target, arrowOffsetTop);
        }
        if (conditions && conditions.CheckDCL && conditions.CheckDCL.overflow) {
          const target = this.callflow.find((f) => conditions.CheckDCL.overflow === f.id);
          this.calcCoordinates(callflow, target, arrowOffsetBottom);
        }
      });
    },
  },
};

function drawArrow(context, fromx, fromy, tox, toy) {
  const headlen = 8; // length of head in pixels
  const cornerrad = Math.abs(toy - fromy) < 5 ? Math.abs(toy - fromy) : 5; // corner radius in pixels
  // var angle = Math.atan2(toy-fromy,tox-fromx);
  context.moveTo(fromx, fromy);
  // context.lineTo(tox, toy);
  // context.lineTo(tox-headlen*Math.cos(angle-Math.PI/6),toy-headlen*Math.sin(angle-Math.PI/6));
  // context.moveTo(tox, toy);
  // context.lineTo(tox-headlen*Math.cos(angle+Math.PI/6),toy-headlen*Math.sin(angle+Math.PI/6));

  if (toy > fromy) {
    context.lineTo(fromx + ((tox - fromx) / 2) - cornerrad, fromy);
    context.arcTo(fromx + ((tox - fromx) / 2), fromy, fromx + ((tox - fromx) / 2), fromy + cornerrad, cornerrad);
    context.lineTo(fromx + ((tox - fromx) / 2), toy - cornerrad);
    context.arcTo(fromx + ((tox - fromx) / 2), toy, fromx + ((tox - fromx) / 2) + cornerrad, toy, cornerrad);
  } else if (toy < fromy) {
    context.lineTo(fromx + ((tox - fromx) / 2) - cornerrad, fromy);
    context.arcTo(fromx + ((tox - fromx) / 2), fromy, fromx + ((tox - fromx) / 2), fromy - cornerrad, cornerrad);
    context.lineTo(fromx + ((tox - fromx) / 2), toy + cornerrad);
    context.arcTo(fromx + ((tox - fromx) / 2), toy, fromx + ((tox - fromx) / 2) + cornerrad, toy, cornerrad);
  }
  context.lineTo(tox, toy);

  context.lineTo(tox - headlen, toy - headlen);
  context.moveTo(tox, toy);
  context.lineTo(tox - headlen, toy + headlen);
}
</script>

<style>
.floweditor {
    height: 1021px;
}

#cfe_canvas {
    /* position: absolute; */
    z-index: 1;
}

#cfe_vue {
  margin-top: -1005px;
}

.v-chip {
  max-width: unset;
  overflow: visible;
}
</style>
