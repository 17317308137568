<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
  >
    <v-layout
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            Incoming Traffic
          </v-toolbar-title>
          <v-spacer />
          <v-select
            v-model="period"
            dense
            required
            :items="timePeriods"
            style="width: 100px;"
            class="body-2 widget__select"
          />
          <v-spacer />
          <v-btn
            icon
            @click="loadCalls"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
      <v-container style="height: 100%; overflow-y: scroll; background-color: #FFF;">
        <v-layout
          v-if="!dataLoaded"
          align-center
          justify-center
          row
          fill-height
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-layout>
        <linechart
          v-if="dataLoaded"
          chart-title="Incoming Traffic"
          :height="252"
          :chart-data="callChart"
          :options="chartOptions"
        />
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';

import config from '@/config.js';
import linechart from '@/components/widgets/charts/linechart';
import ResizeContainer from '@/components/widgets/layout/ResizeContainer';

export default {
  name: 'Inboundtraffix',
  components: {
    linechart,
    'resize-container': ResizeContainer,
  },
  props: {
    id: String,
    layoutProps: Object,
    data: {
      type: Object,
      default() {
        return {
          period: 'TODAY',
        };
      },
    },
  },
  data() {
    return {
      period: this.data.period,
      dataLoaded: false,
      callChart: {
        labels: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
        datasets: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      },
      timePeriods: [
        { text: 'Today', value: 'TODAY' },
        { text: 'Yesterday', value: 'YESTERDAY' },
        { text: 'Same day last week', value: 'LASTWEEK' },
      ],
      chartOptions: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
          }],
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'authentication',
      'timeZone',
    ]),
  },
  watch: {
    period() {
      this.loadCalls();
      this.$emit('saveWidgetData', this.id, { period: this.period });
    },
    authentication: {
      handler() {
        this.dataLoaded = false;
        this.loadCalls();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadCalls();
  },
  methods: {
    loadCalls() {
      this.dataLoaded = false;

      const startDate = moment().tz(this.timeZone).startOf('day');
      const endDate = moment().tz(this.timeZone).endOf('day');

      if (this.period === 'YESTERDAY') {
        startDate.subtract(1, 'day');
        endDate.subtract(1, 'day');
      } else if (this.period === 'LASTWEEK') {
        startDate.subtract(7, 'day');
        endDate.subtract(7, 'day');
      }

      axios.get(`${config.bizvu_analytics_url}/account/${this.selectedAccountId}/incomingtraffic?startdate=${startDate.toISOString()}&enddate=${endDate.toISOString()}&timeZone=${this.timeZone}`)
        .then((response) => {
          const callData = {
            label: 'Incoming Calls',
            data: response.data,
          };
          this.callChart.datasets = [callData];
          this.dataLoaded = true;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
  },
};
</script>

<style scoped>
</style>
