<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Capture Template
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/tenants/<tenant_name>/capturetemplates/<pcIssuerOrId>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
      v-model="valid"
      class="pa-2"
      lazy-validation
    >
      <v-container fluid>
        <v-row dense>
          <v-col cols="2">
            <v-select
              v-model="captureTemplate.TransactionType"
              :items="transactionType"
              :disabled="!createMode"
              :rules="transactionTypeRules"
              label="TransactionType"
              required
            />
          </v-col>
        </v-row>

        <div v-if="captureTemplate.TransactionType === 'CardPayment'">
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="captureTemplate.Issuer"
                :rules="issuerRules"
                label="Issuer"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="captureTemplate.Pan"
                label="Pan"
                :rules="panRules"
              />
            </v-col>
            <v-col cols="2" />
            <v-col>
              <v-text-field
                v-model="captureTemplate.PanRegex"
                label="PanRegex"
                :rules="panRegexRules"
              />
            </v-col>
            <v-col cols="4" />
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="captureTemplate.IinDescriptor"
                label="IinDescriptor"
                :rules="iInDescriptorRules"
              />
            </v-col>
            <v-col cols="2" />
            <v-col>
              <v-text-field
                v-model="captureTemplate.IinRegex"
                label="IinRegex"
                :rules="iInRegexRules"
              />
            </v-col>
            <v-col cols="4" />
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="captureTemplate.ExpiryMonth"
                label="Expiry Month"
                :rules="expiryMonthRules"
              />
            </v-col>
            <v-col cols="2" />
            <v-col>
              <v-text-field
                v-model="captureTemplate.ExpiryYear"
                label="Expiry Year"
                :rules="expiryYearRules"
              />
            </v-col>
            <v-col cols="4" />
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-text-field
                v-model="captureTemplate.Csc"
                label="Csc"
                :rules="cscRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-text-field
                v-model="captureTemplate.IssueNumber"
                label="Issue Number"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="captureTemplate.StartMonth"
                label="Start Month"
              />
            </v-col>
            <v-col cols="2" />
            <v-col>
              <v-text-field
                v-model="captureTemplate.StartYear"
                label="Start Year"
              />
            </v-col>
            <v-col cols="4" />
          </v-row>
        </div>

        <div v-if="captureTemplate.TransactionType === 'AccountCapture'">
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                v-model="captureTemplate.Issuer"
                label="Account"
                :rules="issuerRules"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                v-model="captureTemplate.AccountNumber"
                label="Account Number"
                :rules="accountNumberRules"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                v-model="captureTemplate.SortCode"
                label="Sort Code"
                :rules="sortCodeRules"
              />
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col>
            <v-btn
              primary
              color="primary"
              dark
              @click="createMode ? createCaptureTemplate() : updateCaptureTemplate()"
            >
              Save
            </v-btn>
            <v-btn
              class="ml-2"
              @click="$router.back()"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this payment capture template?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteCaptureTemplate"
          >
            Confirm
          </v-btn>
          <v-btn
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

import { BTL_API_TENANT_URL } from '@/config';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'CaptureTemplate',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,

      transactionType: [
        { text: 'Card', value: 'CardPayment' },
        { text: 'Bank Account', value: 'AccountCapture' },
      ],

      captureTemplateId: this.$route.params.id,
      captureTemplate: {},

      transactionTypeRules: [
        (v) => (v != null && String(v).length > 0) || 'Transaction Type is required',
      ],
      issuerRules: [
        (v) => (v != null && String(v).length > 0) || 'Issuer is required',
      ],
      panRules: [
        (v) => (v != null && String(v).length > 0) || 'Pan is required',
      ],
      panRegexRules: [
        (v) => (v != null && String(v).length > 0) || 'PanRegex is required',
      ],
      iInDescriptorRules: [
        (v) => (v != null && String(v).length > 0) || 'iInDescriptor is required',
      ],
      iInRegexRules: [
        (v) => (v != null && String(v).length > 0) || 'PanRegex is required',
      ],
      expiryMonthRules: [
        (v) => (v != null && String(v).length > 0) || 'Expiry Month is required',
      ],
      expiryYearRules: [
        (v) => (v != null && String(v).length > 0) || 'Expiry Year is required',
      ],
      cscRules: [
        (v) => (v != null && String(v).length > 0) || 'Csv is required',
      ],

      accountNumberRules: [
        (v) => (v != null && String(v).length > 0) || 'Account Number is required',
      ],
      sortCodeRules: [
        (v) => (v != null && String(v).length > 0) || 'Sort Code is required',
      ],
    };
  },
  mounted() {
    if (this.captureTemplateId === 'new') {
      this.createMode = true;
    } else {
      this.loadCaptureTemplate();
    }
  },
  methods: {
    nullifyEmptyFields() {
      const captureTemplate = {};
      Object.entries(this.captureTemplate).forEach(([field, value]) => {
        captureTemplate[field] = value && value.length > 0 ? value : null;
      });
      return captureTemplate;
    },
    async loadCaptureTemplate() {
      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/capturetemplates/${this.captureTemplateId}`);
        this.captureTemplate = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to load capture template', true);
        console.error(e);
      }
    },
    async createCaptureTemplate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        await axios.post(`${BTL_API_TENANT_URL}/capturetemplates`, this.nullifyEmptyFields());
        this.$emit('snack', 'Capture template created');
        this.$router.back();
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.$emit('snack', `${e.response.data.message} ${e.response.data.extra}`, true);
        } else {
          this.$emit('snack', 'Failed to update capture template', true);
          console.error(e);
        }
      }
    },
    async updateCaptureTemplate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        await axios.put(`${BTL_API_TENANT_URL}/capturetemplates/${this.captureTemplateId}`, this.nullifyEmptyFields());
        this.$emit('snack', 'Capture template updated');
        this.$router.back();
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.$emit('snack', `${e.response.data.message} ${e.response.data.extra}`, true);
        } else {
          this.$emit('snack', 'Failed to update capture template', true);
          console.error(e);
        }
      }
    },
    async deleteCaptureTemplate() {
      try {
        await axios.delete(`${BTL_API_TENANT_URL}/capturetemplates/${this.captureTemplateId}`);
        this.$emit('snack', 'Capture template deleted');
        this.$router.back();
      } catch (e) {
        this.$emit('snack', 'Failed to delete capture template', true);
        console.error(e);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
