<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        National Rates Management
      </v-toolbar-title>
      <v-autocomplete
        v-model="accountFilter"
        flat
        :items="accounts"
        item-text="name"
        item-value="account_id"
        label="Account filter"
        single-line
        hide-details
        style="max-width: 20rem"
      />
      <v-btn
        text
        color="blue"
        @click="$router.push('/nationalrate/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="rates"
        :headers="exportHeaders"
        report-name="National Rates"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="rates"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="NRId"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            :custom-sort="customSort"
            sort-by="ChargeBand"
            height="calc(100vh - 220px)"
          >
            <template #item.AccountName="{ item }">
              <router-link
                v-if="isPermitted('/nationals/<nrid>', 'GET', true)"
                :to="item.itemRoute"
              >
                {{ item.AccountName || 'Base' }}
              </router-link>
              <span v-else>{{ item.AccountName || 'Base' }}</span>
            </template>
            <template #item.ChargeBand="{ item }">
              <span>{{ item.ChargeBand }}</span>
            </template>
            <template #item.CostDayRate="{ item }">
              <span>{{ item.CostDayRate | formatMoney }}</span>
            </template>
            <template #item.CostEveRate="{ item }">
              <span>{{ item.CostEveRate | formatMoney }}</span>
            </template>
            <template #item.CostWendRate="{ item }">
              <span>{{ item.CostWendRate | formatMoney }}</span>
            </template>
            <template #item.CostConFee="{ item }">
              <span>{{ item.CostConFee | formatMoney }}</span>
            </template>
            <template #item.SellDayRate="{ item }">
              <span>{{ item.SellDayRate | formatMoney }}</span>
            </template>
            <template #item.SellEveRate="{ item }">
              <span>{{ item.SellEveRate | formatMoney }}</span>
            </template>
            <template #item.SellWendRate="{ item }">
              <span>{{ item.SellWendRate | formatMoney }}</span>
            </template>
            <template #item.SellConFee="{ item }">
              <span>{{ item.SellConFee | formatMoney }}</span>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { sortBy } from 'lodash';
import config from '@/config.js';
import download from '@/components/download/tableDownload';
import customSort from '@/utils/customSort';
import permittedFunction from '@/permittedFunction.js';

import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'NationalRatesManagement',
  components: {
    download,
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatMoney(value) {
      return currencyFormatter(value);
    },
  },
  data() {
    const headers = [
      { text: 'Account', value: 'AccountName' },
      { text: 'Charge Band', value: 'ChargeBand' },
      { text: 'Carrier', value: 'Carrier' },
      { text: 'Cost Day Rate', value: 'CostDayRate' },
      { text: 'Cost Evening Rate', value: 'CostEveRate' },
      { text: 'Cost Weekend Rate', value: 'CostWendRate' },
      { text: 'Cost Connection Fee', value: 'CostConFee' },
      { text: 'Sell Day Rate', value: 'SellDayRate' },
      { text: 'Sell Evening Rate', value: 'SellEveRate' },
      { text: 'Sell Weekend Rate', value: 'SellWendRate' },
      { text: 'Sell Connection Fee', value: 'SellConFee' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      rates: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      accounts: [],
      accountFilter: null,
      loading: false,
    };
  },
  watch: {
    accountFilter() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        await this.loadAccounts();
        await this.loadRates();
      } catch (error) {
        console.error('Could not load data: ', error.message);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadRates() {
      const response = await axios.get(`${config.BTL_API_TENANT_URL}/nationals`);

      this.rates = response.data
        .filter((row) => row.AccountId === this.accountFilter)
        .map((row) => {
          row.itemRoute = `/nationalrate/${row.NRId}`;
          row.AccountName = '';
          row.ChargeBand = 'National';
          if (this.accounts.length > 0) {
            if (row.AccountId) {
              const acc = this.accounts.find((a) => a.account_id === row.AccountId);
              if (acc) {
                row.AccountName = acc.name;
              }
            }
          }

          return row;
        });
    },
    async loadAccounts() {
      const response = await axios.get(config.BTL_API_ACCOUNT_URL);
      this.accounts = [
        { name: 'Base Rates', account_id: null },
        ...sortBy(response.data, [(account) => account.name.toLowerCase()]),
      ];
    },
    isPermitted(endpoint, verb, accountOrTenant = false) {
      const prefixedEndpoint = ((accountOrTenant && this.accountFilter) ? '/tenants/<tenant_name>/accounts/<account_id>' : '/tenants/<tenant_name>') + endpoint;
      return permittedFunction(prefixedEndpoint, verb);
    },
    customSort: customSort('ModifiedOn'),
  },
};
</script>
