<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Number Management
      </v-toolbar-title>

      <v-autocomplete
        v-model="accountFilter"
        flat
        :items="accounts"
        item-text="name"
        item-value="account_id"
        label="Account filter"
        hide-details
        style="max-width: 20rem;"
        single-line
      />
      <v-btn
        text
        color="blue"
        @click="$router.push('/newnumber')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        v-if="accountFilter==='unallocated' && isNumberSelected"
        text
        color="blue"
        @click="allocDialog=true"
      >
        <v-icon class="mr-2">
          group_add
        </v-icon>Allocate
      </v-btn>
      <v-btn
        v-if="accountFilter==='unallocated' && isNumberSelected"
        text
        color="blue"
        @click="deleteDialog=true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-btn
        v-if="accountFilter !== 'unallocated' && isNumberSelected"
        text
        color="blue"
        @click="deallocDialog=true"
      >
        <v-icon class="mr-2">
          remove_circle_outline
        </v-icon>De-allocate
      </v-btn>
      <v-btn
        v-if="isNumberSelected"
        text
        color="blue"
        @click="editNumberDialog=true"
      >
        <v-icon pl-1>
          create
        </v-icon>&nbsp;Edit
      </v-btn>
      <v-btn
        v-if="permittedFunction('/tenants/<tenant_name>/phonenumbers/batch', 'POST')"
        text
        color="blue"
        @click="importDialog = true"
      >
        <v-icon class="mr-2">
          cloud_upload
        </v-icon>Import
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="numbers"
        :headers="exportHeaders"
        report-name="Number Management"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :options="tableOptions"
            :items="numbers"
            show-select
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="PhoneNumber"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 205px)"
            @update:options="handleTableOptionsUpdate"
          >
            <template #item.PhoneNumber="props">
              <td>
                <router-link :to="props.item.detailsRoute">
                  {{ props.item.PhoneNumber }}
                </router-link>
              </td>
            </template>
            <template #item.Enabled="props">
              <td class="text-center">
                <v-icon
                  v-if="props.item.Enabled"
                  color="#1565C0"
                >
                  toggle_on
                </v-icon>
                <v-icon
                  v-else
                  color="grey"
                >
                  toggle_off
                </v-icon>
              </td>
            </template>
            <template #item.LastCallDate="{ item }">
              <span>{{ item.LastCallDate ? formatDate(item.LastCallDate) : '' }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="allocDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>
          Allocate numbers to an account
        </v-card-title>
        <v-subheader>Allocate these numbers:</v-subheader>
        <v-card-text>
          <div class="text-center">
            <v-chip
              v-for="n in selected"
              :key="n.PhoneNumber"
              class="ma-1"
              color="blue"
              text-color="white"
            >
              <v-icon left>
                call
              </v-icon>
              {{ n.PhoneNumber }}
            </v-chip>
          </div>
          <v-form v-model="allocateFormValid">
            <v-select
              v-model="allocId"
              flat
              :items="saccounts"
              item-text="name"
              item-value="account_id"
              label="Account"
              :rules="generalRules"
            />
            <v-text-field
              v-model="allocateName"
              label="Name"
              :rules="generalRules"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            @click="allocateNumbers"
          >
            Allocate
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="allocDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deallocDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Number deallocation
        </v-card-title>
        <v-card-text>Do you want to deallocate the number from account {{ account.name }}?</v-card-text>
        <v-subheader>Deallocate these numbers:</v-subheader>
        <v-layout
          wrap
          class="px-3"
        >
          <v-flex
            v-for="n in selected"
            :key="n.PhoneNumber"
            xs6
            md4
          >
            <p>{{ n.PhoneNumber }}</p>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            @click="deallocateNumbers"
          >
            Deallocate
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="deallocDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Delete numbers
        </v-card-title>
        <v-card-text>Do you want to delete these numbers?</v-card-text>
        <v-layout
          wrap
          class="px-3"
        >
          <v-flex
            v-for="n in selectedNumbers"
            :key="n.PhoneNumber"
            xs6
            md4
          >
            <p>{{ n.PhoneNumber }}</p>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            @click="deleteNumbers"
          >
            Delete
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="deleteDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editNumberDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Edit Numbers
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-chip
              v-for="n in selected"
              :key="n.PhoneNumber"
              class="ma-2"
              color="blue"
              text-color="white"
            >
              <v-avatar left>
                <v-icon>call</v-icon>
              </v-avatar>
              {{ n.PhoneNumber }}
            </v-chip>
          </div>

          <v-form
            v-model="editValid"
          >
            <v-text-field
              v-model="number.Label"
              label="Name"
              :rules="notesRules"
            />
            <v-text-field
              v-model="number.Notes"
              label="Notes"
              :rules="notesRules"
              :counter="80"
            />
            <v-select
              v-model="number.PhoneType"
              flat
              :items="numberTypes"
              item-text="text"
              item-value="value"
              label="Number type"
            />
            <v-text-field
              v-model="number.Maintainer"
              label="Maintainer"
              :counter="60"
            />
            <v-select
              v-model="maskingType"
              flat
              :items="maskingTypes"
              label="Masking CLI"
            />
            <v-text-field
              v-if="maskingType === 'e164'"
              v-model="maskingNumber"
              label="Masking Number"
              :rules="numberRules"
              :counter="13"
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            @click="editNumbers"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="editNumberDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <number-importer
      :show="importDialog"
      :accounts="importAccounts"
      @close="importDialog = false"
      @importRun="loadData"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';

import {
  reactive, ref, computed, watch, toRefs, onMounted,
} from 'vue';

import config from '@/config.js';

import { useStore } from '@/utils/vue';
import NumberImporter from '@/components/NumberImporter.vue';
import download from '@/components/download/tableDownload';
import permittedFunction from '@/permittedFunction.js';
import moment from 'moment';
import usePagination from '../../composables/tablePaginationViaQueryString';

export default {
  name: 'NumberManagement',
  components: {
    NumberImporter,
    download,
  },
  setup(props, context) {
    const store = useStore();

    // refs
    const containerRef = ref(null);

    // composables
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 100, sortBy: 'name' });

    const state = reactive({

      selectedAccountId: computed(() => store.getters.selectedAccountId),

      accountFilter: 'unallocated',

      deallocDialog: false,
      deleteDialog: false,
      allocDialog: false,
      editNumberDialog: false,
      importDialog: false,

      selected: [],
      headers: [
        { text: 'Number', value: 'PhoneNumber' },
        { text: 'Enabled', value: 'Enabled' },
        { text: 'Account', value: 'name' },
        { text: 'Last Call Date', value: 'LastCallDate' },
        { text: 'Name', value: 'Label' },
        { text: 'Notes', value: 'Notes' },
      ],
      exportHeaders: [
        { text: 'DDI', value: 'PhoneNumber' },
        { text: 'Account Name', value: 'name' },
        { text: 'Enabled', value: 'Enabled' },
        { text: 'Name', value: 'Label' },
        { text: 'Last Call Date', value: 'LastCallDate' },
      ],
      numbers: [],

      accounts: [],
      saccounts: [],
      account: { name: '' },

      unAllocatedFilter: { name: '--  Unallocated  --', account_id: 'unallocated' },
      allAccountsFiler: { name: '--  All  --', account_id: 'all' },

      loading: false,

      editValid: true,
      number: {
      },
      maskingType: null,
      maskingNumber: '',
      numberTypes: [
        { value: 'GEO', text: 'Geographic' },
        { value: 'NONGEO', text: 'Non-Geographic' },
      ],
      maskingTypes: [
        { value: '', text: 'None' },
        { value: 'Anonymous', text: 'Anonymous' },
        { value: 'e164', text: 'E164 Phone Number' },
      ],
      numberRules: [
        (v) => !!v || 'Phone number is required',
        (v) => (v && v.length <= 13) || 'Phone number must not have more than 12 characters',
      ],
      notesRules: [
        (v) => (!v || v.length <= 80) || 'Notes must not have more than 80 characters',
      ],

      importAccounts: [],

      allocId: null,
      allocateName: null,
      allocateFormValid: false,
      generalRules: [
        (v) => !!v || 'Field is required',
      ],
    });

    const formatDate = (date) => moment(date).format(config.date_format);

    const isNumberSelected = computed(() => state.selected.length > 0);
    const selectedNumbers = computed(() => state.numbers.filter((number) => number.isSelected));

    const loadNumbers = async () => {
      try {
        const numberURL = state.accountFilter === 'unallocated' || state.accountFilter === 'all' ? `${config.BTL_API_TENANT_URL}/phonenumbers` : `${config.BTL_API_TENANT_URL}/accounts/${state.accountFilter}/phonenumbers`;

        const { data: phonenumbers } = await axios.get(numberURL, {
          dataType: 'json',
        });

        state.numbers = [];
        state.selected = [];

        phonenumbers.forEach((row) => {
          if (state.accountFilter === 'all' || state.accountFilter === 'unallocated' || row.AccountId === state.accountFilter) {
            row.detailsRoute = `/managenumber/${row.PhoneNumber}`;
            // WORK AROUND
            if (row.AccountId === 'null') {
              console.error('ERROR NUMBER', row);
              return;
            }
            if (state.accounts.length > 0) {
              const acc = state.accounts.find((a) => a.account_id === row.AccountId);
              if (acc) {
                row.name = acc.name;
              } else {
                row.name = '';
              }
            } else {
              row.name = '';
            }
            if ((state.accountFilter === 'all') || (state.accountFilter === 'unallocated' && row.name === '') || (state.accountFilter !== 'unallocated' && row.name !== '')) {
              state.numbers.push(row);
            }
          }
        });
      } catch (error) {
        console.error('ERROR', error);
      } finally {
        state.loading = false;
      }
    };

    const loadAccounts = async () => {
      try {
        const { data } = await axios.get(config.BTL_API_ACCOUNT_URL);
        state.accounts = [];
        data.forEach((a) => {
          if (a.name === 'SYSTEM') {
            a.name = 'Not Allocated';
          }
          state.saccounts.push(a);
          state.saccounts = sortBy(state.saccounts, ['name']);
        });

        const sortedAccounts = sortBy(data, ['name']);

        state.importAccounts = sortedAccounts;
        state.accounts = [state.unAllocatedFilter, state.allAccountsFiler].concat(sortedAccounts);
      } catch (error) {
        console.error('ERROR', error.response);
      }
    };

    const loadData = async () => {
      state.loading = true;
      await loadAccounts();
      loadNumbers();
    };

    const deallocateNumbers = () => {
      const payload = { apply_to: state.selected.map((number) => number.PhoneNumber), account_id: state.selectedAccountId };

      axios.delete(`${config.BTL_API_TENANT_URL}/phonenumbers/allocation/batch`, { data: payload })
        .then(() => {
          context.emit('snack', 'Numbers successfully de-allocated!');
          state.deallocDialog = false;
          loadData();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    };

    const deleteNumbers = () => {
      const payload = { apply_to: state.selected.map((number) => number.PhoneNumber), account_id: state.selectedAccountId };

      axios.delete(`${config.BTL_API_TENANT_URL}/phonenumbers/batch`, { data: payload })
        .then(() => {
          context.emit('snack', 'Numbers successfully deleted!');
          state.deleteDialog = false;
          loadData();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    };

    const allocateNumbers = () => {
      if (!state.allocateFormValid) {
        return;
      }

      const payload = {
        apply_to: state.selected.map((number) => number.PhoneNumber),
        expires: 365000,
        account_id: state.allocId,
        name: state.allocateName,
      };
      axios.post(`${config.BTL_API_TENANT_URL}/phonenumbers/allocation/batch`, payload)
        .then(() => {
          context.emit('snack', 'Numbers successfully allocated!');
          state.allocDialog = false;
          loadData();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    };

    const editNumbers = () => {
      if (!state.editValid) {
        return;
      }

      const body = {
        ...state.number,
        apply_to: state.selected.map((n) => n.PhoneNumber),
      };

      axios.put(`${config.BTL_API_TENANT_URL}/phonenumbers/batch`, body)
        .then(() => {
          context.emit('snack', 'Numbers successfully updated!');
          state.editNumberDialog = false;
          state.selected = [];
          state.number = {};
          state.maskingType = '';
          loadData();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    };

    watch(() => state.accountFilter, () => {
      state.loading = true;
      loadNumbers();
    });

    watch(() => state.maskingType, (selectedMaskingType) => {
      if (selectedMaskingType !== 'e164') {
        state.number.Masking = selectedMaskingType;
      } else {
        state.number.Masking = state.maskingNumber;
      }
    });

    watch(() => state.maskingNumber, (maskingNumber) => {
      state.number.Masking = maskingNumber;
    });

    saveQueryParams();
    onMounted(async () => {
      await loadData();
      processQueryParams();
    });

    return {
      containerRef,
      ...toRefs(state),
      isNumberSelected,
      selectedNumbers,
      loadData,
      editNumbers,
      deleteNumbers,
      allocateNumbers,
      deallocateNumbers,
      tableOptions,
      handleTableOptionsUpdate,
      permittedFunction,
      formatDate,
    };
  },
};
</script>

<style scoped>
</style>
