<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Tenant Services
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/services', 'POST')"
        color="blue"
        text
        @click="$router.push('/tenantservices/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="tenantServices"
        :headers="exportHeaders"
        report-name="Tenant Services"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="tenantServices"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 220px)"
          >
            <template #item.Name="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/services/<service_id>', 'GET')"
                :to="`tenantservices/${item.service_id}`"
              >
                {{ item.Name }}
              </router-link>
              <span v-else> {{ item.Name }} </span>
            </template>
            <template #item.ServiceCharges="{ item }">
              <lookup
                v-if="isPermitted('/tenants/<tenant_name>/servicecharges', 'GET')"
                :title="'Service Charges'"
              >
                <ServiceServiceCharges :data="serviceCharges[item.service_id]" />
              </lookup>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import Lookup from '@/components/Lookup';
import download from '@/components/download/tableDownload';
import ServiceServiceCharges from '@/components/lookups/ServiceServiceCharges';

import permittedFunction from '@/permittedFunction.js';
import config, { BTL_API_TENANT_URL } from '@/config.js';

export default {
  name: 'TenantServices',
  components: {
    download,
    Lookup,
    ServiceServiceCharges,
  },
  filters: {
    formatDate(date) {
      return moment(date).format(config.date_format);
    },
  },
  data() {
    const headers = [
      { text: 'Name', value: 'Name' },
      { text: 'Description', value: 'Description' },
      { text: 'Service Charges', value: 'ServiceCharges' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      tenantServices: [],
      serviceCharges: {},
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      loading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadServices();
      this.loadServiceCharges();
    },
    async loadServices() {
      this.loading = true;

      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/services`);
        this.tenantServices = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve tenant services.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadServiceCharges() {
      this.loading = true;

      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/servicecharges`);
        this.serviceCharges = response.data.reduce((acc, cv) => {
          const returnValue = { ...acc };
          if (returnValue[cv.ServiceId]) {
            returnValue[cv.ServiceId].push(cv);
          } else {
            returnValue[cv.ServiceId] = [cv];
          }
          return returnValue;
        }, {});
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve service charges.', true);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
