<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Account Bundles
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/accountbundles', 'POST')"
        color="blue"
        text
        @click="$router.push('/accountbundles/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Assign Bundle
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="accountBundles"
        :headers="exportHeaders"
        report-name="Account Bundles"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>

    <v-container
      fluid
    >
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="accountBundles"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 220px)"
          >
            <template #item.bundle.BundleName="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/accountbundles/<abid>', 'GET')"
                :to="`accountbundles/${item.ABId}`"
              >
                {{ item.bundle.BundleName }}
              </router-link>
              <div v-else>
                {{ item.bundle.BundleName }}
              </div>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import moment from 'moment';
import config, { BTL_API_ACCOUNT_URL } from '@/config.js';

import permittedFunction from '@/permittedFunction.js';
import download from '@/components/download/tableDownload';

export default {
  name: 'AccountBundles',
  components: {
    download,
  },
  filters: {
    formatDate(date) {
      return moment(date).format(config.date_format);
    },
  },
  data() {
    const headers = [
      { text: 'Bundle Name', value: 'bundle.BundleName' },
      { text: 'Bundle Type', value: 'bundle.BundleType' },
      { text: 'Inclusive Minutes', value: 'bundle.InclusiveMinutes' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      accountBundles: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      loading: false,
      tableHeight: 0,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        await this.loadAccountBundles();
      } finally {
        this.loading = false;
      }
    },
    async loadAccountBundles() {
      this.loading = true;

      try {
        const response = await axios.get(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountbundles`);
        this.accountBundles = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve account bundles.', true);
      }
    },
    onResize() {
      this.tableHeight = this.$refs.table.clientHeight - 175;
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
