<template>
  <v-container>
    <node-header
      icon="calendar_today"
      :title="name"
      :detail="siteOrCustomHours === 'custom' ? 'Custom' : siteName"
      :color-a="routeConfig.trueAction ==='none'"
      :color-b="routeConfig.falseAction ==='none'"
      chip-a="TRUE"
      chip-b="FALSE"
    />

    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="primary"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              edit
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Configure name</v-subheader>
          <v-list-item>
            <v-text-field
              v-model="name"
              label="Node Name"
            />
          </v-list-item>
          <v-list-item style="display: flex; flex-direction: column;">
            <v-radio-group
              v-model="siteOrCustomHours"
              class="workinghours-radio"
              row
            >
              <template #label>
                <div>
                  Check working hours:
                </div>
              </template>
              <div
                class="mt-2"
                style="display: flex; flex-direction: row;"
              >
                <v-radio
                  label="Site Hours"
                  value="site"
                />
                <v-radio
                  label="Custom Hours"
                  value="custom"
                />
              </div>
            </v-radio-group>

            <v-select
              v-if="siteOrCustomHours === 'site'"
              v-model="siteId"
              style="width: 100%"
              class="pa-0"
              label="Site"
              required
              :items="sites"
              item-text="SiteName"
              item-value="SiteId"
            />
            <div
              v-else
              style="width: 100%"
            >
              <TimeZonePicker
                :init-zone="timeZone"
                @update="handleTimeZoneUpdate"
              />
              <div style="display: flex; align-items: center; justify-content: space-between;">
                <v-dialog
                  v-model="hoursDialog"
                >
                  <v-card class="pa-8">
                    <OpeningHoursEditor
                      v-model="hours"
                      :hours="hours"
                    />
                    <div>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        style="margin-top: 16px;"
                        block
                        @click="hoursDialog = false"
                      >
                        close
                      </v-btn>
                    </div>
                  </v-card>
                </v-dialog>
              </div>
              <v-data-table
                :headers="hoursHeaders"
                height="200"
                fixed-header
                disable-sort
                :items="hoursTable"
                hide-default-footer
              >
                <template
                  #footer
                >
                  <v-btn
                    class="mt-2"
                    style="width: 100%"
                    color="primary"
                    small
                    @click="hoursDialog = true"
                  >
                    Edit Hours
                  </v-btn>
                </template>
              </v-data-table>
            </div>
          </v-list-item>
          <v-subheader>Add next step</v-subheader>
          <v-list-item class="mb-2">
            <v-select
              v-model="trueAction"
              label="On TRUE"
              :items="actions"
            />
          </v-list-item>
          <v-list-item class="mb-2">
            <v-select
              v-model="falseAction"
              label="On FALSE"
              :items="actions"
            />
          </v-list-item>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="handleCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="menu_delete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleDelete"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import { typesForSelect } from './nodeTypes';

import NodeHeader from './partials/Header';
import OpeningHoursEditor from '../../openinghourseditor/OpeningHoursEditor';
import TimeZonePicker from '../../TimeZonePicker';

export default {
  components: {
    NodeHeader,
    OpeningHoursEditor,
    TimeZonePicker,
  },
  props: {
    node: Object,
    dialplan: Array,
    canedit: Boolean,
  },
  data() {
    return {
      valid: false,
      menu: false,
      menu_delete: false,
      width: 0,
      height: 0,
      sites: null,
      siteName: 'none',
      siteId: null,
      name: null,
      trueAction: 'none',
      falseAction: 'none',
      siteOrCustomHours: 'site',
      hoursDialog: false,
      actions: typesForSelect,
      timeZone: null,
      hours: {
        WeekDay1: [],
        WeekDay2: [],
        WeekDay3: [],
        WeekDay4: [],
        WeekDay5: [],
        WeekDay6: [],
        WeekDay7: [],
      },
      hoursHeaders: [
        {
          text: 'Weekday',
          value: 'weekday',
        },
        {
          text: 'Start',
          value: 'start',
        },
        {
          text: 'Finish',
          value: 'finish',
        },
      ],
      weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      routeConfig: {
        siteId: '',
        trueAction: 'none',
        falseAction: 'none',
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
    hoursTable() {
      const hours = [];
      Object.entries(this.hours).forEach((day) => {
        const dayKey = day[0];
        const dayValue = day[1];
        const weekday = this.weekdays[dayKey[7] /* this grabs '2' from 'Weekday2' */ - 1];
        dayValue.forEach((dayHours) => {
          const { finish, start } = dayHours;
          hours.push({
            weekday,
            finish,
            start,
          });
        });
      });

      return hours;
    },
  },
  watch: {
    siteId() {
      const site = this.sites.find((t) => t.SiteId === this.siteId);
      if (site) {
        this.siteName = site.SiteName;
      } else {
        this.siteName = 'none';
      }
    },
  },
  mounted() {
    this.loadSites();

    this.name = this.node.name;
    this.timeZone = this.node.parameters.timezone;
    this.siteOrCustomHours = this.timeZone ? 'custom' : 'site';
    if (this.node.parameters.workinghours && Object.values(this.node.parameters.workinghours).length) {
      this.hours = this.node.parameters.workinghours;
    } else {
      this.loadAccountWorkingHours();
    }

    this.falseAction = this.getLabel(this.node.conditions.workinghours.False);
    this.routeConfig.falseAction = this.getLabel(this.node.conditions.workinghours.False);
    this.trueAction = this.getLabel(this.node.conditions.workinghours.True);
    this.routeConfig.trueAction = this.getLabel(this.node.conditions.workinghours.True);
  },
  methods: {
    getLabel(nodeId) {
      const node = this.dialplan.find((n) => n.id === nodeId);
      if (node) {
        return node.action;
      }
      return 'none';
    },
    handleDelete() {
      this.$emit('deletenode');
    },
    handleSave() {
      this.menu = false;
      this.node.name = this.name;

      if (this.siteOrCustomHours === 'site') {
        this.node.parameters.siteid = this.siteId;
        delete this.node.parameters.workinghours;
        delete this.node.parameters.timezone;
      } else {
        this.node.parameters.workinghours = this.hours;
        this.node.parameters.timezone = this.timeZone;
        delete this.node.parameters.siteid;
      }

      this.node.routeConfig = this.routeConfig;
      if (this.routeConfig.trueAction !== this.trueAction) {
        this.routeConfig.trueAction = this.trueAction;
        if (this.trueAction === 'none') {
          this.node.conditions.workinghours.True = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.trueAction, 'WHTRUE');
        }
      }
      if (this.routeConfig.falseAction !== this.falseAction) {
        this.routeConfig.falseAction = this.falseAction;
        if (this.falseAction === 'none') {
          this.node.conditions.workinghours.True = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.falseAction, 'WHFALSE');
        }
      }
    },
    handleCancel() {
      this.menu = false;
      this.siteId = this.node.parameters.siteid;
      this.hours = this.node.parameters.workinghours;
      this.timeZone = this.node.parameters.timezone;
      this.name = this.node.name;
    },
    loadSites() {
      const self = this;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${self.selectedAccountId}/sites`)
        .then((response) => {
          self.sites = response.data;
          if (self.node.parameters && self.node.parameters.siteid) {
            self.siteId = self.node.parameters.siteid;
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
    async loadAccountWorkingHours() {
      try {
        const {
          data: {
            Hours,
          },
        } = await axios.get(`${config.bizvu_account_url}/${this.selectedAccountId}/workinghours`);
        this.hours = Hours || this.hours;
      } catch (error) {
        console.error('ERROR', error);
      }
    },
    handleTimeZoneUpdate(timeZone) {
      this.timeZone = timeZone;
    },
  },
};
</script>

<style>

.workinghours-radio {
  margin-top: unset;
  width: 100%;
}

.workinghours-radio [role=radiogroup] {
  display: flex !important;
  flex-direction: column !important;
}

.workinghours-radio legend {
  width: 100%;
}

</style>

<style scoped>
  .v-list-item::after {
    min-height: unset;
  }
</style>
