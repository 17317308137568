<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Exception
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/hcdexceptions/<hcdexceptionid>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-layout v-if="!createMode">
          <v-flex>
            <v-text-field
              readonly
              label="Exception ID"
              :value="exceptionID"
            />
          </v-flex>
        </v-layout>
        <v-layout v-if="exceptionAccountID && !createMode">
          <v-flex>
            <v-text-field
              readonly
              label="Account"
              :value="exception.account"
            />
          </v-flex>
        </v-layout>
        <v-layout v-if="createMode">
          <v-flex>
            <v-select
              v-model="localSelectedAccountId"
              flat
              :items="accounts"
              item-text="name"
              item-value="account_id"
              label="Account"
              :rules="rules.Account"
            />
          </v-flex>
        </v-layout>
        <v-row>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="exception.HCDCode"
                label="Code"
                :rules="rules.HCDCode"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="exception.HCDDescription"
                label="Description"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="exception.HCDCost"
                label="Cost (£0.00)"
                :rules="rules.HCDCost"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="exception.HCDDuration"
                label="Duration (Minutes)"
                :rules="rules.HCDDuration"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="exception.HCDCount"
                label="Count"
                :rules="rules.HCDCount"
              />
            </v-layout>
            <v-layout>
              <v-switch
                v-model="exception.AutoUpdate"
                label="Auto Update"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="exception.HCDToleranceByHour"
                type="number"
                label="Tolerance By Hour (%)"
                :rules="rules.HCDToleranceByHour"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="exception.HCDToleranceBySegment"
                type="number"
                label="Tolerance By Segment (%)"
                :rules="rules.HCDToleranceBySegment"
              />
            </v-layout>
            <v-layout>
              <v-switch
                v-model="exception.HCDIsOutOfHours"
                label="Is Out Of Hours"
              />
            </v-layout>
          </v-col>
        </v-row>

        <v-layout>
          <v-flex class="pa-2">
            <v-row>
              <v-btn
                v-if="isPermitted('/hcdexceptions/<hcdexceptionid>', 'PUT')"
                primary
                color="primary"
                dark
                @click="updateException"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this exception?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteException"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';

import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'HighCostDestinationException',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      exceptionID: this.$route.params.hcdexceptionid,
      exceptionAccountID: this.$route.params.accountid,
      exception: {
        HCDIsOutOfHours: false, // required so need to default to false
        AutoUpdate: true,
      },
      accounts: [],
      localSelectedAccountId: null,
      rules: {
        Account: [
          (v) => (v != null && String(v).length > 0) || 'Account is required',
        ],
        HCDCode: [
          (v) => (v != null && String(v).length > 0) || 'HDC Code is required',
        ],
        HCDCost: [
          (v) => (v != null && String(v).length > 0) || 'HCD Cost is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        HCDDuration: [
          (v) => (v != null && String(v).length > 0) || 'HCD Duration is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        HCDCount: [
          (v) => (v != null && String(v).length > 0) || 'HCD Count is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        HCDToleranceByHour: [
          (v) => !v || !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        HCDToleranceBySegment: [
          (v) => !v || !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
      },
    };
  },
  created() {
    if (this.exceptionID === 'new') {
      this.createMode = true;
      this.loadAccounts();
    } else {
      this.loadException();
    }
  },
  methods: {
    async updateException() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Exception successfully updated.';

        // Britannic ORM can't interpret empty strings as null so gotta convert them to nulls ourselves
        this.exception.HCDToleranceByHour = this.exception.HCDToleranceByHour === '' ? null : this.exception.HCDToleranceByHour;
        this.exception.HCDToleranceBySegment = this.exception.HCDToleranceBySegment === '' ? null : this.exception.HCDToleranceBySegment;

        if (this.createMode) {
          await axios.post(`${config.BTL_API_ACCOUNT_URL}/${this.localSelectedAccountId}/hcdexceptions`, this.exception);
          snackMessage = 'Exception successfully created.';
        } else {
          await axios.put(`${config.BTL_API_ACCOUNT_URL}/${this.exceptionAccountID}/hcdexceptions/${this.exceptionID}`, this.exception);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating exception', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} exception.`, true);
        const errorMessage = error?.response?.data;
        if (errorMessage) {
          this.$emit('snack', errorMessage, true);
        }
      }
    },
    async loadException() {
      try {
        const response = await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.exceptionAccountID}/hcdexceptions/${this.exceptionID}`);
        const exception = response.data;
        if (response.data.AccountId) {
          const account = (await axios.get(`${config.BTL_API_ACCOUNT_URL}/${response.data.AccountId}`)).data;
          this.exceptionAccountID = account.account_id;
          exception.account = account.name;
        }
        this.exception = exception;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve exception.', true);
        console.error('Failed to retrieve exception', error);
      }
    },
    async deleteException() {
      try {
        await axios.delete(`${config.BTL_API_ACCOUNT_URL}/${this.exceptionAccountID}/hcdexceptions/${this.exceptionID}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete exception.', true);
        console.error('Failed to delete exception', error);
      }
    },
    async loadAccounts() {
      try {
        const { data } = await axios.get(config.BTL_API_ACCOUNT_URL);
        this.accounts = sortBy(data, (account) => account.name.toLowerCase());
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve accounts.', true);
        console.error('Failed to retrieve accounts', error);
      }
    },
    async loadCarriers() {
      try {
        const response = await axios.get(`${config.bizvu_billing_catalog_url}/carriers`);
        this.carriers = response.data;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve carriers.', true);
        console.error('Failed to retrieve carriers', error);
      }
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>/accounts/<account_id>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
