<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        NetPCI Configuration
      </v-toolbar-title>
      <v-btn
        text
        color="primary"
        @click="changePasswordDialogue = true"
      >
        <v-icon>lock</v-icon>
        Change Password
      </v-btn>
    </v-toolbar>
    <v-form
      ref="form"
      v-model="valid"
      :lazy-validation="true"
      class="px-2 pb-2"
    >
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="merchant.PspId"
              :items="psps"
              :rules="rules.required"
              item-text="Name"
              item-value="Id"
              label="Payment Service Provider (PSP)"
            />
            <v-text-field
              v-model="merchant.PspUserAccountName"
              label="PSP User Account Name"
              :rules="rules.required"
            />
            <v-text-field
              v-model="merchant.PspUserId"
              label="PSP User ID"
              :rules="rules.required"
            />
            <v-text-field
              v-if="!merchantExists"
              v-model="merchant.PspUserPassword"
              :rules="rules.required"
              label="PSP Password"
            />
            <v-text-field
              v-model="merchant.PspUserAdditonal"
              label="PSP User Additonal"
            />
            <v-text-field
              v-model="merchant.MerchantPaymentsURL"
              label="Merchant Payments URL"
            />
            <v-text-field
              v-model="merchant.PspOverrideSpecificData"
              label="PSP Override Specific Data"
            />
            <v-text-field
              v-model="merchant.MerchantBankAccountsURL"
              label="Merchant Bank Accounts URL"
            />
            <v-text-field
              v-model="merchant.PspOverrideURL"
              label="PSP Override URL"
            />
            <v-text-field
              v-model="merchant.PspOverridePort"
              label="PSP Override Port"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              :value="allFlagsEnabled"
              color="primary"
              label="Toggle All"
              @click.stop="toggleAllFlags"
            />
            <v-divider />
            <v-checkbox
              v-model="merchant.AcceptUnvalidatedBankAccounts"
              color="primary"
              label="Accept Unvalidated Bank Accounts"
            />
            <v-checkbox
              v-model="merchant.ValidateBankAccounts"
              color="primary"
              label="Validate Bank Accounts"
            />
            <v-checkbox
              v-model="merchant.ShowCardStartDate"
              color="primary"
              label="Show Card Start Date"
            />
            <v-checkbox
              v-model="merchant.ShowLast4PanDigits"
              color="primary "
              label="Show Last 4 Pan Digits"
            />
            <v-checkbox
              v-model="merchant.ShowCardExpiryDate"
              color="primary"
              label="Show Card Expiry Date"
            />
            <v-checkbox
              v-model="merchant.ShareFirst6PanDigits"
              color="primary"
              label="Share First 6 Pan Digits"
            />
            <v-checkbox
              v-model="merchant.ShowBankDetails"
              color="primary"
              label="Show Bank Details"
            />
            <v-checkbox
              v-model="merchant.EnableCardPayment"
              color="primary"
              label="Enable Card Payment"
            />
            <v-checkbox
              v-model="merchant.ShowCardIssueNumber"
              color="primary"
              label="Show Card Issue Number"
            />
            <v-checkbox
              v-model="merchant.EnableAccountCapture"
              color="primary"
              label="Enable Account Capture"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="merchant.MerchantSSLCert"
              label="Merchant SSL Cert"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="merchant.Notes"
              label="Notes"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Accepted Payment Cards</v-card-title>
              <v-card-text>
                <v-data-table
                  v-model="selectedpcts"
                  :headers="pctHeaders"
                  :items="pcts"
                  item-key="Id"
                  item-value="Id"
                  show-select
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            class="mt-4"
            primary
            color="primary"
            dark
            @click="saveMerchant"
          >
            Save
          </v-btn>
        </v-row>
      </v-container>
    </v-form>

    <v-dialog
      v-model="changePasswordDialogue"
      max-width="350"
    >
      <v-card>
        <v-card-title>Change Password</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="passwordToSet"
              :rules="rules.required"
              label="PSP Password"
              counter="80"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="secondary"
            @click="changePasswordDialogue = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="primary"
            @click="changePassword"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { sortBy, differenceBy } from 'lodash';
import { mapGetters } from 'vuex';
import Snacks from '@/utils/snacks';

import { BTL_API_TENANT_URL } from '@/config.js';

export default {
  name: 'NetPCI',
  data() {
    return {
      merchant: {
        AcceptUnvalidatedBankAccounts: false,
        ValidateBankAccounts: false,
        ShowCardStartDate: false,
        ShowLast4PanDigits: false,
        ShowCardExpiryDate: false,
        ShareFirst6PanDigits: false,
        ShowBankDetails: false,
        EnableCardPayment: false,
        ShowCardIssueNumber: false,
        EnableAccountCapture: false,
      },
      psps: [],
      pcts: [],
      selectedpcts: [],
      pctHeaders: [
        { text: '', value: 'active' },
        { text: 'Issuer', value: 'Issuer' },
      ],
      merchantExists: true,
      valid: false,
      rules: {
        required: [(value) => !!value || 'Required.'],
      },

      changePasswordDialogue: false,
      passwordToSet: null,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
    allFlagsEnabled() {
      return (
        this.merchant.AcceptUnvalidatedBankAccounts
        && this.merchant.ValidateBankAccounts
        && this.merchant.ShowCardStartDate
        && this.merchant.ShowLast4PanDigits
        && this.merchant.ShowCardExpiryDate
        && this.merchant.ShareFirst6PanDigits
        && this.merchant.ShowBankDetails
        && this.merchant.EnableCardPayment
        && this.merchant.ShowCardIssueNumber
        && this.merchant.EnableAccountCapture
      );
    },
  },
  watch: {
    selectedAccountId() {
      this.loadMerchant();
    },
  },
  mounted() {
    this.loadMerchant();
    this.loadPSPs();
    this.loadPCTs();
  },
  methods: {
    async loadMerchant() {
      try {
        const { data: merchant } = await axios.get(`${BTL_API_TENANT_URL}/merchants/${this.selectedAccountId}`);
        this.merchant = merchant;
        this.selectedpcts = [...this.merchant.AcceptedPaymentCards].map((c) => ({
          Id: c.CaptureTemplateId,
          Issuer: c.CaptureTemplate.Issuer,
        }));
      } catch (err) {
        if (err.isAxiosError && err.response.status === 404) {
          this.merchantExists = false;
          this.merchant = {
            AcceptUnvalidatedBankAccounts: false,
            ValidateBankAccounts: false,
            ShowCardStartDate: false,
            ShowLast4PanDigits: false,
            ShowCardExpiryDate: false,
            ShareFirst6PanDigits: false,
            ShowBankDetails: false,
            EnableCardPayment: false,
            ShowCardIssueNumber: false,
            EnableAccountCapture: false,
            AccountId: this.selectedAccountId,
            AcceptedPaymentCards: [],
          };
          this.selectedpcts = [];
        }
      }
    },
    async loadPSPs() {
      const response = await axios.get(`${BTL_API_TENANT_URL}/paymentserviceproviders`);
      this.psps = response.data;
    },
    async loadPCTs() {
      const response = await axios.get(`${BTL_API_TENANT_URL}/capturetemplates`);
      this.pcts = sortBy(response.data.filter((p) => p.TransactionType === 'CardPayment'), 'Issuer');
    },
    toggleAllFlags() {
      const trueOrFalse = !this.allFlagsEnabled;
      this.merchant.AcceptUnvalidatedBankAccounts = trueOrFalse;
      this.merchant.ValidateBankAccounts = trueOrFalse;
      this.merchant.ShowCardStartDate = trueOrFalse;
      this.merchant.ShowLast4PanDigits = trueOrFalse;
      this.merchant.ShowCardExpiryDate = trueOrFalse;
      this.merchant.ShareFirst6PanDigits = trueOrFalse;
      this.merchant.ShowBankDetails = trueOrFalse;
      this.merchant.EnableCardPayment = trueOrFalse;
      this.merchant.ShowCardIssueNumber = trueOrFalse;
      this.merchant.EnableAccountCapture = trueOrFalse;
    },
    async saveMerchant() {
      await this.$refs.form.validate();
      if (!this.valid) {
        Snacks.$emit('error', 'Correct invalid fields before saving.');
        return;
      }

      try {
        const previousSelectedPCTS = this.merchant.AcceptedPaymentCards
          ? this.merchant.AcceptedPaymentCards.map((c) => ({ Id: c.CaptureTemplateId, Issuer: c.CaptureTemplate.Issuer }))
          : [];
        const addedPCTS = differenceBy(this.selectedpcts, previousSelectedPCTS, 'Id');
        const removedPCTS = differenceBy(previousSelectedPCTS, this.selectedpcts, 'Id');

        delete this.merchant.AcceptedPaymentCards;
        this.merchant.PspOverridePort = this.merchant.PspOverridePort || null;

        if (this.merchantExists) {
          await axios.put(`${BTL_API_TENANT_URL}/merchants/${this.selectedAccountId}`, this.merchant);
        } else {
          await axios.post(`${BTL_API_TENANT_URL}/merchants`, this.merchant);
        }

        await Promise.all(removedPCTS.map((pct) => axios.delete(`${BTL_API_TENANT_URL}/merchants/${this.selectedAccountId}/acceptedpaymentcards/${pct.Id}`)));
        await Promise.all(addedPCTS.map((pct) => axios.post(`${BTL_API_TENANT_URL}/merchants/${this.selectedAccountId}/acceptedpaymentcards/${pct.Id}`)));

        this.$emit('collapse');
        await this.loadMerchant();
        Snacks.$emit('info', 'Merchant saved successfully');
      } catch (error) {
        Snacks.$emit('error', 'Failed to save merchant');
        console.error('Failed to save merchant', error);
      }
    },
    async changePassword() {
      try {
        const merchant = {
          PspUserPassword: this.passwordToSet,
        };
        const { data: updatedMerchant } = await axios.put(`${BTL_API_TENANT_URL}/merchants/${this.selectedAccountId}`, merchant);
        this.merchant = updatedMerchant;
        this.changePasswordDialogue = false;
        this.passwordToSet = null;
        Snacks.$emit('info', 'Merchant Password Changed');
      } catch (err) {
        Snacks.$emit('error', 'Failed to change password');
        console.error('Failed to save merchant', err);
      }
    },
  },
};
</script>
