<script setup>
const emit = defineEmits(['submit']);

const props = defineProps({
  editMode: Boolean,
  item: {
    type: Object,
    default: () => {},
  },
});

const click = () => {
  const item = { ...props.item };

  const query = {
    query: {
      bool: {
        must: [
          {
            term: {
              forwarddialed: true,
            },
          },
        ],
      },
    },
  };

  emit('submit', {
    item,
    query,
    text: 'True',
  });
};

</script>

<template>
  <v-list-item @click="click">
    <span v-if="!editMode">
      {{ item.text }}
    </span>
  </v-list-item>
</template>

<style scoped>

</style>
