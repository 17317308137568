<script>
import { Doughnut, mixins } from 'vue-chartjs';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

const { reactiveProp } = mixins;

export default {
  name: 'DoughnutChartComponent',
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    options: Object,
  },
  mounted() {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        colorschemes: {
          scheme: Tableau20,
        },
      },
      elements: {
        line: {
          borderWidth: 1,
          fill: false,
        },
      },
      ...this.options,
    };
    chartOptions.responsive = true;

    this.renderChart(this.chartData, chartOptions);
  },
};
</script>`
