<template>
  <div class="hourseditor">
    <v-layout wrap>
      <v-flex xs3>
        <p class="subtitle-1">
          Monday
          <v-btn
            icon
            small
            primary
            @click="addWeekDay1"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay1 && hours.WeekDay1.length>0"
        xs9
      >
        <v-layout wrap>
          <v-flex
            v-for="(t,i) in hours.WeekDay1"
            :key="i"
            xs12
          >
            <SimpleTimePeriodPicker
              v-model="hours.WeekDay1"
              :index="i"
              :time-period="t"
            />
          </v-flex>
          <v-flex xs12>
            <v-toolbar
              class="custom_select"
              dense
            >
              <v-select
                v-model="populateWeek1"
                class="custom-populate-week"
                hide-details
                :items="weeks"
                :label="populate_text"
                multiple
                persistent-hint
              />
              <v-divider vertical />
              <v-btn
                icon
                small
                primary
                @click="populateWeekDay(1)"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay1 && hours.WeekDay1.length>0"
        x12
      >
        <v-divider inset />
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs3>
        <p class="subtitle-1">
          Tuesday
          <v-btn
            icon
            small
            primary
            @click="addWeekDay2"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay2 && hours.WeekDay2.length>0"
        xs9
      >
        <v-layout wrap>
          <v-flex
            v-for="(t,i) in hours.WeekDay2"
            :key="i"
            xs12
          >
            <SimpleTimePeriodPicker
              v-model="hours.WeekDay2"
              :index="i"
              :time-period="t"
            />
          </v-flex>
          <v-flex xs12>
            <v-toolbar
              class="custom_select"
              dense
            >
              <v-select
                v-model="populateWeek2"
                class="custom-populate-week"
                hide-details
                :items="weeks"
                :label="populate_text"
                multiple
                persistent-hint
              />
              <v-divider vertical />
              <v-btn
                icon
                small
                primary
                @click="populateWeekDay(2)"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay2 && hours.WeekDay2.length>0"
        x12
      >
        <v-divider inset />
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs3>
        <p class="subtitle-1">
          Wednesday
          <v-btn
            icon
            small
            primary
            @click="addWeekDay3"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay3 && hours.WeekDay3.length>0"
        xs9
      >
        <v-layout wrap>
          <v-flex
            v-for="(t,i) in hours.WeekDay3"
            :key="i"
            xs12
          >
            <SimpleTimePeriodPicker
              v-model="hours.WeekDay3"
              :index="i"
              :time-period="t"
            />
          </v-flex>
          <v-flex xs12>
            <v-toolbar
              class="custom_select"
              dense
            >
              <v-select
                v-model="populateWeek3"
                class="custom-populate-week"
                hide-details
                :items="weeks"
                :label="populate_text"
                multiple
                persistent-hint
              />
              <v-divider vertical />
              <v-btn
                icon
                small
                primary
                @click="populateWeekDay(3)"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay3 && hours.WeekDay3.length>0"
        x12
      >
        <v-divider inset />
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs3>
        <p class="subtitle-1">
          Thursday
          <v-btn
            icon
            small
            primary
            @click="addWeekDay4"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay4 && hours.WeekDay4.length>0"
        xs9
      >
        <v-layout wrap>
          <v-flex
            v-for="(t,i) in hours.WeekDay4"
            :key="i"
            xs12
          >
            <SimpleTimePeriodPicker
              v-model="hours.WeekDay4"
              :index="i"
              :time-period="t"
            />
          </v-flex>
          <v-flex xs12>
            <v-toolbar
              class="custom_select"
              dense
            >
              <v-select
                v-model="populateWeek4"
                class="custom-populate-week"
                hide-details
                :items="weeks"
                :label="populate_text"
                multiple
                persistent-hint
              />
              <v-divider vertical />
              <v-btn
                icon
                small
                primary
                @click="populateWeekDay(4)"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay4 && hours.WeekDay4.length>0"
        x12
      >
        <v-divider inset />
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs3>
        <p class="subtitle-1">
          Friday
          <v-btn
            icon
            small
            primary
            @click="addWeekDay5"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay5 && hours.WeekDay5.length>0"
        xs9
      >
        <v-layout wrap>
          <v-flex
            v-for="(t,i) in hours.WeekDay5"
            :key="i"
            xs12
          >
            <SimpleTimePeriodPicker
              v-model="hours.WeekDay5"
              :index="i"
              :time-period="t"
            />
          </v-flex>
          <v-flex xs12>
            <v-toolbar
              class="custom_select"
              dense
            >
              <v-select
                v-model="populateWeek5"
                class="custom-populate-week"
                hide-details
                :items="weeks"
                :label="populate_text"
                multiple
                persistent-hint
              />
              <v-divider vertical />
              <v-btn
                icon
                small
                primary
                @click="populateWeekDay(5)"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay5 && hours.WeekDay5.length>0"
        x12
      >
        <v-divider inset />
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs3>
        <p class="subtitle-1">
          Saturday
          <v-btn
            icon
            small
            primary
            @click="addWeekDay6"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay6 && hours.WeekDay6.length>0"
        xs9
      >
        <v-layout wrap>
          <v-flex
            v-for="(t,i) in hours.WeekDay6"
            :key="i"
            xs12
          >
            <SimpleTimePeriodPicker
              v-model="hours.WeekDay6"
              :index="i"
              :time-period="t"
            />
          </v-flex>
          <v-flex xs12>
            <v-toolbar
              class="custom_select"
              dense
            >
              <v-select
                v-model="populateWeek6"
                class="custom-populate-week"
                hide-details
                :items="weeks"
                :label="populate_text"
                multiple
                persistent-hint
              />
              <v-divider vertical />
              <v-btn
                icon
                small
                primary
                @click="populateWeekDay(6)"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay6 && hours.WeekDay6.length>0"
        x12
      >
        <v-divider inset />
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs3>
        <p class="subtitle-1">
          Sunday
          <v-btn
            icon
            small
            primary
            @click="addWeekDay7"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay7 && hours.WeekDay7.length>0"
        xs9
      >
        <v-layout wrap>
          <v-flex
            v-for="(t,i) in hours.WeekDay7"
            :key="i"
            xs12
          >
            <SimpleTimePeriodPicker
              v-model="hours.WeekDay7"
              :index="i"
              :time-period="t"
            />
          </v-flex>
          <v-flex xs12>
            <v-toolbar
              class="custom_select"
              dense
            >
              <v-select
                v-model="populateWeek7"
                class="custom-populate-week"
                hide-details
                :items="weeks"
                :label="populate_text"
                multiple
                persistent-hint
              />
              <v-divider vertical />
              <v-btn
                icon
                small
                primary
                @click="populateWeekDay(7)"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        v-if="hours.WeekDay7 && hours.WeekDay7.length>0"
        x12
      >
        <v-divider inset />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import lodash from 'lodash';
import SimpleTimePeriodPicker from '@/components/openinghourseditor/SimpleTimePeriodPicker';

export default {
  name: 'Openinghouseditor',
  components: {
    SimpleTimePeriodPicker,
  },
  props: {
    value: Object,
    hours: Object,
  },
  data: () => ({
    populate_text: 'Populate these hours to other weekday',
    populateWeek1: [],
    populateWeek2: [],
    populateWeek3: [],
    populateWeek4: [],
    populateWeek5: [],
    populateWeek6: [],
    populateWeek7: [],
    weeks: [
      { text: 'Monday', value: 1 },
      { text: 'Tuesday', value: 2 },
      { text: 'Wednesday', value: 3 },
      { text: 'Thursday', value: 4 },
      { text: 'Friday', value: 5 },
      { text: 'Saturday', value: 6 },
      { text: 'Sunday', value: 7 }],
  }),
  created() {
  },
  mounted() {
  },
  methods: {
    populateWeekDay(data) {
      let tempData = [];
      let dataToPopulate = [];
      if (data === 1) {
        tempData = this.populateWeek1;
        this.populateWeek1 = [];
        dataToPopulate = this.hours.WeekDay1;
      } else if (data === 2) {
        tempData = this.populateWeek2;
        this.populateWeek2 = [];
        dataToPopulate = this.hours.WeekDay2;
      } else if (data === 3) {
        tempData = this.populateWeek3;
        this.populateWeek3 = [];
        dataToPopulate = this.hours.WeekDay3;
      } else if (data === 4) {
        tempData = this.populateWeek4;
        this.populateWeek4 = [];
        dataToPopulate = this.hours.WeekDay4;
      } else if (data === 5) {
        tempData = this.populateWeek5;
        this.populateWeek5 = [];
        dataToPopulate = this.hours.WeekDay5;
      } else if (data === 6) {
        tempData = this.populateWeek6;
        this.populateWeek6 = [];
        dataToPopulate = this.hours.WeekDay6;
      } else if (data === 7) {
        tempData = this.populateWeek7;
        this.populateWeek7 = [];
        dataToPopulate = this.hours.WeekDay7;
      }

      tempData.forEach((item, i) => {
        if (item === 1) {
          this.hours.WeekDay1 = dataToPopulate;
        }
        if (item === 2) {
          this.hours.WeekDay2 = lodash.cloneDeep(dataToPopulate);
        }
        if (item === 3) {
          this.hours.WeekDay3 = lodash.cloneDeep(dataToPopulate);
        }
        if (item === 4) {
          this.hours.WeekDay4 = lodash.cloneDeep(dataToPopulate);
        }
        if (item === 5) {
          this.hours.WeekDay5 = lodash.cloneDeep(dataToPopulate);
        }
        if (item === 6) {
          this.hours.WeekDay6 = lodash.cloneDeep(dataToPopulate);
        }
        if (item === 7) {
          this.hours.WeekDay7 = lodash.cloneDeep(dataToPopulate);
        }
        if (i === tempData.length - 1) {
          tempData = [];
        }
      });
    },
    addWeekDay1() {
      if (!Array.isArray(this.hours.WeekDay1)) {
        this.hours.WeekDay1 = [];
      }
      this.hours.WeekDay1.push({ start: '08:00', finish: '17:00' });
    },
    addWeekDay2() {
      if (!Array.isArray(this.hours.WeekDay2)) {
        this.hours.WeekDay2 = [];
      }
      this.hours.WeekDay2.push({ start: '08:00', finish: '17:00' });
    },
    addWeekDay3() {
      if (!Array.isArray(this.hours.WeekDay3)) {
        this.hours.WeekDay3 = [];
      }
      this.hours.WeekDay3.push({ start: '08:00', finish: '17:00' });
    },
    addWeekDay4() {
      if (!Array.isArray(this.hours.WeekDay4)) {
        this.hours.WeekDay4 = [];
      }
      this.hours.WeekDay4.push({ start: '08:00', finish: '17:00' });
    },
    addWeekDay5() {
      if (!Array.isArray(this.hours.WeekDay5)) {
        this.hours.WeekDay5 = [];
      }
      this.hours.WeekDay5.push({ start: '08:00', finish: '17:00' });
    },
    addWeekDay6() {
      if (!Array.isArray(this.hours.WeekDay6)) {
        this.hours.WeekDay6 = [];
      }
      this.hours.WeekDay6.push({ start: '08:00', finish: '17:00' });
    },
    addWeekDay7() {
      if (!Array.isArray(this.hours.WeekDay7)) {
        this.hours.WeekDay7 = [];
      }
      this.hours.WeekDay7.push({ start: '08:00', finish: '17:00' });
    },
  },
};
</script>
<style>
.hourseditor {

}
.v-divider--inset:not(.v-divider--vertical) {
  margin-left: 2px;
  max-width: calc(100% - 2px);
  margin-top: 10px;
  margin-bottom: 20px;
}
.custom_select .v-text-field>.v-input__control>.v-input__slot:before{
  border-width: 0 0 0 !important;
}
.custom_selec {
  background: transparent;
}
.custom_select .v-toolbar__content>:last-child.v-btn--icon {
  padding-left: 14px;
}
.custom_select.v-toolbar{
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.custom-populate-week{
  margin-top:-10px !important;
}
</style>
