<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Bundle Chargebands
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/bundlechargebands', 'POST')"
        color="blue"
        text
        @click="$router.push('/bundlechargebands/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="bundleChargeBands"
        :headers="exportHeaders"
        report-name="Bundle Chargebands"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="bundleChargeBands"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 220px)"
          >
            <template #item.callbundle.BundleName="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/bundlechargebands/<bcbid>', 'GET')"
                :to="`bundlechargebands/${item.BCBId}`"
              >
                {{ item.callbundle.BundleName }}
              </router-link>
              <div v-else>
                {{ item.callbundle.BundleName }}
              </div>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

import moment from 'moment';
import config, { BTL_API_TENANT_URL } from '@/config.js';

import permittedFunction from '@/permittedFunction.js';
import download from '@/components/download/tableDownload';

export default {
  name: 'BundleChargebands',
  components: {
    download,
  },
  filters: {
    formatDate(date) {
      return moment(date).format(config.date_format);
    },
  },
  data() {
    const headers = [
      { text: 'Bundle', value: 'callbundle.BundleName' },
      { text: 'ChargeBand', value: 'ChargeBand' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      bundleChargeBands: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      loading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        await this.loadBundleChargebands();
      } finally {
        this.loading = false;
      }
    },
    async loadBundleChargebands() {
      this.loading = true;

      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/bundlechargebands`);
        this.bundleChargeBands = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve bundle chargebands.', true);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
