<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        text-center
      >
        <label class="subtitle-1">Today: {{ todayHuman }}</label>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-spacer />
      <v-btn
        text
        @click="onCancel"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="onSubmit"
      >
        Submit
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import config from '../../../../config.js';

export default {
  name: 'QueryBuilderDateToday',
  data() {
    return {};
  },
  computed: {
    timeZone() {
      return this.$store.getters.timeZone;
    },
    todayHuman() {
      return moment().tz(this.timeZone).format(config.date_format);
    },
    today() {
      return moment().tz(this.timeZone).endOf('day').toISOString();
    },
    start() {
      return moment().tz(this.timeZone).startOf('day').toISOString();
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      const text = `Today [${this.todayHuman}]`;
      const query = {
        query: {
          bool: {
            filter: [
              {
                range: {
                  call_datetime_iso: {
                    gte: this.start,
                    lte: this.today,
                  },
                },
              },
            ],
          },
        },
      };

      const parameters = [
        {
          fieldName: 'call_datetime_iso',
          type: 'DATE_TODAY',
        },
      ];

      this.$emit('submit', { query, text, parameters });
    },
  },
};
</script>
