<template>
  <v-container>
    <node-header
      icon="print"
      icon-color="red"
      title="Receive Fax"
      :detail="emails.length ? emails.join(', ') : 'Add email addresses'"
    />
    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        max-width="600px"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="primary"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              edit
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Configure Emails</v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              :aria-autocomplete="false"
              lazy-validation
            >
              <v-combobox
                v-model="emails"
                label="Emails"
                multiple
                chips
                :rules="emailRules"
                deletable-chips
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="handleCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-spacer />
      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="menu_delete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleDelete"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>

import Snacks from '@/utils/snacks';
import NodeHeader from './partials/Header';

export default {
  components: {
    NodeHeader,
  },
  props: {
    canedit: Boolean,
    node: Object,
  },
  data: () => ({
    menu: false,
    menu_delete: false,
    emails: [],
    valid: false,
    emailRules: [
      (emails) => {
        const hasEmails = !!emails.length;
        if (!hasEmails) {
          return 'At least one email is required';
        }
        const emptyEmail = emails.find((email) => !email?.trim().length);
        if (emptyEmail) {
          return 'Email cannot be empty';
        }
        const lengthLimitExceeded = emails.find((email) => email.length >= 80);
        if (lengthLimitExceeded) {
          return 'Email address must not have more than 80 characters';
        }
        return true;
      },
    ],
  }),
  created() {
    this.emails = this.node.parameters.email.split(',').filter((email) => !!email);
  },
  methods: {
    handleDelete() {
      this.$emit('deletenode');
    },
    handleSave() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        Snacks.$emit('error', 'Correct invalid fields before saving.');
        return;
      }
      this.menu = false;
      this.node.parameters.email = this.emails.join(',');
    },
    handleCancel() {
      this.menu = false;
      this.emails = this.node.parameters.email.split(',').filter((email) => !!email);
    },
  },
};
</script>
