<template>
  <div
    ref="containerRef"
    v-resize="onResize"
    style="height: 100%;"
  >
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-2">
        Allocated Numbers
      </v-toolbar-title>
      <v-btn
        v-if="numbersSelected && permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/phonenumbers/batch', 'PUT')"
        text
        color="blue"
        @click="callflowDialog=true"
      >
        <v-icon class="mr-2">
          call_split
        </v-icon>Assign to callflow
      </v-btn>
      <v-btn
        v-if="numbersSelected && permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/phonenumbers/batch', 'PUT')"
        text
        color="blue"
        @click="enableDialog=true"
      >
        <v-icon class="mr-2">
          toggle_on
        </v-icon>Enable
      </v-btn>
      <v-btn
        v-if="numbersSelected && permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/phonenumbers/batch', 'PUT')"
        text
        color="blue"
        @click="disableDialog=true"
      >
        <v-icon class="mr-2">
          toggle_off
        </v-icon>Disable
      </v-btn>
      <v-btn
        v-if="numbersSelected && permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/phonenumbers/batch', 'PUT')"
        text
        color="blue"
        @click="editNumberDialog=true"
      >
        <v-icon class="mr-2">
          create
        </v-icon>&nbsp;Edit
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="numbers"
        :headers="exportHeaders"
        report-name="Allocated Numbers"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>

    <v-container fluid>
      <v-alert
        :value="callCountWarning"
        type="warning"
        dismissible
      >
        Call Counts might not be accurate
      </v-alert>

      <v-alert
        :value="callCountError"
        type="error"
      >
        Call Counts system in error and cant be relied upon
      </v-alert>

      <v-layout class="mt-1 px-2">
        <v-flex grow>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="numbers"
            item-key="PhoneNumber"
            show-select
            class="elevation-1"
            :loading="loading"
            :search="$store.getters.searchQuery"
            :options="tableOptions"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            :height="tableHeight"
            @update:options="handleTableOptionsUpdate"
          >
            <template #item.PhoneNumber="props">
              <router-link
                v-if="permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/phonenumbers/<phonenumber>', 'GET')"
                v-intersect="(entries, observer, isIntersecting) => { toggleNumberVisible(props.item.PhoneNumber, isIntersecting) }"
                :to="props.item.numberRoute"
              >
                {{ props.item.PhoneNumber }}
              </router-link>
              <p
                v-else
                v-intersect="(entries, observer, isIntersecting) => { toggleNumberVisible(props.item.PhoneNumber, isIntersecting) }"
              >
                {{ props.item.PhoneNumber }}
              </p>
            </template>

            <template #item.Enabled="props">
              <v-icon
                v-if="props.item.Enabled"
                color="#1565C0"
              >
                toggle_on
              </v-icon>
              <v-icon
                v-else
                color="grey"
              >
                toggle_off
              </v-icon>
            </template>

            <template #item.CallflowName="props">
              <router-link
                v-if="props.item.callflowRoute"
                :to="props.item.callflowRoute"
              >
                {{ props.item.CallflowName }}
              </router-link>
              <span v-else>{{ props.item.CallflowName }}</span>
            </template>

            <template
              v-if="callCountsPermitted"
              #item.allTotal="{ item }"
            >
              <call-counts
                :all-total="item.allTotal"
                :all-in="item.allIn"
                :all-out="item.allOut"
              />
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog
      v-model="callflowDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Assign numbers to a callflow
        </v-card-title>
        <v-subheader>Allocate these numbers:</v-subheader>
        <v-layout
          wrap
          class="px-3"
        >
          <v-flex
            v-for="n in selected"
            :key="n.PhoneNumber"
            xs6
            md4
          >
            <p>{{ n.PhoneNumber }}</p>
          </v-flex>
        </v-layout>
        <v-layout class="px-3">
          <v-flex xs12>
            <v-select
              v-model="callflowId"
              flat
              :items="callflows"
              item-text="Name"
              item-value="CallflowId"
              label="CallFlow"
            />
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="loading"
            primary
            color="primary"
            dark
            @click="assignCallflow"
          >
            Assign
          </v-btn>
          <v-btn
            :disabled="loading"
            text
            color="grey"
            @click="callflowDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="enableDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Enable numbers
        </v-card-title>
        <v-subheader>Enable these numbers:</v-subheader>
        <v-layout
          wrap
          class="px-3"
        >
          <v-flex
            v-for="n in selected"
            :key="n.PhoneNumber"
            xs6
            md4
          >
            <p>{{ n.PhoneNumber }}</p>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="loading"
            primary
            color="primary"
            dark
            @click="enableNumbers"
          >
            Enable
          </v-btn>
          <v-btn
            :disabled="loading"
            text
            color="grey"
            @click="enableDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="disableDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Disable numbers
        </v-card-title>
        <v-subheader>Disable these numbers:</v-subheader>
        <v-layout
          wrap
          class="px-3"
        >
          <v-flex
            v-for="n in selected"
            :key="n.PhoneNumber"
            xs6
            md4
          >
            <p>{{ n.PhoneNumber }}</p>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="loading"
            primary
            color="primary"
            dark
            @click="disableNumbers"
          >
            Disable
          </v-btn>
          <v-btn
            :disabled="loading"
            text
            color="grey"
            @click="disableDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editNumberDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Edit Numbers
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-chip
              v-for="n in selected"
              :key="n.PhoneNumber"
              class="ma-2"
              color="blue"
              text-color="white"
            >
              <v-avatar left>
                <v-icon>call</v-icon>
              </v-avatar>
              {{ n.PhoneNumber }}
            </v-chip>
          </div>
          <v-form
            ref="editFormRef"
            v-model="editValid"
            lazy-validation
          >
            <v-text-field
              v-model="number.Label"
              label="Name"
            />
            <v-text-field
              v-model="number.Notes"
              label="Notes"
              :counter="80"
            />
            <v-select
              v-model="maskingType"
              flat
              :items="maskingTypes"
              label="Masking CLI"
            />
            <v-text-field
              v-if="maskingType === 'e164'"
              v-model="maskingNumber"
              label="Masking Number"
              :rules="numberRules"
              :counter="13"
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            @click="editNumbers"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="editNumberDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import {
  reactive, computed, watch, onMounted, onBeforeUnmount, ref, unref, toRefs, set, nextTick,
} from 'vue';
import axios from 'axios';
import { sortBy, without } from 'lodash';

import config from '@/config.js';
import download from '@/components/download/tableDownload';
import { useStore } from '@/utils/vue';
import permittedFunction from '../../permittedFunction';
import CallCounts from '../../components/CallCounts';
import usePagination from '../../composables/tablePaginationViaQueryString';

export default {
  name: 'AllocatedNumbers',
  components: {
    download,
    CallCounts,
  },
  setup(props, context) {
    const store = useStore();

    // refs
    const editFormRef = ref(null);
    const containerRef = ref(null);

    // composables
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, {
      sortBy: 'CallflowName',
    });

    const state = reactive({

      numbersSelected: computed(() => state.selected.length > 0),
      selectedAccountId: computed(() => store.getters.selectedAccountId),

      loading: false,

      enableDialog: false,
      disableDialog: false,
      callflowDialog: false,
      editNumberDialog: false,

      callflowId: null,

      selected: [],
      numbersInView: [],
      headers: [
        {
          text: 'Number',
          value: 'PhoneNumber',
          sortable: true,
        },
        {
          text: 'Enabled',
          value: 'Enabled',
          align: 'center',
          sortable: true,
        },
        {
          text: 'CallFlow',
          value: 'CallflowName',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'Label',
          sortable: true,
        },
        {
          text: 'Notes',
          value: 'Notes',
          sortable: true,
        },
      ],
      exportHeaders: [
        { text: 'DDI', value: 'PhoneNumber' },
        { text: 'CallFlow Name', value: 'CallflowName' },
        { text: 'Enabled', value: 'Enabled' },
        { text: 'Name', value: 'Label' },
      ],

      numbers: [],
      callflows: [],
      callCountIntervalPeriod: 5000,
      callCountInterval: null,
      callCountWarning: false,
      callCountError: false,
      callCountsPermitted: false,

      editValid: true,
      number: {
      },
      maskingType: null,
      maskingNumber: '',
      maskingTypes: [
        { value: '', text: 'None' },
        { value: 'Anonymous', text: 'Anonymous' },
        { value: 'e164', text: 'E164 Phone Number' },
      ],
      numberRules: [
        (v) => !!v || 'Phone number is required',
        (v) => (v && v.length <= 13) || 'Phone number must not have more than 12 characters',
      ],
      tableHeight: 0,
    });

    const getNumberCallCounts = async () => {
      const phoneNumbers = state.numbersInView;

      if (phoneNumbers.length === 0) {
        return; // No numbers to query
      }

      try {
        const url = `${config.BTL_API_ACCOUNT_URL}/${state.selectedAccountId}/phonenumber_list/${encodeURIComponent(JSON.stringify(phoneNumbers))}/callcount`;
        const response = await axios.get(url);
        const callCountsData = response.data;

        // Reset warning and error initially
        state.callCountWarning = false;
        state.callCountError = false;

        // Check if lostContactNetxGateways or nonAuthoritativeNetxGateways contain IDs
        if (response.data.lostContactNetxGateways.length > 0 || response.data.nonAuthoritativeNetxGateways.length > 0) {
          state.callCountWarning = true;
        }

        if (response.data.serviceStatus !== 'OK') {
          state.callCountError = true;
          state.numbersInView.forEach((number) => {
            const numberObj = state.numbers.find((n) => n.PhoneNumber === number);
            if (numberObj) {
              set(numberObj, 'allTotal', 0); // Set default values
              set(numberObj, 'allIn', 0);
              set(numberObj, 'allOut', 0);
            }
          });
        } else {
          phoneNumbers.forEach((number) => {
            const numberObj = state.numbers.find((n) => n.PhoneNumber === number);
            if (numberObj) {
              const counts = callCountsData.count.find((c) => c.id === number) || {};
              set(numberObj, 'allTotal', counts.allTotal ? Number(counts.allTotal) : 0);
              set(numberObj, 'allIn', counts.allIn ? Number(counts.allIn) : 0);
              set(numberObj, 'allOut', counts.allOut ? Number(counts.allOut) : 0);
            }
          });
        }
      } catch (error) {
        console.error('Failed to fetch call counts:', error);
        state.callCountError = true;
        state.numbersInView.forEach((number) => {
          const numberObj = state.numbers.find((n) => n.PhoneNumber === number);
          if (numberObj) {
            set(numberObj, 'allTotal', 0); // Set default values on error
            set(numberObj, 'allIn', 0);
            set(numberObj, 'allOut', 0);
          }
        });
      }
    };

    const startCallCountInterval = () => {
      if (state.callCountInterval) {
        return;
      }

      // Initial call
      setTimeout(() => {
        getNumberCallCounts();
      }, 350);

      // Set interval for periodic updates
      state.callCountInterval = setInterval(() => {
        getNumberCallCounts();
      }, state.callCountIntervalPeriod);
    };


    const loadNumbers = async () => {
      try {
        const { data: callflows } = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/callflows`);
        state.callflows = sortBy(callflows, 'Name');

        const { data: numbers } = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/phonenumbers`);
        state.numbers = [];

        numbers.forEach((row) => {
          if (row.CallflowId) {
            const cf = state.callflows.find((c) => c.CallflowId === row.CallflowId);
            row.CallflowName = cf ? cf.Name : '';
            row.callflowRoute = `/callflow/${row.CallflowId}`;
          } else {
            row.CallflowName = '';
          }
          row.numberRoute = `/number/${row.PhoneNumber}`;
          state.numbers.push(row);
        });

        // Start the interval to get call counts if permitted
        if (state.callCountsPermitted) {
          startCallCountInterval();
        }
      } catch (err) {
        console.error('Error retrieving numbers', err);
      }
      state.loading = false;
    };

    const loadData = async () => {
      state.loading = true;
      await loadNumbers();

      nextTick(() => {
        if (state.callCountsPermitted) {
          getNumberCallCounts();
        }
      });
    };

    const disableNumbers = () => {
      state.loading = true;
      const payload = { apply_to: state.selected.map((number) => number.PhoneNumber), Enabled: false };
      axios.put(`${config.BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/phonenumbers/batch`, payload)
        .then(() => {
          context.emit('snack', 'Numbers successfully disabled!');
          state.disableDialog = false;
          state.selected = [];
          loadData();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    };

    const assignCallflow = () => {
      state.loading = true;
      const payload = { apply_to: state.selected.map((number) => number.PhoneNumber), CallflowId: state.callflowId };
      axios.put(`${config.BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/phonenumbers/batch`, payload)
        .then(() => {
          context.emit('snack', 'Numbers successfully assigned to callflow!');
          state.callflowDialog = false;
          state.selected = [];
          loadData();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    };

    const enableNumbers = () => {
      state.loading = true;
      const payload = { apply_to: state.selected.map((number) => number.PhoneNumber), Enabled: true };
      axios.put(`${config.BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/phonenumbers/batch`, payload)
        .then(() => {
          context.emit('snack', 'Numbers successfully enabled!');
          state.enableDialog = false;
          state.selected = [];
          loadData();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    };

    const toggleNumberVisible = (number, isVisible) => {
      if (isVisible) {
        if (!state.numbersInView.includes(number)) {
          state.numbersInView.push(number);
        }
      } else {
        state.numbersInView = without(state.numbersInView, number);
      }
    };

    const editNumbers = () => {
      const valid = unref(editFormRef).validate();
      if (!valid) {
        return;
      }

      const body = {
        ...state.number,
        apply_to: state.selected.map((n) => n.PhoneNumber),
      };

      axios.put(`${config.BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/phonenumbers/batch`, body)
        .then(() => {
          context.emit('snack', 'Numbers successfully updated!');
          state.editNumberDialog = false;
          state.selected = [];
          state.number = {};
          state.maskingType = '';
          loadData();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    };

    const onResize = () => {
      nextTick(() => {
        const oldRef = unref(containerRef);
        if (oldRef) {
          state.tableHeight = oldRef.clientHeight - 175;
        }
      });
    };

    watch(() => state.maskingType, (selectedMaskingType) => {
      if (selectedMaskingType !== 'e164') {
        state.number.Masking = selectedMaskingType;
      } else {
        state.number.Masking = state.maskingNumber;
      }
    });

    watch(() => state.maskingNumber, (maskingNumber) => {
      state.number.Masking = maskingNumber;
    });

    watch(() => state.selectedAccountId, () => {
      clearInterval(state.callCountInterval);
      state.callCountInterval = null;
      state.numbersInView = [];
      loadData();
    });

    saveQueryParams();
    onMounted(async () => {
      state.callCountsPermitted = permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/phonenumbers/<phonenumber>/callcount', 'GET');
      if (state.callCountsPermitted) {
        state.headers.push({
          text: 'Current Calls', value: 'allTotal', align: 'right', sortable: false,
        });
      }

      await loadData();
      processQueryParams();
    });

    onBeforeUnmount(() => {
      clearInterval(state.callCountInterval);
    });

    return {
      containerRef,
      editFormRef,
      ...toRefs(state),
      loadData,
      disableNumbers,
      assignCallflow,
      enableNumbers,
      toggleNumberVisible,
      editNumbers,
      handleTableOptionsUpdate,
      tableOptions,
      permittedFunction,
      onResize,
    };
  },
};
</script>
