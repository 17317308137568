<template>
  <v-container
    ref="table"
    v-resize="onResize"
    pa-0
    fluid
    style="height: 100%"
  >
    <v-row>
      <v-col>
        <v-toolbar flat>
          <v-btn
            icon
            @click.stop="$router.back()"
          >
            <v-icon>chevron_left</v-icon>
          </v-btn>
          <v-toolbar-title class="mr-5">
            LCR Providers
          </v-toolbar-title>
          <v-btn
            text
            color="blue"
            @click="$router.push('/provider/new')"
          >
            <v-icon class="mr-2">
              add_box
            </v-icon>Create
          </v-btn>
          <v-btn
            text
            color="blue"
            @click="loadData"
          >
            <v-icon class="mr-2">
              refresh
            </v-icon>Refresh
          </v-btn>

          <download
            :table-data="providers"
            :headers="exportHeaders"
            report-name="LCR Providers"
            color="primary"
          />
          <v-spacer />
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row class="mx-1">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="providers"
          :loading="loading"
          :search="$store.getters.searchQuery"
          item-key="NRId"
          :items-per-page="100"
          :footer-props="{ itemsPerPageOptions: [100, 250, 500] }"
          fixed-header
          :custom-sort="customSort"
          sort-by="Name"
          :height="tableHeight"
        >
          <template #item.ProviderPrefix="{ item }">
            <router-link
              v-if="isPermitted('/providers/<provider_prefix>', 'PUT', true)"
              :to="`provider/${item.ProviderPrefix}`"
            >
              {{ item.ProviderPrefix }}
            </router-link>
            <span v-else> {{ item.ProviderPrefix }} </span>
          </template>
          <template #item.Name="{ item }">
            <span>{{ item.Name }}</span>
          </template>
          <template #item.Description="{ item }">
            <span>{{ item.Description }}</span>
          </template>
          <template #item.ModifiedBy="{ item }">
            <span>{{ item.ModifiedBy }}</span>
          </template>
          <template #item.ModifiedOn="{ item }">
            <span>{{ item.ModifiedOn | formatDate }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { sortBy } from 'lodash';
import config from '@/config.js';
import download from '@/components/download/tableDownload';
import customSort from '@/utils/customSort';
import permittedFunction from '@/permittedFunction.js';

import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'ProvidersManagement',
  components: {
    download,
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatMoney(value) {
      return currencyFormatter(value);
    },
  },
  data() {
    const headers = [
      { text: 'Prefix', value: 'ProviderPrefix' },
      { text: 'Name', value: 'Name' },
      { text: 'Description', value: 'Description' },
      { text: 'Priority', value: 'Priority' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      rates: [],
      providers: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      accounts: [],
      accountFilter: null,
      loading: false,
      tableHeight: 0,
    };
  },
  watch: {

  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        await this.loadPoviders();
        // await this.loadAccounts();
        // await this.loadRates();
      } catch (error) {
        console.error('Could not load data: ', error.message);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadRates() {
      const response = await axios.get(
        `${config.BTL_API_TENANT_URL}/nationals`,
      );

      this.rates = response.data
        .filter((row) => row.AccountId === this.accountFilter)
        .map((row) => {
          row.itemRoute = `/nationalrate/${row.NRId}`;
          row.AccountName = '';
          row.ChargeBand = 'National';
          if (this.accounts.length > 0) {
            if (row.AccountId) {
              const acc = this.accounts.find(
                (a) => a.account_id === row.AccountId,
              );
              if (acc) {
                row.AccountName = acc.name;
              }
            }
          }

          return row;
        });
    },
    async loadPoviders() {
      const response = await axios.get(
        `${config.BTL_API_TENANT_URL}/providers`,
      );
      this.providers = response.data;
    },
    async loadAccounts() {
      const response = await axios.get(config.BTL_API_ACCOUNT_URL);
      this.accounts = [
        { name: 'Base Rates', account_id: null },
        ...sortBy(response.data, [(account) => account.name.toLowerCase()]),
      ];
    },
    isPermitted(endpoint, verb, accountOrTenant = false) {
      const prefixedEndpoint = (accountOrTenant && this.accountFilter
        ? '/tenants/<tenant_name>/accounts/<account_id>'
        : '/tenants/<tenant_name>') + endpoint;
      return permittedFunction(prefixedEndpoint, verb);
    },
    onResize() {
      this.tableHeight = this.$refs.table.clientHeight - 150;
    },
    customSort: customSort('ModifiedOn'),
  },
};
</script>
