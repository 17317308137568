<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="subtitle-1">
        <v-icon class="mr-3">
          build
        </v-icon>Groups

        ({{ selectedGroups.length }} / {{ groups.length }} assigned)
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        ref="groups"
        v-model="selectedGroups"
        :headers="groupsHeaders"
        :items="displayedGroups"
        :search="$store.getters.searchQuery"
        :show-select="canEditGroupMembership"
        sort-by="name"
        item-key="group_id"
        :loading="groupsLoading"
        @input="updateParentModel"
        @toggle-select-all="selectAll"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { bizvu_user_url } from '@/config.js';
import Snacks from '@/utils/snacks';
import permittedFunction from '../../permittedFunction';

export default {
  props: {
    accounts: Array,
    user: Object,
    group: Object,
  },
  data() {
    return {
      groupsHeaders: [
        { text: 'Name', value: 'name' },
      ],
      groups: [],
      selectedGroups: [],
      groupsLoading: false,
    };
  },
  computed: {
    filteredGroups() {
      // THIS MAY BREAK IF VUETIFY CHANGES DATATABLE INTERNAL STRUCTURE!
      return this.$refs.groups.$children[0].filteredItems;
    },
    canEditGroupMembership() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>/members/<user_id>', 'POST')
        && this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>/members/<user_id>', 'DELETE');
    },
    displayedGroups() {
      if (this.canEditGroupMembership) {
        return this.groups;
      }
      return this.selectedGroups;
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  mounted() {
    this.loadGroups();
  },
  methods: {
    async loadGroups() {
      try {
        const response = await axios.get(`${bizvu_user_url}/account/${this.selectedAccountId}/group`);
        this.groupsLoading = false;
        this.groups = response.data;
        if (this.user.groups && this.user.groups.length) {
          this.selectedGroups = this.groups.filter((g) => this.user.groups.find((ug) => ug.group_id === g.group_id));
        }
      } catch (error) {
        Snacks.$emit('error', 'Failed to load user groups');
        console.error('ERROR', error);
      }
    },
    selectAll(selectAllEvent) {
      if (selectAllEvent.value) {
        this.filteredGroups.forEach((func) => {
          const index = this.selectedGroups.indexOf(func);
          if (index === -1) {
            this.selectedGroups.push(func);
          }
        });
      } else {
        this.filteredGroups.forEach((func) => {
          const index = this.selectedGroups.indexOf(func);
          if (index !== -1) {
            this.$delete(this.selectedGroups, index);
          }
        });
      }
      this.updateParentModel();
    },
    updateParentModel() {
      this.$emit('selectedGroupsChanged', this.selectedGroups.map((g) => g.group_id));
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};

</script>
