<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Number Filters
      </v-toolbar-title>
      <v-menu
        v-model="testmenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template #activator="{on}">
          <v-btn
            text
            color="blue"
            v-on="on"
          >
            Test Number
          </v-btn>
        </template>
        <v-card>
          <v-layout class="px-4 pt-4">
            <v-flex xs7>
              <v-text-field
                v-model="phoneNumber"
                label="Phone Number"
                :rules="numberRules"
              />
            </v-flex>
            <v-flex xs5>
              <v-chip
                v-if="testResult==='BARRED'"
                color="red"
                class="white--text mt-3 ml-4"
              >
                BARRED
              </v-chip>
              <v-chip
                v-if="testResult==='ALLOWED'"
                color="green"
                class="white--text mt-3 ml-4"
              >
                ALLOWED
              </v-chip>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="testmenu = false"
            >
              close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn
        text
        color="blue"
        @click="loadFilters"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col>
          <v-toolbar
            dense
            flat
          >
            <v-toolbar-title>Blacklists</v-toolbar-title>
            <v-btn
              v-if="isPermitted(`${permissionsBasePath}/blacklists`, 'POST')"
              text
              color="blue"
              @click="$router.push(`${newFilterBasePath}/Blacklist`)"
            >
              <v-icon class="mr-2">
                add_box
              </v-icon>Add Number
            </v-btn>
            <v-spacer />
            <table-download
              :table-data="blacklist"
              color="primary"
              report-name="Blacklist"
            />
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="blacklist"
            :loading="loadingBlackList"
            height="calc(100vh - 240px)"
            fixed-header
          >
            <template #item.action="props">
              <div class="td-flex">
                <v-tooltip
                  v-if="isPermitted(`${permissionsBasePath}/blacklists/<blacklistid>`, 'PUT') && (level === 'account' || !props.item.AccountId)"
                  bottom
                >
                  <template #activator="{on}">
                    <v-btn
                      icon
                      @click="editFilter(props.item)"
                      v-on="on"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Number</span>
                </v-tooltip>
                <v-tooltip
                  v-if="isPermitted(`${permissionsBasePath}/blacklists/<blacklistid>`, 'DELETE') && (level === 'account' || !props.item.AccountId)"
                  bottom
                >
                  <template #activator="{on}">
                    <v-btn
                      icon
                      @click="deleteBlacklist(props.item)"
                      v-on="on"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Number</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>

        <v-col>
          <v-toolbar
            dense
            flat
          >
            <v-toolbar-title>Whitelists</v-toolbar-title>
            <v-btn
              v-if="isPermitted(`${permissionsBasePath}/whitelists`, 'POST')"
              text
              color="blue"
              @click="$router.push(`${newFilterBasePath}/Whitelist`)"
            >
              <v-icon class="mr-2">
                add_box
              </v-icon>Add Number
            </v-btn>
            <v-spacer />
            <tableDownload
              color="primary"
              :table-data="whitelist"
              report-name="Whitelist"
            />
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="whitelist"
            :loading="loadingWhiteList"
            height="calc(100vh - 240px)"
            fixed-header
          >
            <template
              #item.action="props"
            >
              <div class="td-flex">
                <v-tooltip
                  v-if="isPermitted(`${permissionsBasePath}/whitelists/<whitelistid>`, 'PUT') && (level === 'account' || !props.item.AccountId)"
                  bottom
                >
                  <template #activator="{on}">
                    <v-btn
                      icon
                      @click="editFilter(props.item)"
                      v-on="on"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Number</span>
                </v-tooltip>
                <v-tooltip
                  v-if="isPermitted(`${permissionsBasePath}/whitelists/<whitelistid>`, 'DELETE') && (level === 'account' || !props.item.AccountId)"
                  bottom
                >
                  <template #activator="{on}">
                    <v-btn
                      icon
                      @click="deleteWhitelist(props.item)"
                      v-on="on"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Number</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="editDialogue"
      width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Edit Filter
        </v-card-title>
        <v-card-text>
          <v-form ref="editNumberForm">
            <v-text-field
              v-model="filterToEdit.PhoneNumber"
              label="Number"
              :rules="editNumberRules"
              :counter="13"
              required
            />
            <v-select
              v-model="filterToEdit.Direction"
              label="Direction"
              :items="directions"
            />
            <v-text-field
              v-model="filterToEdit.Comment"
              label="Comment"
              :rules="commentRules"
              :counter="40"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="primary"
            @click="saveFilter"
          >
            Save
          </v-btn>
          <v-btn
            text
            @click="editDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { BTL_API_TENANT_URL } from '@/config.js';
import permittedFunction from '../../permittedFunction';

import tableDownload from '../../components/download/tableDownload';

const e164Number = /^(\+[0-9][\d ]{1,14})?(\d{5,6})?(.*[a-zA-Z].*)?$/;

export default {
  name: 'NumberFilters',
  components: {
    tableDownload,
  },
  props: {
    level: {
      type: String,
      validator: (val) => ['account', 'tenant'].includes(val),
    },
  },
  data() {
    return {
      testmenu: false,
      testResult: '',
      phoneNumber: '',
      headers: [
        { text: 'Phone Number', value: 'PhoneNumber' },
        { text: 'Direction', value: 'Direction' },
        { text: 'Comment', value: 'Comment' },
        {
          text: '', value: 'action', sortable: false, align: 'right',
        },
      ],
      numberRules: [
        (v) => (v && v.length <= 13) || 'Number must not have more than 13 characters',
        (v) => (v && v.startsWith('+')) || 'Number must start with +',
      ],
      accounts: [],
      blacklist: [],
      whitelist: [],
      loadingWhiteList: false,
      loadingBlackList: false,

      editDialogue: false,
      filterToEdit: {},
      editNumberRules: [
        (v) => (v && e164Number.test(v)) || 'Invalid number format. Must be E164 format, 5/6 digit short code, or an alpha tag.',
      ],
      commentRules: [
        (v) => (v && v.length > 0) || 'Comment must be provided',
        (v) => (v && v.length >= 4 && v.length <= 40) || 'Comment be between 4 and 40 characters',
      ],
      directions: ['BOTH', 'INCOMING', 'OUTGOING'],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
    newFilterBasePath() {
      return `/newfilter/${this.level}`;
    },
    permissionsBasePath() {
      const isAccount = this.level === 'account';
      return `/tenants/<tenant_name>${isAccount ? '/accounts/<account_id>' : ''}`;
    },
    apiBasePath() {
      let basePath = `${BTL_API_TENANT_URL}`;
      if (this.level === 'account') {
        basePath += `/accounts/${this.selectedAccountId}`;
      }
      return basePath;
    },
  },
  watch: {
    '$route.path': function () {
      this.loadFilters();
    },
    phoneNumber() {
      let block = false;
      if (this.phoneNumber.length > 1) {
        // check blacklists
        this.blacklist.forEach((b) => {
          if (this.phoneNumber.startsWith(b.PhoneNumber)) {
            block = true;
          }
        });
        if (block) {
          // check whitelists
          this.whitelist.forEach((b) => {
            if (this.phoneNumber.startsWith(b.PhoneNumber)) {
              block = false;
            }
          });
        }
      }
      if (block) {
        this.testResult = 'BARRED';
      } else {
        this.testResult = 'ALLOWED';
      }
    },
    selectedAccountId() {
      this.loadFilters();
    },
  },
  mounted() {
    this.loadFilters();
  },
  methods: {
    async loadFilters() {
      await Promise.all([this.loadBlacklist(), this.loadWhitelist()]);

      const findAccountName = (entry) => ({
        ...entry,
        AccountName: entry.AccountId ? this.accounts.find((account) => account.account_id === entry.AccountId).name : 'Tenant',
      });

      if (this.accounts.length) {
        this.blacklist = this.blacklist.map(findAccountName);
        this.whitelist = this.whitelist.map(findAccountName);
      }
    },
    async loadBlacklist() {
      this.loadingBlackList = true;
      try {
        const { data } = await axios.get(`${this.apiBasePath}/blacklists`);
        this.blacklist = data;
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        this.loadingBlackList = false;
      }
    },
    async loadWhitelist() {
      this.loadingWhiteList = true;
      try {
        const { data } = await axios.get(`${this.apiBasePath}/whitelists`);
        this.whitelist = data;
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        this.loadingWhiteList = false;
      }
    },
    deleteWhitelist(n) {
      axios.delete(`${this.apiBasePath}/whitelists/${n.WhitelistId}`)
        .then(() => {
          this.$emit('snack', 'Whitelist item successfully deleted!');
          this.loadWhitelist();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    deleteBlacklist(n) {
      axios.delete(`${this.apiBasePath}/blacklists/${n.BlacklistId}`)
        .then(() => {
          this.$emit('snack', 'Blacklist item successfully deleted!');
          this.loadBlacklist();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    editFilter(number) {
      this.filterToEdit = number;
      this.editDialogue = true;
    },
    async saveFilter() {
      if (!this.$refs.editNumberForm.validate()) {
        return;
      }
      try {
        let url;
        if (this.filterToEdit.WhitelistId) {
          url = `${this.apiBasePath}/whitelists/${this.filterToEdit.WhitelistId}`;
        } else {
          url = `${this.apiBasePath}/blacklists/${this.filterToEdit.BlacklistId}`;
        }

        await axios.put(url, this.filterToEdit);
        this.loadFilters();
        this.editDialogue = false;
      } catch (err) {
        console.error('Failed to edit filter', err);
        this.$emit('snack', 'Failed to edit filter', true);
      }
    },

  },
};
</script>

<style scoped>
  .td-flex {
    display: flex !important;
  }
</style>
