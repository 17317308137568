<template>
  <v-card>
    <v-card-text>
      <v-form ref="form">
        <v-row class="ma-3">
          <v-text-field
            v-model="schedule.name"
            :rules="[rules.required]"
            required
            label="Report Name"
          />
        </v-row>
        <v-row class="ma-3">
          <v-text-field
            v-if="schedule.id"
            v-model="schedule.id"
            disabled
            label="Schedule Id"
          />
        </v-row>
        <v-row class="ma-3">
          <v-select
            v-model="schedule.interval"
            :items="['Daily', 'Weekly', 'Monthly']"
            label="Time period"
          />
        </v-row>
        <v-row
          v-if="schedule.interval === 'Weekly'"
          class="ma-3"
        >
          <v-select
            v-model="schedule.day"
            :items="days"
            item-text="name"
            item-value="value"
            :menu-props="{ maxHeight: '400' }"
            label="Select day(s)"
            multiple
            persistent-hint
          />
        </v-row>
        <v-row
          v-if="schedule.interval === 'Monthly'"
          class="ma-3"
        >
          <v-select
            v-model="schedule.dateOfMonth"
            :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]"
            label="Day of month (1-31)"
          />
        </v-row>
        <v-row class="ma-3">
          <v-time-picker
            v-model="schedule.time"
            style="margin: auto;"
            format="24hr"
          />
        </v-row>
        <v-row
          v-if="editMode"
          class="ma-3"
        >
          <v-checkbox
            v-model="schedule.enabled"
            label="Enabled"
          />
        </v-row>
        <v-row
          v-if="editMode"
          class="ma-3"
        >
          <v-text-field
            :value="queryType"
            readonly
            label="Report Query Type"
          />
          <v-btn
            class="mt-2 ml-4"
            align-self="center"
            @click="editQuery"
          >
            Edit Query
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="saveReport"
      >
        Save
      </v-btn>
      <v-btn @click="cancelEditDialog">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'ScheduleEditor',
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    clearTrigger: Boolean,
    existingSchedule: Object,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
      },
      days: [
        {
          value: 1,
          name: 'Monday',
        },
        {
          value: 2,
          name: 'Tuesday',
        },
        {
          value: 3,
          name: 'Wednesday',
        },
        {
          value: 4,
          name: 'Thursday',
        },
        {
          value: 5,
          name: 'Friday',
        },
        {
          value: 6,
          name: 'Saturday',
        },
        {
          value: 0,
          name: 'Sunday',
        },
      ],
      schedule: {
        id: null,

        time: '09:00',
        day: [],
        dateOfMonth: null,

        name: null,
        elasticSearchQuery: null,
        queryParameters: null,

        enabled: true,
        interval: 'Weekly',
      },
    };
  },
  computed: {
    queryType() {
      if (!this.schedule.queryParameters) {
        return '';
      }
      return Object.keys(this.schedule.queryParameters).join(', ');
    },
  },
  watch: {
    clearTrigger() {
      this.resetState();
    },
    existingSchedule(existingSchedule) {
      if (existingSchedule) {
        this.schedule = { ...existingSchedule };
      } else {
        this.resetState();
      }
    },
    'schedule.interval': function (timePeriod) {
      if (timePeriod !== 'Weekly') {
        this.schedule.day = [];
      }
    },
  },
  methods: {
    cancelEditDialog() {
      this.$emit('cancel');
    },
    saveReport() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit('save', this.schedule);
    },
    editQuery() {
      const params = {};
      Object.keys(this.schedule.queryParameters).forEach((key) => {
        params[key] = this.schedule.queryParameters[key];
      });
      params.scheduleId = this.schedule.id;
      this.$router.push({ name: 'analytics', query: params });
      this.$emit('close');
    },
    resetState() {
      this.schedule = {
        id: null,

        day: [],
        time: '09:30',
        dateOfMonth: null,

        name: null,
        elasticSearchQuery: null,
        queryParameters: null,

        enabled: true,
        interval: 'Weekly',
      };
    },
  },
};

</script>
