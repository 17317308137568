<template>
  <v-card>
    <v-card-title>
      <span class="headline">New Rate</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          class="px-2"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  class="px-3 py-0"
                  cols="10"
                >
                  <v-select
                    v-model="newRate.accountId"
                    flat
                    :items="accounts"
                    item-text="name"
                    item-value="account_id"
                    label="Account"
                  />
                </v-col>
                <v-btn
                  v-if="newRate.accountId"
                  class="mt-4"
                  icon
                  @click="newRate.accountId = null; delete newRate.accountId;"
                >
                  <v-icon>
                    close
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="newRate.complexity"
                flat
                :items="[1,2,3,4,5]"
                label="Query Complexity"
                :rules="rules.complexity"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newRate.lines"
                label="Line Count"
                :rules="rules.lines"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newRate.cost"
                label="Cost (pence)"
                :rules="rules.cost"
              />
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="newRate.dateFromMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="newRate.effectiveFromDate"
                    label="Effective From"
                    prepend-icon="event"
                    :rules="rules.startDate"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="newRate.effectiveFromDate"
                  no-title
                  :min="newRate.minEffectiveFromDate"
                  @input="newRate.dateFromMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col
              v-if="newRate.accountId && hasDefaultForComplexity"
              cols="6"
            >
              <v-menu
                v-model="newRate.dateUntilMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="newRate.effectiveUntilDate"
                    label="Effective Until (optional)"
                    prepend-icon="event"
                    :disabled="!newRate.effectiveFromDate"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="newRate.effectiveUntilDate"
                  no-title
                  :disabled="!newRate.effectiveFromDate"
                  :min="newRate.minEffectiveUntilDate"
                  @input="newRate.dateUntilMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="createRate"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'NewRate',
  props: {
    rules: Object,
    accounts: Array,
    rates: Array,
  },
  data() {
    return {
      newRate: {},
      valid: false,
      hasDefaultForComplexity: false,
    };
  },
  watch: {
    newRate: {
      deep: true,
      handler() {
        this.newRate.minEffectiveFromDate = moment().subtract(1, 'days').endOf('day').format();
        this.newRate.minEffectiveUntilDate = moment(this.newRate.effectiveFromDate).subtract(1, 'days').format();
        this.hasDefaultForComplexity = !!this.rates.find((r) => !r.accountId && r.complexity === this.newRate.complexity);
      },
    },
  },
  methods: {
    createNewRate() {
      this.newRate = {};
      this.newRateDialog = true;
    },
    async createRate() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Correct invalid fields before saving.', true);
        return;
      }

      const newRate = {
        accountId: this.newRate.accountId,
        complexity: this.newRate.complexity,
        revisions: [
          {
            id: uuidv4(),
            lines: this.newRate.lines,
            cost: this.newRate.cost,
            effectiveFromDate: moment(moment.max([moment(this.newRate.effectiveFromDate).startOf('day'), moment()])).valueOf(),
            effectiveUntilDate: this.newRate.effectiveUntilDate ? moment(this.newRate.effectiveUntilDate).endOf('day').valueOf() : null,
          },
        ],
      };

      this.$emit('createRate', { ...newRate });
      this.newRate = {};
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.newRate = {};
    },
  },
};
</script>
