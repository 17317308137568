<template>
  <v-list-item @click.stop="onShow">
    <span v-if="!editMode">
      {{ item.text }}
    </span>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <!-- Subheading -->
        <v-card-title class="subheading-title">
          Status Filters
        </v-card-title>

        <!-- Search Bar -->
        <v-card-text class="pb-1">
          <v-container fluid class="search">
            <v-row no-gutters>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  label="Search Statuses"
                  dense
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Scrollable List of Status Filters -->
        <v-card-text class="pb-0 scrollable-content">
          <v-container fluid>
            <v-row no-gutters>
              <v-col
                v-for="status in filteredItems"
                :key="status.value"
                cols="12"
              >
                <v-checkbox
                  :value="status"
                  :label="status.text"
                  v-model="selected"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Sticky Footer with Buttons -->
        <v-card-actions class="sticky-footer">
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!selected.length"
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import { sortBy } from 'lodash';

export default {
  name: 'QueryBuilderStatus',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      selected: [],
      search: '', // Search term for filtering statuses
      items: sortBy([
        { value: 'SUCCESS', text: 'Success' },
        { value: 'USER_BUSY', text: 'User Busy' },
        { value: 'ORIGINATOR_CANCEL', text: 'Originator Cancel' },
        { value: 'UNALLOCATED_NUMBER', text: 'Unallocated Number' },
        { value: 'NORMAL_CLEARING', text: 'Normal Clearing' },
        { value: 'NORMAL_UNSPECIFIED', text: 'Normal Unspecified' },
        { value: 'NO_USER_RESPONSE', text: 'No User Response' },
        { value: 'NO_ANSWER', text: 'No Answer' },
        { value: 'NORMAL_TEMPORARY_FAILURE', text: 'Normal Temporary Failure' },
        { value: 'NUMBER_BLACKLISTED', text: 'Number Blacklisted' },
        { value: 'CALL_REJECTED', text: 'Call Rejected' },
        { value: 'INVALID_NUMBER_FORMAT', text: 'Invalid Number Format' },
        { value: 'DESTINATION_OUT_OF_ORDER', text: 'Destination Out Of Order' },
        { value: 'EXCHANGE_ROUTING_ERROR', text: 'Exchange Routing Error' },
        { value: 'RECOVERY_ON_TIMER_EXPIRE', text: 'Recovery On Timer Expire' },
        { value: 'INCOMPATIBLE_DESTINATION', text: 'Incompatible Destination' },
        { value: 'NO_ROUTE_DESTINATION', text: 'No Route Destination' },
        { value: 'ALLOTTED_TIMEOUT', text: 'Allotted Timeout' },
      ], 'text'),
    };
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.selected = [...this.item.defaultValue];
    }
  },
  computed: {
    filteredItems() {
      const searchTerm = this.search.toLowerCase();
      return this.items.filter((status) => status.text.toLowerCase().includes(searchTerm));
    },
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'status';
      item.defaultValue = [...this.selected];
      const query = {
        query: {
          bool: {
            filter: [
              {
                terms: {
                  'call_status.keyword': this.selected.map((status) => status.value),
                },
              },
            ],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        text: this.selected.map((status) => status.text).join(', '),
      });
      this.dialog = false;
      this.selected = [];
    },
  },
};
</script>

<style scoped>
.scrollable-content {
  max-height: 300px;
  overflow-y: auto;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
}

.subheading-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

/* Adjusted padding below search bar */
.search {
  padding-bottom: 1px !important; /* Less padding under search */
}
</style>
