<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Minimum Call Charge
      </v-toolbar-title>
      <v-btn
        v-if="!createMode &&isPermitted('/tenants/<tenant_name>/accounts/<account_id>/minimumcharges/<mcid>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-layout v-if="createMode">
          <v-flex xs4>
            <v-autocomplete
              v-if="createMode"
              v-model="accountFilter"
              flat
              :items="accounts"
              item-text="name"
              item-value="account_id"
              label="Account filter"
            />
          </v-flex>
        </v-layout>
        <v-layout v-if="!createMode">
          <v-flex xs4>
            <v-text-field
              readonly
              label="Call Type"
              :value="charge.CallType"
            />
          </v-flex>
        </v-layout>
        <v-layout v-if="createMode">
          <v-flex xs4>
            <v-select
              v-model="charge.CallType"
              flat
              :items="callTypes"
              label="Call Type"
              :rules="CallTypeRules"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs4>
            <v-text-field
              v-model="charge.MinimumCost"
              label="Minimum Cost"
              :rules="MinimumCostRules"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex
            xs4
            class="pa-2"
          >
            <v-row>
              <v-btn
                v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/minimumcharges/<mcid>', 'PUT')"
                primary
                color="primary"
                dark
                @click="updateCharge"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this charge?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteCharge"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'Callcharge',
  data() {
    return {
      accounts: [],
      accountFilter: null,
      chargeAccountId: this.$route.params.accountId,
      createMode: false,
      deleteDialog: false,
      valid: false,
      mcid: this.$route.params.mcid,
      charge: {},
      callTypes: ['NATIONAL', 'INTERNATIONAL', 'NONGEO'],
      CallTypeRules: [
        (v) => !!v || 'Call Type is required',
      ],
      MinimumCostRules: [
        (v) => (v != null && String(v).length > 0) || 'Minimum Cost is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
    };
  },
  created() {
    if (this.mcid === 'new') {
      this.createMode = true;
      this.loadAccounts();
    } else {
      this.loadCharge();
    }
  },
  methods: {
    async updateCharge() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Charge successfully updated.';
        if (this.createMode) {
          await axios.post(`${config.BTL_API_ACCOUNT_URL}/${this.accountFilter}/minimumcharges`, this.charge);
          snackMessage = 'Charge successfully created.';
        } else {
          await axios.put(`${config.BTL_API_ACCOUNT_URL}/${this.chargeAccountId}/minimumcharges/${this.mcid}`, this.charge);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating charge', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} charge.`, true);
      }
    },
    async loadCharge() {
      try {
        const response = await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.chargeAccountId}/minimumcharges/${this.mcid}`);
        const charge = response.data;
        if (response.data.AccountId) {
          const account = (await axios.get(`${config.BTL_API_ACCOUNT_URL}/${response.data.AccountId}`)).data;
          charge.account = account.name;
        }
        this.charge = charge;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve charge.', true);
        console.error('Failed to retrieve charge', error);
      }
    },
    async deleteCharge() {
      try {
        await axios.delete(`${config.BTL_API_ACCOUNT_URL}/${this.chargeAccountId}/minimumcharges/${this.mcid}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete charge.', true);
        console.error('Failed to delete charge', error);
      }
    },
    async loadAccounts() {
      try {
        const response = await axios.get(config.BTL_API_ACCOUNT_URL);
        this.accounts = sortBy(response.data, [(account) => account.name.toLowerCase()]);
      } catch (err) {
        this.$emit('snack', 'Failed to load accounts', err);
        console.error('Failed to load accounts', err);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
