<template>
  <div class="flex-wrapper">
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create CallFlow
      </v-toolbar-title>
      <v-btn
        color="primary"
        text
        @click="savePDF"
      >
        Export as PDF
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container
      v-if="callflow"
      fluid
    >
      <v-layout class="px-2">
        <v-flex xs7>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-text-field
              ref="CallFlowName"
              v-model="callflow.Name"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
            <v-switch
              v-model="callflow.Enabled"
              label="Enabled"
            />
          </v-form>
        </v-flex>
      </v-layout>
      <v-layout class="px-2 mt-3">
        <p class="subtitle-1 mt-2 mr-5">
          Dial plan
        </p>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            :disabled="!valid"
            dark
            @click="createCallflow"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-layout>
      <v-layout
        v-if="callflow"
        class="growme"
      >
        <CallFlowEditor
          id="callFlowComponent"
          :callflow="callflow.Dialplan"
        />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import * as html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

import config from '@/config.js';

import CallFlowEditor from '@/components/callfloweditor2/CallFlowEditor';

export default {
  name: 'CloneCallFlow',
  components: {
    CallFlowEditor,
  },
  data() {
    return {
      valid: false,
      callflow: null,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'timeZone',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  mounted() {
    this.loadCallFlow();
  },
  methods: {
    savePDF() {
      const doc = new JsPDF({
        orientation: 'landscape',
      });
      const el = document.getElementById('callFlowComponent');
      const _self = this;
      html2canvas(el, {
        scale: 1.0,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const filename = `${_self.callflow.Name}-${new Date().toJSON().split('T')[0]}.pdf`;
        doc.addImage(imgData, 'PNG', 15, 20);
        doc.text(filename.split('.')[0], 15, 10);
        doc.autoPrint(); // this works when pdf is opened in viewer
        doc.save(filename);
      });
    },
    async loadCallFlow() {
      try {
        const callflow = await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/callflows/${this.$route.params.callflowId}`);
        const {
          data: {
            Dialplan, Name,
          },
        } = callflow;
        const copyName = `${Name} COPY ${this.formatDate(Date.now())}`;

        this.callflow = {
          Name: copyName,
          Dialplan,
          Enabled: false,
        };

        setTimeout(() => {
          this.$refs.CallFlowName.focus();
        }, 0);
      } catch (error) {
        console.error('Failed to load callflow to clone', error);
        if (error.response?.data?.ValidationReport) {
          this.processValidationErrors(error.response.data.ValidationReport);
        } else {
          this.$emit('snack', 'Failed to clone a CallFlow', true);
        }
      } finally {
        this.cloneDialog = false;
      }
    },
    async createCallflow() {
      axios.post(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/callflows`, this.callflow)
        .then(() => {
          this.$emit('snack', 'CallFlow successfully created!');
          this.$router.push('/callflows');
        })
        .catch((error) => {
          if (error.response.data.ValidationReport) {
            const errors = [
              ...error.response.data.ValidationReport.Error,
              ...error.response.data.ValidationReport.Critical,
              ...error.response.data.ValidationReport.Warning,
            ].map((e) => ({
              text: e.text,
              isError: true,
            }));
            this.$emit('snack', errors, true);
          } else {
            this.$emit('snack', 'Failed to save CallFlow', true);
          }
        });
    },
    formatDate(d) {
      return moment(d).tz(this.timeZone).format(config.date_time_format);
    },
  },
};
</script>

<style>
  .flex-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .flex-wrapper > header {
    flex-grow: 0;
  }

  .flex-wrapper > .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .flex-wrapper > .container > .layout {
    flex-grow: 0;
  }

  .flex-wrapper > .container > .growme {
    flex-grow: 1;
  }
</style>
