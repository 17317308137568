<template>
  <div class="simpletimeperiodpicker">
    <v-layout wrap>
      <v-flex xs12>
        <p class="subtitle-1">
          Add
          <v-btn
            icon
            small
            primary
            @click="addDay"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
    </v-layout>
    <v-layout
      v-for="(t,i) in originHours"
      :key="i"
      wrap
    >
      <v-flex xs5>
        <v-layout wrap>
          <v-flex xs2>
            <v-icon class="mt-2">
              schedule
            </v-icon>
          </v-flex>
          <v-flex xs3>
            <v-select
              v-model="t.shrs"
              class="pt-0 mt0"
              dense
              flat
              :item-value="t.shrs"
              :items="hrsArray"
            />
          </v-flex>
          <v-flex xs1>
            <p class="subtitle-1 text-center mt-2">
              :
            </p>
          </v-flex>
          <v-flex xs3>
            <v-select
              v-model="t.smin"
              class="pt-0 mt0"
              dense
              flat
              :item-value="t.smin"
              :items="minArray"
            />
          </v-flex>
          <v-flex xs1>
            <p class="subtitle-1 text-right mt-2">
              to
            </p>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout wrap>
          <v-flex xs3>
            <v-select
              v-model="t.ehrs"
              class="pt-0 mt0"
              dense
              flat
              :item-value="t.ehrs"
              :items="hrsArray"
            />
          </v-flex>
          <v-flex xs1>
            <p class="subtitle-1 text-center mt-2">
              :
            </p>
          </v-flex>
          <v-flex xs3>
            <v-select
              v-model="t.emin"
              class="pt-0 mt0"
              dense
              flat
              :item-value="t.emin"
              :items="minArray"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs1>
        <v-layout wrap>
          <v-flex xs12>
            <v-btn
              icon
              small
              primary
              @click="removeWeekDay(i)"
            >
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-toolbar
          class="custom_select"
          dense
        >
          <v-select
            v-model="populateDay"
            class="custom-populate-week"
            hide-details
            :items="daysList"
            :label="populate_text"
            multiple
            persistent-hint
            item-value="value"
          />
          <v-divider vertical />
          <v-btn
            icon
            small
            primary
            @click="fnPopulateDay"
          >
            <v-icon>send</v-icon>
          </v-btn>
        </v-toolbar>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'Timeperiodandpopulate',
  props: {
    value: Array,
    index: Number,
    days: Array,
    id: String,
  },
  data: () => ({
    populate_text: 'Populate these hours to other bank holiday',
    originDays: null,
    populateDay: [],
    originHours: [],
    timePeriod: { start: '08:00', finish: '17:00' },
    daysList: [],
    hrsArray: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
      '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minArray: ['00', '15', '30', '45'],
  }),
  watch: {
    originHours: {
      handler() {
        this.updateHour();
        this.onUpdate();
      },
      deep: true,
    },
  },
  created() {
    this.originHours.forEach(() => {
      const sArray = this.item.start.split(':');
      const eArray = this.item.finish.split(':');
      this.shrs = sArray[0];
      this.smin = sArray[1];
      this.ehrs = eArray[0];
      this.emin = eArray[1];
    });
  },
  mounted() {
    const self = this;
    self.originHours = lodash.cloneDeep(self.value);
    self.originDays = lodash.cloneDeep(self.days);
    self.originDays.forEach((item) => {
      self.daysList.push({ text: item.text, value: item.id });
    });
  },
  methods: {
    onUpdate() {
      this.updateHour();
      const data = {
        hours: this.originHours,
        index: this.index,
        id: this.id,
      };
      this.$emit('update', data);
    },
    updateHour() {
      this.originHours.forEach((item) => {
        item.finish = `${item.ehrs}:${item.emin}`;
        item.start = `${item.shrs}:${item.smin}`;
      });
      return this.originHours;
    },
    fnPopulateDay() {
      this.originHours = this.updateHour();
      const data = {
        days: lodash.cloneDeep(this.populateDay),
        hours: this.originHours,
        index: this.index,
      };
      this.populateDay = [];
      this.$emit('populate', data);
    },
    addDay() {
      if (!Array.isArray(this.originHours)) {
        this.originHours = [];
      }
      this.originHours.push({
        start: '08:00',
        finish: '17:00',
        smin: '00',
        shrs: '08',
        emin: '00',
        ehrs: '17',
      });
      this.onUpdate();
    },
    removeWeekDay(i) {
      this.originHours.splice(i, 1);
      this.originHours = this.updateHour();
      this.onUpdate();
    },
  },
};
</script>
<style>
</style>
