<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Analytics Rates Management
      </v-toolbar-title>
      <v-autocomplete
        v-model="accountFilter"
        flat
        :items="accountFilterOptions"
        item-text="name"
        item-value="account_id"
        label="Account filter"
        hide-details
        single-line
        style="max-width: 20rem"
      />
      <v-btn
        text
        color="blue"
        @click="addNewRate"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        color="primary"
        :table-data="rates"
        :headers="exportHeaders"
        report-name="Analytics Rates"
      />
      <v-row class="mx-1" />

      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="rates"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            :custom-sort="customSort"
            height="calc(100vh - 220px)"
            show-expand
            :expanded="expanded"
            @click:row="expandService"
          >
            <template #item.activeRevision.effectiveFromDate="{ item }">
              <span v-if="item.activeRevision">{{ item.activeRevision.effectiveFromDate | formatDate }}</span>
            </template>
            <template #item.activeRevision.effectiveUntilDate="{ item }">
              <span v-if="item.activeRevision">{{ item.activeRevision.effectiveUntilDate | formatDate }}</span>
            </template>
            <template #item.data-table-expand="{ item, isExpanded }">
              <v-layout>
                <v-btn icon>
                  <v-icon :style="`transform: rotate(${isExpanded ? '180' : '0'}deg)`">
                    expand_more
                  </v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template #activator="{on}">
                    <v-btn
                      icon
                      @click.stop="addNewRevision(item)"
                      v-on="on"
                    >
                      <v-icon v-on="on">
                        add
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Add Revision</span>
                </v-tooltip>
              </v-layout>
            </template>
            <template #expanded-item="{ item, headers: itemHeaders }">
              <td
                class="py-4 px-8"
                :colspan="itemHeaders.length"
              >
                <v-data-table
                  :headers="[
                    { text: 'Line Count', value: 'lines' },
                    { text: 'Cost (pence)', value: 'cost' },
                    { text: 'Effective From', value: 'effectiveFromDate' },
                    { text: 'Effective Until', value: 'effectiveUntilDate' },
                    { text: 'Actions', value: 'action', sortable: false },
                  ]"
                  :items="item.revisions"
                  :item-key="item.effectiveFromDate"
                  :items-per-page="10"
                  :footer-props="{itemsPerPageOptions: [5, 10, 20]}"
                  :custom-sort="customSort"
                >
                  <template #item.effectiveFromDate="{ item: nestedItem }">
                    <span>{{ nestedItem.effectiveFromDate | formatDate }}</span>
                  </template>
                  <template #item.effectiveUntilDate="{ item: nestedItem }">
                    <span>{{ nestedItem.effectiveUntilDate | formatDate }}</span>
                  </template>
                  <template #item.action="{ item: nestedItem }">
                    <v-btn
                      v-if="nestedItem.isFutureRevision || nestedItem.isActiveRevision"
                      icon
                      @click="editRevision(nestedItem)"
                    >
                      <v-icon>
                        edit
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="moment().isBefore(nestedItem.effectiveFromDate)"
                      icon
                      @click="deleteRevision(nestedItem)"
                    >
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-dialog
        v-model="newRateDialog"
        max-width="500px"
      >
        <!-- v-if is needed to create a new instance every time -->
        <new-rate
          v-if="newRateDialog"
          :rules="rules"
          :accounts="accounts"
          :rates="rates"
          @createRate="saveNewRate"
          @closeDialog="newRateDialog = false"
        />
      </v-dialog>

      <v-dialog
        v-model="newRevisionDialog"
        max-width="500px"
      >
        <!-- v-if is needed to create a new instance every time -->
        <new-revision
          v-if="newRevisionDialog"
          :rules="rules"
          :rates="rates"
          :parent-rate="newRevisionParentRate"
          @createRevision="saveNewRevision"
          @closeDialog="newRevisionDialog = false"
        />
      </v-dialog>

      <v-dialog
        v-model="editedRevisionDialog"
        max-width="500px"
      >
        <!-- v-if is needed to create a new instance every time -->
        <edit-revision
          v-if="editedRevisionDialog"
          :rules="rules"
          :rates="rates"
          :revision="editedRevision"
          :parent-rate="editedRevisionParentRate"
          @updateRevisions="saveRevisions"
          @closeDialog="editedRevisionDialog = false"
        />
      </v-dialog>

      <v-dialog
        v-model="revisionDeleteDialog"
        max-width="430"
      >
        <v-card>
          <v-card-title>Are you sure you want to delete this revision?</v-card-title>
          <v-card-actions class="mr-4">
            <v-spacer />
            <v-btn
              color="primary"
              @click="confirmDelete"
            >
              Confirm
            </v-btn>
            <v-btn
              color="error"
              @click="revisionDeleteDialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { sortBy } from 'lodash';
import config from '@/config.js';

import download from '@/components/download/tableDownload';
import NewRate from '@/components/billing/NewRate';
import NewRevision from '@/components/billing/NewRevision';
import EditRevision from '@/components/billing/EditRevision';
import customSort from '@/utils/customSort';

export default {
  name: 'AnalyticsRates',
  components: {
    download,
    NewRate,
    NewRevision,
    EditRevision,
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : 'Indefinitely';
    },
  },
  data() {
    const headers = [
      { text: 'Account', value: 'accountName' },
      { text: 'Complexity', value: 'complexity' },
      { text: 'Line Count', value: 'activeRevision.lines' },
      { text: 'Cost (pence)', value: 'activeRevision.cost' },
      { text: 'Effective From', value: 'activeRevision.effectiveFromDate' },
      { text: 'Effective Until', value: 'activeRevision.effectiveUntilDate' },
      { text: 'Revisions', value: 'data-table-expand' },
    ];
    return {
      rates: [],
      headers,
      exportHeaders: headers.slice(0, -1).map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      expanded: [],
      accounts: [],
      accountFilterOptions: [],
      accountFilter: 'all',
      loading: false,
      newRateDialog: false,
      newRevisionDialog: false,
      newRevisionParentRate: {},
      editedRevisionDialog: false,
      editedRevision: {},
      editedRevisionParentRate: {},
      revisionToDelete: {},
      revisionDeleteDialog: false,
      rules: {
        lines: [
          (v) => (v != null && String(v).length > 0) || 'Line Count is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        complexity: [
          (v) => (v != null && String(v).length > 0) || 'Query Complexity is required',
        ],
        cost: [
          (v) => (v != null && String(v).length > 0) || 'Cost is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        startDate: [
          (v) => (v != null && String(v).length > 0) || 'Effective From Date is required',
        ],
      },
      moment,
    };
  },
  watch: {
    accountFilter() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        await this.loadAccounts();
        await this.loadRates();
      } catch (error) {
        console.error('Could not load data: ', error.message);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadAccounts() {
      const response = await axios.get(config.BTL_API_ACCOUNT_URL);
      this.accounts = sortBy(response.data, [(account) => account.name.toLowerCase()]);

      this.accountFilterOptions = [
        { name: '--  All  --', account_id: 'all' },
        { name: '--  Default  --', account_id: null },
        ...this.accounts,
      ];
    },
    async loadRates() {
      const response = await axios.get(`${config.bizvu_billing_catalog_url}/rates`);

      this.rates = response.data
        .filter((rate) => (this.accountFilter === 'all' ? true : (rate.accountId || null) === this.accountFilter))
        .map((rate) => {
          rate.accountName = '';
          if (this.accounts.length > 0) {
            if (rate.accountId) {
              const acc = this.accounts.find((a) => a.account_id === rate.accountId);
              if (acc) {
                rate.accountName = acc.name;
              }
            } else {
              rate.accountId = null;
            }
          }

          rate.revisions = rate.revisions.map((r) => {
            const startedBeforeNow = moment().isSameOrAfter(r.effectiveFromDate);
            const validUntilAfterNow = !r.effectiveUntilDate || moment().isSameOrBefore(r.effectiveUntilDate);
            return {
              ...r,
              rateID: rate.id,
              isFutureRevision: moment().isBefore(r.effectiveFromDate),
              isActiveRevision: startedBeforeNow && validUntilAfterNow,
            };
          });

          return rate;
        });

      this.expanded = this.rates.filter((rate) => this.expanded.find((r) => r.id === rate.id));
    },
    addNewRate() {
      this.newRateDialog = true;
    },
    async saveNewRate(newRate) {
      try {
        await axios.post(`${config.bizvu_billing_catalog_url}/rates`, newRate);
        this.$emit('snack', 'Rate created successfully');
      } catch (error) {
        let suffix = '';
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          suffix += `: ${error.response.data.error}`;
        }
        console.error('Failed to create rate', error);
        this.$emit('snack', `Failed to create rate${suffix}.`, true);
      } finally {
        this.newRateDialog = false;
        this.loadRates();
      }
    },
    addNewRevision(rate) {
      this.newRevisionDialog = true;
      this.newRevisionParentRate = { ...rate };
    },
    async saveNewRevision(rate) {
      try {
        await axios.put(`${config.bizvu_billing_catalog_url}/rates/${rate.id}`, {
          accountId: rate.accountId,
          complexity: rate.complexity,
          revisions: rate.revisions,
        });
        this.$emit('snack', 'Revision added successfully');
      } catch (error) {
        let suffix = '';
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          suffix += `: ${error.response.data.error}`;
        }
        console.error('Failed to add revision', error);
        this.$emit('snack', `Failed to add revision${suffix}.`, true);
      } finally {
        this.newRevisionDialog = false;
        this.newRevisionParentRate = {};
        this.loadRates();
      }
    },
    editRevision(revision) {
      this.editedRevision = { ...revision };
      const parentRate = this.rates.find((rate) => rate.id === revision.rateID);
      this.editedRevisionParentRate = { ...parentRate };
      this.editedRevisionDialog = true;
    },
    async saveRevisions(rateUpdate) {
      try {
        await axios.put(`${config.bizvu_billing_catalog_url}/rates/${rateUpdate.id}`, {
          accountId: rateUpdate.accountId,
          complexity: rateUpdate.complexity,
          revisions: rateUpdate.revisions.map((revision) => {
            delete revision.isFutureRevision;
            delete revision.isActiveRevision;
            delete revision.rateID;
            return revision;
          }),
        });
        this.$emit('snack', 'Rate updated successfully');
      } catch (error) {
        let suffix = '';
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          suffix += `: ${error.response.data.error}`;
        }
        console.error('Failed to update revisions', error);
        this.$emit('snack', `Failed to update revisions${suffix}.`, true);
      } finally {
        this.editedRevisionDialog = false;
        this.editedRevision = {};
        this.loadRates();
      }
    },
    async deleteRevision(revision) {
      this.revisionToDelete = revision;
      this.revisionDeleteDialog = true;
    },
    async confirmDelete() {
      try {
        const parentRate = this.rates.find((rate) => rate.id === this.revisionToDelete.rateID);
        const rateIndex = parentRate.revisions.findIndex((rate) => rate.id === this.revisionToDelete.id);
        if (!parentRate.accountId && rateIndex !== parentRate.revisions.length - 1) {
          throw new Error('cannot leave gaps in default rates');
        } else if (!parentRate.accountId) {
          // if last revision for a default rate was deleted, ensure that the previous revision is active indefinitely
          parentRate.revisions[rateIndex - 1].effectiveUntilDate = null;
        }

        parentRate.revisions.splice(rateIndex, 1);
        await axios.put(`${config.bizvu_billing_catalog_url}/rates/${parentRate.id}`, {
          accountId: parentRate.accountId,
          complexity: parentRate.complexity,
          revisions: parentRate.revisions,
        });
      } catch (error) {
        let suffix = '';
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          suffix += `: ${error.response.data.error}`;
        } else {
          suffix += `: ${error.message}`;
        }
        console.error('Failed to delete revision', error);
        this.$emit('snack', `Failed to delete revision${suffix}.`, true);
      } finally {
        this.revisionDeleteDialog = false;
        this.revisionToDelete = {};
        this.loadRates();
      }
    },
    expandService(row) {
      const index = this.expanded.indexOf(row);
      if (index === -1) {
        this.expanded.push(row);
      } else {
        this.$delete(this.expanded, index);
      }
    },
    customSort: customSort(['activeRevision.effectiveFromDate', 'activeRevision.effectiveUntilDate']),
  },
};

</script>

<style>
  tr {
    cursor: pointer;
  }

  .v-data-table__expanded__content > td > div > div > table > tbody > tr {
    cursor: auto;
  }
</style>
