import { render, staticRenderFns } from "./FieldSorter.vue?vue&type=template&id=2722e64a&scoped=true"
import script from "./FieldSorter.vue?vue&type=script&lang=js"
export * from "./FieldSorter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2722e64a",
  null
  
)

export default component.exports