<template>
  <div class="user-context">
    <v-card>
      <v-tabs
        grow
        background-color="primary"
        centered
        icons-and-text
      >
        <v-tab>
          User Profile - {{ authentication.userName }}
          <v-icon>account_circle</v-icon>
        </v-tab>
        <v-tab v-if="userCanViewAnalytics">
          Schedules & Reports
          <v-icon>description</v-icon>
        </v-tab>

        <v-tab-item>
          <v-container>
            <v-row>
              <v-col>
                <v-card class="px-5 mb-3">
                  <v-card-title>Time Zone</v-card-title>
                  <v-autocomplete
                    v-model="timeZone"
                    :items="timeZones"
                    label="Time Zone"
                  >
                    <template #item="{ item }">
                      <small class="font-weight-light">({{ item.offset }})</small> &nbsp; {{ item.name }} <v-spacer />  <small class="font-weight-light">({{ item.value }})</small>
                    </template>
                    <template #selection="{ item }">
                      <div><small class="font-weight-light">({{ item.value }} {{ item.offset }})</small> &nbsp; {{ item.name }}</div>
                    </template>
                  </v-autocomplete>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="updateTimeZone"
                    >
                      Update
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="px-5 mb-3">
                  <v-card-title v-if="canLoadRoles">
                    Roles ({{ roles.length }}/{{ allRoles }})
                  </v-card-title>
                  <v-card-title v-else>
                    Roles
                  </v-card-title>
                  <v-data-table
                    :headers="rolesHeaders"
                    :items="roles"
                    item-key="role_id"
                    sort-by="name"
                    fixed-header
                    height="300"
                    :items-per-page="-1"
                    dense
                  />
                </v-card>
              </v-col>
              <v-col>
                <v-card class="px-5 mb-3">
                  <v-card-title v-if="canLoadFunctions">
                    Functions ({{ functions.length }}/{{ allFunctions }})
                  </v-card-title>
                  <v-card-title v-else>
                    Functions
                  </v-card-title>
                  <v-data-table
                    :headers="functionsHeaders"
                    :items="functions"
                    item-key="function_id"
                    sort-by="name"
                    fixed-header
                    height="300"
                    :items-per-page="-1"
                    dense
                  />
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card class="px-5 mb-3">
                  <v-card-title>Change Password</v-card-title>
                  <v-form
                    ref="passwordForm"
                    v-model="passwordChangeValid"
                  >
                    <v-text-field
                      v-model="password"
                      label="Change Password"
                      :rules="rules.passwordRules"
                      :counter="30"
                      :append-icon="
                        (password && password.length)
                          ? showPwd
                            ? 'visibility_off'
                            : 'visibility'
                          : ''
                      "
                      :type="showPwd ? 'text' : 'password'"
                      required
                      @click:append="showPwd = !showPwd"
                    />
                    <v-text-field
                      v-model="confirmPassword"
                      label="Confirm Password"
                      :rules="[rules.matching, rules.passwordRules]"
                      :counter="30"
                      :append-icon="
                        (confirmPassword && confirmPassword.length)
                          ? showPwd
                            ? 'visibility_off'
                            : 'visibility'
                          : ''
                      "
                      :type="showPwd ? 'text' : 'password'"
                      required
                      @click:append="showPwd = !showPwd"
                    />
                  </v-form>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      :disabled="!passwordChangeValid"
                      @click="changePassword"
                    >
                      Change Password
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item v-if="userCanViewAnalytics">
          <SchedulesAndReports
            :account-names="accountNames"
            :dialogue-open="dialogueOpen"
            @close="close"
            @snack="$emit('snack', $event)"
          />
        </v-tab-item>
      </v-tabs>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import {
  BTL_API_URL, BTL_API_TENANT_URL, BTL_API_ACCOUNT_URL, PASSWORD_REGEX,
} from '@/config.js';

import SchedulesAndReports from '../schedule/SchedulesAndReports';
import permittedFunction from '../../permittedFunction';

export default {
  name: 'Usercontext',
  components: {
    SchedulesAndReports,
  },
  props: {
    dialogueOpen: Boolean,
    accounts: Array,
  },
  data() {
    return {
      roles: [],
      functions: [],
      canLoadFunctions: false,
      canLoadRoles: false,
      allFunctions: 0,
      allRoles: 0,
      password: '',
      confirmPassword: '',
      showPwd: false,
      rules: {
        matching: (value) => value === this.password || 'Passwords do not match',
        passwordRules: [
          (v) => !!v || 'Password is required',
          (v) => (v && new RegExp(PASSWORD_REGEX).test(v)) || 'Password must be 12 or more characters containing lower case, upper case, numeric and a special characters',
          (v) => (v && (v.length <= 30 && v.length >= 12)) || 'Password must have between 12 and 30 characters',
        ],
      },
      rolesHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
      ],
      functionsHeaders: [
        { text: 'Name', value: 'name' },
      ],
      timeZone: this.$store.getters.timeZone,
      passwordChangeValid: false,
      accountNames: null,
    };
  },
  computed: {
    userPreferences() {
      return this.$store.state.userPreferences;
    },
    userHasAccessToMultipleAccounts() {
      return Object.keys(this.accountNames).length > 1;
    },
    userCanViewAnalytics() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/analytics', 'POST');
    },
    ...mapGetters([
      'authentication',
      'timeZones',
    ]),
  },
  watch: {
    authentication: {
      handler() {
        this.loadEntitlements();
      },
      deep: true,
    },
    dialogueOpen(dialogueOpen) {
      if (dialogueOpen) this.timeZone = this.$store.getters.timeZone;
    },
  },
  created() {
    this.canLoadFunctions = this.isPermitted('/functions', 'GET');
    if (this.canLoadFunctions) {
      this.loadFunctions();
    }
    this.canLoadRoles = this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/roles', 'GET');
    if (this.canLoadRoles) {
      this.loadRoles();
    }
  },
  mounted() {
    this.createAccountNameMap();
    this.loadEntitlements();
  },
  methods: {
    createAccountNameMap() {
      const accountNames = {};
      accountNames[this.authentication.userAccountId] = this.authentication.userAccountName;
      if (this.accounts) {
        this.accounts.forEach((account) => {
          accountNames[account.account_id] = account.name;
        });
      }
      this.accountNames = accountNames;
    },
    changePassword() {
      if (!this.$refs.passwordForm.validate()) {
        return;
      }
      const accountId = this.authentication.userAccountId;
      const { userId } = this.authentication;
      const changeURL = `${BTL_API_TENANT_URL}/accounts/${accountId}/users/${userId}/password`;
      axios.post(changeURL, { password: this.password })
        .then(() => {
          this.$emit('snack', 'Password successfully changed');
        })
        .catch((e) => {
          console.error(e);
        });
    },
    loadEntitlements() {
      const functions = {};
      const roles = {};
      this.authentication.entitlements.forEach((e) => {
        const entityType = e.resource.entity_type;
        const entityId = e.resource.entity_id;
        e.roles.forEach((r) => {
          roles[`${r.name.replace(/\s/g, '')}_${e.entity_type}_${e.entity_id}_${e.entity_account}`] = {
            name: r.name,
            entity_type: e.resource.entity_type,
            entity_id: e.resource.entity_id,
            entityName: this.findEntityName(entityType, entityId),
            description: r.description,
          };

          r.functions.forEach((f) => {
            functions[`${f.verb}_${f.endpoint}_${e.entity_type}_${e.entity_id}_${e.entity_account}`] = {
              name: f.name,
              entity_type: e.resource.entity_type,
              entity_id: e.resource.entity_id,
              entityName: this.findEntityName(entityType, entityId),
            };
          });
        });
        e.functions.forEach((f) => {
          functions[`${f.verb}_${f.endpoint}_${e.entity_type}_${e.entity_id}_${e.entity_account}`] = {
            name: f.name,
            entity_type: e.resource.entity_type,
            entity_id: e.resource.entity_id,
            entityName: this.findEntityName(entityType, entityId),
          };
        });
      });

      this.functions = Object.entries(functions).map((e) => e[1]);
      this.roles = Object.entries(roles).map((r) => r[1]);
    },
    async loadFunctions() {
      try {
        const { data: functions } = await axios.get(`${BTL_API_URL}/functions`);
        this.allFunctions = functions.length;
      } catch (error) {
        console.error('Failed to load functions', error);
      }
    },
    async loadRoles() {
      try {
        const { data: roles } = await axios.get(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/roles`);
        this.allRoles = roles.length;
      } catch (error) {
        console.error('Failed to load roles', error);
      }
    },
    findEntityName(entityType, entityId) {
      if (entityType === 'account') {
        const name = this.accountNames[entityId];
        if (name) {
          return name;
        }
      }
      return entityId;
    },
    close() {
      this.$refs.passwordForm.reset();
      this.$emit('close');
    },
    updateTimeZone() {
      if (!this.timeZone) {
        this.$emit('snack', 'No Time Zone Selected! Restored Original Setting');
        this.timeZone = this.userPreferences.timeZone;
        return;
      }
      const updatedUserPreferences = {
        ...this.userPreferences,
        timeZone: this.timeZone,
      };
      this.$store.dispatch('saveUserPreferences', updatedUserPreferences)
        .then(() => {
          this.$emit('snack', 'Time Zone updated');
        })
        .catch((error) => {
          this.$emit('snack', 'Time Zone update failed!');
          console.error('failed to save time zone', error);
        });
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>
</style>
