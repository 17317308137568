<template>
  <v-dialog
    v-model="alertDialog"
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-badge
        v-if="authentication.userName && isPermitted('/tenants/<tenant_name>/accounts/<account_id>/alerts', 'GET')"
        color="red"
        :content="unreadAlerts.length"
        :offset-x="20"
        :offset-y="20"
        :value="unreadAlerts.length > 0"
      >
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            :color="unreadAlerts.length > 0 ? 'red' : 'white'"
          >
            notification_important
          </v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card>
      <v-tabs
        v-model="tab"
        background-color="primary"
        center-active
        grow
        dark
      >
        <v-tabs-slider color="green" />

        <v-tab
          key="new"
        >
          New
        </v-tab>
        <v-tab
          key="read"
        >
          Read <span class="ml-2 text-caption">(Last 7 Days)</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          key="new"
        >
          <timeline v-if="unreadAlerts.length">
            <timeline-item
              v-for="(record,i) in unreadAlerts"
              :key="i"
            >
              <template #others>
                <v-icon>{{ record.icon }}</v-icon>
              </template>
              <v-layout>
                <div>
                  <span style="color: grey;font-style: italic;">{{ record.date }} ({{ record.user }})</span><br>
                  <span>{{ record.description }}</span><br>
                  <span class="text-caption pt-3">{{ record.comment }}</span>
                </div>
                <div class="close">
                  <v-progress-circular
                    v-if="deleting === record.id"
                    indeterminate
                    color="red"
                    :size="24"
                    :width="3"
                  />
                  <v-tooltip
                    v-else
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="close"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleDelete(record.id)"
                      >
                        done
                      </v-icon>
                    </template>
                    <span>Mark Read</span>
                  </v-tooltip>
                </div>
              </v-layout>
            </timeline-item>
          </timeline>
          <v-card-title
            v-else
            class="headline"
          >
            All Clear!
          </v-card-title>
        </v-tab-item>

        <v-tab-item
          key="read"
        >
          <timeline
            v-if="readAlerts.length"
            class="acknowledged"
          >
            <timeline-item
              v-for="(record,i) in readAlerts"
              :key="i"
            >
              <template #others>
                <v-icon>{{ record.icon }}</v-icon>
              </template>
              <v-layout>
                <div>
                  <span style="color: grey;font-style: italic;">{{ record.date }} ({{ record.user }})</span><br>
                  <span>{{ record.description }}</span><br>
                  <span class="text-caption pt-3">{{ record.comment }}</span>
                </div>
              </v-layout>
            </timeline-item>
          </timeline>
          <v-card-title
            v-else
            class="headline"
          >
            All Clear!
          </v-card-title>
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="alertDialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Timeline, TimelineItem } from 'vue-cute-timeline';
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';

import config from '@/config.js';
import permittedFunction from '@/permittedFunction.js';

const THIRTY_SECONDS = 30 * 1000;

export default {
  name: 'Alerts',
  components: {
    Timeline,
    TimelineItem,
  },
  data() {
    return {
      alertDialog: false,
      tab: false,

      unreadAlerts: [],
      readAlerts: [],

      deleting: false,

      refreshTimer: null,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'timeZone',
      'authentication',
    ]),
  },
  watch: {
    alertDialog(open) {
      if (open) {
        this.tab = 'new';
        this.loadAlerts();
      }
    },
  },
  async mounted() {
    if (this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/alerts', 'GET')) {
      await this.loadAlerts();
      this.startRefresh();
    }
  },
  beforeMount() {
    clearInterval(this.refreshTimer);
  },
  methods: {
    async loadAlerts() {
      try {
        const lastweek = moment().subtract(7, 'days').format();
        const unreadAlerts = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/alerts?pagenum=1&pagesize=1000&q={"order_by":[{"field":"Datetime_raised","direction":"desc"}], "filters":[{"name":"Read","op":"eq","val":false}]}`);
        const readAlerts = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/alerts?pagenum=1&pagesize=100&q={"order_by":[{"field":"Datetime_raised","direction":"desc"}], "filters":[{"name":"Read","op":"eq","val":true},{"name":"Datetime_raised","op":">","val":"${lastweek}"}]}`);
        this.unreadAlerts = unreadAlerts.data.map(this.processAlert);
        this.readAlerts = readAlerts.data.map(this.processAlert);
      } catch (error) {
        console.error('Error roleLoading alerts', error);
        this.$emit('snack', 'Failed to load alerts', true);
      }
    },
    async handleDelete(id) {
      try {
        this.deleting = id;
        await axios.delete(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/alerts/${id}`);
        this.loadAlerts();
      } catch (error) {
        this.$emit('snack', 'Failed to delete alert.', true);
      }
    },
    processAlert(alert) {
      const record = {
        user: alert.Service,
        description: alert.AlertName,
        comment: alert.Comment,
        date: moment(`${alert.Datetime_raised}Z`).tz(this.timeZone).format(config.long_date_time_format),
        id: alert.AlertId,
      };

      if (alert.EntityType === 'Whitelist' || alert.EntityType === 'Blacklist') {
        record.icon = 'filter_list';
      } else if (alert.EntityType === 'Device') {
        record.icon = 'dns';
      } else if (alert.EntityType === 'Subscription') {
        record.icon = 'settings';
      } else if (alert.EntityType === 'Trunk') {
        record.icon = 'shuffle';
      } else if (alert.EntityType === 'Callflow') {
        record.icon = 'call_split';
      } else if (alert.EntityType === 'User') {
        record.icon = 'account_circle';
      } else if (alert.EntityType === 'PhoneNumber') {
        record.icon = 'call';
      } else if (alert.EntityType === 'Account') {
        record.icon = 'supervisor_account';
      } else if (alert.EntityType === 'Group') {
        record.icon = 'people';
      } else if (alert.EntityType === 'Site') {
        record.icon = 'business';
      } else if (alert.EntityType === 'National') {
        record.icon = 'local_atm';
      } else if (alert.EntityType === 'Toll Fraud') {
        record.icon = 'warning';
      }

      return record;
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    startRefresh() {
      this.refreshTimer = setInterval(this.loadAlerts, THIRTY_SECONDS);
    },
  },
};
</script>

<style scoped>

  .timeline-wrapper {
    padding-top: 16px;
  }

  .timeline-wrapper:nth-of-type(2) {
    padding-top: 32px;
  }

  .timeline {
    height: 70vh;
    overflow-y: auto;
    margin: 0;
  }

  .timeline-item {
    padding-bottom: 6px;
    margin-top: 6px;
  }

  .timeline.acknowledged {
    height: 70vh;
  }

  .close {
    position: relative;
    right: 10px;
    top: 4px;
  }

  .close > i {
    cursor: pointer;
    transition: all .2s;
  }

  .close > i:hover {
    color: red;
    transform: scale(1.1);
  }
</style>
