<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-spacer class="pt-8" />
        <v-card-text>
          <label class="subtitle-1">Minimum Ring Time (seconds)</label>
          <v-container>
            <v-row no-gutters>
              <v-col>
                <div class="pa-2">
                  <v-text-field
                    v-model="min"
                    hide-details
                    single-line
                    type="number"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'QueryLostCalls',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      min: 0,
    };
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.min = this.item.defaultValue;
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'incomingLostCall';
      item.defaultValue = this.min;
      const query = {
        query: {
          bool: {
            must: [
              {
                term: {
                  call_direction: 'INCOMING',
                },
              },
              {
                term: {
                  billsec: 0,
                },
              },
              {
                range: {
                  duration: {
                    gte: this.min,
                  },
                },
              },
            ],
            filter: [],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        text: `Min. ${this.min} seconds`,
      });
      this.dialog = false;
      this.number = '';
    },
  },
};
</script>
