<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  value: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  confirmLabel: {
    type: String,
    default: 'Delete',
  },
  description: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['input', 'delete']);

const value = computed({
  get() {
    return props.value;
  },
  set(val) {
    emit('input', val);
  },
});

</script>

<template>
  <v-dialog
    v-model="value"
    max-width="280px"
  >
    <v-card>
      <v-card-title>
        <h4>{{ title }}</h4>
        <v-spacer />
      </v-card-title>
      <v-card-text>{{ description }}</v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              color="secondary"
              text
              block
              @click="value = false"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="error"
              block
              :loading="loading"
              @click="emit('delete')"
            >
              {{ confirmLabel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
