import store from '@/store/';

function permittedFunction(endpoint, verb, resources) {
  let permitted = true;

  if (endpoint === 'TenantSettingsMainMenu') {
    return (
      permittedFunction('/tenants/<tenant_name>/accounts', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts', verb, resources)
      || permittedFunction('/roles', verb, resources)
      || permittedFunction('/concurrentcalls', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/nationals', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/countries', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/internationals', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/chargebands', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/nongeos', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/highcostdestinations', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/hcdexceptions', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/minimumcharges', verb, resources)
    );
  }

  if (endpoint === 'AccountSettingsMainMenu') {
    return (
      permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/accountservices', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/accountbundles', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts/<account_id>', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/users', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/groups', verb, resources)
      || permittedFunction('/tenant/<tenant_name>/account/<account_id>/endpoint', verb, resources)
    );
  }

  if (endpoint === 'TenantBillingMenu') {
    return (
      permittedFunction('/tenants/<tenant_name>/nationals', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/countries', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/internationals', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/chargebands', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/nongeos', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/highcostdestinations', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/hcdexceptions', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/minimumcharges', verb, resources)
      || permittedFunction("/tenants/<tenant_name>'", verb, resources)
    );
  }

  if (endpoint === 'TenantServicesMenu') {
    return (
      permittedFunction('/tenants/<tenant_name>/services', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/servicecharges', verb, resources)
    );
  }

  if (endpoint === 'LCRMenu') {
    return (
      permittedFunction('/tenants/<tenant_name>/providers', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/providers/<provider_prefix>', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/providergateways', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/providergateways/<short_code>', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/lcrprefixes', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/lcrprefixes/<prefix_id>', verb, resources)
    );
  }

  if (endpoint === 'TenantBundlesMenu') {
    return (
      permittedFunction('/tenants/<tenant_name>/callbundles', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/bundlechargebands', verb, resources)
    );
  }

  if (endpoint === 'AccountRates') {
    return (
      permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/nationals', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/countries', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/chargebands', verb, resources)
    );
  }

  if (endpoint === 'NetPCIMenu') {
    return (
      permittedFunction('/tenants/<tenant_name>/paymentserviceproviders', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/capturetemplates', verb, resources)
    );
  }

  if (endpoint === 'TenantSecurity') {
    return (
      permittedFunction('/functions', verb, resources)
      || permittedFunction('/tenants/<tenant_name>/roles', verb, resources)
    );
  }

  if (endpoint === 'Help') {
    return true;
  }

  if (endpoint === 'SMSMenu') {
    return permittedFunction('/tenants/<tenant_name>/sms/numbers', verb, resources);
  }

  if (endpoint && verb) {
    permitted = false;
    // Find out if the function is permitted for the user

    store.getters.authentication.entitlements.forEach((e) => {
      // check if function is permitted
      e.functions.forEach((f) => {
        if (f.endpoint === endpoint && f.verb === verb) {
          permitted = true;
        }
      });
      e.roles.forEach((r) => {
        r.functions.forEach((f) => {
          if (f.endpoint === endpoint && f.verb === verb) {
            permitted = true;
          }
        });
      });
    });
  }

  return permitted;
}

export default permittedFunction;
