<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="subtitle-1">
        <v-icon class="mr-3">
          build
        </v-icon>Roles

        ({{ selectedRoles.length }} / {{ roles.length }} assigned)
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card flat>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title />
          <v-spacer />
          <v-btn
            v-if="canChangeEntityType"
            color="primary"
            :disabled="selectedRoles.length < 1"
            text
            @click="showDialog"
          >
            Assign entity to selected roles
          </v-btn>
        </v-toolbar>

        <v-data-table
          ref="roles"
          v-model="selectedRoles"
          :headers="rolesHeaders"
          :items="roles"
          :search="$store.getters.searchQuery"
          :loading="loading"
          sort-by="name"
          item-key="role_id"
          show-select
          @input="updateParentModel"
          @toggle-select-all="selectAll"
        >
          <template #item="props">
            <tr :active="props.isSelected">
              <td>
                <v-simple-checkbox
                  :value="props.isSelected"
                  color="blue"
                  hide-details
                  @input="toggleRole(props, $event)"
                />
              </td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.description }}</td>
              <td v-if="canChangeEntityType">
                <v-select
                  v-if="props.isSelected"
                  v-model="props.item.entity_type"
                  :items="entityTypes"
                  item-text="name"
                  item-value="key"
                  label="Type"
                  @change="changeDetect++"
                />
              </td>
              <td
                v-if="canChangeEntityType"
                :key="changeDetect"
              >
                <v-autocomplete
                  v-if="props.isSelected && props.item.entity_type==='tenant'"
                  v-model="props.item.entity_id"
                  label="Tenant"
                  :items="tenantIds"
                />
                <v-autocomplete
                  v-if="props.isSelected && props.item.entity_type==='account'"
                  v-model="props.item.entity_id"
                  label="Account"
                  :items="sortedAccounts"
                  item-text="name"
                  item-value="account_id"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog
        v-model="selectAllDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title class="headline">
            Apply changes to all Roles?
          </v-card-title>
          <v-container v-if="canChangeEntityType">
            <v-row>
              <v-col>
                <v-select
                  v-model="setAllEntityType"
                  :items="entityTypes"
                  item-text="name"
                  item-value="key"
                  label="Type"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  v-if="setAllEntityType === 'tenant'"
                  v-model="setAllTenant"
                  label="Tenant"
                  :items="tenantIds"
                />
                <v-autocomplete
                  v-if="setAllEntityType === 'account'"
                  v-model="setAllAccount"
                  :items="sortedAccounts"
                  label="Account"
                  item-text="name"
                  item-value="account_id"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="selectAllDialog=false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="applySetAllChanges"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import { mapGetters } from 'vuex';

import { BTL_API_ACCOUNT_URL } from '@/config';

export default {
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      entityTypes: [
        { name: 'Account', key: 'account' },
        { name: 'Tenant', key: 'tenant' },
      ],
      tenantIds: ['btlcloud'],
      roles: [],
      selectedRoles: [],
      loading: false,
      selectAllDialog: false,
      setAllEntityType: 'account',
      setAllTenant: 'btlcloud',
      setAllAccount: '',
      changeDetect: 1,
    };
  },
  computed: {
    filteredRoles() {
      return this.$refs.roles.$children[0].filteredItems;
    },
    sortedAccounts() {
      return sortBy(this.accounts, (account) => account.name.toLowerCase());
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
    canChangeEntityType() {
      if (this.user) {
        return this.user?.max_entitlement_level === 'Tenant' && this.$store.getters.authentication.maxEntitlementLevel === 'Tenant';
      }
      if (this.group) {
        return this.$store.getters.authentication.maxEntitlementLevel === 'Tenant';
      }
      return false;
    },
    rolesHeaders() {
      const headers = [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
      ];
      if (this.canChangeEntityType) {
        headers.push({ text: 'Entity Type', value: 'entity_type' }, { text: 'Entity', value: 'entity_id' });
      }

      return headers;
    },
  },
  watch: {
    changeDetect() {
      this.updateParentModel();
    },
  },
  mounted() {
    this.setAllAccount = this.selectedAccountId;
    this.loadRoles();
  },
  methods: {
    loadRoles() {
      axios.get(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/roles`)
        .then((response) => {
          this.loading = false;
          this.roles = response.data;
          const entitlement = this.user ? this.user.entitlement : this.group.entitlements;
          if (entitlement) this.updateRolesWithEntitlementInfo(entitlement);
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
    updateRolesWithEntitlementInfo(entitlement) {
      entitlement.forEach((e) => {
        if (e.roles) {
          e.roles.forEach((r) => {
            r.entity_type = e.resource.entity_type;
            r.entity_id = e.resource.entity_id;
            this.selectedRoles.push(r);
            const role = this.roles.filter((fr) => fr.role_id === r.role_id);
            if (role.length === 1) {
              role[0].entity_type = r.entity_type;
              role[0].entity_id = r.entity_id;
            }
          });
          this.updateParentModel();
        }
      });
    },
    showDialog() {
      this.selectAllDialog = true;
    },
    applySetAllChanges() {
      this.filteredRoles.forEach((prop) => {
        prop.entity_type = this.setAllEntityType;
        const entityIdSelector = `setAll${this.uppercaseFirst(prop.entity_type)}`;
        prop.entity_id = this[entityIdSelector];
      });

      this.$refs.roles.$children[0].sort();
      this.selectAllDialog = false;
      this.updateParentModel();
    },
    updateParentModel() {
      this.$emit('selectedRolesChanged', this.roles.filter((r) => this.selectedRoles.find((rf) => r.role_id === rf.role_id)));
    },
    uppercaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    changeSort(column, pagination) {
      if (pagination.sortBy === column) {
        pagination.descending = !pagination.descending;
      } else {
        pagination.sortBy = column;
        pagination.descending = false;
      }
    },
    selectAll(selectAllEvent) {
      if (selectAllEvent.value) {
        this.filteredRoles.forEach((role) => {
          const index = this.selectedRoles.indexOf(role);
          if (index === -1) {
            role.entity_type = 'account';
            role.entity_id = this.selectedAccountId;
            this.selectedRoles.push(role);
          }
        });
        if (this.canChangeEntityType) {
          this.showDialog();
        }
      } else {
        this.filteredRoles.forEach((role) => {
          const index = this.selectedRoles.indexOf(role);
          if (index !== -1) {
            this.$delete(this.selectedRoles, index);
          }
        });
      }
    },
    toggleRole(tableRow, selected) {
      if (selected) {
        tableRow.item.entity_type = 'account';
        tableRow.item.entity_id = this.selectedAccountId;
      } else {
        tableRow.item.entity_type = null;
        tableRow.item.entity_id = null;
      }

      tableRow.select(selected);
    },
  },
};

</script>
