<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-text-field
          v-model="days"
          autofocus
          label="Days"
          persistent-hint
        />
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-spacer />
      <v-btn
        text
        @click="onCancel"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="onSubmit"
      >
        Submit
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';

import { date_format } from '../../../../config';

export default {
  name: 'QueryBuilderDatePastDays',
  data() {
    return {
      days: 0,
    };
  },
  computed: {
    timeZone() {
      return this.$store.getters.timeZone;
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      const from = moment().tz(this.timeZone).startOf('day').subtract(this.days, 'days');
      const to = moment().tz(this.timeZone).endOf('day');
      const text = `Past ${this.days} Days [${from.format(date_format)} - ${to.format(date_format)}]`;
      const query = {
        query: {
          bool: {
            filter: [
              {
                range: {
                  call_datetime_iso: {
                    gte: from.toISOString(),
                    lte: to.toISOString(),
                  },
                },
              },
            ],
          },
        },
      };

      const parameters = [
        {
          fieldName: 'call_datetime_iso',
          type: 'DATE_PAST_DAYS',
          pastDays: this.days,
        },
      ];

      this.$emit('submit', { query, text, parameters });
    },
  },
};
</script>
