<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-spacer class="pt-8" />
        <v-card-text>
          <v-select
            v-model="selected"
            label="Select call filter type"
            :items="items"
          />
          <v-text-field
            v-model="number"
            label="Number"
            :error-messages="numberErrors"
            @blur="validateNumber"
          />
          <v-select
            v-if="comments && comments.length"
            v-model="selectedComments"
            label="Comments (optional)"
            :items="comments"
            item-text="Comment"
            deletable-chips
            return-object
            chips
            multiple
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>

import axios from 'axios';
import { sortBy } from 'lodash';
import { mapGetters } from 'vuex';
import { BTL_API_ACCOUNT_URL } from '@/config.js';
import Snacks from '@/utils/snacks';

export default {
  name: 'QueryBuilderNumberFilters',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      items: ['BLACKLIST', 'WHITELIST'],
      selected: '',
      number: '',
      numberErrors: [],
      comments: [],
      selectedComments: [],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selected(selected) {
      this.loadFilters(selected);
    },
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.selected = this.item.defaultValue.selected;
      this.comments = this.item.defaultValue.comments;
      this.selectedComments = this.item.defaultValue.selectedComments;
      this.number = this.item.defaultValue.number || '';
    }
  },
  methods: {
    async loadFilters(type) {
      try {
        this.comments = [];

        const pathMap = {
          BLACKLIST: 'blacklists',
          WHITELIST: 'whitelists',
        };

        const path = `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/${pathMap[type]}`;
        const { data } = await axios.get(path);
        this.comments = sortBy(data.filter(({ Comment }) => Comment != null), ({ Comment }) => Comment.toLowerCase());
      } catch (error) {
        console.error('ERROR', error);
        Snacks.$emit('error', 'Failed to load comments');
      }
    },
    validateNumber() {
      const regex = /^(\+[0-9][\d ]{1,14})?(\d{5,6})?(.*[a-zA-Z].*)?$/;
      this.numberErrors = [];
      if (!regex.test(this.number)) {
        this.numberErrors.push('Invalid number format. Must be E164 format, 5/6 digit short code, or an alpha tag.');
      }
    },
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      this.validateNumber();
      if (this.numberErrors.length) {
        return;
      }

      const item = { ...this.item };
      item.type = 'numberFilters';
      item.defaultValue = {
        selected: this.selected,
        number: this.number,
        comments: this.comments,
        selectedComments: this.selectedComments,
      };

      const query = {
        query: {
          bool: {
            filter: [
              {
                term: {},
              },
            ],
          },
        },
      };

      if (this.selected === 'BLACKLIST') {
        query.query.bool.filter[0].term.blacklisted = true;
      }

      if (this.selected === 'WHITELIST') {
        query.query.bool.filter[0].term.whitelisted = true;
      }

      if (this.selectedComments?.length) {
        query.query.bool.filter.push({
          bool: {
            should: this.selected === 'WHITELIST' ? [
              ...[...this.selectedComments.map((comment) => ({
                term: {
                  'whitelisted_comment.keyword': comment.Comment,
                },
              }))],
              ...[...this.selectedComments.map((comment) => ({
                term: {
                  'whitelisted_uuid.keyword': comment.WhitelistId,
                },
              }))],
            ] : [
              ...[...this.selectedComments.map((comment) => ({
                term: {
                  'blacklisted_comment.keyword': comment.Comment,
                },
              }))],
              ...[...this.selectedComments.map((comment) => ({
                term: {
                  'blacklisted_uuid.keyword': comment.BlacklistId,
                },
              }))],
            ],
          },
        });
      }

      this.$emit('submit', {
        item,
        query,
        text: `${this.selected}${this.number ? ` Number: ${this.number}` : ''}${this.selectedComments?.length ? ` Comments: ${this.selectedComments.map(({ Comment }) => Comment).join(', ')}` : ''}`,
      });
      this.dialog = false;
      this.selected = '';
      this.number = '';
    },
  },
};
</script>
