<template>
  <v-btn
    text
    :disabled="loading || (downloadURL ? false : !hasData)"
    :color="color"
    @click="handleDownload"
  >
    <div
      class="mr-2"
    >
      <v-progress-circular
        v-if="loading"
        color="primary"
        size="28"
        indeterminate
      />
      <v-icon v-else>
        cloud_download
      </v-icon>
    </div>
    <slot />
  </v-btn>
</template>

<script>
import axios from 'axios';
import { utils, writeFile } from 'xlsx';
import dotNotation from '@/utils/dotNotation';
import Snacks from '@/utils/snacks';

export default {
  name: 'TableDownload',
  props: {
    tableData: Array,
    downloadURL: String,
    headers: Array,
    reportName: {
      type: String,
      default: 'Export',
    },
    color: {
      type: String,
    },
    sheetName: String,
  },
  data() {
    return {
      loading: false,
      durationColumns: ['duration', 'billsec', 'answersec', 'biz_ring_time'],
    };
  },
  computed: {
    hasData() {
      return this.tableData.length > 0;
    },
  },
  methods: {
    async handleDownload() {
      try {
        this.loading = true;
        const wb = utils.book_new();

        wb.Props = {
          Title: 'Britannic Report',
          Subject: this.reportName,
          Author: 'Britannic',
          CreatedDate: new Date(),
        };

        const data = this.downloadURL ? (await axios.get(this.downloadURL)).data : this.tableData;

        const sheetName = this.sheetName ? this.sheetName : this.reportName.substring(0, 30);
        wb.SheetNames.push(sheetName);
        wb.Sheets[sheetName] = utils.json_to_sheet(data.map((record) => {
          if (this.headers) {
            const returnObj = {};
            this.headers.forEach((header) => {
              let headerName;
              if (typeof header.text === 'string') {
                headerName = header.text;
              } else {
                headerName = header.text.text;
              }
              returnObj[headerName] = this.formatValue(header.value, dotNotation(header.value, record));
            });
            return returnObj;
          }
          return record;
        }));
        writeFile(wb, `${this.reportName}.xlsx`);
      } catch (error) {
        console.error('Failed to download', error);
        Snacks.$emit('error', 'Download failed');
      } finally {
        this.loading = false;
      }
    },
    formatValue(header, value) {
      if (this.isDuration(header)) {
        return this.formatDuration(value);
      }
      return value;
    },
    formatDuration(duration) {
      const minutes = Math.floor(duration / 60);
      const seconds = `0${duration % 60}`.slice(-2);
      return `${minutes > 9 ? minutes : `0${minutes}`}:${seconds}`;
    },
    isDuration(key) {
      return this.durationColumns.includes(key);
    },
  },
};
</script>
