<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-spacer class="pt-8" />
        <v-card-text>
          <label class="subtitle-1">Out of Hours:</label>
          <!-- Options laid out in a vertical stack -->
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-btn
                  block
                  :color="ooh === true ? 'primary' : 'default'"
                  @click="ooh = true"
                >
                  Is Out of Hours
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  :color="ooh === false ? 'primary' : 'default'"
                  @click="ooh = false"
                >
                  Not Out of Hours
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'QueryBuilderOutOfHours',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      ooh: false, // Initial default value
    };
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue !== undefined) {
      this.ooh = this.item.defaultValue;
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'outOfHours';
      item.defaultValue = this.ooh;

      const query = {
        query: {
          bool: {
            filter: [
              {
                term: { out_of_hours: this.ooh },
              },
            ],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        text: this.ooh ? 'Is Out of Hours' : 'Not Out of Hours',
      });
      this.dialog = false;
      this.ooh = false;
    },
  },
};
</script>
