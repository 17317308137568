<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create number
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs4>
            <v-text-field
              v-model="number.PhoneNumber"
              label="Number"
              :rules="numberRules"
              :counter="13"
              required
            />
          </v-flex>
          <v-flex
            xs4
            offset-xs1
          >
            <v-checkbox
              v-model="checkRange"
              label="Number range"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="checkRange"
          class="px-2"
        >
          <v-flex xs2>
            <v-text-field
              v-model.number="number.Range"
              label="Range size"
              :rules="rangeRules"
              :counter="4"
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs4>
            <v-switch
              v-model="number.Enabled"
              label="Enabled"
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs4>
            <v-text-field
              v-model="number.Label"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs6>
            <v-text-field
              v-model="number.Notes"
              label="Notes"
              :rules="notesRules"
              :counter="80"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs3>
            <v-select
              v-model="number.PhoneType"
              flat
              :items="numberTypes"
              item-text="text"
              item-value="value"
              label="Number type"
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs6>
            <v-text-field
              v-model="number.Maintainer"
              label="Maintainer"
              :rules="maintainerRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs3>
            <v-select
              v-model="maskingType"
              flat
              :items="maskTypes"
              item-text="text"
              item-value="value"
              label="Masking"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="maskingType === 'e164'"
          class="px-2"
        >
          <v-flex xs3>
            <v-text-field
              v-model="maskingNumber"
              label="Masking Number"
              :rules="numberRules"
              :counter="13"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createNumber"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

import config from '@/config.js';

export default {
  name: 'NewNumber',
  data() {
    return {
      valid: false,
      checkRange: false,
      number: {
        PhoneNumber: '',
        Enabled: false,
      },
      maskingNumber: '',
      maskingType: '',
      numberTypes: [
        { value: 'GEO', text: 'Geographic' },
        { value: 'NONGEO', text: 'Non-Geographic' },
      ],
      maskTypes: [
        { value: '', text: 'None' },
        { value: 'Anonymous', text: 'Anonymous' },
        { value: 'e164', text: 'E164 Phone Number' },
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      maintainerRules: [
        (v) => !!v || 'Maintainer is required',
        (v) => (v && v.length <= 60) || 'Maintainer must not have more than 60 characters',
      ],
      notesRules: [
        (v) => (!v || v.length <= 80) || 'Notes must not have more than 80 characters',
      ],
      numberRules: [
        (v) => !!v || 'Phone number is required',
        (v) => (v && v.length <= 13) || 'Phone number must not have more than 12 characters',
      ],
      rangeRules: [
        (v) => (v && parseInt(v, 10) > 0 && parseInt(v, 10) <= 1000) || 'Range size must be between 1 and 1000',
      ],
    };
  },
  watch: {
    checkRange(n) {
      if (!n) {
        delete this.number.Range;
      }
    },
    maskingType(selectedMaskingType) {
      if (selectedMaskingType !== 'e164') {
        this.number.Masking = selectedMaskingType;
      } else {
        this.number.Masking = this.maskingNumber;
      }
    },
    maskingNumber(maskingNumber) {
      this.number.Masking = maskingNumber;
    },
  },
  created() {
  },
  methods: {
    createNumber() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      axios.post(`${config.BTL_API_TENANT_URL}/phonenumbers`, this.number)
        .then(() => {
          this.$emit('snack', 'Number successfully created!');
          this.$router.push('/numbermanagement');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    cancel() {
      this.$router.push('/numbermanagement');
    },
  },
};
</script>

<style scoped>
</style>
