<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Account Bundle
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/tenants/<tenant_name>/accounts/<account_id>/accountbundles/<abid>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-layout>
              <v-select
                v-model="accountBundle.BundleId"
                :disabled="!createMode"
                flat
                :items="callBundles"
                item-text="BundleName"
                item-value="BundleId"
                label="Bundle"
                :rules="rules.Bundle"
              />
            </v-layout>
          </v-col>
          <v-col />
        </v-row>
        <v-layout>
          <v-flex class="pa-2">
            <v-row>
              <v-btn
                v-if="createMode && isPermitted('/tenants/<tenant_name>/accounts/<account_id>/accountbundles', 'POST')"
                primary
                color="primary"
                dark
                @click="saveAccountBundle"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this Account Bundle?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteAccountBundle"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'AccountBundle',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      accountBundleId: this.$route.params.accountBundleId,
      callBundles: [],
      accountBundle: {},
      rules: {
        Bundle: [
          (v) => (v != null && String(v).length > 0) || 'Bundle is required',
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  created() {
    if (this.accountBundleId === 'new') {
      this.createMode = true;
    } else {
      this.loadAccountBundle();
    }
  },
  mounted() {
    this.loadBundles();
  },
  methods: {
    async loadBundles() {
      this.loading = true;

      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/callbundles`);
        this.callBundles = response.data.filter((bundle) => bundle.chargebands.length);
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve call bundles.', true);
      } finally {
        this.loading = false;
      }
    },
    async saveAccountBundle() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Account bundle successfully updated.';

        if (this.createMode) {
          await axios.post(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountbundles`, this.accountBundle);
          snackMessage = 'Account bundle successfully created.';
        } else {
          throw new Error('Cannot update account bundle');
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating account bundle', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} account bundle.`, true);
      }
    },
    async loadAccountBundle() {
      try {
        const { data: accountBundle } = await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountbundles/${this.accountBundleId}`);
        this.accountBundle = accountBundle;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve account bundle.', true);
        console.error('Failed to retrieve account bundle', error);
      }
    },
    async deleteAccountBundle() {
      try {
        await axios.delete(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountbundles/${this.accountBundleId}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete account bundle.', true);
        console.error('Failed to delete account bundle', error);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
