<template>
  <v-expansion-panel-content>
    <v-card class="mt-2">
      <v-card-title>Calls</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="callHeaders"
          :items="calls"
          :loading="loading"
          :search="$store.getters.searchQuery"
          :custom-sort="customSort"
          dense
        >
          <template #item.call_type="{ item }">
            {{ item.call_type }}
          </template>
          <template #item.eventTime="{ item }">
            {{ item.eventTime | formatDate(item.eventTime) }}
          </template>
          <template #item.price="{ item }">
            {{ item.price | formatMoney(item.price) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>Reports</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="reportHeaders"
          :items="reports"
          :loading="loading"
          :search="$store.getters.searchQuery"
          :custom-sort="customSort"
          dense
        >
          <template #item.eventTime="{ item }">
            {{ item.eventTime | formatDate(item.eventTime) }}
          </template>
          <template #item.price="{ item }">
            {{ item.price | formatMoney(item.price) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-expansion-panel-content>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import customSort from '@/utils/customSort';

import { date_time_format, bizvu_billing_url } from '@/config.js';

import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(2);

export default {
  name: 'UnbilledUsage',
  filters: {
    formatMoney(value) {
      return currencyFormatter(value / 100);
    },
    formatDate(timestamp) {
      const date = moment(timestamp);
      return date.format(date_time_format);
    },
  },
  data() {
    return {
      loading: false,
      callHeaders: [
        { text: 'Date', value: 'eventTime' },
        { text: 'Type', value: 'call_type' },
        { text: 'Duration (sec)', value: 'duration' },
        { text: 'Caller', value: 'source' },
        { text: 'Destination', value: 'target' },
        { text: 'Price', value: 'price', align: 'end' },
      ],
      calls: [],
      reports: [],
      reportHeaders: [
        { text: 'Date', value: 'eventTime' },
        { text: 'Type', value: 'report_type' },
        { text: 'Complexity', value: 'complexity' },
        { text: 'Records', value: 'records' },
        { text: 'Price', value: 'price', align: 'end' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const { data } = await axios.get(`${bizvu_billing_url}/tenant/btlcloud/account/${this.selectedAccountId}/unbilledUsage`);
        this.calls = data.calls.map((call) => ({
          eventTime: call.eventTime,
          call_type: call.call_type,
          duration: call.billable_seconds,
          source: call.source_e164,
          target: call.destination_e164,
          price: call.price,
        }));
        this.reports = data.reports;
      } catch (error) {
        console.error("Couldn't fetch unbilled usage", error);
      }
      this.loading = false;
    },
    customSort: customSort('eventTime'),
  },
};

</script>
