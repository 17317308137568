<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Non-geographic Rates Management
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/chargebands', 'POST')"
        text
        color="blue"
        @click="$router.push('/nongeorate/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        v-if="isPermitted('/chargebands/load', 'POST')"
        text
        color="blue"
        @click="importDialog = true"
      >
        <v-icon class="mr-2">
          cloud_upload
        </v-icon>Import
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="rates"
        :headers="exportHeaders"
        report-name="Non-geographic Rates"
        color="primary"
      >
        Download
      </download>
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="4">
          <v-autocomplete
            v-model="accountFilter"
            flat
            :items="accounts"
            item-text="name"
            item-value="account_id"
            label="Account filter"
            single-line
          />
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="chargeBandFilter"
            flat
            :items="chargebands"
            item-text="Name"
            item-value="CBId"
            label="Chargeband Filter"
            clearable
            single-line
          />
        </v-col>
      </v-row>

      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="rates"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="CBId"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            :custom-sort="customSort"
            sort-by="Name"
            height="calc(100vh - 315px)"
          >
            <template #item.AccountName="{ item }">
              <span>{{ item.AccountName || 'Base' }}</span>
            </template>
            <template #item.Name="{ item }">
              <router-link
                v-if="isPermitted('/chargebands/<cb_id>', 'GET', true)"
                :to="item.itemRoute"
              >
                {{ item.Name }}
              </router-link>
              <span v-else>{{ item.Name }}</span>
              <codes-lookup
                v-if="!item.AccountId && isPermitted('/nongeos', 'GET')"
                rate-name="chargeband.Name"
                header="name"
                api-path="nongeos"
                filter-name="CBId"
                :filter-value="item.CBId"
                redirect-path="nongeocodes"
                :small="true"
              />
            </template>
            <template #item.CostDayRate="{ item }">
              <span>{{ item.CostDayRate | formatMoney }}</span>
            </template>
            <template #item.CostEveRate="{ item }">
              <span>{{ item.CostEveRate | formatMoney }}</span>
            </template>
            <template #item.CostWendRate="{ item }">
              <span>{{ item.CostWendRate | formatMoney }}</span>
            </template>
            <template #item.CostConFee="{ item }">
              <span>{{ item.CostConFee | formatMoney }}</span>
            </template>
            <template #item.SellDayRate="{ item }">
              <span>{{ item.SellDayRate | formatMoney }}</span>
            </template>
            <template #item.SellEveRate="{ item }">
              <span>{{ item.SellEveRate | formatMoney }}</span>
            </template>
            <template #item.SellWendRate="{ item }">
              <span>{{ item.SellWendRate | formatMoney }}</span>
            </template>
            <template #item.SellConFee="{ item }">
              <span>{{ item.SellConFee | formatMoney }}</span>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <csv-importer
        :show="importDialog"
        :account="accountFilter"
        headings="Name, Description, Carrier, SellDayRate, SellEveRate, SellWendRate, SellConFee, CostDayRate, CostEveRate CostWendRate &amp; CostConFee"
        example="/examplenongeorate.csv"
        :url="importURLs"
        @close="importDialog = false"
        @snack="handleSnack"
      />
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { sortBy } from 'lodash';
import config from '@/config.js';

import download from '@/components/download/tableDownload';
import csvImporter from '@/components/billing/csvImporter.vue';
import CodesLookup from '@/components/billing/CodesLookup';
import customSort from '@/utils/customSort';
import permittedFunction from '@/permittedFunction.js';

import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'NongeoRatesManagement',
  components: {
    download,
    csvImporter,
    CodesLookup,
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatMoney(value) {
      return currencyFormatter(value);
    },
  },
  data() {
    const headers = [
      { text: 'Account', value: 'AccountName' },
      { text: 'Charge Band', value: 'Name' },
      { text: 'Destination Count', value: 'CodeCount' },
      { text: 'Carrier', value: 'Carrier' },
      { text: 'Cost Day Rate', value: 'CostDayRate' },
      { text: 'Cost Evening Rate', value: 'CostEveRate' },
      { text: 'Cost Weekend Rate', value: 'CostWendRate' },
      { text: 'Cost Connection Fee', value: 'CostConFee' },
      { text: 'Sell Day Rate', value: 'SellDayRate' },
      { text: 'Sell Evening Rate', value: 'SellEveRate' },
      { text: 'Sell Weekend Rate', value: 'SellWendRate' },
      { text: 'Sell Connection Fee', value: 'SellConFee' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      rates: [],
      headers,
      exportHeaders: [
        { text: 'Charge Band', value: 'Name' },
        { text: 'Account', value: 'AccountName' },
        { text: 'Carrier', value: 'Carrier' },
        { text: 'Description', value: 'Description' },
        { text: 'CostDayRate', value: 'CostDayRate' },
        { text: 'CostEveningRate', value: 'CostEveRate' },
        { text: 'CostWeekendRate', value: 'CostWendRate' },
        { text: 'CostConnectionFee', value: 'CostConFee' },
        { text: 'SellDayRate', value: 'SellDayRate' },
        { text: 'SellEveningRate', value: 'SellEveRate' },
        { text: 'SellWeekendRate', value: 'SellWendRate' },
        { text: 'SellConnectionFee', value: 'SellConFee' },
        { text: 'ModifiedBy', value: 'ModifiedBy' },
        { text: 'ModifiedOn', value: 'ModifiedOn' },
      ],
      accounts: [],
      accountFilter: null,
      chargebands: [],
      loadingChargebands: false,
      chargeBandFilter: null,
      loading: false,
      importDialog: false,
      downloadURL: `${config.BTL_API_TENANT_URL}/chargebands`,
      importURLs: (account) => (account ? `${config.BTL_API_ACCOUNT_URL}/${account}/chargebands/load` : `${config.BTL_API_TENANT_URL}/chargebands/load`),
    };
  },
  watch: {
    accountFilter() {
      this.loadData();
    },
    chargeBandFilter() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.loadChargeBands();
  },
  methods: {
    async loadChargeBands() {
      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/chargebands`);
        this.loadingChargebands = true;
        const chargebands = response.data.filter((item) => !item.AccountId);
        this.chargebands = sortBy(chargebands, [(chargeband) => chargeband.Name.toLowerCase()]);
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve chargebands.', true);
        console.error('Failed to retrieve chargebands', error);
      }
      this.loadingChargebands = false;
    },
    async loadData() {
      this.loading = true;
      try {
        await this.loadAccounts();
        await this.loadRates();
      } catch (error) {
        console.error('Could not load data: ', error.message);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadRates() {
      // alert('load');
      let url = this.downloadURL;
      const paramsObj = {
        filters: [],
      };

      const filter = {
        name: 'AccountId',
        op: this.accountFilter ? 'eq' : 'is_null',
      };
      if (this.accountFilter) {
        filter.val = this.accountFilter;
      }

      paramsObj.filters.push(filter);

      if (this.chargeBandFilter) {
        const chargeBandQueryFilter = {
          name: 'CBId',
          op: 'eq',
          val: this.chargeBandFilter,
        };
        paramsObj.filters.push(chargeBandQueryFilter);
      }

      if (paramsObj.filters.length) {
        url += `?q=${JSON.stringify(paramsObj)}`;
      }

      const response = await axios.get(url);

      this.rates = response.data
        .filter((row) => (this.accountFilter === 'all' ? true : row.AccountId === this.accountFilter))
        .map((row) => {
          row.itemRoute = `/nongeorate/${row.CBId}${row.AccountId ? `/${row.AccountId}` : ''}`;
          row.AccountName = '';
          if (this.accounts.length > 0) {
            if (row.AccountId) {
              const acc = this.accounts.find((a) => a.account_id === row.AccountId);
              if (acc) {
                row.AccountName = acc.name;
              }
            }
          }

          return row;
        });
    },
    async loadAccounts() {
      const response = await axios.get(config.BTL_API_ACCOUNT_URL);
      this.accounts = [
        { name: 'Base', account_id: null },
        ...sortBy(response.data, [(account) => account.name.toLowerCase()]),
      ];
    },
    isPermitted(endpoint, verb, accountOrTenant = false) {
      const prefixedEndpoint = ((accountOrTenant && this.accountFilter) ? '/tenants/<tenant_name>/accounts/<account_id>' : '/tenants/<tenant_name>') + endpoint;
      return permittedFunction(prefixedEndpoint, verb);
    },
    handleSnack(message, isError = false) {
      this.$emit('snack', message, isError);
    },
    customSort: customSort('ModifiedOn'),
  },
};
</script>
