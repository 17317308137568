<template>
  <div v-resize="onResize">
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Concurrent Calls
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-layout row>
        <v-flex
          xs4
          pa-2
        >
          <v-select
            v-model="selectedProviderProxies"
            :items="providerProxyList"
            label="Provider Proxies"
            multiple
          >
            <template #prepend-item>
              <v-list-item
                @click="toggleProviderProxySelection"
              >
                <v-list-item-action>
                  <v-icon>{{ providerProxySelectIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-1" />
            </template>

            <template #selection="data">
              <span v-if="data.index === 0 ">{{ data.item.substring(0,15) }}...</span>
              <span
                v-if="data.index === 1"
                class="grey--text caption"
              >(+{{ selectedProviderProxies.length - 1 }} others)</span>
            </template>
          </v-select>
        </v-flex>

        <v-flex
          xs4
          pa-2
        >
          <v-select
            v-model="selectedNetxGateways"
            :items="netxGatewayList"
            label="netX Gateways"
            multiple
          >
            <template #prepend-item>
              <v-list-item
                @click="toggleNetxGatewaySelection"
              >
                <v-list-item-action>
                  <v-icon>{{ netxGatewaySelectIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-1" />
            </template>

            <template #selection="data">
              <span v-if="data.index === 0 ">{{ data.item.substring(0,15) }}...</span>
              <span
                v-if="data.index === 1"
                class="grey--text caption"
              >(+{{ selectedNetxGateways.length - 1 }} others)</span>
            </template>
          </v-select>
        </v-flex>

        <v-flex
          xs4
          pa-2
        >
          <v-select
            v-model="selectedCustomerProxies"
            :items="customerProxyList"
            label="Customer Proxies"
            multiple
          >
            <template #prepend-item>
              <v-list-item
                @click="toggleCustomerProxySelection"
              >
                <v-list-item-action>
                  <v-icon>{{ customerProxySelectIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-1" />
            </template>

            <template #selection="data">
              <span v-if="data.index === 0 ">{{ data.item.substring(0,15) }}...</span>
              <span
                v-if="data.index === 1"
                class="grey--text caption"
              >(+{{ selectedCustomerProxies.length - 1 }} others)</span>
            </template>
          </v-select>
        </v-flex>
      </v-layout>

      <v-alert
        :value="callCountWarning"
        type="warning"
        dismissible
      >
        Call Counts might not be accurate
      </v-alert>

      <v-alert
        :value="callCountError"
        type="error"
      >
        Call Counts system in error and cant be relied upon
      </v-alert>

      <v-layout
        v-if="!callCountError"
        column
        class="mt-1 px-2"
      >
        <LineChart
          ref="providerProxyChart"
          :style="chartStyle"
          :chart-data="providerProxyChart"
          :options="providerProxyChartOptions"
          chart-title="Fred"
        />
        <LineChart
          ref="netxGatewayChart"
          :style="chartStyle"
          :chart-data="netxGatewayChart"
          :options="netxGatewayChartOptions"
        />
        <LineChart
          ref="customerProxyChart"
          :style="chartStyle"
          :chart-data="customerProxyChart"
          :options="customerProxyChartOptions"
          chart-title="Fred"
        />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { rangeRight, takeRight, orderBy } from 'lodash';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import config from '@/config.js';
import LineChart from '../../components/widgets/charts/linechart';

const FIVE_SECONDS = 5000;

export default {
  name: 'ConcurrentCalls',
  components: {
    LineChart,
  },
  data() {
    return {
      dataFetchInterval: null,
      intervalPeriod: FIVE_SECONDS,
      chartStyle: '',

      providerProxyChartOptions: {
        title: {
          text: 'Provider Proxies',
          display: true,
        },
        animation: {
          duration: 0,
        },
        plugins: {
          colorschemes: {
            scheme: Tableau20,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
          }],
        },
      },
      providerProxyChart: {
        labels: rangeRight(0, 60 + 1, 5),
        datasets: [],
      },
      providerProxies: {},
      selectedProviderProxies: [],
      loadedProvidedProxies: false,

      netxGatewayChartOptions: {
        title: {
          text: 'netX Gateways',
          display: true,
        },
        animation: {
          duration: 0,
        },
        plugins: {
          colorschemes: {
            scheme: Tableau20,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
          }],
        },
      },
      netxGatewayChart: {
        labels: rangeRight(0, 60 + 1, 5),
        datasets: [],
      },
      netxGateways: {},
      selectedNetxGateways: [],
      loadedNetxGateways: false,

      customerProxyChartOptions: {
        title: {
          text: 'Customer Proxies',
          display: true,
        },
        animation: {
          duration: 0,
        },
        plugins: {
          colorschemes: {
            scheme: Tableau20,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
          }],
        },
      },
      customerProxyChart: {
        labels: rangeRight(0, 60 + 1, 5),
        datasets: [],
      },
      customerProxies: {},
      selectedCustomerProxies: [],
      loadedCustomerProxies: false,
      callCountWarning: false,
      callCountError: false,
    };
  },
  computed: {
    providerProxyList() {
      return Object.keys(this.providerProxies);
    },
    netxGatewayList() {
      return Object.keys(this.netxGateways);
    },
    customerProxyList() {
      return Object.keys(this.customerProxies);
    },
    providerProxySelectIcon() {
      if (this.providerProxyList.length === this.selectedProviderProxies.length) return 'check_box';
      if (this.selectedProviderProxies.length > 0) return 'indeterminate_check_box';
      return 'check_box_outline_blank';
    },
    netxGatewaySelectIcon() {
      if (this.netxGatewayList.length === this.selectedNetxGateways.length) return 'check_box';
      if (this.selectedNetxGateways.length > 0) return 'indeterminate_check_box';
      return 'check_box_outline_blank';
    },
    customerProxySelectIcon() {
      if (this.customerProxyList.length === this.selectedCustomerProxies.length) return 'check_box';
      if (this.selectedCustomerProxies.length > 0) return 'indeterminate_check_box';
      return 'check_box_outline_blank';
    },
  },
  watch: {
    selectedNetxGateways() {
      this.calculateNetxGatewayDataSets();
    },
  },
  mounted() {
    this.fetchData();
    this.startFetchingData();
  },
  beforeDestroy() {
    this.stopFetchingData();
  },
  methods: {
    onResize() {
      const height = Math.max((window.innerHeight - 270) / 3, 200); // we don't want the height to be less than 200 px
      this.chartStyle = `position: relative; height:${height}px`;
    },
    toggleProviderProxySelection() {
      if (this.selectedProviderProxies.length === this.providerProxyList.length) {
        this.selectedProviderProxies = [];
      } else {
        this.selectedProviderProxies = this.providerProxyList;
      }
    },
    toggleNetxGatewaySelection() {
      if (this.selectedNetxGateways.length === this.netxGatewayList.length) {
        this.selectedNetxGateways = [];
      } else {
        this.selectedNetxGateways = this.netxGatewayList;
      }
    },
    toggleCustomerProxySelection() {
      if (this.selectedCustomerProxies.length === this.customerProxyList.length) {
        this.selectedCustomerProxies = [];
      } else {
        this.selectedCustomerProxies = this.customerProxyList;
      }
    },
    startFetchingData() {
      this.dataFetchInterval = setInterval(() => this.fetchData(), this.intervalPeriod);
    },
    stopFetchingData() {
      clearInterval(this.dataFetchInterval);
    },
    fetchData() {
      axios.get(`${config.BTL_API_TENANT_URL}/callcount/system`)
        .then((response) => {
          const {
            serviceStatus, nonAuthoritativeNetxGateways, knownProviderProxies, knownCustomerProxies, authoritativeNetxGateways,
          } = response.data;

          if (serviceStatus !== 'OK') {
            this.callCountError = true;
            return;
          }
          this.callCountError = false;

          this.callCountWarning = nonAuthoritativeNetxGateways.length > 0;

          Promise.all(knownProviderProxies.map((proxy) => axios.get(`${config.BTL_API_TENANT_URL}/callcount/providerproxy/${proxy.proxyHostName}`)
            .then(({ data }) => {
              const { id, count } = data;

              let providerProxy = this.providerProxies[id];

              if (!providerProxy) {
                providerProxy = { id, data: [] };
                this.$set(this.providerProxies, id, providerProxy);
              }
              providerProxy.data.push(count);
            })
            .catch((error) => console.error(`Failed to request provider proxy ${proxy} call count`, error))))
            .then(() => {
              if (!this.loadedProvidedProxies) {
                this.toggleProviderProxySelection();
                this.loadedProvidedProxies = true;
              }
              this.calculateProviderProxyDataSets();
            });

          Promise.all(authoritativeNetxGateways.map((gateway) => axios.get(`${config.BTL_API_TENANT_URL}/callcount/netxgateway/${gateway}`)
            .then(({ data }) => {
              const { count, netxGatewayHostName: id } = data;

              let netxGateway = this.netxGateways[id];

              if (!netxGateway) {
                netxGateway = { id, data: [] };
                this.$set(this.netxGateways, id, netxGateway);
              }
              netxGateway.data.push(count);
            })
            .catch((error) => console.error(`Failed to request netxGateway ${gateway} call count`, error))))
            .then(() => {
              if (!this.loadedNetxGateways) {
                this.toggleNetxGatewaySelection();
                this.loadedNetxGateways = true;
              }
              this.calculateNetxGatewayDataSets();
            });

          Promise.all(knownCustomerProxies.map((proxy) => axios.get(`${config.BTL_API_TENANT_URL}/callcount/customerproxy/${proxy.proxyHostName}`)
            .then(({ data }) => {
              const { id, count } = data;

              let customerProxy = this.customerProxies[id];

              if (!customerProxy) {
                customerProxy = { id, data: [] };
                this.$set(this.customerProxies, id, customerProxy);
              }
              customerProxy.data.push(count);
            })
            .catch((error) => console.error(`Failed to request customer proxy ${proxy} call count`, error))))
            .then(() => {
              if (!this.loadedCustomerProxies) {
                this.toggleCustomerProxySelection();
                this.loadedCustomerProxies = true;
              }
              this.calculateCustomerProxyDataSets();
            });
        })
        .catch((error) => {
          console.error('Failed to fetch system call count', error);
        });
    },
    calculateProviderProxyDataSets() {
      Object.values(this.providerProxies).forEach((proxy) => {
        if (!this.selectedProviderProxies.includes(proxy.id)) {
          this.providerProxyChart.datasets = this.providerProxyChart.datasets.filter((dataset) => dataset.label !== proxy.id);
          return;
        }

        let dataSet = this.providerProxyChart.datasets.find(
          (set) => set.label === proxy.id,
        );

        if (!dataSet) {
          dataSet = {
            label: proxy.id,
            fill: false,
            borderWidth: 1,
          };
          this.providerProxyChart.datasets.push(dataSet);
        }

        let data = takeRight(proxy.data, 13).map((counts) => this.toNumber(counts, 'allTotal'));
        if (data.length !== 13) {
          data = [...Array(13 - data.length).fill(null), ...data];
        }
        dataSet.data = data;
      });
      this.providerProxyChart.datasets = orderBy(this.providerProxyChart.datasets, 'label');
      this.$refs.providerProxyChart.update();
    },
    calculateNetxGatewayDataSets() {
      Object.values(this.netxGateways).forEach((gateway) => {
        if (!this.selectedNetxGateways.includes(gateway.id)) {
          this.netxGatewayChart.datasets = this.netxGatewayChart.datasets.filter((dataset) => dataset.label !== gateway.id);
          return;
        }

        let dataSet = this.netxGatewayChart.datasets.find(
          (set) => set.label === gateway.id,
        );

        if (!dataSet) {
          dataSet = {
            label: gateway.id,
            fill: false,
            borderWidth: 1,
          };
          this.netxGatewayChart.datasets.push(dataSet);
        }

        let data = takeRight(gateway.data, 13).map((counts) => this.toNumber(counts, 'allTotal'));
        if (data.length !== 13) {
          data = [...Array(13 - data.length).fill(null), ...data];
        }
        dataSet.data = data;
      });
      this.netxGatewayChart.datasets = orderBy(this.netxGatewayChart.datasets, 'label');
      this.$refs.netxGatewayChart.update();
    },
    calculateCustomerProxyDataSets() {
      Object.values(this.customerProxies).forEach((proxy) => {
        if (!this.selectedCustomerProxies.includes(proxy.id)) {
          this.customerProxyChart.datasets = this.customerProxyChart.datasets.filter((dataset) => dataset.label !== proxy.id);
          return;
        }

        let dataSet = this.customerProxyChart.datasets.find(
          (set) => set.label === proxy.id,
        );

        if (!dataSet) {
          dataSet = {
            label: proxy.id,
            fill: false,
            borderWidth: 1,
          };
          this.customerProxyChart.datasets.push(dataSet);
        }

        let data = takeRight(proxy.data, 13).map((counts) => this.toNumber(counts, 'allTotal'));
        if (data.length !== 13) {
          data = [...Array(13 - data.length).fill(null), ...data];
        }
        dataSet.data = data;
      });
      this.customerProxyChart.datasets = orderBy(this.customerProxyChart.datasets, 'label');
      this.$refs.customerProxyChart.update();
    },
    toNumber(counts, count) {
      if (counts[count]) {
        return Number(counts[count]);
      }
      return 0;
    },
  },
};
</script>
