<template>
  <v-list-item @click.stop="onShow">
    <span v-if="!editMode">
      {{ item.text }}
    </span>
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card class="scroll-card">
        <!-- Subheading for Callflow(s) -->
        <v-card-title class="subheading-title">
          Callflow(s)
        </v-card-title>

        <!-- Search Bar with Reduced Space Below -->
        <v-card-text class="pb-1">
          <v-container fluid class="search">
            <v-row no-gutters>
              <v-col cols="7">
                <v-text-field
                  v-model="search"
                  label="Search Callflows"
                  dense
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Scrollable Content with Filtered Callflows -->
        <v-card-text class="scrollable-content">
          <v-container fluid>
            <v-row no-gutters>
              <v-col
                v-for="callflow in filteredCallflows"
                :key="callflow.CallflowId"
                cols="12"
              >
                <v-checkbox
                  :value="callflow.CallflowId"
                  :label="callflow.Name"
                  v-model="id"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Sticky Footer -->
        <v-card-actions class="sticky-footer">
          <v-spacer />
          <v-chip-group
            v-if="id.length"
            v-model="id"
            column
            multiple
          >
            <v-chip
              v-for="selectedCallflow in selectedCallflows"
              :key="selectedCallflow.CallflowId"
              close
              @click:close="removeCallflow(selectedCallflow.CallflowId)"
            >
              {{ selectedCallflow.Name }}
            </v-chip>
          </v-chip-group>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
/* eslint-disable */ 
import axios from 'axios';
import { sortBy } from 'lodash';
import { mapGetters } from 'vuex';
import Snacks from '@/utils/snacks';

import config from '@/config.js';

export default {
  name: 'QueryBuilderCallflowId',
  props: {
    item: Object,
    query: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      id: [],
      callflows: [],
      search: '', // Search term
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
    selectedCallflows() {
      return this.callflows.filter((callflow) => this.id.includes(callflow.CallflowId));
    },
    // Computed property for filtering callflows
    filteredCallflows() {
      const searchTerm = this.search.toLowerCase();
      return this.callflows.filter((callflow) =>
        callflow.Name.toLowerCase().includes(searchTerm)
      );
    },
  },
  mounted() {
    this.loadCallflows();
    if (this.editMode) {
      this.onShow();
    }
    if (this.item && this.item.defaultValue) {
      this.id = [...this.item.defaultValue];
    }
  },
  methods: {
    loadCallflows() {
      axios
        .get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/callflows`)
        .then((response) => {
          this.callflows = sortBy(response.data, (callflow) => callflow.Name.toLowerCase());
        })
        .catch((error) => {
          Snacks.$emit('error', 'Failed to load call flows');
          console.error('ERROR', error.response);
        });
    },
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    removeCallflow(id) {
      this.id = this.id.filter(callflowId => callflowId !== id);
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'callflowId';
      item.defaultValue = [...this.id];

      const query = {
        query: {
          bool: {
            filter: [
              {
                terms: {
                  'callflowid.keyword': this.id,
                },
              },
            ],
          },
        },
      };

      const callflows = this.callflows.filter((c) => this.id.includes(c.CallflowId));
      this.$emit('submit', {
        item,
        query,
        text: callflows.map((callflow) => callflow.Name).join(', '),
      });
      this.dialog = false;
      this.id = [];
    },
  },
};
</script>

<style scoped>
.scroll-card {
  max-width: 800px;
}

.subheading-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
}

.v-row {
  margin: 0;
}

.v-col {
  padding: 0;
}

/* Reduced the bottom spacing under search */
.search {
  padding-bottom: 4px !important; /* Less padding under search */
}
</style>
