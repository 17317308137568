<template>
  <v-dialog
    v-model="show"
    max-width="600"
    import-dialog="false"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="headline">
        Import Codes
        <v-spacer />
        <v-btn
          :href="example"
          color="info"
          download="example.csv"
        >
          Download Example File
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-subheader>CVS should have 2 columns with headings: {{ headings }}</v-subheader>
        <v-file-input
          v-model="file"
          show-size
          accept=".csv"
          label="File input"
        />

        <v-btn
          :disabled="!file"
          :loading="importRunning"
          color="primary"
          width="100%"
          @click="runImport"
        >
          Import
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          @click="reset"
        >
          Reset
        </v-btn>
        <v-btn
          text
          color="grey"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CodeImporter',
  props: {
    show: Boolean,
    headings: String,
    example: String,
    url: String,
  },
  data() {
    return {
      file: null,
      importRunning: false,
    };
  },
  methods: {
    async runImport() {
      this.importRunning = true;
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        await axios.post(this.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$emit('snack', 'Codes uploaded successfully.');
        this.reset();
      } catch (error) {
        console.error('Failed to import codes', error);
        this.$emit('snack', 'Failed to import codes.', true);
      } finally {
        this.importRunning = false;
        this.$emit('close');
      }
    },
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    close() {
      this.reset();
      this.$emit('close');
    },
  },
};
</script>
