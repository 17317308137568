import moment from 'moment';

const label = '1 week - 6 hour interval';
const limit = 12;
const text = '1 Week';

const createFilter = () => {
  const now = moment(); // Get the current date and time
  const oneWeekAgo = now.clone().subtract(1, 'week'); // Get the date 1 week ago

  const timeRangeFilter = {
    range: {
      call_datetime_iso: {
        gte: oneWeekAgo.startOf('day').toISOString(), // Start of the day 1 week ago
        lte: now.toISOString(), // Current date and time
      },
    },
  };
  return [timeRangeFilter];
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  fixed_interval: '6h',
  min_doc_count: 0,
});

const dateFormat = (date) => moment(date).format('DD MMM - HH:mm');

export default {
  label,
  limit,
  text,
  createFilter,
  dateHistogram,
  dateFormat,
};
