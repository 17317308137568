<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        prepend-icon="date_range"
        v-bind="attrs"
        :clearable="clearable"
        v-on="on"
      />
    </template>
    <v-date-picker
      :value="value"
      no-title
      scrollable
      @change="dateChange"
    />
  </v-menu>
</template>

<script setup>
import { computed, ref } from 'vue';
import moment from 'moment-timezone';
import { formatDate } from '@/utils/DateTimeFormatter';

const props = defineProps({
  value: { type: String, default: null },
  label: { type: String, default: '' },
  clearable: { type: Boolean, default: false },
});

const menu = ref(false);

const formattedDate = computed({
  get: () => (props.value ? formatDate(props.value) : ''),
  set: () => {},
});

const emit = defineEmits(['input']);

const dateChange = (newDate) => {
  console.error(moment(newDate).toISOString());
  emit('input', moment(newDate).toISOString());
  menu.value = false;
};
</script>

<style scoped>

</style>
