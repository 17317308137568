<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
  >
    <v-layout
      align-center
      justify-center
      column
      fill-height
      elevation-6
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            Number Types
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="loadNumbers"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
      <v-container style="height: 100%; overflow-y: hidden; background-color: #FFF;">
        <v-layout
          v-if="!dataLoaded"
          align-center
          justify-center
          row
          fill-height
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-layout>
        <dashdoughnutchart
          v-if="dataLoaded"
          :height="252"
          :labels="numberChart.labels"
          :data="numberChart.data"
        />
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import config from '@/config.js';

import dashdoughnutchart from '@/components/widgets/charts/dashdoughnutchart';
import ResizeContainer from '@/components/widgets/layout/ResizeContainer';

export default {
  name: 'Numbertypes',
  components: {
    dashdoughnutchart,
    'resize-container': ResizeContainer,
  },
  props: {
    id: String,
    layoutProps: Object,
  },
  data() {
    return {
      dataLoaded: false,
      numbers: [],
      numberChart: {
        labels: ['Geographic', 'Non-Geographic'],
        data: [0, 0],
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'authentication',
    ]),
  },
  watch: {
    authentication: {
      handler() {
        this.dataLoaded = false;
        this.loadNumbers();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadNumbers();
  },
  methods: {
    loadNumbers() {
      const self = this;
      this.dataLoaded = false;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${self.selectedAccountId}/phonenumbers?pagenum=1&pagesize=5000`)
        .then((response) => {
          self.numberChart.data[0] = 0;
          self.numberChart.data[1] = 0;
          self.numbers = response.data;
          self.numbers.forEach((n) => {
            if (n.PhoneType === 'GEO') {
              self.numberChart.data[0] += 1;
            } else {
              self.numberChart.data[1] += 1;
            }
          });
          self.dataLoaded = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
