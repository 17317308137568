<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <!-- Subheading -->
        <v-card-title class="subheading-title">
          Rate Type
        </v-card-title>

        <!-- Scrollable List of Rate Types -->
        <v-card-text class="pb-0 scrollable-content">
          <v-list dense>
            <v-list-item
              v-for="(rateType, index) in items"
              :key="index"
              class="pa-0"
            >
              <v-checkbox
                :value="rateType"
                :label="rateType"
                v-model="rateTypes"
                dense
                hide-details
              />
            </v-list-item>
          </v-list>
        </v-card-text>

        <!-- Sticky Footer with Buttons -->
        <v-card-actions class="sticky-footer">
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!rateTypes.length"
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'QueryBuilderRateType',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      rateTypes: [],
      items: ['Account', 'Bundle', 'Default'],
    };
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.rateTypes = [...this.item.defaultValue];
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'rateType';
      item.defaultValue = [...this.rateTypes];

      const query = {
        query: {
          bool: {
            filter: [
              {
                terms: {
                  rate_type: this.rateTypes,
                },
              },
            ],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        text: this.rateTypes.join(', '),
      });
      this.dialog = false;
      this.rateTypes = [];
    },
  },
};
</script>

<style scoped>
.scrollable-content {
  max-height: 300px;
  overflow-y: auto;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
}

.subheading-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
</style>
