<template>
  <div>
    <v-layout class="header-wrapper">
      <v-badge
        bordered
        left
        offset-x="8"
        offset-y="8"
        :color="badgeColor || 'blue darken-3'"
        avatar
        :value="!!badge"
        :icon="badge"
      >
        <v-icon
          class="header-title-icon mr-2"
          :color="iconColor || 'blue darken-3'"
        >
          {{ icon }}
        </v-icon>
      </v-badge>
      <v-flex class="titles">
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <p

              v-bind="attrs"
              class="header-title"

              v-on="on"
            >
              {{ title }}
            </p>
          </template>
          <span>{{ title }}</span>
        </v-tooltip>
        <p class="header-detail">
          {{ detail }}
        </p>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex
        offset-xs5
        xs3
      >
        <v-chip
          v-if="chipA"
          :color="colorA ? 'lightgrey' : 'blue lighten-4'"
        >
          <span>{{ chipA }}</span>
        </v-chip>
        <v-chip
          v-if="chipB"
          class="mb-2"
          :color="colorB ? 'lightgrey' : 'blue lighten-4'"
        >
          <span>{{ chipB }}</span>
        </v-chip>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    badge: String,
    iconColor: String,
    badgeColor: String,
    title: String,
    detail: [String, Number, Boolean],
    colorA: Boolean,
    colorB: Boolean,
    chipA: String,
    chipB: String,
  },
};
</script>

<style scoped>

  .titles {
    max-width: calc(100% - 30px);
  }

  p {
      margin-bottom: unset;
      line-height: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  }

  .header-wrapper {
    align-items: center;
  }

  .header-title {
    font-size: 12px;
    font-weight: 600;
  }

  .header-detail {
    font-size: 10px;
  }

  .header-title-icon mr-1 {
    height: 30px;
  }

  .v-chip {
    height: 14px;
    width: 80px;
    text-align: center;
    font-size: 10px;
    left: 20px;
  }
</style>
