<template>
  <div>
    <div> <!-- This is the single root element -->
      <v-menu offset-y v-if="!hideSelectors">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="text-right"
            dark
            v-bind="attrs"
            v-on="on"
            :class="{ 'disabled-btn': isActionDisabled }"
            :disabled="isActionDisabled"
          >
            Actions
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="downloadSelected('recordings')" :disabled="isActionDisabled">
            <v-icon class="action-icon">cloud_download</v-icon>
            <v-list-item-title>Download Selected Call Recordings</v-list-item-title>
          </v-list-item>
          <v-list-item @click="downloadSelected('calltrace')" :disabled="isActionDisabled">
            <v-icon class="action-icon">cloud_download</v-icon>
            <v-list-item-title>Download Selected Call Traces</v-list-item-title>
          </v-list-item>
          <v-list-item @click="downloadBothSelected" :disabled="isActionDisabled">
            <v-icon class="action-icon">cloud_download</v-icon>
            <v-list-item-title>Download Both</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showDeleteConfirmation" :disabled="isActionDisabled">
            <v-icon class="action-icon">delete</v-icon>
            <v-list-item-title>Delete Selected Call Recordings / Call Traces</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-data-table
      :headers="headers"
      :items="records"
      :loading="isLoading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :fixed-header="true"
      :height="tableHeight"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions
      }"
      :items-per-page="itemsPerPage"
      dense
      :search="$store.getters.searchQuery"
      :no-data-text="noDataText"
    >
    <template #header="{ props }">
  <thead>
    <tr>
      <th
        v-for="header in props.headers"
        :key="header.text.text || header.text"
        :class="['clickable-header', header.class]"
        @click="header.sortable && sort(header.value)"
        :style="{ cursor: header.sortable ? 'pointer' : 'default' }"
      >
        <!-- Handle Select All Checkbox -->
        <div v-if="header.value === 'isSelected' && !hideSelectors" class="d-flex align-center">
          <v-checkbox v-model="allSelected" class="pa-0 selectall"></v-checkbox>
        </div>

        <!-- Handle Header Text -->
        <span v-else>
          {{ typeof header.text === 'object' ? header.text.text : header.text }}
          <!-- Updated Arrow SVG -->
          <svg
            v-if="header.sortable && sortBy === header.value"
            :class="{'rotate-down': !sortDesc, 'rotate-up': sortDesc}"
            data-name="1-Arrow Up"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="12"
            height="12"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow"
          >
            <path
              d="m26.71 10.29-10-10a1 1 0 0 0-1.41 0l-10 10 1.41 1.41L15 3.41V32h2V3.41l8.29 8.29z"
            />
          </svg>
        </span>
      </th>
    </tr>
  </thead>
</template>

      <template #item="props">
        <tr :class="{ 'highlighted-row': props.item.isSelected }">
          <td v-if="!hideSelectors">
            <v-checkbox v-model="props.item.isSelected"></v-checkbox>
          </td>
          <td>
            <v-layout align-left>
              <v-tooltip
                v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/recordings/<call_id>', 'GET') && hasRecordingSubscription"
                bottom
              >
                <template #activator="{on}">
                  <v-btn
                    icon
                    @click="getCallRecording(props.item)"
                    v-on="on"
                  >
                    <v-icon>record_voice_over</v-icon>
                  </v-btn>
                </template>
                <span>Call Recording</span>
              </v-tooltip>

              <v-tooltip
                v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/calltrace/<call_id>', 'GET')"
                bottom
              >
                <template #activator="{on}">
                  <v-btn
                    icon
                    @click="getCallTrace(props.item)"
                    v-on="on"
                  >
                    <v-icon>device_hub</v-icon>
                  </v-btn>
                </template>
                <span>Call Trace</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{on}">
                  <v-btn
                    icon
                    @click="itemSelect(props.item)"
                    v-on="on"
                  >
                    <v-icon>info</v-icon>
                  </v-btn>
                </template>
                <span>Call Info</span>
              </v-tooltip>
            </v-layout>
          </td>
          <td
            v-for="(item, index) in displayHeaders"
            :key="index"
            class="text-xs"
          >
            {{ formatColumn(item.value, props.item[item.value]) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="showDetailsDialog">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title class="mr-5">
            Call Details: {{ detailsCDR.sip_call_id }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              text
              color="primary"
              @click="showDetailsDialog=false"
            >
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <call-details :call-details="detailsCDR" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="showDetailsDialog=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteConfirmDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Please Confirm</v-card-title>
        <v-card-text>
          <span style="margin-bottom: 16px; display: block;"> You are about to delete {{ selectedCount }} record(s). Please type "DELETE" to confirm.</span>
          <v-text-field
            v-model="confirmationText"
            outlined
            dense
            label="Type DELETE here"
            class="delete-field"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="deleteConfirmDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text :disabled="confirmationText !== 'DELETE'" @click="performDeletion">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { saveAs } from 'file-saver';
import { mapGetters } from 'vuex';
import moment from 'moment';
import permittedFunction from '@/permittedFunction.js';
import Snacks from '@/utils/snacks';

import callDetails from '@/views/CallDetails/CallDetails';
import { BTL_API_ACCOUNT_URL, date_time_format } from '../../../../config.js';

const filenameRegx = /attachment; filename=(.*)/i;

export default {
  name: 'QueryBuilderTable',
  components: {
    callDetails,
  },
  props: {
    displayHeaders: {
      type: Array,
      required: true,
    },
    hideSelectors: {
      type: Boolean,
      default: false,
    },
    records: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: [String, Array],
      default: () => [],
    },
    sortDesc: {
      type: Boolean,
      default: () => false,
    },
    tableHeight: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 25, -1],
    },
    noDataText: {
      type: String,
      default: () => 'No data available',
    },
  },
  data() {
    return {
      selectAll: false,
      durationColumns: ['duration', 'billsec', 'answersec', 'biz_ring_time'],
      showDetailsDialog: false,
      detailsCDR: {},
      hasRecordingSubscription: false,
      downloading: false,
      priceFormatter: new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }),
      deleteConfirmDialog: false,
      confirmationText: '',
    };
  },
  /* eslint-disable */ 
  computed: {
    headers() {
      const baseHeaders = [
      {
        text: 'Actions',
        value: 'actions',
        sortable: false, // Ensure Actions column is non-sortable
        align: 'center', // Align center for buttons
        class: 'actions-header',
      },
    ];

    if (!this.hideSelectors) {
      baseHeaders.unshift({
        text: '', // Empty text for Select All checkbox header
        value: 'isSelected',
        sortable: false, // Ensure Select All column is non-sortable
        align: 'left',
        class: 'select-all-header',
      });
    }

    // Ensure all headers from displayHeaders remain sortable unless explicitly set otherwise
    const sortableDisplayHeaders = this.displayHeaders.map(header => {
      return {
        ...header,
        sortable: header.sortable !== undefined ? header.sortable : true // Default to sortable unless specified otherwise
      };
    });

    return baseHeaders.concat(sortableDisplayHeaders);
  },
    isLoading() {
      return this.loading || this.downloading;
    },
    ...mapGetters([
      'selectedAccountId',
      'timeZone',
    ]),
    selectedCount() {
      return this.records.filter(record => record.isSelected).length;
    },
    isActionDisabled() {
      return this.selectedCount === 0;
    },
    allSelected: {
      get() {
        return this.records.length && this.records.every(record => record.isSelected);
      },
      set(value) {
        this.records.forEach((record, index) => {
          // Ensuring reactivity when changing isSelected
          this.$set(this.records[index], 'isSelected', value);
        });
      }
    }
  },

  mounted() {
    if (this.selectedAccountId) {
      this.loadAccountServices();
    }
  },
  /* eslint-disable */ 
  watch: {
    records: {
    handler: function(newRecords, oldRecords) {
      // This might not be necessary if computed properties handle the logic correctly
      // Check if individual unchecking should update `allSelected`
      if (oldRecords.some((rec, idx) => rec.isSelected !== newRecords[idx].isSelected)) {
        this.selectAll = newRecords.every(record => record.isSelected);
      }
    },
    deep: true
  }
  },
  methods: {
    async downloadSelected(type) {
      const selectedRecords = this.records.filter(record => record.isSelected);
      this.downloading = true;

      const ids = selectedRecords.map(record => record.call_uuid);
      let apiUrl = `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/analytics/`;

      apiUrl += type === 'recordings' ? 'recordings' : 'traces';

      try {
        const response = await axios.post(apiUrl, ids, { // Note the change here
          responseType: 'blob',
        });
        if (response.data instanceof Blob) {
          saveAs(response.data, `${type}_bulk_${new Date().toISOString()}.zip`);
        } else {
          throw new Error('Invalid response type');
        }
        Snacks.$emit('success', 'Bulk download successful');
      } catch (error) {
        console.error('Bulk download failed:', error);
        Snacks.$emit('error', 'Bulk download failed');
      } finally {
        this.downloading = false;
      }
    },
    async deleteSelectedRecords() {
      const selectedRecords = this.records.filter(record => record.isSelected);
      const ids = selectedRecords.map(record => record.call_uuid);
      const recordingsUrl = `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/analytics/recordings`;
      const tracesUrl = `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/analytics/traces`;

      try {
        const responses = await Promise.all([
          axios.delete(recordingsUrl, { data: ids, headers: { "Content-Type": "application/json" }}),
          axios.delete(tracesUrl, { data: ids, headers: { "Content-Type": "application/json" }})
        ]);

        // Log responses to debug if needed
        console.log('Recordings response:', responses[0]);
        console.log('Traces response:', responses[1]);

        const [recordingsResponse, tracesResponse] = responses;

        if ((recordingsResponse.status === 200 || recordingsResponse.status === 204) &&
            (tracesResponse.status === 200 || tracesResponse.status === 204)) {
          Snacks.$emit('success', 'Selected recordings and call traces deleted successfully');

          // Unselect and remove the deleted records from the records array
          this.records = this.records.map(record => {
            if (record.isSelected) {
              record.isSelected = false;
            }
            return record;
          }).filter(record => !record.isSelected);
        } else {
          console.error('Unexpected response status:', recordingsResponse.status, tracesResponse.status);
        }
      } catch (error) {
        console.error('Failed to delete:', error);
      }
    },
    performDeletion() {
      if (this.confirmationText.toUpperCase() === 'DELETE') {
        this.deleteSelectedRecords();
        this.deleteConfirmDialog = false; // close the confirmation dialog
        this.confirmationText = ''; // reset the confirmation text
      } else {
        Snacks.$emit('info', 'Please type "DELETE" to confirm.');
      }
    },
    showDeleteConfirmation() {
      if (this.selectedCount > 0) {
        this.deleteConfirmDialog = true;
      }
    },
    async downloadBothSelected() {
      const selectedRecords = this.records.filter(record => record.isSelected);
      if (selectedRecords.length === 0) {
          Snacks.$emit('info', 'No records selected');
          return;
      }
      this.downloading = true;
      const ids = selectedRecords.map(record => record.call_uuid);

      const downloadEndpoints = {
          recordings: `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/analytics/recordings`,
          traces: `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/analytics/traces`
      };

      // Creating download promises for both recordings and traces
      const downloadPromises = [
          axios.post(downloadEndpoints.recordings, ids, { responseType: 'blob' })
              .then(response => saveAs(response.data, `recordings_bulk_${new Date().toISOString()}.zip`))
              .catch(error => {
                  console.error('Failed to download recordings:', error);
                  Snacks.$emit('error', 'Failed to download recordings');
              }),
          axios.post(downloadEndpoints.traces, ids, { responseType: 'blob' })
              .then(response => saveAs(response.data, `traces_bulk_${new Date().toISOString()}.zip`))
              .catch(error => {
                  console.error('Failed to download traces:', error);
                  Snacks.$emit('error', 'Failed to download traces');
              })
      ];

      // Execute all download promises
      Promise.all(downloadPromises)
          .then(() => {
              Snacks.$emit('success', 'Both recordings and traces downloaded successfully');
          })
          .catch(error => {
              console.error('Error downloading files:', error);
              Snacks.$emit('error', 'Download operation failed');
          })
          .finally(() => {
              this.downloading = false;
          });
    },
    toggleSelectAll(value) {
    this.allSelected = value; // This will trigger the setter and update all records
  },
    itemSelect(callData) {
      this.showDetailsDialog = true;
      this.detailsCDR = callData;
    },
    async getCallRecording(callData) {
      this.download('recordings', callData, 'call_recording', 'mp3', 'Failed to download call recording');
    },
    async getCallTrace(callData) {
      this.download('calltrace', callData, 'call_trace', 'pcap', 'Failed to download call trace');
    },
    async download(path, callData, filePrefix, fileSuffix, genericError) {
      this.downloading = true;
      try {
        const response = await axios.get(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/${path}/${callData.call_uuid}`, {
          responseType: 'blob',
        });
        if (response.data instanceof Blob) {
          const filename = filenameRegx.exec(response.headers['content-disposition'])?.[1];
          saveAs(response.data, filename || `${filePrefix}_${callData.call_uuid}.${fileSuffix}`);
          console.log(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/${path}/${callData.call_uuid}`)
        } else {
          throw new Error('invalid response type');
        }
      } catch (error) {
        console.error(error);
        Snacks.$emit('error', genericError);
      } finally {
        this.downloading = false;
      }
    },
    fileReader: (file) => {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new Error('Problem parsing file'));
        };

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.readAsText(file);
      });
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    sort(value) {
    if (this.sortBy === value) {
      this.sortDesc = !this.sortDesc;
    } else {
      this.sortBy = value;
      this.sortDesc = false;
    }
  },
    async loadAccountServices() {
      if (!this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/accountservices/services', 'GET')) {
        return;
      }
      try {
        const { data: services } = await axios.get(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountservices/services`);
        this.hasRecordingSubscription = services.callrecorder;
      } catch (error) {
        console.error('Failed to load services', error);
        Snacks.$emit('error', 'Failed to load services');
      }
    },
    formatDuration(duration) {
      const minutes = Math.floor(duration / 60);
      const seconds = `0${duration % 60}`.slice(-2);
      return `${minutes > 9 ? minutes : `0${minutes}`}:${seconds}`;
    },
    isDuration(key) {
      return this.durationColumns.includes(key);
    },
    isPrice(key) {
      return key === 'call_price';
    },
    formatPrice(price = 0) {
      return this.priceFormatter.format(price);
    },
    isTime(key) {
      return key === 'call_datetime_iso';
    },
    formatTime(val) {
      return moment(`${val}Z`).tz(this.timeZone).format(date_time_format);
    },
    formatColumn(key, val) {
      if (this.isDuration(key)) {
        return this.formatDuration(val);
      } if (this.isPrice(key)) {
        return this.formatPrice(val);
      } if (this.isTime(key)) {
        return this.formatTime(val);
      }
      return val;
    },
  },
};
</script>

<style scoped>
:deep(.v-data-table-header) {
  display: none;
}
.text-right {
  text-align: right;
  margin-bottom: 20px;
}
.action-icon {
  margin-right: 10px;
}

.v-input--selection-controls {
  padding-top: 0px;
}

.v-input__slot {
  margin-bottom: 1px !important;
}

.v-input--selection-controls .v-input__slot, .v-input--selection-controls .v-radio {
  width: 0px !important;
}

.disabled-btn {
  opacity: 0.5 !important;
  pointer-events: none;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg  {
  opacity: 0.5 !important;
  pointer-events: none;
  background-color: #1976d2 !important;
}

.highlighted-row {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.d-flex.align-center {
  display: flex;
  align-items: center;
}

.sortSVG {
  margin-left: 15px;
  margin-top: 2px;
}
.clickable-header {
  cursor: pointer !important;
}
:deep(.v-messages) {
  min-height: 7px !important;
}

/* Initial state */
.feather {
  transition: transform 0.3s ease;
}

/* Rotate up arrow (opposite to default) */
.rotate-up {
  transform: rotate(-180deg); /* Rotate arrow 180 degrees */
}

/* Rotate down arrow (opposite to rotated) */
.rotate-down {
  transform: rotate(0deg); /* No rotation for down state */
}
</style>