<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Applications
      </v-toolbar-title>
      <v-btn
        v-if="canCreateApplication"
        text
        color="blue"
        to="/application"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create Application
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadApplications"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <tableDownload
        :table-data="applications"
        color="primary"
        report-name="Applications"
      />
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-layout class="mt-1 px-2">
        <v-flex>
          <v-data-table
            :headers="headers"
            :options="tableOptions"
            :items="applications"
            :loading="loading"
            :search="$store.getters.searchQuery"
            @update:options="handleTableOptionsUpdate"
          >
            <template #item="{ item}">
              <tr>
                <td>
                  <router-link
                    v-if="canViewApplication"
                    :to="`/application/${item.client_id}`"
                  >
                    {{ item.name }}
                  </router-link>
                  <p v-else>
                    {{ item.name }}
                  </p>
                </td>
                <td class="text-xs">
                  {{ item.client_id }}
                </td>
                <td class="text-xs">
                  <v-chip
                    v-for="role in item.roles"
                    :key="role"
                    x-small
                    class="mr-1"
                  >
                    {{ role }}
                  </v-chip>
                </td>
                <td class="text-right">
                  <v-icon
                    v-if="item.active"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script setup>
import tableDownload from '@/components/download/tableDownload.vue';

import {
  ref, computed, watch, onMounted,
} from 'vue';
import { useStore } from '@/utils/vue';

import usePagination from '@/composables/tablePaginationViaQueryString';
import permittedFunction from '@/permittedFunction';
import axios from 'axios';
import { BTL_API_ACCOUNT_URL } from '@/config';

const store = useStore();

const {
  tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
} = usePagination(null, { itemsPerPage: 15, sortBy: 'name' });

const selectedAccountId = computed(() => store.getters.selectedAccountId);

const applications = ref([]);
const loading = ref(false);

const headers = [
  { text: 'Name', value: 'name' },
  { text: 'Client Id', value: 'client_id' },
  { text: 'Roles', value: 'roles' },
  { text: 'Active', value: 'active', align: 'right' },
];

const canViewApplication = computed(() => permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/applications/<client_id>', 'GET'));
const canCreateApplication = computed(() => permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/applications', 'POST'));

const loadApplications = async () => {
  loading.value = true;
  try {
    const { data: apps } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectedAccountId.value}/applications`);

    applications.value = apps.map((app) => ({
      ...app,
      roles: app.entitlements.map((entitlement) => entitlement.roles).flat(),
    }));
  } catch (error) {
    console.error('ERROR', error.response);
  } finally {
    loading.value = false;
  }
};

watch(() => selectedAccountId, loadApplications);

saveQueryParams();
onMounted(async () => {
  await loadApplications();
  processQueryParams();
});

</script>

<style scoped>

</style>
