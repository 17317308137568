import moment from 'moment';

const label = '2 weeks - 12 hour interval';
const limit = 12;
const text = '2 Weeks';

const createFilter = () => {
  const now = moment(); // Get the current date and time
  const twoWeeksAgo = now.clone().subtract(2, 'weeks'); // Get the date 2 weeks ago

  const timeRangeFilter = {
    range: {
      call_datetime_iso: {
        gte: twoWeeksAgo.toISOString(),
        lte: now.toISOString(),
      },
    },
  };
  return [timeRangeFilter];
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  fixed_interval: '12h',
  min_doc_count: 0,
});

const dateFormat = (date) => moment(date).format('DD MMM - HH:mm');

export default {
  label,
  limit,
  text,
  createFilter,
  dateHistogram,
  dateFormat,
};
