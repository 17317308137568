let env;

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  env = require('../env');
} else {
  env = JSON.parse(window.ENV_VARS);
  delete window.ENV_VARS;
}

// CANT BE SERIALISED by server.js
env.PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,}$/;

module.exports = env;
