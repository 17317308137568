<template>
  <div v-resize="onResize">
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Roles
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/roles', 'POST')"
        text
        color="blue"
        @click="$router.push('/newrole')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create Role
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadRoles"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-container
      ref="table"
      style="height: calc(100vh - 150px);"
    >
      <v-data-table
        :headers="headers"
        :items="tenantRoles"
        :loading="loading"
        :search="$store.getters.searchQuery"
        sort-by="name"
        :height="tableHeight"
        fixed-header
        disable-pagination
        hide-default-footer
        dense
      >
        <template #item="{ item: role }">
          <tr>
            <td>
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/roles/<role_id>','GET')"
                :to="`/role/${role.role_id}`"
              >
                {{ role.name }}
              </router-link>
              <div v-else>
                {{ role.name }}
              </div>
            </td>
            <td>
              {{ role.description }}
            </td>
            <td>
              <v-chip
                v-for="func in role.functions"
                :key="func.name"
                x-small
                class="mr-1"
              >
                {{ func.name }}
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

import { BTL_API_TENANT_URL } from '../../../config';
import permittedFunction from '../../../permittedFunction';

export default {
  name: 'Roles',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Granted Functions', value: 'functions' },
      ],
      roles: [],
      loading: false,
      tableHeight: 300,
    };
  },
  computed: {
    tenantRoles() {
      return this.roles.filter((role) => role.EntityType === 'Tenant');
    },
  },
  mounted() {
    this.loadRoles();
  },
  methods: {
    async loadRoles() {
      this.loading = true;
      try {
        const { data: roles } = await axios.get(`${BTL_API_TENANT_URL}/roles`);
        this.roles = roles;
      } catch (err) {
        this.$emit('snack', 'Failed to load roles', true);
      }
      this.loading = false;
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    onResize() {
      this.tableHeight = this.$refs.table.clientHeight;
    },
  },
};
</script>
