import moment from 'moment';

import store from '@/store';

import {
  time_format,
  date_format,
  date_time_format,
  long_date_format,
  long_date_time_format,
} from '@/config.js';

export const formatTime = (iso8061) => moment(iso8061).tz(store.getters.timeZone).format(time_format);
export const formatDate = (iso8061) => moment(iso8061).tz(store.getters.timeZone).format(date_format);
export const formatDateTime = (iso8061) => moment(iso8061).tz(store.getters.timeZone).format(date_time_format);
export const formatLongDate = (iso8061) => moment(iso8061).tz(store.getters.timeZone).format(long_date_format);
export const formatLongDateTime = (iso8061) => moment(iso8061).tz(store.getters.timeZone).format(long_date_time_format);
