<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        International Rates Management
      </v-toolbar-title>
      <v-autocomplete
        v-model="accountFilter"
        flat
        :items="accounts"
        item-text="name"
        item-value="account_id"
        label="Account filter"
        single-line
        hide-details
        style="max-width: 20rem;"
      />
      <v-btn
        v-if="isPermitted('/countries', 'POST')"
        text
        color="blue"
        @click="$router.push('/internationalrate/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        v-if="isPermitted('/countries/load', 'POST')"
        text
        color="blue"
        @click="importDialog = true"
      >
        <v-icon class="mr-2">
          cloud_upload
        </v-icon>Import
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="rates"
        :headers="exportHeaders"
        report-name="International Rates"
        color="primary"
      >
        Download
      </download>

      <v-spacer />
    </v-toolbar>

    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="4">
          <v-autocomplete
            v-model="countryFilter"
            flat
            :items="countries"
            item-text="CountryName"
            item-value="CountryId"
            label="Charge Band"
          />
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="rates"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="key"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            :custom-sort="customSort"
            sort-by="CountryName"
            height="calc(100vh - 315px)"
          >
            <template #item.AccountName="{ item }">
              <span>{{ item.AccountName || 'Base' }}</span>
            </template>
            <template #item.CountryName="{ item }">
              <router-link
                v-if="isPermitted('/countries/<countryid>', 'GET', true)"
                :to="item.itemRoute"
              >
                {{ item.CountryName }}
              </router-link>
              <span v-else>{{ item.CountryName }}</span>
              <codes-lookup
                v-if="!item.AccountId && isPermitted('/internationals', 'GET')"
                rate-name="country.CountryName"
                header="country"
                api-path="internationals"
                filter-name="CountryId"
                :filter-value="item.CountryId"
                redirect-path="internationalcodes"
                :small="true"
              />
            </template>
            <template #item.CostDayRate="{ item }">
              <span>{{ item.CostDayRate | formatMoney }}</span>
            </template>
            <template #item.CostEveRate="{ item }">
              <span>{{ item.CostEveRate | formatMoney }}</span>
            </template>
            <template #item.CostWendRate="{ item }">
              <span>{{ item.CostWendRate | formatMoney }}</span>
            </template>
            <template #item.CostConFee="{ item }">
              <span>{{ item.CostConFee | formatMoney }}</span>
            </template>
            <template #item.SellDayRate="{ item }">
              <span>{{ item.SellDayRate | formatMoney }}</span>
            </template>
            <template #item.SellEveRate="{ item }">
              <span>{{ item.SellEveRate | formatMoney }}</span>
            </template>
            <template #item.SellWendRate="{ item }">
              <span>{{ item.SellWendRate | formatMoney }}</span>
            </template>
            <template #item.SellConFee="{ item }">
              <span>{{ item.SellConFee | formatMoney }}</span>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <csv-importer
        :show="importDialog"
        :account="accountFilter"
        headings="CountryName, Description, Carrier, SellDayRate, SellEveRate, SellWendRate, SellConFee, CostDayRate, CostEveRate CostWendRate &amp; CostConFee"
        example="/examplerate.csv"
        :url="importURLs"
        @close="importDialog = false"
        @snack="handleSnack"
      />
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { sortBy } from 'lodash';
import config from '@/config.js';

import download from '@/components/download/tableDownload';
import csvImporter from '@/components/billing/csvImporter.vue';
import CodesLookup from '@/components/billing/CodesLookup';
import customSort from '@/utils/customSort';
import permittedFunction from '@/permittedFunction.js';

import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'InternationalRatesManagement',
  components: {
    download,
    csvImporter,
    CodesLookup,
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatMoney(value) {
      return value != null ? currencyFormatter(value) : '';
    },
  },
  data() {
    const headers = [
      { text: 'Account', value: 'AccountName' },
      { text: 'Charge Band', value: 'CountryName' },
      { text: 'Destination Count', value: 'CodeCount' },
      { text: 'Carrier', value: 'Carrier' },
      { text: 'Cost Day Rate', value: 'CostDayRate' },
      { text: 'Cost Evening Rate', value: 'CostEveRate' },
      { text: 'Cost Weekend Rate', value: 'CostWendRate' },
      { text: 'Cost Connection Fee', value: 'CostConFee' },
      { text: 'Sell Day Rate', value: 'SellDayRate' },
      { text: 'Sell Evening Rate', value: 'SellEveRate' },
      { text: 'Sell Weekend Rate', value: 'SellWendRate' },
      { text: 'Sell Connection Fee', value: 'SellConFee' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      rates: [],
      headers,
      exportHeaders: [
        { text: 'Account', value: 'AccountName' },
        { text: 'Charge Band', value: 'CountryName' },
        { text: 'Destination Count', value: 'DestinationCount' },
        { text: 'Carrier', value: 'Carrier' },
        { text: 'CostDayRate', value: 'CostDayRate' },
        { text: 'CostEveningRate', value: 'CostEveRate' },
        { text: 'CostWeekendRate', value: 'CostWendRate' },
        { text: 'CostConnectionFee', value: 'CostConFee' },
        { text: 'SellDayRate', value: 'SellDayRate' },
        { text: 'SellEveningRate', value: 'SellEveRate' },
        { text: 'SellWeekendRate', value: 'SellWendRate' },
        { text: 'SellConnectionFee', value: 'SellConFee' },
        { text: 'ModifiedBy', value: 'ModifiedBy' },
        { text: 'ModifiedOn', value: 'ModifiedOn' },
      ],
      accounts: [],
      countries: [],
      accountFilter: null,
      countryFilter: 'all',
      loading: false,
      importDialog: false,
      downloadURL: `${config.BTL_API_TENANT_URL}/countries`,
      importURLs: (account) => (account ? `${config.BTL_API_ACCOUNT_URL}/${account}/countries/load` : `${config.BTL_API_TENANT_URL}/countries/load`),
    };
  },
  watch: {
    accountFilter() {
      this.loadData();
    },
    countryFilter() {
      this.loadData();
    },
  },
  async mounted() {
    this.loadCountries();
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        await this.loadAccounts();
        await this.loadRates();
      } catch (error) {
        console.error('Could not load data: ', error.message);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadRates() {
      let url = this.downloadURL;
      const paramsObj = {
        filters: [],
      };

      const filter = {
        name: 'AccountId',
        op: this.accountFilter ? 'eq' : 'is_null',
      };
      if (this.accountFilter) {
        filter.val = this.accountFilter;
      }
      paramsObj.filters.push(filter);

      if (this.countryFilter !== 'all') {
        paramsObj.filters.push({
          name: 'CountryId',
          op: 'eq',
          val: this.countryFilter,
        });
      }

      if (paramsObj.filters.length) {
        url += `?q=${JSON.stringify(paramsObj)}`;
      }

      const response = await axios.get(url);

      this.rates = response.data
        .map((row) => {
          row.itemRoute = `/internationalrate/${row.CountryId}${row.AccountId ? `/${row.AccountId}` : ''}`;
          row.key = row.CountryId + (row.AccountId || '');
          row.AccountName = '';
          if (this.accounts.length > 0) {
            if (row.AccountId) {
              const acc = this.accounts.find((a) => a.account_id === row.AccountId);
              if (acc) {
                row.AccountName = acc.name;
              }
            }
          }

          return row;
        });
    },
    async loadAccounts() {
      const response = await axios.get(config.BTL_API_ACCOUNT_URL);
      this.accounts = [
        { name: 'Base', account_id: null },
        ...sortBy(response.data, [(account) => account.name.toLowerCase()]),
      ];
    },
    async loadCountries() {
      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/countries`);
        this.countries = [
          { CountryName: '--  All  --', CountryId: 'all' },
          ...sortBy(response.data.filter((c) => !c.AccountId), [(country) => country.CountryName.toLowerCase()]),
        ];
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve countries.', true);
        console.error('Failed to retrieve countries', error);
      }
    },
    isPermitted(endpoint, verb, accountOrTenant = false) {
      const prefixedEndpoint = ((accountOrTenant && this.accountFilter) ? '/tenants/<tenant_name>/accounts/<account_id>' : '/tenants/<tenant_name>') + endpoint;
      return permittedFunction(prefixedEndpoint, verb);
    },
    handleSnack(message, isError = false) {
      this.$emit('snack', message, isError);
    },
    openCountryCodes(item) {
      const routeData = this.$router.resolve({ name: 'internationalcodesbycountry', params: { countryID: item.CountryId } });
      window.open(routeData.href, '_blank');
    },
    customSort: customSort('ModifiedOn'),
  },
};
</script>
