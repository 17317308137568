<template>
  <v-container>
    <node-header
      icon="label"
      icon-color="secondary"
      :title="name"
      :detail="routeConfig.phoneNumber"
      :color-a="routeConfig.defaultAction === 'none'"
      chip-a="NEXT"
    />

    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="primary"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              edit
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Configure Name</v-subheader>
          <v-list-item>
            <v-text-field
              v-model="name"
              label="Node Name"
            />
          </v-list-item>
          <v-subheader>Set Header</v-subheader>
          <v-list-item>
            <v-text-field
              v-model="headerName"
              label="Name"
              :rules="[ruleRequired, ruleNoSpace]"
            />
          </v-list-item>

          <v-list-item>
            <v-text-field
              v-model="headerValue"
              label="Value"
              :rules="[ruleRequired]"
            />
          </v-list-item>

          <v-subheader>Add next step</v-subheader>
          <v-list-item class="mb-2">
            <v-select
              v-model="defaultAction"
              label="NEXT"
              :items="actions"
            />
          </v-list-item>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="handleCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="menu_delete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleDelete"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import NodeHeader from './partials/Header';

import { typesForSelect } from './nodeTypes';

export default {
  name: 'SetSIPHeader',
  components: {
    NodeHeader,
  },
  props: {
    node: Object,
    dialplan: Array,
    canedit: Boolean,
  },
  data: () => ({
    menu: false,
    menu_delete: false,
    name: null,
    defaultAction: 'none',
    actions: typesForSelect,

    headerName: null,
    headerValue: null,

    routeConfig: {
      defaultAction: 'none',
    },
    ruleRequired: (v) => !!v || 'Field is required',
    ruleNoSpace: (v) => !v.includes(' ') || 'Header Cannot Contain Space(s)',
  }),
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  mounted() {
    this.name = this.node.name;
    this.defaultAction = this.getLabel(this.node.next);
    this.routeConfig.defaultAction = this.getLabel(this.node.next);
    this.headerName = this.node.parameters.name;
    this.headerValue = this.node.parameters.value;
  },
  methods: {
    getLabel(nodeId) {
      const node = this.dialplan.find((n) => n.id === nodeId);
      if (node) {
        return node.action;
      }
      return 'none';
    },
    handleDelete() {
      this.$emit('deletenode');
    },
    handleSave() {
      this.menu = false;
      this.node.name = this.name;
      this.node.routeConfig = this.routeConfig;
      this.node.parameters.name = this.headerName;
      this.node.parameters.value = this.headerValue;

      if (this.routeConfig.defaultAction !== this.defaultAction) {
        this.routeConfig.defaultAction = this.defaultAction;
        if (this.defaultAction === 'none') {
          this.node.next = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.defaultAction, 'NEXT');
        }
      }
    },
    handleCancel() {
      this.menu = false;
      this.name = this.node.name;
    },
  },
};
</script>
