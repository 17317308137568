<template>
  <div>
    <!-- Toolbar for navigation -->
    <v-toolbar flat>
      <!-- Back button -->
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <!-- Title -->
      <v-toolbar-title class="mr-5">
        Accounts
      </v-toolbar-title>
      <!-- Button to create a new account -->
      <v-btn
        v-if="permittedFunction('/tenants/<tenant_name>/accounts', 'POST')"
        text
        color="blue"
        @click="$router.push('/newaccount')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <!-- Switch to toggle archived accounts -->
      <v-switch
        v-model="showArchived"
        label="Archived"
        class="btn ml-2"
        inset
        dense
        hide-details
      />
      <!-- Button to refresh accounts -->
      <v-btn
        text
        color="blue"
        icon
        @click="loadAccounts"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>
      </v-btn>
      <!-- Component for downloading table data -->
      <tableDownload
        color="primary"
        :table-data="accounts"
        report-name="Accounts"
      />
      <v-spacer />
    </v-toolbar>
    <!-- Container for table and alerts -->
    <v-container>
      <!-- Alert for call count warning -->
      <v-alert
        :value="callCountWarning"
        type="warning"
        dismissible
      >
        Call Counts might not be accurate
      </v-alert>
      <!-- Alert for call count error -->
      <v-alert
        :value="callCountError"
        type="error"
      >
        Call Counts system in error and cant be relied upon
      </v-alert>
      <!-- Table layout -->
      <v-layout class="mt-1 px-2">
        <v-flex>
          <!-- Data table for displaying accounts -->
          <v-data-table
            :headers="headers"
            :options="tableOptions"
            :items="filteredAccounts"
            :loading="loading"
            :search="$store.getters.searchQuery"
            @update:options="handleTableOptionsUpdate"
          >
            <!-- Custom template for name column -->
            <template #item.name="{ item: account }">
              <router-link
                v-intersect="(entries, observer, isIntersecting) => { toggleAccountVisible(account.account_id, isIntersecting) }"
                :to="account.detailsRoute"
              >
                {{ account.name }}
              </router-link>
            </template>
            <!-- Custom template for call counts column -->
            <template #item.allTotal="{ item: account }">
              <call-counts
                :all-total="account.allTotal"
                :all-in="account.allIn"
                :all-out="account.allOut"
              />
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { sortBy, without } from 'lodash';
import axios from 'axios';
import {
  reactive, computed, toRefs, onMounted, onBeforeUnmount, set,
} from 'vue';
import { BTL_API_ACCOUNT_URL } from '@/config.js';

import permittedFunction from '@/permittedFunction.js';
import usePagination from '../../composables/tablePaginationViaQueryString';

import tableDownload from '../../components/download/tableDownload';
import CallCounts from '../../components/CallCounts';

export default {
  name: 'Accounts',
  components: {
    tableDownload,
    CallCounts,
  },
  setup(props, context) {
    // Use composable for pagination
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 15, sortBy: 'name' });

    // Reactive state
    const state = reactive({
      accounts: [],
      accountsInView: [],
      callCountIntervalPeriod: 5000,
      callCountInterval: null,
      loading: false,
      callCountWarning: false,
      callCountError: false,
      showArchived: false,
    });

    // Computed property for filtered accounts
    const filteredAccounts = computed(() => state.accounts.filter((account) => account.Archived === state.showArchived));

    // Computed property for table headers
    const headers = computed(() => {
      const heads = [
        { text: 'Name', value: 'name' },
        { text: 'State', value: 'State' },
        { text: 'Contact', value: 'ContactName' },
        { text: 'Main number', value: 'MainNumber' },
      ];
      if (!state.showArchived) {
        heads.push(
          { text: 'Current Calls', value: 'allTotal', sortable: false },
        );
      }
      return heads;
    });

    // Function to fetch call counts for accounts
    const getAccountCallCounts = () => {
      if (state.showArchived) {
        return;
      }
      state.accountsInView.map((accountId) => {
        const account = state.accounts.find((a) => a.account_id === accountId);
        if (!account) {
          return null;
        }

        return axios.get(`${BTL_API_ACCOUNT_URL}/${account.account_id}/callcount`)
          .then((response) => {
            const callCounts = response.data.count;

            if (response.data.serviceStatus !== 'OK') {
              state.callCountError = true;
              set(account, 'allTotal', null);
              set(account, 'allIn', null);
              set(account, 'allOut', null);
            } else {
              set(account, 'allTotal', callCounts.allTotal ? Number(callCounts.allTotal) : 0);
              set(account, 'allIn', callCounts.allIn ? Number(callCounts.allIn) : 0);
              set(account, 'allOut', callCounts.allOut ? Number(callCounts.allOut) : 0);
            }

            if (response.data.nonAuthoritativeNetxGateways.length > 0) {
              state.callCountWarning = true;
            }
          })
          .catch((error) => {
            console.error(`Failed to fetch account ${account.account_id} callcount`, error);
          });
      });
    };

    // Function to start call count interval
    const startCallCountInterval = () => {
      if (state.callCountInterval) {
        return;
      }
      // Wait for table to render and calculate in-view numbers before fetching call counts
      setTimeout(state.getAccountCallCounts, 350);
      state.callCountInterval = setInterval(() => {
        getAccountCallCounts();
      }, state.callCountIntervalPeriod);
    };

    // Function to load accounts from the server
    const loadAccounts = async () => {
      state.loading = true;
      try {
        const { data } = await axios.get(`${BTL_API_ACCOUNT_URL}?show_archived=true`);
        state.accounts = data;
        state.accounts.forEach((row) => {
          row.detailsRoute = `/account/${row.account_id}`;
        });
        state.loading = false;
        state.accounts = sortBy(state.accounts, ['name']);

        // Start call count interval if not already running
        if (!state.callCountInterval) {
          startCallCountInterval();
        }
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        state.loading = false;
      }
    };

    // Function to toggle visibility of accounts in view
    const toggleAccountVisible = (accountId, isVisible) => {
      if (isVisible) {
        if (!state.accountsInView.includes(accountId)) {
          state.accountsInView.push(accountId);
        }
      } else {
        state.accountsInView = without(state.accountsInView, accountId);
      }
    };

    // Save query params on mount and process them
    saveQueryParams();
    onMounted(async () => {
      await loadAccounts();
      processQueryParams();
    });

    // Clear call count interval on unmount
    onBeforeUnmount(() => {
      clearInterval(state.callCountInterval);
    });

    // Return reactive state and methods
    return {
      ...toRefs(state),
      filteredAccounts,
      headers,
      loadAccounts,
      toggleAccountVisible,
      permittedFunction,
      tableOptions,
      handleTableOptionsUpdate,
    };
  },
};
</script>/* eslint-disable */
