<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        lg6
      >
        <v-menu
          ref="fromDateMenu"
          v-model="fromDateMenu"
          max-width="290px"
          min-width="290px"
          offset-y
          transition="scale-transition"
          :close-on-content-click="false"
          :nudge-right="40"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="formattedFromDate"
              label="Date - From"
              persistent-hint
              prepend-icon="event"
              v-bind="attrs"
              @blur="fromDate = parseDate(formattedFromDate)"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="fromDateMenu = false"
          />
        </v-menu>
      </v-flex>
      <v-flex
        xs12
        lg6
      >
        <v-menu
          ref="toDateMenu"
          v-model="toDateMenu"
          max-width="290px"
          min-width="290px"
          offset-y
          transition="scale-transition"
          :close-on-content-click="false"
          :nudge-right="40"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="formattedToDate"
              label="Date - To"
              persistent-hint
              prepend-icon="event"
              v-bind="attrs"
              @blur="toDate = parseDate(formattedToDate)"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            @input="toDateMenu = false"
          />
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        lg6
      >
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="fromTime"
              label="Between Hours - From"
              prepend-icon="access_time"
              readonly
              v-on="on"
              @click:minute="$refs.fromTimeMenu.save(fromTime)"
            />
          </template>
          <v-time-picker
            v-model="fromTime"
            style="margin: auto;"
            format="24hr"
          />
        </v-menu>
      </v-flex>
      <v-flex
        xs12
        lg6
      >
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="toTime"
              label="Between Hours - To"
              prepend-icon="access_time"
              readonly
              v-on="on"
            />
          </template>
          <v-time-picker
            v-model="toTime"
            style="margin: auto;"
            format="24hr"
          />
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-spacer />
      <v-btn
        text
        @click="onCancel"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="onSubmit"
      >
        Submit
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment-timezone';
import config from '../../../../config.js';

export default {
  name: 'QueryBuilderDateCustomRange',
  data() {
    const fromDate = new Date().toISOString().substr(0, 10);
    const toDate = new Date().toISOString().substr(0, 10);

    const formattedFromDate = this.formatDate(fromDate);
    const formattedToDate = this.formatDate(toDate);

    return {
      fromDate,
      toDate,
      formattedFromDate,
      formattedToDate,
      fromDateMenu: false,
      fromTime: '00:00',
      toDateMenu: false,
      toTime: '23:59',
    };
  },
  computed: {
    timeZone() {
      return this.$store.getters.timeZone;
    },
  },
  watch: {
    fromDate(newDate) {
      this.formattedFromDate = this.formatDate(newDate);
    },
    toDate(newDate) {
      this.formattedToDate = this.formatDate(newDate);
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    formatDate(date) {
      return moment(date).format(config.date_format);
    },
    parseDate(date) {
      return moment(date, config.date_format).format('YYYY-MM-DD');
    },
    onSubmit() {
      let text = `${moment(this.fromDate).format(config.date_format)} - ${moment(this.toDate).format(config.date_format)}`;
      const from = moment.tz(`${this.fromDate}T${this.fromTime}`, this.timeZone).tz('UTC');
      const to = moment.tz(`${this.toDate}T${this.toTime}`, this.timeZone).tz('UTC');

      const dateRange = {
        gte: from.toISOString(),
        lte: to.toISOString(),
      };

      const query = {
        query: {
          bool: {
            filter: [
              {
                range: {
                  call_datetime_iso: dateRange,
                },
              },
            ],
          },
        },
      };

      if (this.fromTime || this.toTime) {
        const fromTime = this.fromTime || '00:00';
        const toTime = this.toTime || '23:59';
        text += ` between ${fromTime} and ${toTime}`;

        const [fromHours, fromMinutes] = fromTime.split(':');
        const [toHours, toMinutes] = toTime.split(':');

        const fromTimeTz = moment.tz(this.timeZone).set({
          hour: fromHours,
          minute: fromMinutes,
          second: 0,
          millisecond: 0,
        }).tz('UTC');

        const toTimeTz = moment.tz(this.timeZone).set({
          hour: toHours,
          minute: toMinutes,
          second: 0,
          millisecond: 0,
        }).tz('UTC');

        const params = {
          fromHour: +fromTimeTz.format('HH'),
          toHour: +toTimeTz.format('HH'),
          fromMinute: +fromTimeTz.format('mm'),
          toMinute: +toTimeTz.format('mm'),
        };

        query.query.bool.filter.push({
          script: {
            script: {
              lang: 'painless',
              params,
              source: `
                if (params.fromHour < params.toHour) {
                    if (doc['call_datetime_iso'].value.getHour() >= params.fromHour && doc['call_datetime_iso'].value.getHour() <= params.toHour) {
                        // from hour
                        if (doc['call_datetime_iso'].value.getHour() == params.fromHour) {
                            // from minutes
                            if (doc['call_datetime_iso'].value.getMinute() >= params.fromMinute) {
                                return true
                            } else {
                                return false
                            }
                            // to hour
                        } else if (doc['call_datetime_iso'].value.getHour() == params.toHour) {
                            // upper minutes
                            if (doc['call_datetime_iso'].value.getMinute() <= params.toMinute) {
                                return true
                            } else {
                                return false
                            }
                            // hours between
                        } else {
                            return true
                        }
                    } else {
                        return false
                    }
                } else {
                    if ((doc['call_datetime_iso'].value.getHour() >= params.fromHour && doc['call_datetime_iso'].value.getHour() <= 24) ||
                        (doc['call_datetime_iso'].value.getHour() <= params.toHour && doc['call_datetime_iso'].value.getHour() >= 1)
                    ) {
                        // from hour
                        if (doc['call_datetime_iso'].value.getHour() == params.fromHour) {
                            // from minutes
                            if (doc['call_datetime_iso'].value.getMinute() >= params.fromMinute) {
                                return true
                            } else {
                                return false
                            }
                            // to hour
                        } else if (doc['call_datetime_iso'].value.getHour() == params.toHour) {
                            // upper minutes
                            if (doc['call_datetime_iso'].value.getMinute() <= params.toMinute) {
                                return true
                            } else {
                                return false
                            }
                            // hours between
                        } else {
                            return true
                        }
                    } else {
                        return false
                    }
                }
              `,
            },
          },
        });
      }

      this.$emit('submit', { query, text });
    },
  },
};
</script>
