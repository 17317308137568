<template>
  <div>
    <!-- Toolbar for navigation -->
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create User
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <!-- Form for user creation -->
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        :aria-autocomplete="false"
        lazy-validation
      >
        <!-- Input field for user's name -->
        <v-layout class="px-2">
          <v-flex xs5>
            <v-text-field
              ref="userName"
              v-model="user.fullname"
              autocomplete="new-password"
              :messages="nameErrors"
              :error="nameError"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <!-- Input field for user's password -->
        <v-layout class="px-2">
          <v-flex xs5>
            <v-text-field
              v-model="user.password"
              :messages="passwordErrors"
              :error="passwordError"
              label="Password"
              :rules="passwordRules"
              :counter="30"
              :append-icon="showPwd ? 'visibility_off' : 'visibility'"
              :type="showPwd ? 'text' : 'password'"
              required
              @click:append="showPwd = !showPwd"
            />
          </v-flex>
        </v-layout>
        <!-- Input field for user's email -->
        <v-layout class="px-2">
          <v-flex xs5>
            <v-text-field
              v-model="user.emails[0].email"
              :messages="emailErrors"
              :error="emailError"
              label="Email"
              :rules="emailRules"
              :counter="80"
              required
            />
          </v-flex>
        </v-layout>
        <!-- Switches for user's attributes -->
        <v-layout
          class="px-2"
          wrap
        >
          <v-flex xs4>
            <v-switch
              v-model="user.active"
              label="Active"
            />
          </v-flex>
          <v-flex xs10>
            <v-switch
              v-model="user.accepted_gdpr"
              label="Accepted GDPR"
            />
          </v-flex>
          <v-flex xs10>
            <v-switch
              v-model="user.accepted_terms"
              label="Accepted Terms & Conditions"
            />
          </v-flex>
        </v-layout>
        <!-- Expansion panels for additional user attributes -->
        <v-layout
          class="px-2"
          wrap
        >
          <v-flex
            xs12
            sm10
          >
            <!-- Roles panel -->
            <v-expansion-panels
              focusable
              class="my-3 mx-2"
            >
              <Roles
                v-if="canAddEntitlements"
                :accounts="accounts"
                :selected-roles="selectedRoles"
                :user="user"
                @selectedRolesChanged="updateSelectedRoles"
              />
              <!-- Functions panel -->
              <Functions
                v-if="canAddEntitlements"
                :accounts="accounts"
                :user="user"
                @selectedFunctionsChanged="updateSelectedFunctions"
              />
              <!-- Groups panel -->
              <Groups
                v-if="canAddGroupMembership"
                :accounts="accounts"
                :user="user"
                @selectedGroupsChanged="updateSelectedGroups"
              />
            </v-expansion-panels>
          </v-flex>
        </v-layout>
        <!-- Buttons for saving or canceling user creation -->
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createUser"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import {
  BTL_API_ACCOUNT_URL, bizvu_user_url, PASSWORD_REGEX,
} from '@/config.js';

import Roles from '@/components/user/RolesPanel';
import Functions from '@/components/user/FunctionsPanel';
import Groups from '@/components/user/GroupsPanel';
import permittedFunction from '../../permittedFunction';

export default {
  name: 'Newuser',
  components: {
    Roles,
    Functions,
    Groups,
  },
  data() {
    return {
      t1: 1,
      t2: 1,
      showPwd: false,
      selectedGroups: [],
      selectedRoles: [],
      selectedFunctions: [],
      valid: false,
      user: {
        fullname: '',
        active: true,
        password: '',
        accepted_terms: false,
        accepted_gdpr: false,
        emails: [
          { contact_me: true, email: '' },
        ],
      },
      accounts: [],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => (v && v.length <= 80) || 'Email address must not have more than 80 characters',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && new RegExp(PASSWORD_REGEX).test(v)) || 'Password must be 12 or more characters containing lower case, upper case, numeric and a special characters',
        (v) => (v && (v.length <= 30 && v.length >= 12)) || 'Password must have between 12 and 30 characters',
      ],
      dialogInitiator: '',
      selectAllDialog: false,
      setAllEntityType: 'tenant',
      setAllTenant: '',
      setAllAccount: '',
      setAllSite: '',
      emailError: false,
      emailErrors: [],
      nameError: false,
      nameErrors: [],
      passwordError: false,
      passwordErrors: [],
    };
  },
  computed: {
    canAddEntitlements() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/users/<user_id>/entitlements', 'POST');
    },
    canAddGroupMembership() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>/members/<user_id>', 'POST');
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  mounted() {
    this.valid = false;
    this.$refs.userName.focus();
    if (this.canAddEntitlements) {
      this.loadAccounts();
    }
  },
  methods: {
    // Get available entity types
    getEntityTypes() {
      return [
        { name: 'Tenant', key: 'tenant' },
        { name: 'Account', key: 'account' },
        { name: 'Site', key: 'site' },
      ];
    },
    // Get entity IDs based on type
    getEntityIds(type) {
      if (type === 'tenant') {
        return ['btlcloud', 'abc'];
      } if (type === 'account') {
        return this.accounts;
      }
      return [];
    },
    // Load accounts from the server
    loadAccounts() {
      const self = this;
      axios.get(BTL_API_ACCOUNT_URL)
        .then((response) => {
          self.accounts = response.data;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    // Show select all dialog
    showDialog(initiator) {
      // could reset defaults but likely they want to re-use them?
      this.selectAllDialog = true;
      this.dialogInitiator = initiator;
    },
    // Update selected roles
    updateSelectedRoles(updatedRoles) {
      this.selectedRoles = updatedRoles.slice();
      this.user.updateEntitlements = true;
    },
    // Update selected functions
    updateSelectedFunctions(functions) {
      this.selectedFunctions = functions.slice();
      this.user.updateEntitlements = true;
    },
    // Update selected groups
    updateSelectedGroups(groups) {
      this.selectedGroups = groups.slice();
      this.user.updateGroups = true;
    },
    // Capitalize first letter of a string
    uppercaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // Change sorting order
    changeSort(column, pagination) {
      if (pagination.sortBy === column) {
        pagination.descending = !pagination.descending;
      } else {
        pagination.sortBy = column;
        pagination.descending = false;
      }
    },
    // Create a new user
    async createUser() {
      // Validate form
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.$emit('snack', 'Correct invalid fields before saving.', true);
        return;
      }
      // Prepare user data for submission
      this.user.selectedGroups = this.selectedGroups;
      this.user.roles = [];
      this.selectedRoles.forEach((r) => {
        this.user.roles.push({
          role_id: r.role_id,
          entity_id: r.entity_id,
          entity_type: r.entity_type,
        });
      });
      this.user.functions = [];
      this.selectedFunctions.forEach((f) => {
        this.user.functions.push({
          function_id: f.function_id,
          entity_id: f.entity_id,
          entity_type: f.entity_type,
        });
      });

      try {
        // Send request to create user
        await axios.post(`${bizvu_user_url}/account/${this.selectedAccountId}/user`, this.user);
        // Notify user about successful creation
        this.$emit('snack', 'User successfully created!');
        this.$router.push('/users');
      } catch (error) {
        // Handle errors
        if (error.response.status) {
          const response = error.response.data;
          switch (error.response.status) {
            case 400:
              if (response.fullname) {
                this.nameError = true;
                this.nameErrors.push('Name invalid');
              }
              if (response.password) {
                this.passwordError = true;
                this.passwordErrors.push('Password invalid');
              }
              break;
            case 409:
              this.emailError = true;
              this.emailErrors.push('Email already in use');
              break;
            default:
              break;
          }
        } else {
          console.error('ERROR', error.response);
        }
      }
    },
    // Check user's permissions
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>
</style>
