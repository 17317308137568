<template>
  <v-list>
    <template v-for="node in permittedNodes">
      <v-list-group
        v-if="node.children && node.children.length"
        :key="node.text"
        v-model="node.active"
      >
        <template #activator>
          <v-list-item-action>
            <v-icon>{{ node.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ node.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <node-list
          class="py-0 pl-3"
          :nodes="node.children"
        />
      </v-list-group>
      <div
        v-else
        :key="node.text"
      >
        <v-list-item
          link
          :to="node.route"
        >
          <v-list-item-action v-if="node.icon">
            <v-icon>{{ node.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ node.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </template>
  </v-list>
</template>

<script>

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'NodeList',
  props: {
    nodes: Array,
  },
  computed: {
    permittedNodes() {
      return this.nodes.filter((node) => node.disabled !== false && this.isPermitted(node.endpoint, node.verb));
    },
  },
  methods: {
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>
  .v-list-item__title {
    white-space: normal;
  }
</style>
