<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <!-- Subheading for Call Type(s) -->
        <v-card-title class="subheading-title">
          Call Type(s)
        </v-card-title>

        <!-- Scrollable List of Options -->
        <v-card-text class="pb-0 scrollable-content">
          <v-container fluid>
            <v-row no-gutters>
              <v-col
                v-for="(callType, index) in items"
                :key="index"
                cols="12"
              >
                <v-checkbox
                  :value="callType"
                  :label="callType"
                  v-model="status"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Sticky Footer with Buttons -->
        <v-card-actions class="sticky-footer">
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!!!status.length"
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'QueryBuilderCallType',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      status: [],
      items: ['NATIONAL', 'INTERNATIONAL', 'MOBILE', 'NONGEO', 'UNKNOWN'],
    };
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.status = [...this.item.defaultValue];
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'callType';
      item.defaultValue = [...this.status];

      const query = {
        query: {
          bool: {
            filter: [
              {
                terms: {
                  call_type: this.status,
                },
              },
            ],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        text: this.status.join(', '),
      });
      this.dialog = false;
      this.status = [];
    },
  },
};
</script>

<style scoped>
.scrollable-content {
  max-height: 300px;
  overflow-y: auto;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
}

.subheading-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.v-row {
  margin: 0;
}

.v-col {
  padding: 0;
}
</style>
