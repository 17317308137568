<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create {{ filterType }}
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs8>
            <v-text-field
              v-model="filter.PhoneNumber"
              label="Number"
              :rules="numberRules"
              :counter="13"
              required
            />
            <v-select
              v-model="filter.Direction"
              label="Direction"
              :items="directions"
            />
            <v-text-field
              v-model="filter.Comment"
              label="Comment"
              :rules="commentRules"
              :counter="40"
            />
          </v-flex>
        </v-layout>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createFilter"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click.stop="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';

const e164Number = /^(\+[0-9][\d ]{1,14})?(\d{5,6})?(.*[a-zA-Z].*)?$/;

export default {
  name: 'Newfilter',
  data() {
    return {
      filterType: this.$route.params.type,
      filterLevel: this.$route.params.level,
      valid: false,
      filter: {
        PhoneNumber: '',
        Direction: 'BOTH',
        Comment: '',
      },
      numberRules: [
        (v) => (v && e164Number.test(v)) || 'Invalid number format. Must be E164 format, 5/6 digit short code, or an alpha tag.',
      ],
      commentRules: [
        (v) => (v && v.length > 0) || 'Comment must be provided',
        (v) => (v && v.length >= 4 && v.length <= 40) || 'Comment be between 4 and 40 characters',
      ],
      directions: ['BOTH', 'INCOMING', 'OUTGOING'],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
    postPath() {
      let basePath = `${config.BTL_API_TENANT_URL}`;
      if (this.filterLevel === 'account') {
        basePath += `/accounts/${this.selectedAccountId}`;
      }
      return `${basePath}/${this.filterType === 'Whitelist' ? 'whitelists' : 'blacklists'}`;
    },
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  methods: {
    createFilter() {
      if (!this.$refs.form.validate()) {
        this.$emit('snack', 'Correct invalid fields before saving', true);
        return;
      }
      axios.post(this.postPath, this.filter)
        .then(() => {
          this.$emit('snack', `${this.filterType} successfully created!`);
          this.$router.push(this.filterLevel === 'account' ? '/account-filters' : '/tenant-filters');
        })
        .catch((error) => {
          this.$emit('snack', `Failed to create ${this.filterType}`, true);
          console.error('ERROR', error.response);
        });
    },
  },
};
</script>

<style scoped>
</style>
