<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Minimum Call Charge Management
      </v-toolbar-title>
      <v-autocomplete
        v-model="accountFilter"
        flat
        :items="accounts"
        item-text="name"
        item-value="account_id"
        label="Account filter"
        single-line
        hide-details
        style="max-width: 20rem;"
      />
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/minimumcharges', 'POST')"
        text
        color="blue"
        @click="$router.push('/minimumcallcharge/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="charges"
        :headers="exportHeaders"
        report-name="Minimum Call Charges"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="charges"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="CallType"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            :custom-sort="customSort"
            height="calc(100vh - 220px)"
          >
            <template #item.CallType="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/minimumcharges/<mcid>', 'GET')"
                :to="item.itemRoute"
              >
                {{ item.CallType }}
              </router-link>
              <span v-else>{{ item.CallType }}</span>
            </template>
            <template #item.MinimumCost="{ item }">
              <span>{{ item.MinimumCost | formatMoney }}</span>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { sortBy } from 'lodash';
import config from '@/config.js';

import download from '@/components/download/tableDownload';
import customSort from '@/utils/customSort';

import formatter from '@/utils/currencyFormatter';
import permittedFunction from '@/permittedFunction.js';

const currencyFormatter = formatter(4);

export default {
  name: 'MinimumCallChargeManagement',
  components: {
    download,
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatMoney(value) {
      return currencyFormatter(value);
    },
  },
  data() {
    const headers = [
      { text: 'Call Type', value: 'CallType' },
      { text: 'Minimum Cost', value: 'MinimumCost' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      accounts: [],
      accountFilter: null,
      charges: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      loading: false,
    };
  },
  watch: {
    accountFilter() {
      this.loadData();
    },
  },
  mounted() {
    this.loadAccounts();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        await this.loadCharges();
      } catch (error) {
        console.error('Could not load data: ', error.message);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadCharges() {
      const response = await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.accountFilter}/minimumcharges`);

      this.charges = response.data.map((row) => ({
        ...row,
        itemRoute: `/minimumcallcharge/${row.AccountId}/${row.MCId}`,
      }));
    },
    async loadAccounts() {
      try {
        const response = await axios.get(config.BTL_API_ACCOUNT_URL);
        this.accounts = sortBy(response.data, [(account) => account.name.toLowerCase()]);
      } catch (err) {
        this.$emit('snack', 'Failed to load accounts', err);
        console.error('Failed to load accounts', err);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    customSort: customSort('ModifiedOn'),
  },
};
</script>
