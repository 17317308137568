<template>
  <div>
    <page-toolbar title="SMS Number Management">
      <v-autocomplete
        v-model="accountFilter"
        flat
        :items="accounts"
        item-text="name"
        item-value="account_id"
        label="Account filter"
        hide-details
        style="max-width: 20rem;"
        single-line
        clearable
      />
      <v-btn
        text
        color="green"
        @click="createNumberDialogue = true"
      >
        <v-icon left>
          add_box
        </v-icon>Create
      </v-btn>

      <v-btn
        v-if="canAllocateNumbers"
        text
        color="primary"
        :loading="allocating"
        @click="showAllocateDialogue = true"
      >
        <v-icon left>
          add_circle_outline
        </v-icon>Allocate
      </v-btn>

      <v-btn
        v-if="canDeAllocateNumbers"
        text
        color="primary"
        :loading="deAllocating"
        @click="deAllocateDialogue = true"
      >
        <v-icon left>
          remove_circle_outline
        </v-icon>De-Allocate
      </v-btn>

      <v-btn
        v-if="canDelete"
        text
        color="error"
        @click="deleteDialogue = true"
      >
        <v-icon left>
          delete
        </v-icon>Delete
      </v-btn>

      <v-btn
        text
        color="blue"
        :loading="loadingNumbers"
        @click="loadNumbers"
      >
        <v-icon left>
          refresh
        </v-icon>Refresh
      </v-btn>
    </page-toolbar>

    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selectedNumbers"
            :headers="headers"
            :items="filteredNumbers"
            show-select
            :loading="loadingNumbers"
            item-key="SourceNumber"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 205px)"
            :search="$store.getters.searchQuery"
          >
            <template #item.ModifiedOn="{item}">
              {{ formatDateTime(item.ModifiedOn) }}
            </template>
            <template #item.AccountId="props">
              <div>
                <v-chip
                  v-if="props.item.AccountId"
                  small
                  class="ma-1"
                  color="secondary"
                  text-color="white"
                >
                  {{ accountName(props.item.AccountId) }}
                </v-chip>
              </div>
            </template>
            <template #item.actions="props">
              <div>
                <v-tooltip
                  bottom
                >
                  <template #activator="{on}">
                    <v-btn
                      icon
                      @click="openEditDialogue({ number: props.item })"
                      v-on="on"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Number</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="createNumberDialogue"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Create Number
        </v-card-title>
        <v-card-text>
          <v-form v-model="createNumberForm">
            <v-text-field
              v-model="numberToCreate.SourceNumber"
              label="Number"
              :rules="generalRules"
            />
            <v-text-field
              v-model="numberToCreate.Notes"
              label="Notes"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            @click="createNumber"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="secondary"
            @click="createNumberDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editNumberDialogue"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Edit Number
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="numberToEdit.Notes"
              label="Notes"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            @click="editNumber"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="secondary"
            @click="editNumberDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAllocateDialogue"
      persistent
      max-width="420"
    >
      <v-card>
        <v-card-title>
          Allocate SMS Number(s) to an Account
        </v-card-title>
        <v-subheader>Allocate these numbers:</v-subheader>
        <v-card-text>
          <div class="text-center">
            <v-chip
              v-for="n in selectedNumbers"
              :key="n.SourceNumber"
              class="ma-1"
              color="blue"
              text-color="white"
            >
              <v-icon left>
                call
              </v-icon>
              {{ n.SourceNumber }}
            </v-chip>
          </div>
          <v-form>
            <v-select
              v-model="selectedAccount"
              flat
              :items="accounts"
              item-text="name"
              item-value="AccountId"
              label="Account"
              :rules="generalRules"
              return-object
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            :loading="allocating"
            @click="allocate"
          >
            Allocate
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="showAllocateDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deAllocateDialogue"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Deallocate Number(s)
        </v-card-title>
        <v-card-text>
          Do you want to deallocate the number(s):
          <div
            v-for="n in selectedNumbers"
            :key="n.SourceNumber"
          >
            <v-chip
              class="ma-1"
              color="blue"
              text-color="white"
              small
            >
              <v-icon
                left
                small
              >
                call
              </v-icon>
              {{ n.SourceNumber }}
            </v-chip>

            from the account

            <v-chip
              class="ma-1"
              color="secondary"
              text-color="white"
              small
            >
              {{ accountName(n.AccountId) }}
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="error"
            dark
            @click="deAllocate"
          >
            Deallocate
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="deAllocateDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialogue"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Delete Number(s)
        </v-card-title>
        <v-card-text>Do you want to delete these numbers?</v-card-text>
        <div class="text-center">
          <v-chip
            v-for="n in selectedNumbers"
            :key="n.SourceNumber"
            class="ma-1"
            color="blue"
            text-color="white"
          >
            <v-icon left>
              call
            </v-icon>
            {{ n.SourceNumber }}
          </v-chip>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="error"
            dark
            :loading="deletingNumbers"
            @click="deleteNumbers"
          >
            Delete
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="deleteDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import axios from 'axios';
import {
  onMounted, ref, computed, defineEmits,
} from 'vue';
import {
  sortBy, filter, isNil, find,
} from 'lodash';

import config, { BTL_API_TENANT_URL, BTL_API_ACCOUNT_URL } from '@/config';
import { formatDateTime } from '@/utils/DateTimeFormatter';

import PageToolbar from '@/components/PageToolbar';

const headers = [
  { text: 'Number', value: 'SourceNumber' },
  { text: 'Account', value: 'AccountId' },
  { text: 'Notes', value: 'Notes' },
  { text: 'Modified On', value: 'ModifiedOn' },
  { text: 'Modified By', value: 'ModifiedBy' },
  { text: '', value: 'actions', align: 'right' },
];

const generalRules = [
  (v) => !!v || 'Field is required',
];

const emit = defineEmits(['snack']);

const unallocatedCategory = '---   Unallocated   ---';

const selectedNumbers = ref([]);
const accountFilter = ref(null);
const numbers = ref([]);

const filteredNumbers = computed(() => {
  if (!accountFilter.value) {
    return numbers.value;
  } if (accountFilter.value === unallocatedCategory) {
    return filter(numbers.value, (a) => isNil(a.AccountId));
  }
  return filter(numbers.value, { AccountId: accountFilter.value });
});

const accounts = ref([]);

const loadAccounts = async () => {
  try {
    const { data } = await axios.get(config.BTL_API_ACCOUNT_URL);
    accounts.value = [unallocatedCategory, ...sortBy(data, ['name'])];
  } catch (error) {
    console.error('ERROR', error.response);
  }
};

const accountName = (accountId) => {
  const account = find(accounts.value, { account_id: accountId });
  return account?.name;
};

const loadingNumbers = ref(true);

const loadNumbers = async () => {
  try {
    loadingNumbers.value = true;
    const { data } = await axios.get(`${BTL_API_TENANT_URL}/sms/numbers`);
    numbers.value = data;
    selectedNumbers.value = [];
  } catch (err) {
    console.error('ERROR', err.response);
  } finally {
    loadingNumbers.value = false;
  }
};

const createNumberDialogue = ref(false);
const creatingNumber = ref(false);
const numberToCreate = ref({
  SourceNumber: null,
  Notes: null,
});
const createNumberForm = ref(false);

const createNumber = async () => {
  if (!createNumberForm.value) return;

  creatingNumber.value = true;
  try {
    await axios.post(`${BTL_API_TENANT_URL}/sms/numbers`, numberToCreate.value);
    createNumberDialogue.value = false;
    numberToCreate.value = {
      SourceNumber: '',
      Notes: '',
    };
    emit('snack', 'Number Created');
    await loadNumbers();
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    creatingNumber.value = false;
  }
};

const editNumberDialogue = ref(false);
const numberToEdit = ref({});
const editingNumber = ref(false);

const openEditDialogue = ({ number }) => {
  numberToEdit.value = number;
  editNumberDialogue.value = true;
};

const editNumber = async () => {
  editingNumber.value = true;
  try {
    await axios.put(`${BTL_API_TENANT_URL}/sms/numbers/${numberToEdit.value.SourceNumber}`, numberToEdit.value);
    editNumberDialogue.value = false;
    emit('snack', 'Number Updated');
    await loadNumbers();
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    editingNumber.value = false;
  }
};

const canAllocateNumbers = computed(() => selectedNumbers.value.length > 0 && filter(selectedNumbers.value, (a) => isNil(a.AccountId)).length > 0);

const showAllocateDialogue = ref(false);
const allocating = ref(false);
const selectedAccount = ref(null);

const allocate = async () => {
  allocating.value = true;
  try {
    await Promise.all(selectedNumbers.value.map(async (number) => {
      await axios.post(`${BTL_API_ACCOUNT_URL}/${selectedAccount.value.account_id}/sms/numbers/${number.SourceNumber}`);
    }));
    selectedNumbers.value = [];
    showAllocateDialogue.value = false;
    emit('snack', 'Number(s) Allocated');
    await loadNumbers();
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    allocating.value = false;
  }
};

const deAllocating = ref(false);
const deAllocateDialogue = ref(false);

const canDeAllocateNumbers = computed(() => selectedNumbers.value.length > 0 && filter(selectedNumbers.value, (a) => !isNil(a.AccountId)).length > 0);

const deAllocate = async () => {
  deAllocating.value = true;
  try {
    await Promise.all(selectedNumbers.value.map(async (number) => {
      await axios.delete(`${BTL_API_ACCOUNT_URL}/${number.AccountId}/sms/numbers/${number.SourceNumber}`);
    }));
    emit('snack', 'Number(s) DeAllocated');
    await loadNumbers();
    deAllocateDialogue.value = false;
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    deAllocating.value = false;
  }
};

const canDelete = computed(() => selectedNumbers.value.length > 0);
const deleteDialogue = ref(false);
const deletingNumbers = ref(false);

const deleteNumbers = async () => {
  deletingNumbers.value = true;
  try {
    await Promise.all(selectedNumbers.value.map(async (number) => {
      await axios.delete(`${BTL_API_TENANT_URL}/sms/numbers/${number.SourceNumber}`);
    }));
    emit('snack', 'Number(s) Deleted');
    await loadNumbers();
    deleteDialogue.value = false;
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    deletingNumbers.value = false;
  }
};

onMounted(async () => {
  await Promise.all([
    await loadNumbers(),
    await loadAccounts(),
  ]);
});

</script>
