<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Code
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/tenants/<tenant_name>/nongeos/<nongeoid>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-layout v-if="!createMode">
          <v-flex xs4>
            <v-text-field
              label="Chargeband"
              :value="code.ChargeBandName"
              readonly
            />
          </v-flex>
        </v-layout>
        <v-layout v-if="createMode">
          <v-flex xs4>
            <v-select
              v-model="code.CBId"
              flat
              :items="chargebands"
              item-text="Name"
              item-value="CBId"
              label="Chargeband"
              :rules="ChargeBandRules"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs4>
            <v-text-field
              v-model="code.Code"
              label="Code"
              :rules="CodeRules
              "
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex
            xs4
            class="pa-2"
          >
            <v-row>
              <v-btn
                v-if="isPermitted('/tenants/<tenant_name>/nongeos/<nongeoid>', 'PUT')"
                primary
                color="primary"
                dark
                @click="updateCode"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this code?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteCode"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'Nongeocode',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      codeID: this.$route.params.codeID,
      code: {},
      chargebands: [],
      ChargeBandRules: [
        (v) => !!v || 'Chargeband is required',
      ],
      CodeRules: [
        (v) => !!v || 'Code is required',
      ],
    };
  },
  async created() {
    await this.loadChargebands();
    if (this.codeID === 'new') {
      this.createMode = true;
    } else {
      this.loadCode();
    }
  },
  methods: {
    async updateCode() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Code successfully updated.';
        if (this.createMode) {
          snackMessage = 'Code successfully created.';
          await axios.post(`${config.BTL_API_TENANT_URL}/nongeos`, this.code);
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/nongeos/${this.codeID}`, {
            Code: this.code.Code,
          });
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating code', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} code.`, true);
      }
    },
    async loadCode() {
      try {
        const code = (await axios.get(`${config.BTL_API_TENANT_URL}/nongeos/${this.codeID}`)).data;
        const chargeband = this.chargebands.find((c) => c.CBId === code.CBId);
        code.ChargeBandName = chargeband.Name;
        this.code = code;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve code.', true);
        console.error('Failed to retrieve code', error);
      }
    },
    async deleteCode() {
      try {
        await axios.delete(`${config.BTL_API_TENANT_URL}/nongeos/${this.codeID}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete code.', true);
        console.error('Failed to delete code', error);
      }
    },
    async loadChargebands() {
      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/chargebands`);
        this.chargebands = sortBy(response.data, [(chargeband) => chargeband.Name.toLowerCase()]);
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve chargebands.', true);
        console.error('Failed to retrieve chargebands', error);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
