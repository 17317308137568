<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Call Bundles
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/callbundles', 'POST')"
        color="blue"
        text
        @click="$router.push('/callbundles/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="callBundles"
        :headers="exportHeaders"
        report-name="Call Bundles"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="callBundles"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 220px)"
          >
            <template #item.BundleName="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/callbundles/<bundleid>', 'GET')"
                :to="`callbundles/${item.BundleId}`"
              >
                {{ item.BundleName }}
              </router-link>
              <div v-else>
                {{ item.BundleName }}
              </div>
            </template>
            <template #item.BundleCost="{ item }">
              <span>{{ item.BundleCost | formatMoney }}</span>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
            <template #item.Accounts="{ item }">
              <lookup
                v-if="isPermitted('/tenants/<tenant_name>/accounts', 'GET')"
                :title="'Accounts'"
              >
                <CallBundleAccounts :data="getAccountsFromIDs(item.accounts)" />
              </lookup>
            </template>
            <template #item.ChargeBands="{ item }">
              <div v-if="item.BundleType === 'INTERNATIONAL' ? isPermitted('/tenants/<tenant_name>/internationals', 'GET') : isPermitted('/tenants/<tenant_name>/nongeos', 'GET') ">
                <codes-lookup
                  v-if="item.BundleType === 'INTERNATIONAL'"
                  rate-name="country.CountryName"
                  header="country"
                  api-path="internationals"
                  filter-name="CountryId"
                  :filter-value="item.chargebands.map(cb => cb.CountryId)"
                  redirect-path="internationalcodes"
                  :small="true"
                />
                <codes-lookup
                  v-else
                  rate-name="chargeband.Name"
                  header="name"
                  api-path="nongeos"
                  filter-name="CBId"
                  :filter-value="item.chargebands.map(cb => cb.CBId)"
                  redirect-path="nongeocodes"
                  :small="true"
                />
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

import Lookup from '@/components/Lookup';
import CallBundleAccounts from '@/components/lookups/CallBundleAccounts';
import download from '@/components/download/tableDownload';
import CodesLookup from '@/components/billing/CodesLookup';

import { BTL_API_TENANT_URL, BTL_API_ACCOUNT_URL, date_format } from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'CallBundles',
  components: {
    download,
    Lookup,
    CodesLookup,
    CallBundleAccounts,
  },
  filters: {
    formatDate(date) {
      return moment(date).format(date_format);
    },
    formatMoney(value) {
      return currencyFormatter(value);
    },
  },
  data() {
    const headers = [
      { text: 'Name', value: 'BundleName' },
      { text: 'Type', value: 'BundleType' },
      { text: 'Cost', value: 'BundleCost' },
      { text: 'Inclusive Minutes', value: 'InclusiveMinutes' },
      { text: 'Accounts', value: 'Accounts' },
      { text: 'Charge Bands', value: 'ChargeBands' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      callBundles: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      accounts: {},
      loading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        await this.loadBundles();
        this.loadAccounts();
      } finally {
        this.loading = false;
      }
    },
    async loadBundles() {
      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/callbundles`);
        this.callBundles = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve call bundles.', true);
      }
    },
    async loadAccounts() {
      try {
        if (this.isPermitted('/tenants/<tenant_name>/accounts', 'GET')) {
          const response = await axios.get(BTL_API_ACCOUNT_URL);
          this.accounts = response.data.reduce((acc, cv) => {
            const returnValue = { ...acc };
            returnValue[cv.account_id] = cv;
            return returnValue;
          }, {});
        }
      } catch (error) {
        console.error('Failed to retrieve accounts', error);
        this.$emit('snack', 'Failed to retrieve accounts', true);
      }
    },
    getAccountsFromIDs(accountIDs = []) {
      return accountIDs.map(({ AccountId }) => {
        const { name } = (this.accounts?.[AccountId] || {});
        return {
          accountId: AccountId,
          name,
        };
      });
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
