/* eslint-disable */
<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Edit User
      </v-toolbar-title>
      <v-btn
        v-if="canDeleteUser"
        text
        color="blue"
        @click="dialog=true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-container v-if="forbidden">
      <v-layout>
        <v-flex
          xs12
          md8
        >
          <p class="ml-5 headline red">
            You are not allowed to access user {{ userId }}!
          </p>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container v-else-if="serverError">
      <v-layout>
        <v-flex
          xs12
          md8
        >
          <p class="ml-5 headline red">
            Internal API error when accessing user {{ userId }}!
          </p>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container v-else>
      <v-form
        v-if="user"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs5>
            <v-text-field
              v-model="user.fullname"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs5>
            <v-text-field
              v-model="user.emails[0].email"
              disabled
              label="Email"
              :rules="emailRules"
              :counter="80"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="canChangeMaxEntitlementLevel"
          class="px-2"
        >
          <v-flex xs4>
            <v-select
              v-model="user.max_entitlement_level"
              :items="entitlementLevels"
              label="Max Entitlement Level"
            />
          </v-flex>
        </v-layout>
        <v-layout
          class="px-2"
          wrap
        >
          <v-flex xs4>
            <v-switch
              v-model="user.active"
              label="Active"
            />
          </v-flex>
          <v-flex xs10>
            <v-switch
              v-model="user.accepted_gdpr"
              label="Accepted GDPR"
            />
          </v-flex>
          <v-flex xs10>
            <v-switch
              v-model="user.accepted_terms"
              label="Accepted Terms & Conditions"
            />
          </v-flex>
        </v-layout>

        <v-form
          v-if="user && canChangeUserPassword"
          v-model="passwordChangeValid"
        >
          <v-container class="py-0">
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  v-model="user.password"
                  label="Change Password"
                  :rules="passwordRules"
                  :counter="30"
                  :append-icon="
                    (user.password && user.password.length)
                      ? showPwd
                        ? 'visibility_off'
                        : 'visibility'
                      : ''
                  "
                  :type="showPwd ? 'text' : 'password'"
                  @click:append="showPwd = !showPwd"
                />
              </v-col>
              <v-col class="py-0">
                <v-btn
                  class="mt-3 ml-2"
                  color="primary"
                  :disabled="!passwordChangeValid"
                  @click="changePassword"
                >
                  Change
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-layout>
          <v-flex
            xs12
            sm10
          >
            <v-expansion-panels
              focusable
              class="my-3 mx-2"
            >
              <Roles
                v-if="canViewEntitlements"
                :accounts="accounts"
                :ref-selected-roles="selectedRoles"
                :user="user"
                @selectedRolesChanged="updateSelectedRoles"
              />
              <Functions
                v-if="canViewEntitlements"
                :accounts="accounts"
                :ref-selected-functions="selectedFunctions"
                :user="user"
                @selectedFunctionsChanged="updateSelectedFunctions"
              />
              <Groups
                v-if="canViewGroups"
                :accounts="accounts"
                :user="user"
                @selectedGroupsChanged="updateSelectedGroups"
              />
            </v-expansion-panels>
          </v-flex>
        </v-layout>

        <v-layout
          v-if="canUpdateUser"
          class="mt-2"
        >
          <v-btn
            primary
            color="primary"
            @click="updateUser"
          >
            Save
          </v-btn>
          <v-btn
            class="ml-2"
            text
            @click.stop="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="primary py-1 white--text">
          Delete User
        </v-card-title>
        <v-card-text class="mt-3 pb-3">
          Do you really want to delete the user? This action cannot be revoked!
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteUser"
          >
            Yes, delete user
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';

import {
  BTL_API_ACCOUNT_URL, bizvu_user_url, PASSWORD_REGEX,
} from '@/config.js';

import Roles from '@/components/user/RolesPanel';
import Functions from '@/components/user/FunctionsPanel';
import Groups from '@/components/user/GroupsPanel';
import permittedFunction from '../../permittedFunction';

export default {
  name: 'User',
  components: {
    Roles,
    Functions,
    Groups,
  },
  data() {
    return {
      t1: 1,
      t2: 1,
      dialog: false,
      forbidden: false,
      serverError: false,
      showPwd: false,
      valid: false,
      userId: this.$route.params.userId,
      user: null,
      accounts: [],
      groupLoading: true,
      selectedGroups: [],
      selectedRoles: [],
      selectedFunctions: [],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => (v && v.length <= 80) || 'Address must not have more than 80 characters',
      ],
      passwordRules: [
        (v) => (v && new RegExp(PASSWORD_REGEX).test(v)) || 'Password must be 12 or more characters containing lower case, upper case, numeric and a special characters',
        (v) => !v || (v.length <= 30 && v.length >= 12) || 'Password must have between 12 and 30 characters',
      ],
      entitlementLevels: [
        { text: 'Tenant', value: 'Tenant' },
        { text: 'Account', value: 'Account' },
      ],
      dialogInitiator: '',
      selectAllDialog: false,
      setAllEntityType: 'tenant',
      setAllTenant: '',
      setAllAccount: '',
      passwordChangeValid: false,
    };
  },
  computed: {
    canUpdateUser() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/users/<user_id>', 'PUT');
    },
    canDeleteUser() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/users/<user_id>', 'DELETE');
    },
    canViewEntitlements() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/users/<user_id>/entitlements', 'GET');
    },
    canChangeUserPassword() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/users/<user_id>/password', 'PUT');
    },
    canViewGroups() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups', 'GET');
    },
    canChangeMaxEntitlementLevel() {
      return this.$store.getters.authentication.maxEntitlementLevel;
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  mounted() {
    // Load accounts, groups, and user data when component is mounted
    this.loadAccounts();
    this.loadGroups();
    this.loadUser();
  },
  methods: {
    loadAccounts() {
      // Load accounts if permitted
      if (!this.isPermitted('/tenants/<tenant_name>/accounts', 'GET')) {
        return;
      }
      const self = this;
      axios.get(BTL_API_ACCOUNT_URL)
        .then((response) => {
          // Sort accounts alphabetically
          self.accounts = sortBy(response.data, [(account) => account.name.toLowerCase()]);
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    updateSelectedRoles(updatedRoles) {
      // Update selected roles
      this.selectedRoles = updatedRoles.slice();
      this.user.updateEntitlements = true;
    },
    updateSelectedFunctions(functions) {
      // Update selected functions
      this.selectedFunctions = functions.slice();
      this.user.updateEntitlements = true;
    },
    updateSelectedGroups(groups) {
      // Update selected groups
      this.selectedGroups = groups.slice();
      this.user.updateGroups = true;
    },
    uppercaseFirst(string) {
      // Uppercase first letter of a string
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    changeSort(column, pagination) {
      // Change sorting column
      if (pagination.sortBy === column) {
        pagination.descending = !pagination.descending;
      } else {
        pagination.sortBy = column;
        pagination.descending = false;
      }
    },
    loadGroups() {
      // Load groups if permitted
      if (!this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups', 'GET')) {
        return;
      }
      const self = this;
      axios.get(`${bizvu_user_url}/account/${this.selectedAccountId}/group`)
        .then((response) => {
          self.groupLoading = false;
          self.groups = response.data;
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
    updateUser() {
      // Update user data
      const valid = this.$refs.form.validate();
      if (!valid) {
        // Validate form fields
        this.$emit('snack', 'Correct invalid fields before saving.', true);
        return;
      }
      this.user.groups = this.user.groups.map((g) => g.group_id);
      this.user.selectedGroups = this.selectedGroups;
      // Minimize payload before sending
      this.user.roles = this.selectedRoles.map((r) => ({
        role_id: r.role_id,
        entity_id: r.entity_id,
        entity_type: r.entity_type,
      }));
      this.user.functions = this.selectedFunctions.map((f) => ({
        function_id: f.function_id,
        entity_id: f.entity_id,
        entity_type: f.entity_type,
      }));
      this.user.entitlement.forEach((e) => {
        delete e.functions;
        delete e.resource;
        delete e.roles;
      });
      axios.put(`${bizvu_user_url}/account/${this.selectedAccountId}/user/${this.user.user_id}`, this.user)
        .then(() => {
          this.$emit('snack', 'User successfully updated!');
          this.$router.push('/users');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    deleteUser() {
      // Delete user
      axios.delete(`${bizvu_user_url}/account/${this.selectedAccountId}/user/${this.user.user_id}`)
        .then(() => {
          this.$emit('snack', 'User successfully deleted!');
          this.$router.push('/users');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    async loadUser() {
      // Load user data
      try {
        const response = await axios.get(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/users/${this.userId}`);
        const user = response.data;
        user.entitlement = [];

        if (this.canViewEntitlements) {
          // Load entitlements if permitted
          const entitlementsResponse = await axios.get(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/users/${this.userId}/entitlements`);
          user.entitlement = entitlementsResponse.data;
        }

        if (user.emails.length === 0) {
          // Add empty email if none exists
          user.emails.push({
            email: '',
            contact_me: true,
          });
        }
        this.user = user;
      } catch (e) {
        console.error('Failed to load user', e);
        if (e.response && e.response.status === 403) {
          this.forbidden = true;
        }
      }
    },
    async changePassword() {
      // Change user password
      try {
        const body = {
          password: this.user.password,
        };
        await axios.put(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/users/${this.user.user_id}/password`, body);
        this.$emit('snack', 'Password successfully changed!');
        this.$router.back();
      } catch (err) {
        console.error('Password Update Failed', err);
        this.$emit('snack', 'Password update failed', false);
      }
    },
    isPermitted(endpoint, verb, resources) {
      // Check permission for given endpoint and verb
      return permittedFunction(endpoint, verb, resources);
    },
  },
};

// Changes made:
// 1. Added comments for clarification throughout the code.
// 2. Replaced arrow functions with regular function declarations for clarity and consistency.
// 3. Reorganized method definitions for better readability.
// 4. Minor code formatting and style adjustments.
// 5. Ensured no functionality changes were made.
</script>
