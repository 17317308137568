<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
    @resizing="onResize"
  >
    <v-layout
      ref="container"
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
          style="margin-top: -5px;"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            Concurrent Calls
          </v-toolbar-title>
          <v-spacer />
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="300"
          >
            <template #activator="{on}">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-subheader class="mb-2">
                CDR Filter
              </v-subheader>
              <v-list-item>
                <v-layout
                  row
                  wrap
                  class="mb-4"
                >
                  <v-flex
                    xs12
                    lg6
                  >
                    <v-menu
                      ref="menud1"
                      v-model="menud1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{on}">
                        <v-text-field
                          v-model="startDateFormatted"
                          readonly
                          label="Start Date"
                          prepend-icon="event"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        @input="menud1 = false"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    lg6
                  >
                    <v-menu
                      ref="menud2"
                      v-model="menud2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{on}">
                        <v-text-field
                          v-model="endDateFormatted"
                          readonly
                          label="End Date"
                          prepend-icon="event"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        @input="menud2 = false"
                      />
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-list-item>
              <v-list-item>
                <v-layout
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-select
                      v-model="query.call_direction"
                      label="Direction"
                      required
                      :items="callDirections"
                    />
                  </v-flex>
                </v-layout>
              </v-list-item>
              <v-list-item>
                <v-layout
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-select
                      v-model="query.call_type"
                      label="Type"
                      required
                      :items="callTypes"
                    />
                  </v-flex>
                </v-layout>
              </v-list-item>
            </v-list>
            <v-card>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  @click="handleCancel"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="handleSave"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-btn
            icon
            @click="loadData"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <table-download
            :headers="columns"
            :table-data="records"
            report-name="Concurrent Calls"
            sheet-name="Concurrent Calls"
          />
        </v-toolbar>
      </v-container>
      <v-container style="background-color:#FFF;">
        <queryTable
          :records="records"
          :display-headers="columns"
          sort-by="call_datetime_iso"
          :sort-desc="true"
          :table-height="tableHeight"
          :loading="loading"
          :hide-selectors="true"
        />
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import ResizeContainer from '@/components/widgets/layout/ResizeContainer';
import queryTable from '@/components/query/components/queryTable/queryTable';
import tableDownload from '../download/tableDownload';

export default {
  name: 'Calldata',
  components: {
    queryTable,
    'resize-container': ResizeContainer,
    tableDownload,
  },
  props: {
    id: String,
    layoutProps: Object,
  },
  data() {
    return {
      startDate: moment().toISOString().substr(0, 10),
      endDate: moment().toISOString().substr(0, 10),
      startDateFormatted: moment().format(config.date_format),
      endDateFormatted: moment().format(config.date_format),
      menud1: false,
      menud2: false,
      menu: false,
      loading: false,
      query: {
        call_direction: 'OUTGOING',
        call_type: 'ALL',
      },
      ESquery: {
        size: 500,
        sort: [
          { call_datetime_iso: { order: 'desc' } },
          '_score',
        ],
        query: {
          bool: {
            must: [],
            filter: {
              range: { call_datetime_iso: { gte: '2018-10-25', lte: '2018-10-25' } },
            },
          },
        },
      },
      records: [],
      columns: [
        { text: 'Source', value: 'source_e164' },
        { text: 'Destination', value: 'dst_e164' },
      ],
      callDirections: [
        { text: 'All', value: 'ALL' },
        { text: 'Incoming', value: 'INCOMING' },
        { text: 'Outgoing', value: 'OUTGOING' },
      ],
      callTypes: [
        { text: 'All', value: 'ALL' },
        { text: 'National', value: 'NATIONAL' },
        { text: 'International', value: 'INTERNATIONAL' },
        { text: 'Mobile', value: 'MOBILE' },
        { text: 'Shared Cost', value: 'SHARED_COST' },
      ],
      tableHeight: 0,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'authentication',
      'timeZone',
    ]),
  },
  watch: {
    authentication: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    endDate() {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },
  created() {
    this.handleSave();
    this.loadData();
  },
  mounted() {
    this.tableHeight = this.layoutProps.h - 100;
  },
  methods: {
    handleSave() {
      this.ESquery.query.bool.must = [];
      if (this.query.call_direction !== 'ALL') {
        this.ESquery.query.bool.must.push({ match: { call_direction: this.query.call_direction } });
      }
      if (this.query.call_type !== 'ALL') {
        this.ESquery.query.bool.must.push({ match: { call_type: this.query.call_type } });
      }
      this.ESquery.query.bool.filter.range.call_datetime_iso.gte = moment(this.startDate).tz(this.timeZone).startOf('day').toISOString();
      this.ESquery.query.bool.filter.range.call_datetime_iso.lte = moment(this.endDate).tz(this.timeZone).endOf('day').toISOString();
      this.menu = false;
      this.addDirectionColumns();
      this.loadData();
    },
    addDirectionColumns() {
      const direction = this.query.call_direction;
      let directionColumns;
      if (direction !== 'ALL') {
        const callDirection = direction === 'INCOMING' ? 'destination' : 'source';
        directionColumns = [
          { text: 'Incoming', value: `cc_${callDirection}_in` },
          { text: 'Outgoing', value: `cc_${callDirection}_out` },
          { text: 'All', value: `cc_${callDirection}_all` },
        ];
      } else {
        directionColumns = [
          { text: 'Incoming All', value: 'cc_destination_all' },
          { text: 'Outgoing All', value: 'cc_source_all' },
        ];
      }
      this.columns = this.columns.slice(0, 2);
      this.columns = this.columns.concat(directionColumns);
    },
    handleCancel() {
      this.menu = false;
    },
    loadData() {
      this.loading = true;
      axios.post(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/analytics`, this.ESquery)
        .then((response) => {
          const recs = [];
          response.data.hits.hits.forEach(({ _source: src }) => {
            if (src.cc_source_in || src.cc_source_out || src.cc_destination_in || src.cc_destination_out) {
              recs.push(src);
            }
          });
          this.records = recs;
          this.loading = false;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format(config.date_format);
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
    onResize() {
      this.tableHeight = this.$refs.container.clientHeight - 100;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
