<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Rate
      </v-toolbar-title>
      <codes-lookup
        v-if="!createMode && !rateAccountID"
        rate-name="country.CountryName"
        header="country"
        api-path="internationals"
        filter-name="CountryId"
        :filter-value="countryID"
        redirect-path="internationalcodes"
      />
      <v-btn
        v-if="!createMode && isPermitted('/countries/<countryid>', 'DELETE', true)"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col cols="6">
            <v-layout v-if="rateAccountID && !createMode">
              <v-flex>
                <v-text-field
                  readonly
                  label="Account"
                  :value="rate.account"
                />
              </v-flex>
            </v-layout>
            <v-layout v-if="createMode">
              <v-flex>
                <v-select
                  v-model="localSelectedAccountId"
                  flat
                  :items="accounts"
                  item-text="name"
                  item-value="account_id"
                  label="Account"
                />
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-layout v-if="!createMode || createMode && !localSelectedAccountId">
              <v-flex>
                <v-text-field
                  v-model="rate.CountryName"
                  :readonly="!createMode"
                  label="Country"
                  :rules="CountryNameRules"
                />
              </v-flex>
            </v-layout>
            <v-layout v-if="createMode && localSelectedAccountId">
              <v-flex>
                <v-select
                  v-model="rate.CountryName"
                  flat
                  :items="countries"
                  item-text="CountryName"
                  label="Country"
                  :rules="CountryNameRules"
                />
              </v-flex>
            </v-layout>
          </v-col>
          <v-col>
            <v-layout v-if="!createMode">
              <v-flex>
                <v-text-field
                  v-model="rate.CodeCount"
                  readonly
                  label="Destination Count"
                />
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-layout>
              <v-flex>
                <v-select
                  v-model="rate.Carrier"
                  flat
                  :items="carriers"
                  :rules="CarrierRules"
                  item-text="name"
                  item-value="name"
                  label="Carrier"
                />
              </v-flex>
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="rate.Description"
                label="Description"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="rate.CostDayRate"
                  label="Cost Day Rate"
                  :rules="CostDayRateRules
                  "
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="rate.CostEveRate"
                  label="Cost Evening Rate"
                  :rules="CostEveRateRules
                  "
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="rate.CostWendRate"
                  label="Cost Weekend Rate"
                  :rules="CostWendRateRules
                  "
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="rate.CostConFee"
                  label="Cost Connection Fee"
                  :rules="CostConFee
                  "
                />
              </v-flex>
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="rate.SellDayRate"
                  label="Sell Day Rate"
                  :rules="SellDayRateRules
                  "
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="rate.SellEveRate"
                  label="Sell Evening Rate"
                  :rules="SellEveRateRules
                  "
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="rate.SellWendRate"
                  label="Sell Weekend Rate"
                  :rules="SellWendRateRules
                  "
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="rate.SellConFee"
                  label="Sell Connection Fee"
                  :rules="SellConFee
                  "
                />
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-layout>
          <v-flex class="pa-2">
            <v-row>
              <v-btn
                v-if="isPermitted('/countries/<countryid>', 'PUT', true)"
                primary
                color="primary"
                dark
                @click="updateRate"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this rate?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteRate"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';

import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';
import CodesLookup from '@/components/billing/CodesLookup';

export default {
  name: 'Internationalrate',
  components: {
    CodesLookup,
  },
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      countryID: this.$route.params.countryID,
      rateAccountID: this.$route.params.accountID,
      rate: {},
      accounts: [],
      countries: [],
      localSelectedAccountId: null,
      carriers: [],
      CarrierRules: [
        (v) => !!v || 'Carrier is required',
      ],
      CountryNameRules: [
        (v) => !!v || 'Country is required',
      ],
      CostEveRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Cost Evening Rate is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
      SellEveRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Sell Evening Rate is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
      CostDayRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Cost Day Rate is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
      CostWendRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Cost Weekend Rate is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
      CostConFee: [
        (v) => (v != null && String(v).length > 0) || 'Cost Connection Fee is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
      SellDayRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Sell Day Rate is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
      SellWendRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Sell Weekend Rate is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
      SellConFee: [
        (v) => (v != null && String(v).length > 0) || 'Sell Connection Fee is required',
        (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
      ],
    };
  },
  created() {
    this.loadCarriers();
    if (this.countryID === 'new') {
      this.createMode = true;
      this.loadAccounts();
      this.loadCountries();
    } else {
      this.loadRate();
    }
  },
  methods: {
    async updateRate() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Rate successfully updated.';
        if (this.createMode) {
          if (this.localSelectedAccountId) {
            await axios.post(`${config.BTL_API_ACCOUNT_URL}/${this.localSelectedAccountId}/countries`, this.rate);
          } else {
            await axios.post(`${config.BTL_API_TENANT_URL}/countries`, this.rate);
          }
          snackMessage = 'Rate successfully created.';
        } else if (this.rateAccountID) {
          await axios.put(`${config.BTL_API_ACCOUNT_URL}/${this.rateAccountID}/countries/${this.countryID}`, this.rate);
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/countries/${this.countryID}`, this.rate);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating rate', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} rate.`, true);
      }
    },
    async loadRate() {
      try {
        if (this.rateAccountID) {
          const account = (await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.rateAccountID}`)).data;
          const rate = (await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.rateAccountID}/countries/${this.countryID}`)).data;
          rate.account = account.name;
          this.rate = rate;
        } else {
          const rate = (await axios.get(`${config.BTL_API_TENANT_URL}/countries/${this.countryID}`)).data;
          this.rate = rate;
        }
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve rate.', true);
        console.error('Failed to retrieve rate', error);
      }
    },
    async deleteRate() {
      try {
        if (this.rateAccountID) {
          await axios.delete(`${config.BTL_API_ACCOUNT_URL}/${this.rateAccountID}/countries/${this.countryID}`);
        } else {
          await axios.delete(`${config.BTL_API_TENANT_URL}/countries/${this.countryID}`);
        }
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete rate.', true);
        console.error('Failed to delete rate', error);
      }
    },
    async loadAccounts() {
      try {
        const { data } = await axios.get(config.BTL_API_ACCOUNT_URL);
        this.accounts = sortBy(data, (account) => account.name.toLowerCase());
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve accounts.', true);
        console.error('Failed to retrieve accounts', error);
      }
    },
    async loadCountries() {
      try {
        const { data } = await axios.get(`${config.BTL_API_TENANT_URL}/countries`);
        this.countries = sortBy(data, [(country) => country.CountryName.toLowerCase()]);
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve countries.', true);
        console.error('Failed to retrieve countries', error);
      }
    },
    async loadCarriers() {
      try {
        const { data } = await axios.get(`${config.bizvu_billing_catalog_url}/carriers`);
        this.carriers = data;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve carriers.', true);
        console.error('Failed to retrieve carriers', error);
      }
    },
    isPermitted(endpoint, verb, accountOrTenant = false) {
      const prefixedEndpoint = ((accountOrTenant && this.rateAccountID) ? '/tenants/<tenant_name>/accounts/<account_id>' : '/tenants/<tenant_name>') + endpoint;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
