<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create account
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="mb-4">
          <v-flex xs4>
            <v-text-field
              ref="AccountName"
              v-model="account.name"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="mb-4">
          <v-flex xs2>
            <v-text-field
              v-model="pcLookup"
              label="Postcode"
              :counter="10"
            />
          </v-flex>
          <v-flex
            xs2
            class="ml-3"
          >
            <v-btn
              class="mt-3"
              color="primary"
              text
              @click="findAddresses"
            >
              Find Address
            </v-btn>
          </v-flex>
          <v-flex
            v-if="addresses && addresses.addresses.length>0"
            xs8
            class="ml-3"
          >
            <v-select
              v-model="selectedAddress"
              flat
              :items="addresses.addresses"
              item-text="addressString"
              item-value="key"
              label="Select Street Address"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs10>
            <v-text-field
              v-model="account.Address1"
              label="Street address 1"
              :rules="address1Rules"
              :counter="120"
              required
            />
            <v-text-field
              v-model="account.Address2"
              label="Street address 2"
              :rules="address2Rules"
              :counter="120"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs5>
            <v-layout>
              <v-text-field
                v-model="account.City"
                label="City"
                :rules="cityRules"
                :counter="60"
                required
              />
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout>
              <v-text-field
                v-model="account.PostCode"
                label="Postcode"
                class="ml-2"
                :rules="postcodeRules"
                :counter="10"
                required
              />
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout>
              <v-text-field
                v-model="account.Country"
                label="Country"
                class="ml-2"
                :rules="countryRules"
                :counter="30"
                required
              />
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs4>
            <v-text-field
              v-model="account.MainNumber"
              label="Main Number"
              :rules="mainnumberRules"
              :counter="15"
              required
            />
            <v-text-field
              v-model="account.ContactName"
              label="Contact Name"
              :rules="contactnameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs4>
            <v-text-field
              v-model="account.RTPCaptureTargets"
              label="RTP Capture Targets"
              :rules="RTPCaptureTargets"
            />
          </v-flex>
          <v-flex xs4>
            <DatePicker
              v-model="account.RTPCaptureExpiry"
              label="RTP Capture Expiry"
              class="ml-8"
              clearable
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs4>
            <v-switch
              v-model="account.State"
              :label="account.State ? 'ACTIVE' : 'DISABLED'"
            />
          </v-flex>
        </v-layout>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createAccount"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.push('/accounts')"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import config from '@/config.js';

import DatePicker from '@/components/DatePicker.vue';
import moment from 'moment-timezone';

const isValidJson = (v) => {
  try {
    JSON.parse(v);
    return true;
  } catch (e) {
    return false;
  }
};

export default {
  name: 'Newaccount',
  components: {
    DatePicker,
  },
  data() {
    return {
      valid: false,
      selectedAddress: null,
      addresses: null,
      pcLookup: '',
      account: {
        name: '',
        Address2: '',
        State: true,
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      address1Rules: [
        (v) => !!v || 'Street address 1 is required',
        (v) => (v && v.length <= 120) || 'Street adress must not have more than 120 characters',
      ],
      address2Rules: [
        (v) => (v.length <= 120) || 'Adress must not have more than 120 characters',
      ],
      cityRules: [
        (v) => !!v || 'City is required',
        (v) => (v && v.length <= 60) || 'City must not have more than 60 characters',
      ],
      postcodeRules: [
        (v) => !!v || 'Post code is required',
        (v) => (v && v.length <= 10) || 'Post code must not have more than 10 characters',
      ],
      countryRules: [
        (v) => !!v || 'Country is required',
        (v) => (v && v.length <= 30) || 'Country must not have more than 30 characters',
      ],
      mainnumberRules: [
        (v) => !!v || 'Main numer is required',
        (v) => (v && v.length <= 15) || 'Main number must not have more than 15 characters',
      ],
      contactnameRules: [
        (v) => !!v || 'Contact name is required',
        (v) => (v && v.length <= 60) || 'Contact name must not have more than 60 characters',
      ],
      RTPCaptureTargets: [
        (v) => (v?.length === 0 || isValidJson(v)) || 'RTP Capture Targets must be valid json',
      ],
    };
  },
  watch: {
    selectedAddress() {
      this.account.Address1 = this.addresses.addresses[this.selectedAddress].line_1;
      this.account.Address2 = this.addresses.addresses[this.selectedAddress].line_2;
      this.account.City = this.addresses.addresses[this.selectedAddress].town_or_city;
      this.account.Country = this.addresses.addresses[this.selectedAddress].country;
      this.account.PostCode = this.addresses.postcode;

      if (this.addresses.addresses[this.selectedAddress].locality !== '') {
        this.account.Address2 = this.addresses.addresses[this.selectedAddress].locality;
      }
    },
    'account.RTPCaptureTargets': function (newVal, oldVal) {
      if (!oldVal) {
        this.account.RTPCaptureExpiry = moment().add(14, 'days').toISOString();
      }
    },
  },
  mounted() {
    this.$refs.AccountName.focus();
  },
  methods: {
    findAddresses() {
      axios.get(`${config.getaddress_url}/${this.pcLookup}?expand=true&api-key=${config.getaddress_api_key}`)
        .then((response) => {
          this.addresses = response.data;
          this.addresses.addresses.forEach((a, i) => {
            a.addressString = `${a.formatted_address[0]}, ${a.formatted_address[3]}, ${a.formatted_address[4]}`;
            a.key = i;
          });
        })
        .catch(() => {
          this.$emit('snack', 'Invalid Postcode!');
          this.pcLookup = '';
          this.addresses = null;
        });
    },
    createAccount() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }

      if (this.account.State) {
        this.account.State = 'ACTIVE';
      } else {
        this.account.State = 'DISABLED';
      }
      axios.post(config.BTL_API_ACCOUNT_URL, this.account)
        .then(() => {
          this.$emit('newAccount');
          this.$emit('snack', 'Account successfully created!');
          this.$router.push('/accounts');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
  },
};
</script>

<style scoped>
</style>
