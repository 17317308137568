<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} LCR Prefix
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/lcrprefixes/<prefix_id>', 'DELETE', true)"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col xs12>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model="payload.Prefix"
                  label="Prefix"
                  :rules="PrefixRules
                  "
                />
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0">
              <v-col
                sm="12"
                md="6"
              >
                <v-select
                  v-model="payload.ProviderPrefix"
                  flat
                  :items="providers"
                  item-value="ProviderPrefix"
                  item-text="ProviderPrefix"
                  label="Provider"
                  :rules="ProviderPrefixRules"
                />
              </v-col>
              <v-col
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="payload.Priority"
                  label="Priority"
                  type="number"
                  :rules="PriorityRules
                  "
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model="payload.Notes"
                  label="Notes"
                  counter="80"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-2">
            <v-btn
              v-if="isPermitted('/lcrprefixes/<prefix_id>', 'PUT', true)"
              primary
              color="primary"
              dark
              @click="updatePrefix"
            >
              Save
            </v-btn>
            <v-btn
              class="ml-2"
              @click="$router.back()"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this prefix?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="error"
            @click="deletePrefix"
          >
            <v-icon left>
              delete
            </v-icon>
            Confirm
          </v-btn>
          <v-btn
            color="secondary"
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'Prefix',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      payload: {
        Prefix: null,
        ProviderPrefix: null,
        Priority: null,
      },
      providers: [],
      valid: false,
      prefixID: this.$route.params.prefixID,
      accounts: [],
      PrefixRules: [
        (v) => !!v || 'Prefix is required',
      ],
      ProviderPrefixRules: [
        (v) => !!v || 'Provider prefix is required',
      ],
      ShortCodeRules: [
        (v) => !!v || 'Short code is required',
      ],
      PriorityRules: [
        (v) => !!v || 'Priority is required',
      ],
    };
  },
  created() {
    this.loadPoviders();
    if (this.prefixID === 'new') {
      this.createMode = true;
    } else {
      this.loadPrefix();
    }
  },
  methods: {
    async updatePrefix() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Prefix successfully updated.';

        if (this.createMode) {
          await axios.post(`${config.BTL_API_TENANT_URL}/lcrprefixes`, this.payload);
          snackMessage = 'Prefix successfully created.';
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/lcrprefixes/${this.prefixID}`, this.payload);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating prefix', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} prefix.`, true);
      }
    },
    async loadPoviders() {
      const response = await axios.get(
        `${config.BTL_API_TENANT_URL}/providers`,
      );
      this.providers = response.data;
    },
    async loadPrefix() {
      try {
        const { data: prefix } = await axios.get(`${config.BTL_API_TENANT_URL}/lcrprefixes/${encodeURIComponent(this.prefixID)}`);
        this.payload = prefix;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve prefix.', true);
        console.error('Failed to retrieve prefix.', error);
      }
    },
    async deletePrefix() {
      try {
        await axios.delete(`${config.BTL_API_TENANT_URL}/lcrprefixes/${this.prefixID}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete prefix.', true);
        console.error('Failed to delete prefix', error);
      }
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
