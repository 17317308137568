<template>
  <div>
    <page-tool-bar title="Sms Messages" />

    <div class="d-flex justify-center mx-1">
      <v-text-field
        v-model="source"
        style="max-width: 18rem"
        class="mx-3"
        label="Source"
        prepend-icon="phone"
        clearable
        @input="search"
      />
      <v-text-field
        v-model="destination"
        style="max-width: 18rem"
        label="Destination"
        class="mx-3"
        prepend-icon="phone"
        clearable
        @input="search"
      />
      <div style="max-width: 18rem">
        <date-range
          v-model="dateFilter"
          @input="search"
        />
      </div>
      <v-btn
        text
        color="green"
        class="ml-3 mt-3"
        @click="search"
      >
        <v-icon left>
          refresh
        </v-icon>
        Refresh
      </v-btn>
      <TableDownload
        color="primary"
        class="ml-3 mt-3"
        :table-data="messages"
        report-name="messages"
        :headers="downloadHeaders"
      />
    </div>

    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            must-sort
            :items="messages"
            :sort-by="sort"
            :sort-desc="sortDescending"
            :loading="searching"
            :items-per-page="pageSize"
            :server-items-length="totalMessages"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 275px)"
            @update:options="optionsUpdated"
          >
            <template #item.ClientSubmitTime="{ item }">
              {{ formatLongDateTime(item.ClientSubmitTime) }}
            </template>
            <template #item.DeliveredTime="{ item }">
              {{ item.DeliveredTime ? formatLongDateTime(item.DeliveredTime) : '' }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import axios from 'axios';
import moment from 'moment';

import { BTL_API_ACCOUNT_URL } from '@/config';
import { useStore } from '@/utils/vue';
import { formatLongDateTime } from '@/utils/DateTimeFormatter';

import PageToolBar from '@/components/PageToolbar';
import DateRange from '@/components/DateRange';
import TableDownload from '@/components/download/tableDownload';

const store = useStore();

const selectAccountId = computed(() => store.getters.selectedAccountId);

const page = ref(1);
const pageSize = ref(100);
const sort = ref('ClientSubmitTime');
const sortDescending = ref(true);

const dateFilter = ref({ start: null, end: null });

const destination = ref(null);
const source = ref(null);

const headers = [
  { text: 'Status', value: 'MessageStatus' },
  { text: 'Message', value: 'Message' },
  { text: 'Source', value: 'Source' },
  { text: 'Destination', value: 'Destination' },
  { text: 'Client Submit Time', value: 'ClientSubmitTime' },
  { text: 'Delivered', value: 'DeliveredTime' },
];

const downloadHeaders = [
  { text: 'Message Id', value: 'MessageId' },
  { text: 'Conversation Id', value: 'ConversationId' },
  { text: 'Status', value: 'MessageStatus' },
  { text: 'Message', value: 'Message' },
  { text: 'Source', value: 'Source' },
  { text: 'Destination', value: 'Destination' },
  { text: 'Client Submit Time', value: 'ClientSubmitTime' },
  { text: 'Delivered', value: 'DeliveredTime' },
];

const messages = ref([]);
const totalMessages = ref(0);

const searching = ref(false);
let controller = null;

const search = async () => {
  searching.value = true;
  try {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    const { data } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/messages`, {
      params: {
        Page: page.value,
        PageSize: pageSize.value,
        SortField: sort.value,
        SortDir: sortDescending.value ? -1 : 1,
        Source: source.value ? source.value : undefined,
        Destination: destination.value ? destination.value : undefined,
        To: dateFilter.value.end ? moment(dateFilter.value.end).toISOString() : undefined,
        From: dateFilter.value.start ? moment(dateFilter.value.start).toISOString() : undefined,
      },
      signal: controller.signal,
    });
    controller = null;

    messages.value = data.results;
    page.value = data.page;
    totalMessages.value = data.total;
  } catch (err) {
    if (err.message === 'canceled') return;
    console.error('ERROR', err);
  } finally {
    searching.value = false;
  }
};

const optionsUpdated = (tableOptions) => {
  page.value = tableOptions.page;
  pageSize.value = tableOptions.itemsPerPage;
  sort.value = tableOptions.sortBy[0];
  sortDescending.value = tableOptions.sortDesc[0];
  search();
};

onMounted(search);
</script>
