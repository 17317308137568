<template>
  <v-container>
    <node-header
      icon="import_export"
      :title="name"
      :detail="apiEndpoint.url"
      :color-a="routeConfig.defaultAction ==='none'"
      :color-b="routeConfig.failoverAction ==='none'"
      chip-a="NEXT"
      chip-b="FAILOVER"
    />

    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="primary"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              edit
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Configure name</v-subheader>
          <v-list-item>
            <v-text-field
              v-model="name"
              label="Node Name"
            />
          </v-list-item>
          <v-subheader>Configure Dynamic Route</v-subheader>
          <v-list-item>
            <v-select
              v-model="trunkId"
              label="Trunk"
              required
              :items="trunks"
              item-text="Description"
              item-value="TrunkID"
            />
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="apiEndpointId"
              label="API Endpoint"
              required
              :items="apiEndpoints"
              item-text="text"
              item-value="id"
            />
          </v-list-item>
          <v-list-item class="mb-2">
            <v-text-field
              v-model="ringTimeout"
              type="number"
              label="Ring Timeout (s)"
              :rules="ringTimeoutRules"
              required
              counter="2"
            />
          </v-list-item>
          <v-list-item class="mb-2">
            <v-switch
              v-model="sendRequestIfAnon"
              label="Send Request if Anonymous"
            />
          </v-list-item>
          <v-subheader>Add next step</v-subheader>
          <v-list-item class="mb-2">
            <v-select
              v-model="defaultAction"
              label="NEXT"
              :items="actions"
            />
          </v-list-item>
          <v-list-item class="mb-2">
            <v-select
              v-model="failoverAction"
              label="On Failover"
              :items="actions"
            />
          </v-list-item>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="handleCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="menu_delete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleDelete"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';

import config from '@/config.js';

import NodeHeader from './partials/Header';
import { typesForSelect } from './nodeTypes';

export default {
  name: 'DynamicDialTrunk',
  components: {
    NodeHeader,
  },
  props: {
    node: Object,
    dialplan: Array,
    canedit: Boolean,
  },
  data: () => ({
    loading: false,
    valid: false,
    menu: false,
    menu_delete: false,
    name: null,
    width: 0,
    height: 0,
    defaultAction: 'none',
    failoverAction: 'none',
    actions: typesForSelect.filter((item) => item.value !== 'CheckDCL'),

    routeConfig: {
      defaultAction: 'none',
      failoverAction: 'none',
    },
    apiEndpoints: [],
    apiEndpointId: null,
    apiEndpoint: {},

    trunks: [],
    trunkId: null,
    sendRequestIfAnon: false,

    ringTimeout: 10,
    ringTimeoutRules: [
      (v) => !!v || 'Timeout is required',
      (v) => (v && v < 31) || 'Timeout must be less or equal to 30',
    ],
  }),
  computed: {
    isApiVersionCurrent() {
      const apiVersion = this.apiEndpoint.version;
      const currentApiVersion = this.node.parameters.endpointVersion;
      return !(apiVersion && currentApiVersion && currentApiVersion < apiVersion);
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    apiEndpointId() {
      this.loadApiEndpoint();
    },
  },
  mounted() {
    this.loadApiEndpoints();
    this.loadTrunks();
    this.name = this.node.name;
    this.apiEndpointId = this.node.parameters.endpointId;
    this.sendRequestIfAnon = this.node.parameters.sendRequestIfAnon;
    this.failoverAction = this.getLabel(this.node.conditions.DIALSTATUS.FAILOVER);
    this.routeConfig.failoverAction = this.getLabel(this.node.conditions.DIALSTATUS.FAILOVER);
    this.defaultAction = this.getLabel(this.node.next);
    this.routeConfig.defaultAction = this.getLabel(this.node.next);
  },
  methods: {
    getLabel(nodeId) {
      const node = this.dialplan.find((n) => n.id === nodeId);
      if (node) {
        return node.action;
      }
      return 'none';
    },
    handleDelete() {
      this.$emit('deletenode');
    },
    handleSave() {
      this.menu = false;

      this.node.name = this.name;
      this.node.parameters.endpointId = this.apiEndpoint.id;
      this.node.parameters.endpointVersion = this.apiEndpoint.version;
      this.node.parameters.endpointUrl = this.apiEndpoint.url;
      this.node.parameters.endpointAuthType = this.apiEndpoint.authType;
      this.node.parameters.endpointUserName = this.apiEndpoint.username;
      this.node.parameters.endpointPassword = this.apiEndpoint.password;
      this.node.parameters.endpointApiKey = this.apiEndpoint.apiKey;
      this.node.parameters.endpointTimeout = this.apiEndpoint.timeoutPeriod;
      this.node.parameters.ringTimeout = Number(this.ringTimeout);
      this.node.parameters.trunkid = this.trunkId;
      this.node.parameters.sendRequestIfAnon = this.sendRequestIfAnon;

      this.node.routeConfig = this.routeConfig;
      if (this.routeConfig.defaultAction !== this.defaultAction) {
        this.routeConfig.defaultAction = this.defaultAction;
        if (this.defaultAction === 'none') {
          this.node.next = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.defaultAction, 'NEXT');
        }
      }
      if (this.routeConfig.failoverAction !== this.failoverAction) {
        this.routeConfig.failoverAction = this.failoverAction;
        if (this.failoverAction === 'none') {
          this.node.conditions.DIALSTATUS.False = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.failoverAction, 'FAILOVER');
        }
      }
    },
    handleCancel() {
      this.menu = false;
      this.apiEndpointId = this.node.parameters.endpointId;
      this.name = this.node.name;
    },
    loadApiEndpoints() {
      this.loading = true;
      axios.get(`${config.bizvu_3rd_party_endpoint_url}/account/${this.selectedAccountId}/endpoint`)
        .then((response) => {
          this.apiEndpoints = sortBy(response.data
            .filter((endpoint) => endpoint.type === 'callflowAction')
            .map((endpoint) => ({ ...endpoint, text: `${endpoint.name ? `${endpoint.name} - ` : ''}${endpoint.url}` })), (endpoint) => endpoint.text.toLowerCase());
        })
        .catch((error) => {
          console.error('Could not fetch api endpoints', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadApiEndpoint() {
      if (!this.apiEndpointId) {
        return;
      }
      axios.get(`${config.bizvu_3rd_party_endpoint_url}/account/${this.selectedAccountId}/endpoint/${this.apiEndpointId}`)
        .then((response) => {
          this.apiEndpoint = response.data;
        })
        .catch((error) => {
          console.error('Could not fetch api endpoint', error);
        });
    },
    loadTrunks() {
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/trunks`)
        .then((response) => {
          this.trunks = sortBy(response.data, (trunk) => trunk.Description.toLowerCase());
          if (this.node.parameters && this.node.parameters.trunkid) {
            this.trunkId = this.node.parameters.trunkid;
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
  },
};
</script>
