<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Account Services
      </v-toolbar-title>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-container pa-0>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(service,i) in combinedServices"
              :key="i"
              :readonly="!service.accountService"
            >
              <v-expansion-panel-header :hide-actions="!service.accountService">
                <div style="max-width: 15rem">
                  {{ service.Name }}
                </div>
                <div style="max-width: 22px">
                  <v-switch
                    dense
                    :disabled="service.accountService ? !canDisableService : !canEnableService"
                    color="green"
                    inset
                    hide-details
                    class="mt-0"
                    :input-value="service.accountService"
                    readonly
                    :loading="service.enabling"
                    @click.stop="service.accountService ? disableService(service) : enableService(service)"
                  />
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="service.accountService">
                <v-toolbar
                  rounded
                  flat
                  outlined
                  primary
                >
                  Service Charges
                </v-toolbar>
                <v-data-table
                  :items="service.combinedCharges"
                  :headers="headers"
                  :footer-props="{
                    itemsPerPageOptions: 50,
                    itemsPerPageOptions: [50, 100, 250, -1]
                  }"
                >
                  <template #item.UnitCost="{ item }">
                    {{ item.UnitCost | formatMoney }}
                  </template>
                  <template #item.actions="{ item: charge }">
                    <v-btn
                      v-if="charge.isAccountServiceCharge ? canUpdateAccountServiceCharge : canCreateAccountServiceCharge"
                      icon
                      @click="openAccountServiceDialogue(service, charge)"
                    >
                      <v-icon>
                        edit
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="charge.isAccountServiceCharge && canDeleteAccountServiceCharge"
                      icon
                      color="error"
                      :loading="charge.deleting"
                      @click="deleteServiceCharge(service, charge)"
                    >
                      <v-icon>
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="accountServiceDialogue"
      max-width="25vw"
    >
      <v-card>
        <v-card-title>{{ editAccountServiceChargeId ? 'Edit' : 'Add' }} Account Service Charge</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="serviceChargeForm"
            class="px-2"
            lazy-validation
          >
            <v-container>
              <v-row v-if="accountServiceChargeErrors">
                <v-col>
                  <v-alert
                    v-for=" [field, errors] in Object.entries(accountServiceChargeErrors)"
                    :key="field"
                    border="left"
                    dense
                    type="error"
                  >
                    <b>{{ field }}</b> {{ errors.join('. ') }}
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="accountServiceCharge.Units"
                    label="Units"
                    :rules="rules.Units"
                  />
                  <v-text-field
                    v-model="accountServiceCharge.UnitCost"
                    label="Unit Cost"
                    :rules="rules.UnitCost"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="savingAccountServiceCharge"
            @click="saveAccountServiceCharge"
          >
            Save
          </v-btn>
          <v-btn @click="accountServiceDialogue = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { find, pick } from 'lodash';
import axios from 'axios';
import { mapGetters } from 'vuex';

import { BTL_API_ACCOUNT_URL, BTL_API_TENANT_URL } from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'AccountServices',
  filters: {
    formatMoney(value) {
      return currencyFormatter(value);
    },
  },
  data() {
    return {
      loading: false,
      services: [],
      accountServices: [],
      combinedServices: [],
      headers: [
        { text: 'Name', value: 'Name' },
        { text: 'Description', value: 'Description' },
        { text: 'Unit Type', value: 'UnitType' },
        { text: 'Units', value: 'Units' },
        { text: 'Unit Cost', value: 'UnitCost' },
        { text: 'Total Cost', value: 'TotalCost' },
        { text: 'Charge Frequency', value: 'ChargeFrequency' },
        { text: '', value: 'actions' },
      ],

      accountServiceDialogue: false,
      serviceChargeForm: false,
      accountServiceCharge: {},
      accountServiceChargeErrors: [],
      editAccountServiceChargeId: false,
      savingAccountServiceCharge: false,
      rules: {
        Units: [
          (v) => (v != null && String(v).length > 0) || 'Units are required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        UnitCost: [
          (v) => (v != null && String(v).length > 0) || 'Unit Cost is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
    canEnableService() {
      return permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/accountservices', 'POST');
    },
    canDisableService() {
      return permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/accountservices/<accountservice_id>', 'DELETE');
    },
    canCreateAccountServiceCharge() {
      return permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/accountservices/<accountservice_id>/charges', 'POST');
    },
    canDeleteAccountServiceCharge() {
      return permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/accountservices/<accountservice_id>/charges/<asc_id>', 'DELETE');
    },
    canUpdateAccountServiceCharge() {
      return permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/accountservices/<accountservice_id>/charges/<asc_id>', 'PUT');
    },
  },
  watch: {
    selectedAccountId() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        await this.loadServices();
        await this.loadAccountServices();
        this.combinedServices = this.services.map((service) => {
          const accountService = find(this.accountServices, { ServiceId: service.service_id });

          let combinedCharges = [];
          if (accountService) {
            combinedCharges = [
              ...accountService.accountservicecharges.map((charge) => ({
                ...charge,
                isAccountServiceCharge: true,
                deleting: false,
              })),
              ...accountService.service.servicecharges.map((charge) => ({
                ...charge,
                isAccountServiceCharge: false,
              })),
            ];
          }

          return {
            ...service,
            enabling: false,
            accountService,
            combinedCharges,
          };
        });
      } finally {
        this.loading = false;
      }
    },
    async loadAccountServices() {
      try {
        const response = await axios.get(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountservices`);
        this.accountServices = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve account services.', true);
      }
    },
    async loadServices() {
      try {
        const { data: services } = await axios.get(`${BTL_API_TENANT_URL}/services`);
        this.services = services;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve services.', true);
      }
    },
    async enableService(service) {
      try {
        service.enabling = true;
        const serviceToEnable = {
          ServiceId: service.service_id,
          ServiceStartDate: new Date().toJSON(),
        };
        await axios.post(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountservices`, serviceToEnable);
        await this.loadData();
        this.$emit('snack', 'Service Enabled', false);
      } catch (err) {
        service.enabling = false;
        console.error(err);
        this.$emit('snack', 'Failed to enable Service', true);
      }
    },
    async disableService(service) {
      try {
        service.enabling = true;
        await axios.delete(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountservices/${service.accountService.accountservice_id}`);
        await this.loadData();
        this.$emit('snack', 'Service Disabled', false);
      } catch (err) {
        service.enabling = false;
        console.error(err);
        this.$emit('snack', 'Failed to disable Service', true);
      }
    },
    async deleteServiceCharge(service, charge) {
      try {
        charge.deleting = true;
        await axios.delete(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountservices/${service.accountservice_id}/charges/${charge.asc_id}`);
        await this.loadData();
        this.$emit('snack', 'Service Charge Deleted', false);
      } catch (err) {
        console.error(err);
        this.$emit('snack', 'Failed to Delete Service Charge', true);
      }
    },
    openAccountServiceDialogue(service, charge) {
      this.accountServiceDialogue = true;
      this.accountServiceChargeErrors = null;
      if (charge.isAccountServiceCharge) {
        this.editAccountServiceChargeId = charge.asc_id;
      } else {
        this.editAccountServiceChargeId = null;
      }
      this.accountServiceCharge = {
        AccountServiceId: service.accountService.accountservice_id,
        ...pick(charge, ['Name', 'Description', 'Units', 'UnitCost', 'UnitType', 'ChargeFrequency']),
      };
    },
    async saveAccountServiceCharge() {
      try {
        this.savingAccountServiceCharge = true;
        const serviceId = this.accountServiceCharge.AccountServiceId;
        if (this.editAccountServiceChargeId) {
          await axios.put(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountservices/${serviceId}/charges/${this.editAccountServiceChargeId}`, this.accountServiceCharge);
          this.$emit('snack', 'Service Charge Updated', false);
        } else {
          await axios.post(`${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/accountservices/${serviceId}/charges`, this.accountServiceCharge);
          this.$emit('snack', 'Service Charge Created', false);
        }
        await this.loadData();
        this.accountServiceDialogue = false;
      } catch (err) {
        if (err.name === 'AxiosError' && err.response?.data?.errors) {
          this.accountServiceChargeErrors = err.response.data.errors;
        } else {
          console.error(err);
          this.$emit('snack', 'Failed to create Service Charge', true);
        }
      }
      this.savingAccountServiceCharge = false;
    },
  },
};
</script>
