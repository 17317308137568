import moment from 'moment';

const label = '1 year - monthly';
const limit = 12;
const text = '1 Year';

const createFilter = () => {
  const now = moment(); // Get the current date and time
  const oneYearAgo = now.clone().subtract(1, 'year'); // Get the date 1 year ago

  const timeRangeFilter = {
    range: {
      call_datetime_iso: {
        gte: oneYearAgo.startOf('year').toISOString(),
        lte: oneYearAgo.endOf('year').toISOString(),
      },
    },
  };
  return [timeRangeFilter];
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  calendar_interval: 'month',
  min_doc_count: 0,
});

const dateFormat = (date) => moment(date).format('MMM YY');

export default {
  label,
  limit,
  text,
  createFilter,
  dateHistogram,
  dateFormat,
};
