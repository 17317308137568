<template>
  <div>
    <v-card class="px-5 mb-3">
      <v-card-title>Reports</v-card-title>
      <v-data-table
        :headers="reportHeaders"
        :items="reports"
        item-key="role_id"
        :loading="loading"
        sort-by="created"
        sort-desc
        dense
      >
        <template #item.created="{ item : report }">
          {{ report.created | toLocalTime(timeZone) }}
        </template>

        <template #item.accountId="{ item : report }">
          {{ accountNames[report.accountId] }}
        </template>

        <template #item.scheduleId="{ item : report }">
          {{ findScheduleName(report.scheduleId) }}
        </template>

        <template #item.actions="{ item : report }">
          <v-btn
            v-if="!report.downloading"
            title="Download"
            text
            icon
            @click="downloadReport(report)"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
          <v-progress-circular
            v-else
            :color="color"
            size="24"
            width="2"
            :value="report.progress"
          />
          <v-btn
            title="Delete"
            text
            icon
            :disabled="report.deleting"
            @click="deleteReport(report)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';

import { bizvu_schedule_url, date_time_format } from '../../config';

export default {
  name: 'Reports',
  filters: {
    toLocalTime(time, timeZone) {
      return moment(time).tz(timeZone).format(date_time_format);
    },
  },
  props: {
    dialogueOpen: Boolean,
    accountNames: Object,
    schedules: Array,
  },
  data() {
    return {
      reports: [],
      loading: false,
      downloadProgress: 0,
    };
  },
  computed: {
    userHasAccessToMultipleAccounts() {
      return Object.keys(this.accountNames).length > 1;
    },
    reportHeaders() {
      const reportHeaders = [
        { text: 'Name', value: 'name' },
        { text: 'Schedule', value: 'scheduleId' },
      ];

      if (this.userHasAccessToMultipleAccounts) {
        reportHeaders.push({ text: 'Account', value: 'accountId' });
      }

      reportHeaders.push({ text: 'Created', value: 'created' }, { text: 'Actions', value: 'actions', align: 'right' });
      return reportHeaders;
    },
    ...mapGetters([
      'authentication',
      'timeZone',
    ]),
  },
  watch: {
    dialogueOpen(dialogueOpen) {
      if (dialogueOpen) {
        this.loadReports();
      }
    },
  },
  mounted() {
    this.loadReports();
  },
  methods: {
    async loadReports() {
      this.loading = true;
      try {
        const { data: reports } = await axios.get(`${bizvu_schedule_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/report`);
        this.reports = reports;
      } catch (err) {
        console.error('Failed to load reports', err);
      }
      this.loading = false;
    },
    async downloadReport(report) {
      try {
        this.$set(report, 'downloading', true);
        this.$set(report, 'downloadProgress', 0);
        const { reportId, accountId, name } = report;
        const { data } = await axios.get(
          `${bizvu_schedule_url}/account/${accountId}/report/${reportId}`,
          {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              this.$set(report, 'downloadProgress', Math.floor((progressEvent.loaded / progressEvent.total) * 100));
            },
          },
        );
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error('Failed to download report', err);
      }
      this.$set(report, 'downloading', false);
    },
    async deleteReport(report) {
      try {
        this.$set(report, 'deleting', true);
        await axios.delete(`${bizvu_schedule_url}/account/${report.accountId}/report/${report.reportId}`);
        await this.loadReports();
      } catch (err) {
        console.error('Failed to delete report', err);
      }
    },
    findScheduleName(scheduleId) {
      const schedule = this.schedules.find((s) => s.id === scheduleId);
      if (!schedule) {
        return '';
      }

      return schedule.name;
    },
  },
};
</script>
