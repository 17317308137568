<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Edit Site
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/sites/<site_id>', 'DELETE')"
        text
        color="blue"
        @click="delDialog=true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/sites/<site_id>', 'GET')"
        text
        color="blue"
        @click="simDialog=true"
      >
        <v-icon class="mr-2">
          call
        </v-icon>Simulate 999 call
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout>
          <v-flex xs6>
            <v-layout class="px-2">
              <v-flex xs11>
                <v-text-field
                  v-model="site.SiteName"
                  label="Name"
                  :rules="nameRules"
                  :counter="60"
                  required
                />
              </v-flex>
            </v-layout>
            <v-layout class="px-2">
              <v-flex xs8>
                <v-switch
                  v-model="site.Default"
                  inset
                  label="Default Site"
                />
              </v-flex>
            </v-layout>
            <v-layout class="px-2">
              <v-flex xs9>
                <TimeZonePicker
                    v-if=timeZone
                    :init-zone="timeZone"
                    @update="handleTimeZoneUpdate"
                />
              </v-flex>
            </v-layout>
            <v-layout class="px-2">
              <v-flex xs9>
                <v-combobox
                  v-model="site.SiteType"
                  flat
                  :items="siteTypes"
                  label="Site type"
                  @keyup.native="forceSiteTypeUpdate"
                />
              </v-flex>
            </v-layout>
            <v-layout class="px-2">
              <v-flex xs9>
                <v-switch
                  v-model="site.InDRMode"
                  label="Enable/Disable DR Mode"
                  inset
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <GmapMap
              v-if="geo"
              :center="geo"
              :zoom="12"
              map-type-id="terrain"
              style="width: 500px; height: 300px"
            >
              <GmapMarker
                :position="geo"
                :clickable="true"
                :draggable="false"
              />
            </GmapMap>
          </v-flex>
        </v-layout>
        <v-expansion-panels
          focusable
          class="my-3 mx-2"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  location_on
                </v-icon>Address
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs12>
                    <v-text-field
                      v-model="site.Address1"
                      label="Street address 1"
                      :rules="address1Rules"
                      :counter="120"
                      required
                    />
                    <v-text-field
                      v-model="site.Address2"
                      label="Street address 2"
                      :rules="address2Rules"
                      :counter="120"
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs5>
                    <v-layout class="pr-2">
                      <v-text-field
                        v-model="site.City"
                        label="City"
                        :rules="cityRules"
                        :counter="60"
                        required
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex xs2>
                    <v-layout class="px-2">
                      <v-text-field
                        v-model="site.PostCode"
                        label="Postcode"
                        :rules="postcodeRules"
                        :counter="10"
                        required
                      />
                    </v-layout>
                  </v-flex>
                  <v-flex xs5>
                    <v-layout class="pl-2">
                      <v-text-field
                        v-model="site.Country"
                        label="Country"
                        :rules="countryRules"
                        :counter="30"
                        required
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout class="mt-3">
                  <p class="subtitle-1">
                    Find new address:
                  </p>
                </v-layout>
                <v-layout>
                  <v-flex xs2>
                    <v-text-field
                      v-model="pcLookup"
                      label="Postcode"
                      :counter="10"
                    />
                  </v-flex>
                  <v-flex
                    xs2
                    class="ml-3"
                  >
                    <v-btn
                      class="mt-3"
                      color="primary"
                      text
                      @click="findAddresses"
                    >
                      Find Address
                    </v-btn>
                  </v-flex>
                  <v-flex
                    v-if="addresses && addresses.addresses.length>0"
                    xs8
                    class="ml-3"
                  >
                    <v-select
                      v-model="selectedAddress"
                      flat
                      :items="addresses.addresses"
                      item-text="addressString"
                      item-value="key"
                      label="Select Street Address"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  call
                </v-icon>Contact information
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex
                    xs6
                    class="pr-2"
                  >
                    <v-text-field
                      v-model="site.MainNumber"
                      label="Main Number"
                      :rules="mainnumberRules"
                      :counter="15"
                      required
                    />
                    <v-text-field
                      v-model="site.ContactName"
                      label="Contact Name"
                      :rules="contactnameRules"
                      :counter="60"
                      required
                    />
                    <v-text-field
                      v-model="site.ContactNumber"
                      label="Contact Number"
                      :rules="contactnumberRules"
                      :counter="15"
                      required
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    class="pl-2"
                  >
                    <v-text-field
                      v-model="site.EmergencyCLI"
                      label="Emergency CLI"
                      :rules="emergencycliRules"
                      :counter="15"
                      required
                    />
                    <v-text-field
                      v-model="site.MatchingCLI"
                      label="Matching CLI"
                      :rules="matchingcliRules"
                      :counter="15"
                      required
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  calendar_today
                </v-icon>Working hours
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs12>
                    <OpeningHoursEditor
                      v-if="site.Hours && Array.isArray(site.Hours.WeekDay1)"
                      v-model="site.Hours"
                      :hours="site.Hours"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  calendar_today
                </v-icon>Working hours (Bank Holiday)
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <OpeningHourBankHoliday
                  :key="bankHolidaIndex"
                  v-model="site.BankHolidays"
                  :load-defaults="false"
                  @update="onUpdateBankHolidays"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="updateSite"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
      <v-dialog
        v-model="delDialog"
        persistent
        max-width="300"
      >
        <v-card>
          <v-card-title class="headline">
            Delete Site
          </v-card-title>
          <v-container>
            <v-layout class="px-2">
              <v-flex xs12>
                <p class="subtitle-1">
                  Do you really want to delete this Site?
                </p>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              primary
              color="primary"
              dark
              @click="deleteSite"
            >
              Delete
            </v-btn>
            <v-btn
              text
              color="grey"
              @click="delDialog=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="simDialog"
        persistent
        max-width="500"
      >
        <v-card class="px-3 pb-3">
          <v-card-title class="headline">
            Simulate 999 Call
          </v-card-title>
          <v-container>
            <v-layout
              class="px-2"
              wrap
            >
              <v-flex xs12>
                <p class="subtitle-1">
                  Simulating a 999 call to ensure that your location is detected correctly.
                </p>
              </v-flex>
              <v-flex xs1>
                <v-icon
                  color="orange"
                  class="mt-0"
                >
                  warning
                </v-icon>
              </v-flex>
              <v-flex xs10>
                <p class="subtitle-1">
                  This is NOT a real 999 call to emergency services.
                </p>
              </v-flex>
              <v-flex xs10>
                <p class="subtitle-1">
                  You should hear {{ site.EmergencyCLI }}
                </p>
              </v-flex>
              <v-flex
                v-if="timerEnabled"
                xs10
                class="mt-3"
              >
                <p class="headline">
                  Remaining time: {{ minLeft }}:{{ secLeft }}
                </p>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!timerEnabled"
              primary
              color="primary"
              dark
              @click="simulate999"
            >
              Simulate call
            </v-btn>
            <v-btn
              v-if="!timerEnabled"
              text
              color="grey"
              @click="simDialog=false"
            >
              Close
            </v-btn>
            <v-btn
              v-else
              primary
              color="primary"
              dark
              @click="stopSimulation"
            >
              Stop simulation
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmMakingSiteDefaultDialogue"
        width="500"
      >
        <v-card>
          <v-card-title
            class="headline"
            primary-title
          >
            Confirm Default Site
          </v-card-title>
          <v-card-text>Are you sure you want to make this site the default site?</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="commitSite"
            >
              Yes
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="confirmMakingSiteDefaultDialogue = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';
import permittedFunction from '@/permittedFunction.js';

import TimeZonePicker from '@/components/TimeZonePicker';
import OpeningHourBankHoliday from '@/components/openinghourseditor/OpeningHourBankHolidayEditor';
import OpeningHoursEditor from '@/components/openinghourseditor/OpeningHoursEditor';

export default {
  name: 'Site',
  components: {
    OpeningHoursEditor,
    OpeningHourBankHoliday,
    TimeZonePicker,
  },
  data() {
    return {
      timerEnabled: false,
      timerEnd: 0,
      minLeft: '00',
      secLeft: '00',
      timeRefresherFunction: null,
      delDialog: false,
      simDialog: false,
      bankHolidaIndex: 0,
      siteId: this.$route.params.id,
      valid: false,
      addresses: null,
      pcLookup: '',
      selectedAddress: null,
      site: {},
      geo: null,
      siteTypes: ['Office', 'Home', 'Factory'],
      timeZone: null,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      address1Rules: [
        (v) => !!v || 'Street address 1 is required',
        (v) => (v && v.length <= 120) || 'Adress must not have more than 120 characters',
      ],
      address2Rules: [
        (v) => (v && v.length <= 120) || 'Adress must not have more than 120 characters',
      ],
      cityRules: [
        (v) => !!v || 'City is required',
        (v) => (v && v.length <= 60) || 'City must not have more than 60 characters',
      ],
      postcodeRules: [
        (v) => !!v || 'Post code is required',
        (v) => (v && v.length <= 10) || 'Post code must not have more than 10 characters',
      ],
      countryRules: [
        (v) => !!v || 'Country is required',
        (v) => (v && v.length <= 30) || 'Country must not have more than 30 characters',
      ],
      mainnumberRules: [
        (v) => !!v || 'Main numer is required',
        (v) => (v && v.length <= 15) || 'Main number must not have more than 15 characters',
      ],
      emergencycliRules: [
        (v) => !!v || 'Emergency CLI is required',
        (v) => (v && v.length <= 15) || 'Emergency CLI must not have more than 15 characters',
      ],
      matchingcliRules: [
        (v) => !!v || 'Matching CLI is required',
        (v) => (v && v.length <= 15) || 'Matching CLI must not have more than 15 characters',
      ],
      contactnameRules: [
        (v) => !!v || 'Contact name is required',
        (v) => (v && v.length <= 60) || 'Contact name must not have more than 60 characters',
      ],
      contactnumberRules: [
        (v) => !!v || 'Contact number is required',
        (v) => (v && v.length <= 15) || 'Contact number must not have more than 15 characters',
      ],
      isCurrentlyDefault: false,
      confirmMakingSiteDefaultDialogue: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAddress() {
      this.site.Address1 = this.addresses.addresses[this.selectedAddress].line_1;
      this.site.Address2 = this.addresses.addresses[this.selectedAddress].line_2;
      this.site.City = this.addresses.addresses[this.selectedAddress].town_or_city;
      this.site.Country = this.addresses.addresses[this.selectedAddress].country;
      this.site.PostCode = this.addresses.postcode;

      if (this.addresses.addresses[this.selectedAddress].locality !== '') {
        this.site.Address2 = this.addresses.addresses[this.selectedAddress].locality;
      }
    },
    selectedAccountId() {
      this.$router.back();
    },
  },
  created() {
    this.loadSite();
  },
  methods: {
    handleTimeZoneUpdate(TimeZone) {
      this.timeZone = TimeZone;
      this.site.TimeZone = TimeZone;
    },
    calculateRemainingTime() {
      if (this.timerEnabled) {
        const timeLeft = Math.round((this.timerEnd - Date.now()) / 1000);
        if (timeLeft <= 0) {
          this.timerEnabled = false;
          this.stopSimulation();
        } else {
          this.minLeft = Math.trunc(timeLeft / 60) < 10 ? `0${Math.trunc(timeLeft / 60).toString()}` : Math.trunc(timeLeft / 60).toString;
          this.secLeft = timeLeft % 60 < 10 ? `0${Number(timeLeft % 60).toString()}` : Number(timeLeft % 60).toString();
        }
      } else {
        this.minLeft = '00';
        this.secLeft = '00';
      }
    },
    simulate999() {
      axios.put(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/sites/${this.siteId}`, { EmergencyTest: true })
        .then(() => {
          this.timerEnabled = true;
          this.timerEnd = Date.now() + 120000;
          this.timeRefresherFunction = setInterval(this.calculateRemainingTime, 100);
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    stopSimulation() {
      axios.put(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/sites/${this.siteId}`, { EmergencyTest: false })
        .then(() => {
          this.minLeft = '00';
          this.secLeft = '00';
          this.timerEnabled = false;
          clearInterval(this.timeRefresherFunction);
          this.timeRefresherFunction = null;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    validForm() {
      if (this.site.SiteName === '') {
        this.$emit('snack', 'Site Name is a required field!');
        return false;
      }
      if (this.site.Address1 === '') {
        this.$emit('snack', 'Street Address 1 is a required field!');
        return false;
      }
      if (this.site.PostCode === '') {
        this.$emit('snack', 'PostCode is a required field!');
        return false;
      }
      if (this.site.City === '') {
        this.$emit('snack', 'City is a required field!');
        return false;
      }
      if (this.site.Country === '') {
        this.$emit('snack', 'Country is a required field!');
        return false;
      }
      if (this.site.MainNumber === '') {
        this.$emit('snack', 'MainNumber is a required field!');
        return false;
      }
      if (this.site.ContactName === '') {
        this.$emit('snack', 'ContactName is a required field!');
        return false;
      }
      if (this.site.ContactNumber === '') {
        this.$emit('snack', 'ContactNumber is a required field!');
        return false;
      }
      if (this.site.EmergencyCLI === '') {
        this.$emit('snack', 'EmergencyCLI is a required field!');
        return false;
      }
      if (this.site.MatchingCLI === '') {
        this.$emit('snack', 'MatchingCLI is a required field!');
        return false;
      }

      // All good and valid
      return true;
    },
    findAddresses() {
      axios.get(`${config.getaddress_url}/${this.pcLookup}?expand=true&api-key=${config.getaddress_api_key}`)
        .then((response) => {
          this.addresses = response.data;
          this.addresses.addresses.forEach((a, i) => {
            a.addressString = `${a.formatted_address[0]}, ${a.formatted_address[3]}, ${a.formatted_address[4]}`;
            a.key = i;
          });
        })
        .catch(() => {
          this.$emit('snack', 'Invalid Postcode!');
          this.pcLookup = '';
          this.addresses = null;
        });
    },
    onUpdateBankHolidays(data) {
      this.site.BankHolidays = data;
    },
    forceSiteTypeUpdate(event) {
      this.site.SiteType = event.target.value;
    },
    updateSite() {
      if (!this.validForm()) {
        return;
      }

      if (!this.isCurrentlyDefault && this.site.Default) {
        this.confirmMakingSiteDefaultDialogue = true;
        return;
      }

      this.commitSite();
    },
    async commitSite() {
      try {
        await axios.put(`${config.bizvu_site_url}/account/${this.selectedAccountId}/site/${this.siteId}`, this.site);
        this.$emit('snack', 'Site successfully updated!');
        this.$router.back();
      } catch (error) {
        console.error('Error updating site', error.response);
        if (error.response.status === 400) {
          const fieldErrors = error.response.data.errors;
          Object.values(fieldErrors).forEach((errors) => {
            this.$emit('snack', errors[0], true);
          });
        } else {
          this.$emit('snack', 'Failed to update site', true);
        }
      }
    },
    deleteSite() {
      this.delDialog = false;
      axios.delete(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/sites/${this.siteId}`)
        .then(() => {
          this.$emit('snack', 'Site successfully deleted!');
          this.$router.back();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    async loadSite() {
      try {
        const { data: site } = await axios.get(`${config.bizvu_site_url}/account/${this.selectedAccountId}/site/${this.siteId}`);
        this.site = site;
        this.isCurrentlyDefault = site.Default;
        this.timeZone = `${site.TimeZone}`;

        const address = `${site.Address1},${site.Address2},${site.PostCode
        }${site.City},${site.Country}`;

        const { data } = await axios.get(`${config.google_geocoding_url}?address=${address}&key=${config.google_mapsapi_key}`);
        if (data.results[0].geometry) {
          this.geo = data.results[0].geometry.location;
        } else {
          console.error('No GEO', data);
        }
      } catch (err) {
        console.error('Failed to load site', err);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style>
.custom-list .v-list__tile{
  min-height: 48px!important ;
  height: auto !important;
  padding: 20px 16px !important;
}
</style>
