<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Exports
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-btn
        @click="downloadExports()"
      >
        Site Emergency Data
      </v-btn>
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import { BTL_API_TENANT_URL } from '../../config';

export default {
  name: 'Exports',
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    downloadExports() {
      this.download(`${BTL_API_TENANT_URL}/sites/export/emergency`);
    },
    async download(path) {
      const { data } = await axios.get(path, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sites_emergency_data.csv');
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
