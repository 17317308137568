<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Fax Account
      </v-toolbar-title>
      <v-tooltip
        bottom
        :disabled="!(faxUsers.length > 0)"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              v-if="isPermitted('DELETE') && faxAccountExists"
              text
              color="blue"
              :disabled="faxUsers.length > 0"

              @click="deleteFaxAccountDialog = true"
            >
              <v-icon class="mr-2">
                delete
              </v-icon>
              Delete account fax source number
            </v-btn>
          </div>
        </template>
        <span>Cannot delete while there are users assigned</span>
      </v-tooltip>
      <v-btn
        v-if="isPermitted('POST', '/users') && faxAccountExists"
        text
        color="blue"
        @click="addUserDialog = true"
      >
        <v-icon class="mr-2">
          add
        </v-icon>
        Add user fax source number
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-container>
        <v-flex xs4>
          <v-form
            ref="faxAccountForm"
            v-model="faxAccountFormValid"
            :aria-autocomplete="false"
            lazy-validation
          >
            <v-select
              v-model="faxAccount.SourceNumber"
              flat
              :rules="rules.required"
              :items="accountNumbers"
              label="Source Number"
            />
          </v-form>
          <v-layout
            v-if="faxAccountExists ? isPermitted('PUT') : isPermitted('POST')"
            class="mt-2"
          >
            <v-spacer />
            <v-btn
              primary
              color="primary"
              @click="faxAccountExists ? updateFaxAccount() : createFaxAccount()"
            >
              Save
            </v-btn>
          </v-layout>
        </v-flex>
      </v-container>
      <v-container v-if="faxUsers && faxUsers.length">
        <h2>Users</h2>
        <v-flex xs6>
          <v-simple-table
            fixed-header
          >
            <thead>
              <tr>
                <th>
                  Name
                </th>
                <th>
                  Email
                </th>
                <th>
                  Source Number
                </th>
                <th>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in faxUsers"
                :key="user.UserId"
              >
                <td>{{ findUser(user.UserId) ? findUser(user.UserId).userRef.fullname : '' }}</td>
                <td>{{ findUser(user.UserId) ? findUser(user.UserId).userRef.email : '' }}</td>
                <td>{{ user.SourceNumber }}</td>
                <td>
                  <v-btn
                    color="error"
                    small
                    @click="userToDeleteID = user.UserId"
                  >
                    Delete
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-flex>
      </v-container>
    </v-container>

    <v-dialog
      v-model="deleteFaxAccountDialog"
      width="400"
    >
      <v-card
        style="display: flex;
        flex-direction: column;"
      >
        <v-card-title>
          <span>Delete account fax source number</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>Are you sure you want to delete the account fax source number?</v-card-text>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteFaxAccount"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteFaxAccountDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addUserDialog"
      width="400"
    >
      <v-card
        style="display: flex;
        flex-direction: column;"
      >
        <v-card-title>
          <span>Add user fax source number</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-form
            ref="newUserForm"
            v-model="newUserFormValid"
            :aria-autocomplete="false"
            lazy-validation
          >
            <v-select
              v-model="newUser.userId"
              :item-disabled="userExists"
              flat
              :rules="rules.required"
              :items="accountUsers"
              label="User"
            />
            <v-select
              v-model="newUser.number"
              flat
              :rules="rules.required"
              :items="accountNumbers"
              label="Number"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="addFaxAccountUser"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="addUserDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="userToDeleteID"
      width="400"
      @click:outside="userToDeleteID = null"
    >
      <v-card
        style="display: flex;
        flex-direction: column;"
      >
        <v-card-title>
          <span>Delete user</span>
          <v-spacer />
        </v-card-title>
        <v-card-text>Are you sure you want to delete this user?</v-card-text>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteFaxAccountUser"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="userToDeleteID = null"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import config, { bizvu_user_url } from '@/config.js';

import Snacks from '@/utils/snacks';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'FaxAccount',
  data() {
    return {
      faxAccount: {},
      faxUsers: [],
      accountUsers: [],
      accountNumbers: [],
      deleteFaxAccountDialog: false,
      addUserDialog: false,
      userToDeleteID: null,
      newUser: {},
      faxAccountExists: false,
      faxAccountFormValid: false,
      newUserFormValid: false,
      rules: {
        required: [(value) => !!value || 'Required.'],
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.loadFaxAccount();
      this.loadAccountNumbers();
      this.loadAccountUsers();
    },
  },
  mounted() {
    this.loadFaxAccount();
    this.loadAccountNumbers();
    this.loadAccountUsers();
  },
  methods: {
    isPermitted(verb, suffix, resources) {
      const endpointRoot = '/tenants/<tenant_name>/accounts/<account_id>/fax';
      const endpoint = endpointRoot + (suffix || '');
      return permittedFunction(endpoint, verb, resources);
    },
    async loadFaxAccount() {
      try {
        const { data: faxAccount } = await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/fax`);
        this.faxAccount = faxAccount;
        this.faxAccountExists = true;
        this.loadFaxAccountUsers();
      } catch (error) {
        console.error('Failed to fetch fax account', error);
        this.faxAccount = {};
        this.faxAccountExists = false;
        this.faxUsers = [];
        if (error.response.status !== 404) {
          Snacks.$emit('error', 'Failed to fetch fax account.');
        }
      }
    },
    async loadAccountNumbers() {
      try {
        const { data: numbers } = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/phonenumbers`);
        this.accountNumbers = numbers.map((number) => number.PhoneNumber);
      } catch (error) {
        console.error('Failed to load account numbers', error);
        Snacks.$emit('error', 'Failed to load account numbers.');
        this.accountNumbers = [];
      }
    },
    async loadAccountUsers() {
      try {
        const { data: users } = await axios.get(`${bizvu_user_url}/account/${this.selectedAccountId}/user`);
        this.accountUsers = users.map((user) => {
          const { user_id, fullname } = user;
          const email = user.emails.length > 0 ? user.emails[0].email : '';
          return {
            text: `${fullname} - ${email}`,
            value: user_id,
            userRef: { ...user, email },
          };
        });
      } catch (error) {
        console.error('Failed to load account users', error);
        Snacks.$emit('error', 'Failed to load account users.');
        this.accountUsers = [];
      }
    },
    async createFaxAccount() {
      try {
        const valid = this.$refs.faxAccountForm.validate();
        if (!valid) {
          Snacks.$emit('error', 'Correct invalid fields before saving');
          return;
        }
        await axios.post(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/fax`, this.faxAccount);
        Snacks.$emit('info', 'Fax account created successfully.');
      } catch (error) {
        console.error('Failed to create fax account', error);
        Snacks.$emit('error', 'Failed to create fax account.');
        if (error.response?.data?.errors) {
          Object.values(error.response?.data?.errors || {}).forEach((err) => {
            Snacks.$emit('error', err.toString());
          });
        }
      } finally {
        this.loadFaxAccount();
      }
    },
    async updateFaxAccount() {
      try {
        const valid = this.$refs.faxAccountForm.validate();
        if (!valid) {
          Snacks.$emit('error', 'Correct invalid fields before saving');
          return;
        }
        await axios.put(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/fax`, this.faxAccount);
        Snacks.$emit('info', 'Fax account updated successfully.');
      } catch (error) {
        console.error('Failed to update fax account', error);
        Snacks.$emit('error', 'Failed to update fax account.');
        if (error.response?.data?.errors) {
          Object.values(error.response?.data?.errors || {}).forEach((err) => {
            Snacks.$emit('error', err.toString());
          });
        }
      } finally {
        this.loadFaxAccount();
      }
    },
    async deleteFaxAccount() {
      try {
        await axios.delete(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/fax`);
        Snacks.$emit('info', 'Fax account deleted successfully.');
      } catch (error) {
        console.error('Failed to delete fax account', error);
        Snacks.$emit('error', 'Failed to delete fax account.');
      } finally {
        this.loadFaxAccount();
        this.deleteFaxAccountDialog = false;
      }
    },
    async loadFaxAccountUsers() {
      if (this.isPermitted('GET', '/users')) {
        try {
          const { data: users } = await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/fax/users`);
          this.faxUsers = users;
        } catch (error) {
          console.error('Failed to fetch fax account users', error);
          Snacks.$emit('error', 'Failed to load fax account users.');
          this.faxUsers = [];
        }
      }
    },
    async addFaxAccountUser() {
      try {
        const valid = this.$refs.newUserForm.validate();
        if (!valid) {
          Snacks.$emit('error', 'Correct invalid fields before saving');
          return;
        }
        await axios.post(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/fax/users`, {
          UserId: this.newUser.userId,
          SourceNumber: this.newUser.number,
        });
        this.newUser = {};
        this.addUserDialog = false;
      } catch (error) {
        console.error('Failed to add fax account user', error);
        Snacks.$emit('error', 'Failed to add fax account user.');
      } finally {
        this.loadFaxAccountUsers();
      }
    },
    async deleteFaxAccountUser() {
      try {
        await axios.delete(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/fax/users/${this.userToDeleteID}`);
      } catch (error) {
        console.error('Failed to delete fax account user', error);
        Snacks.$emit('error', 'Failed to delete fax account user.');
      } finally {
        this.userToDeleteID = null;
        this.loadFaxAccountUsers();
      }
    },
    userExists({ value }) {
      return this.faxUsers.find((faxUser) => faxUser.UserId === value);
    },
    findUser(userId) {
      const theUser = this.accountUsers.find((accountUser) => accountUser.value === userId);
      return theUser;
    },
  },
};
</script>
