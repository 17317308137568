<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
  >
    <v-layout
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            Release Notes
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-container>
      <v-container style="height: 100%; overflow-y: scroll; background-color: #FFF;">
        <v-layout
          v-if="!dataLoaded"
          align-center
          justify-center
          row
          fill-height
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-layout>
        <timeline v-if="dataLoaded">
          <timeline-item
            v-for="(release,i) in timeline"
            :key="i"
          >
            <v-card class="elevation-3">
              <v-toolbar
                color="blue"
                dark
                dense
                flat
                height="30"
              >
                <v-toolbar-title class="body-2">
                  Version {{ release.version }} - {{ shortDate(release.date) }}
                </v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-list two-line>
                  <template v-for="(change, index) in release.changes">
                    <v-list-item
                      :key="index"
                      icon
                    >
                      <v-list-item-avatar>
                        <v-icon :class="[change.icon]">
                          {{ change.icon }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="body-2">
                          {{ change.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="body-1">
                          {{ change.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-container>
            </v-card>
          </timeline-item>
        </timeline>
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import { Timeline, TimelineItem } from 'vue-cute-timeline';
import moment from 'moment';
import { mapGetters } from 'vuex';

import ResizeContainer from '@/components/widgets/layout/ResizeContainer';
import config from '../../config.js';
import changelog from '../../../changelog.json';

export default {
  name: 'Releasenotes',
  components: {
    Timeline,
    TimelineItem,
    'resize-container': ResizeContainer,
  },
  props: {
    id: String,
    layoutProps: Object,
  },
  data() {
    return {
      timeline: [],
      dataLoaded: false,
      iconConfig: {
        debt: { icon: 'attach_money', order: 3 },
        bugfix: { icon: 'bug_report', order: 2 },
        improvement: { icon: 'arrow_upward', order: 1 },
        feature: { icon: 'add_circle_outline', order: 0 },
      },
    };
  },
  computed: {
    ...mapGetters([
      'authentication',
    ]),
  },
  watch: {
    authentication: {
      handler() {
        this.loadHistory();
      },
      deep: true,
    },
  },
  created() {
    this.loadHistory();
  },
  methods: {
    shortDate(d) {
      return moment.unix(d).format(config.date_format);
    },
    loadHistory() {
      this.timeline = [];
      changelog.releases.forEach((release) => {
        const t = {
          version: release.version,
          date: release.timestamp,
          id: release.id,
          changes: release.changes.map((change) => {
            const iconInfo = this.iconConfig[change.type];
            if (!iconInfo) {
              console.error('Invalid change type:', change.type);
              return { ...change, icon: '', order: -1 }; // Handle the undefined case
            }
            change.icon = iconInfo.icon;
            change.order = iconInfo.order;
            return change;
          }),
        };
        this.timeline.push(t);
      });
      this.dataLoaded = true;
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
  },
};
</script>

<style scoped>
</style>
