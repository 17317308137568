import auth0 from 'auth0-js';
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '@/store/';

import {
  BTL_API_URL,
  bizvu_account_url,
  auth0_clientID,
  AUTH0_DOMAIN,
  auth0_redirectUri,
} from '@/config';

import Snacks from '@/utils/snacks';

const britannicBizvuApis = [new URL(BTL_API_URL).hostname, new URL(bizvu_account_url).hostname, 'localhost'];

const TEN_MINUTES = 10 * 60 * 1000;
let renewTokenTimeout;

const webAuth = new auth0.WebAuth({
  clientID: auth0_clientID,
  domain: new URL(AUTH0_DOMAIN).host,
  redirectUri: auth0_redirectUri,
  responseType: 'token id_token',
  scope: 'openid email profile',
});

const login = (appState = {}) => {
  webAuth.authorize({ appState });
};

const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('auth_context');
  webAuth.logout({
    returnTo: window.location.origin,
  });
};

const setSession = (authResult) => {
  const { idTokenPayload, idToken } = authResult;

  const expiresAt = JSON.stringify(
    idTokenPayload.exp * 1000,
  );
  localStorage.setItem('access_token', idToken);
  localStorage.setItem('expires_at', expiresAt);
};

// eslint-disable-next-line consistent-return
const handleAuthentication = async () => {
  try {
    // eslint-disable-next-line no-use-before-define
    const authResult = await extractAuthToken();
    setSession(authResult);
    // eslint-disable-next-line no-use-before-define
    const authenticationContext = await retrieveAuthenticationContext();
    store.dispatch('handleLogin', authenticationContext);
    // eslint-disable-next-line no-use-before-define
    startRenewTokenTimer();
    return {
      appState: authResult.appState || {},
    };
  } catch (e) {
    console.error(e);
    login();
  }
};

const extractAuthToken = () => new Promise((resolve, reject) => {
  webAuth.parseHash((err, authResult) => {
    if (err) {
      reject(err);
    } else {
      resolve(authResult);
    }
  });
});

const isAuthenticated = () => {
  const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  return new Date().getTime() < expiresAt;
};

const startRenewTokenTimer = () => {
  if (!isAuthenticated()) {
    return;
  }

  if (renewTokenTimeout) {
    clearTimeout(renewTokenTimeout);
  }

  const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  const timeout = expiresAt - new Date().getTime() - TEN_MINUTES;

  // eslint-disable-next-line no-use-before-define
  renewTokenTimeout = setTimeout(() => renewToken(), timeout);
};

const renewToken = () => new Promise((resolve, reject) => {
  if (!isAuthenticated()) {
    reject(new Error('Not logged in'));
  }

  webAuth.checkSession({}, (err, authResult) => {
    if (err) {
      console.error('Cant renew token. Sending user to login', err);
      login();
      reject();
    } else {
      setSession(authResult);
      startRenewTokenTimer();
      resolve(authResult);
    }
  });
});

const retrieveAuthenticationContext = async () => {
  try {
    const response = await axios.get(`${BTL_API_URL}/auth`);
    const userProfile = response.data;

    const authentication = {
      userId: userProfile.user.user_id,
      userName: userProfile.user.fullname,
      userEmail: userProfile.user.emails[0].email,
      userAccountId: userProfile.user.account.account_id,
      userAccountName: userProfile.user.account.name,
      maxEntitlementLevel: userProfile.user.max_entitlement_level,
      tokenId: userProfile.token_id,
      entitlements: userProfile.entitlements,
      valid: Date.parse(userProfile.expires) - Date.now(),
    };

    localStorage.setItem('auth_context', JSON.stringify(authentication));
    return authentication;
  } catch (e) {
    console.error('Unable to fetch user profile', e);
    throw e;
  }
};

const getToken = () => localStorage.getItem('access_token');

const getAuthenticationContext = () => JSON.parse(localStorage.getItem('auth_context'));

axios.defaults.headers.common['Content-Type'] = 'application/json ';
axios.defaults.headers.common.Accept = 'application/json ';

axios.interceptors.request.use((config) => {
  if (britannicBizvuApis.includes(new URL(config.url).hostname)) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (britannicBizvuApis.includes(new URL(error.config.url).hostname)) {
      if (error.response && error.response.status === 401) {
        login();
      } else if (error.response && error.response.status === 403) {
        Snacks.$emit('error', `You don't have the permissions to access this resource: ${error.response.config.method.toUpperCase()} ${error.response.config.url}`);
      }
    }
    return Promise.reject(error);
  },
);

startRenewTokenTimer();

export {
  login,
  logout,
  renewToken,
  handleAuthentication,
  getToken,
  getAuthenticationContext,
  isAuthenticated,
};
