<template>
  <div>
    <v-card class="px-5 mb-3">
      <v-card-title>Schedules</v-card-title>
      <v-data-table
        :headers="reportHeaders"
        :items="schedules"
        item-key="role_id"
        :loading="schedulesLoading"
        sort-by="name"
        dense
      >
        <template #item.day="{ item }">
          <div v-if="item.interval === 'Daily'">
            {{ '-' }}
          </div>
          <div v-if="item.interval === 'Weekly'">
            {{ item.day | convertToDays }}
          </div>
          <div v-if="item.interval === 'Monthly'">
            {{ item.dateOfMonth | formatDayOfMonth }}
          </div>
        </template>

        <template #item.time="{ item }">
          {{ item.time }}
        </template>

        <template #item.enabled="{ item }">
          <v-icon
            v-if="item.enabled"
            color="#1565C0"
          >
            toggle_on
          </v-icon>
          <v-icon
            v-else
            color="grey"
          >
            toggle_off
          </v-icon>
        </template>

        <template #item.account="{ item }">
          {{ accountNames[item.accountId] }}
        </template>

        <template #item.actions="{ item }">
          <v-btn
            title="Run"
            text
            icon
            :disabled="item.running"
            @click="runSchedule(item)"
          >
            <v-icon>play_arrow</v-icon>
          </v-btn>
          <v-btn
            title="Edit"
            text
            icon
            @click="editSchedule(item)"
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn
            title="Delete"
            text
            icon
            @click="deleteSchedule(item.id)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="editReportDialog"
      width="500px"
    >
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">
          <v-icon class="white--text">
            event
          </v-icon> Edit Scheduled Report
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <ScheduleEditor
          :edit-mode="true"
          :existing-schedule="schedule"
          @close="close"
          @cancel="closeScheduleEditor"
          @save="updateSchedule"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';

import { bizvu_schedule_url } from '@/config.js';
import ScheduleEditor from './ScheduleEditor';

export default {
  name: 'Schedules',
  components: {
    ScheduleEditor,
  },
  filters: {
    convertToDays(data) {
      const dayString = ['Sun', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'];
      return data.map((day) => dayString[day]).join(', ');
    },
    formatDayOfMonth(day) {
      return moment().date(day).format('Do');
    },
  },
  props: {
    dialogueOpen: Boolean,
    accountNames: Object,
  },
  data() {
    return {
      schedules: [],
      editReportDialog: false,
      schedulesLoading: false,
      schedule: null,
    };
  },
  computed: {
    userHasAccessToMultipleAccounts() {
      return Object.keys(this.accountNames).length > 1;
    },
    reportHeaders() {
      const reportHeaders = [
        { text: 'Name', value: 'name' },
        { text: 'Enabled', value: 'enabled' },
        { text: 'Interval', value: 'interval' },
        { text: 'Time', value: 'time' },
        { text: 'Day(s)', value: 'day' },
      ];

      if (this.userHasAccessToMultipleAccounts) {
        reportHeaders.push({ text: 'Account', value: 'account' });
      }

      reportHeaders.push({ text: 'Actions', value: 'actions', align: 'right' });
      return reportHeaders;
    },
    ...mapGetters([
      'authentication',
    ]),
  },
  watch: {
    dialogueOpen(dialogueOpen) {
      if (dialogueOpen) {
        this.loadSchedules();
      }
    },
  },
  mounted() {
    this.loadSchedules();
  },
  methods: {
    async loadSchedules() {
      this.schedulesLoading = true;
      try {
        const { data: schedules } = await axios.get(`${bizvu_schedule_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/schedule`);
        this.schedules = schedules;
        this.$emit('schedulesLoaded', schedules);
      } catch (err) {
        console.error('Failed to load schedules', err);
      }
      this.schedulesLoading = false;
    },
    editSchedule(schedule) {
      this.editReportDialog = true;

      setTimeout(() => {
        this.schedule = schedule;
      }, 0);
    },
    updateSchedule(schedule) {
      const { id: scheduleId } = schedule;
      this.schedulesLoading = true;

      axios.put(`${bizvu_schedule_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/schedule/${scheduleId}`, schedule, {
        dataType: 'json',
      })
        .then(() => {
          this.schedulesLoading = false;
          this.editReportDialog = false;
          this.loadSchedules();
        })
        .catch((error) => {
          this.schedulesLoading = false;
          if (error.response) {
            if (error.response.status === 404) {
              this.loadSchedules();
            }
          } else {
            console.error('ERROR', error);
          }
        });
    },
    deleteSchedule(scheduleId) {
      this.schedulesLoading = true;
      axios.delete(`${bizvu_schedule_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/schedule/${scheduleId}`)
        .then(() => {
          this.schedulesLoading = false;
          this.loadSchedules();
        })
        .catch((error) => {
          this.schedulesLoading = false;
          if (error.response) {
            if (error.response.status === 404) {
              this.loadSchedules();
            }
          } else {
            console.error('ERROR', error);
          }
        });
    },
    runSchedule(schedule) {
      this.$emit('snack', 'Running schedule');
      this.$set(schedule, 'running', true);
      axios.post(`${bizvu_schedule_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/run-schedule/${schedule.id}`)
        .then(() => {
          this.$emit('snack', 'Schedule Run');
          this.$emit('scheduleRun');
        })
        .catch((e) => {
          console.error(e);
          this.$emit('snack', 'Failed to run schedule', e);
        })
        .finally(() => {
          schedule.running = false;
        });
    },
    close() {
      this.$emit('close');
    },
    closeScheduleEditor() {
      this.schedule = null;
      this.editReportDialog = false;
    },
  },
};

</script>
