<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Edit Group
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container v-if="forbidden">
      <v-layout>
        <v-flex
          xs12
          md8
        >
          <p class="ml-5 headline red">
            You are not allowed to access group {{ groupId }}!
          </p>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container v-else>
      <v-form
        v-if="group"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs5>
            <v-text-field
              v-model="group.name"
              :readonly="!canUpdateGroup"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex
            xs12
            sm10
          >
            <v-expansion-panels
              focusable
              class="my-3"
            >
              <Roles
                v-if="canViewGroupEntitlements"
                :accounts="accounts"
                :selected-roles="selectedRoles"
                :group="group"
                @selectedRolesChanged="updateSelectedRoles"
              />
              <Functions
                v-if="canViewGroupEntitlements"
                :accounts="accounts"
                :group="group"
                @selectedFunctionsChanged="updateSelectedFunctions"
              />

              <v-expansion-panel v-if="canViewGroupMembership">
                <v-expansion-panel-header>
                  <div class="subtitle-1">
                    <v-icon class="mr-3">
                      build
                    </v-icon>Users
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card class="px-5 mb-3">
                    <v-data-table
                      ref="users"
                      v-model="selectedUsers"
                      :headers="usersHeaders"
                      :items="usersToDisplay"
                      item-key="user_id"
                      :show-select="canUpdateGroupMembership"
                      sort-by="fullname"
                      :search="$store.getters.searchQuery"
                      :loading="usersLoading"
                      @toggle-select-all="toggleAllUsers"
                    >
                      <template #item.data-table-select="{ isSelected, select }">
                        <v-simple-checkbox
                          color="primary"
                          :value="isSelected"
                          @input="select($event)"
                        />
                      </template>
                      <template #item.email="props">
                        <td class="text-left">
                          {{ props.item.emails[0].email }}
                        </td>
                      </template>
                      <template #item.active="props">
                        <td class="text-center">
                          <v-icon
                            v-if="props.item.active"
                            color="primary"
                          >
                            toggle_on
                          </v-icon>
                          <v-icon
                            v-else
                            color="grey"
                          >
                            toggle_off
                          </v-icon>
                        </td>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="canUpdateGroup || canUpdateGroupMembership"
          class="mt-2"
        >
          <v-btn
            primary
            color="primary"
            dark
            @click="updateGroup"
          >
            Save
          </v-btn>
          <v-btn
            class="ml-2"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import Roles from '@/components/user/RolesPanel';
import Functions from '@/components/user/FunctionsPanel';
import permittedFunction from '../../permittedFunction';

export default {
  name: 'Group',
  components: {
    Roles,
    Functions,
  },
  data() {
    return {
      forbidden: false,
      showPwd: false,
      valid: false,
      groupId: this.$route.params.groupId,
      group: null,
      groupLoading: true,
      accounts: [],
      users: [],
      selectedRoles: [],
      selectedFunctions: [],
      selectedUsers: [],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => (v && v.length <= 30) || 'Address must not have more than 30 characters',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length <= 30) || 'Address must not have more than 30 characters',
      ],
      usersHeaders: [
        { text: 'Name', value: 'fullname' },
        { text: 'Email', value: 'email' },
        { text: 'Active', value: 'active' },
      ],
      usersLoading: false,
    };
  },
  computed: {
    filteredUsers() {
      // THIS MAY BREAK IF VUETIFY CHANGES DATATABLE INTERNAL STRUCTURE!
      return this.$refs.users.$children[0].filteredItems;
    },
    canUpdateGroup() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>', 'PUT');
    },
    canViewGroupEntitlements() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>/entitlements/<entitlement_id>', 'GET');
    },
    canViewGroupMembership() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>/members', 'GET');
    },
    canUpdateGroupMembership() {
      return this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>/members/<user_id>', 'POST')
        && this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/groups/<group_id>/members/<user_id>', 'DELETE');
    },
    canViewAccounts() {
      return this.isPermitted('/tenants/<tenant_name>/accounts', 'GET');
    },
    usersToDisplay() {
      if (this.canUpdateGroupMembership) {
        return this.users;
      }
      return this.selectedUsers;
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  mounted() {
    this.loadGroup();
    this.loadAccounts();
    this.loadUsers();
  },
  methods: {
    updateGroup() {
      this.group.roles = this.selectedRoles.map((r) => ({
        role_id: r.role_id,
        entity_id: r.entity_id,
        entity_type: r.entity_type,
      }));

      this.group.functions = this.selectedFunctions.map((f) => ({
        function_id: f.function_id,
        entity_id: f.entity_id,
        entity_type: f.entity_type,
      }));

      this.group.users = this.selectedUsers.map((user) => user.user_id);

      this.group.entitlements.forEach((e) => {
        delete e.functions;
        delete e.resource;
        delete e.roles;
      });

      axios.put(`${config.bizvu_user_url}/account/${this.selectedAccountId}/group/${this.groupId}`, this.group)
        .then(() => {
          this.$emit('snack', 'Group successfully updated!');
          this.$router.push('/groups');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    loadGroup() {
      const { groupId } = this.$route.params;

      axios.get(`${config.bizvu_user_url}/account/${this.selectedAccountId}/group/${groupId}`)
        .then((response) => {
          this.groupLoading = false;
          this.group = response.data;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.forbidden = true;
          }
          console.error('ERROR', error);
        });
    },
    loadAccounts() {
      if (!this.canViewAccounts) {
        return;
      }
      axios.get(config.BTL_API_ACCOUNT_URL)
        .then((response) => {
          this.accounts = response.data;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    loadUsers() {
      this.usersLoading = true;
      axios.get(`${config.bizvu_user_url}/account/${this.selectedAccountId}/user`)
        .then((response) => {
          this.users = response.data;
          this.usersLoading = false;
          this.loadUsersForGroup();
        })
        .catch((error) => {
          this.usersLoading = false;
          console.error('ERROR', error);
        });
    },
    loadUsersForGroup() {
      axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/groups/${this.groupId}/members`)
        .then((response) => {
          this.selectedUsers = response.data;
        });
    },
    updateSelectedRoles(updatedRoles) {
      this.selectedRoles = updatedRoles.slice();
      this.group.updateEntitlements = true;
    },
    updateSelectedFunctions(functions) {
      this.selectedFunctions = functions.slice();
      this.group.updateEntitlements = true;
    },
    toggleAllUsers(selectAllEvent) {
      if (selectAllEvent.value) {
        this.filteredUsers.forEach((func) => {
          const index = this.selectedUsers.indexOf(func);
          if (index === -1) {
            this.selectedUsers.push(func);
          }
        });
      } else {
        this.filteredUsers.forEach((func) => {
          const index = this.selectedUsers.indexOf(func);
          if (index !== -1) {
            this.$delete(this.selectedUsers, index);
          }
        });
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>
</style>
