<template>
  <div class="pa-2">
    <div class="analytics-dashboard-view">
      <v-row>
        <template v-for="item in layout">
          <v-col
            :key="item.i"
          >
            <chart-container
              :key="'i' + item.i + 'w' + item.w + 'h' + item.h + 'x' + item.x + 'y' + item.y"
              :grid="item"
            />
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import ChartContainer from '@/components/analytics/chart/ChartContainer';
import dashboardLayout from '@/utils/analytics/layouts/default';

export default {
  name: 'DashboardView',
  components: {
    ChartContainer,
  },
  data() {
    return {
      layout: [
      ],
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.layout = dashboardLayout;
  },
  methods: {
  },
};
</script>

<style>
.analytics-dashboard-view {
  margin-top: 80px;
}
.analytics-dashboard-view .chart-container {
  padding: 10px;
}
.analytics-dashboard-view .bar-chart-container {
  width: 100%;
  height: 100%
}
.analytics-dashboard-view .grid-container {
  padding: 10px;
  border: 1px solid #ccc;
}
.analytics-dashboard-view .loading-container {
  width: 100%;
  height: 100%;
  padding: 30px
}
</style>
