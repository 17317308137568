export default [
  {
    x: 0, y: 0, w: 9, h: 10, i: '0', type: 'bar', static: false, labelField: 'call_direction.keyword', yField: 'call_datetime_iso', title: '1 year - monthly', interval: 'interval1year',
  },
  {
    x: 6, y: 0, w: 3, h: 10, i: '1', type: 'doughnut', static: false, labelField: 'call_status.keyword', yField: 'call_datetime_iso', title: '2 years - monthly', interval: 'interval2years',
  },
  {
    x: 6, y: 0, w: 12, h: 10, i: '2', type: 'line', static: false, labelField: 'call_type.keyword', yField: 'call_datetime_iso', title: '1 year - monthly', interval: 'interval1year',
  },
  {
    x: 6, y: 0, w: 12, h: 10, i: '3', type: 'bar', static: false, labelField: 'call_type.keyword', yField: 'call_datetime_iso', title: '1 week - 6 hour interval', interval: 'interval1week',
  },
  {
    x: 6, y: 0, w: 12, h: 10, i: '4', type: 'bar', static: false, labelField: 'call_type.keyword', yField: 'call_datetime_iso', title: '2 weeks - 12 hour interval', interval: 'interval2weeks',
  },
  {
    x: 6, y: 0, w: 12, h: 10, i: '5', type: 'bar', static: false, labelField: 'call_type.keyword', yField: 'call_datetime_iso', title: '1 month - 1 day interval (Dec 2021)', interval: 'interval1month',
  },
  {
    x: 6, y: 0, w: 12, h: 10, i: '6', type: 'bar', static: false, labelField: 'call_type.keyword', yField: 'call_datetime_iso', title: '6 months - weekly interval', interval: 'interval6months',
  },
  {
    x: 6, y: 0, w: 12, h: 10, i: '7', type: 'bar', static: false, labelField: 'call_direction.keyword', yField: 'call_datetime_iso', title: '1 year - monthly', interval: 'interval1year',
  },
  {
    x: 6, y: 0, w: 12, h: 10, i: '8', type: 'bar', static: false, labelField: 'call_direction.keyword', yField: 'call_datetime_iso', title: '2 years - monthly', interval: 'interval2years',
  },
];
