<template>
  <div>
    <v-layout wrap>
      <v-flex xs4>
        <p class="subtitle-1">
          Access control list
          <v-btn
            icon
            small
            primary
            @click="addIP"
          >
            <v-icon>add_box</v-icon>
          </v-btn>
        </p>
      </v-flex>
      <v-flex
        v-if="acl && acl.length>0"
        xs8
      >
        <v-layout wrap>
          <v-flex
            v-for="(t,i) in acl"
            :key="i"
            xs12
          >
            <IpInput
              :ip="t"
              :idx="i"
              @removeIP="removeIP"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import IpInput from '@/components/acl/IpInput';

export default {
  name: 'Acl',
  components: {
    IpInput,
  },
  props: {
    acl: Array,
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    addIP() {
      this.acl.push({
        ip: '',
        port: '',
        protocol: 'udp',
      });
    },
    removeIP(i) {
      this.acl.splice(i, 1);
    },
  },
};
</script>

<style scoped>
</style>
