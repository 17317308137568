<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/appliances/<applianceid>', 'PUT') && isPermitted('/tenants/<tenant_name>/accounts/<account_id>/devices/<device_id>', 'PUT')"
        class="mr-5"
      >
        Edit Appliance
      </v-toolbar-title>
      <v-toolbar-title
        v-else
        class="mr-5"
      >
        Browse Appliance
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/appliances/<applianceid>', 'DELETE')"
        text
        color="blue"
        @click="delDialog=true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="provDialog=true"
      >
        <v-icon class="mr-2">
          launch
        </v-icon>Provisioning Info
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs8>
            <v-text-field
              v-model="appliance.Name"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-menu
          v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/devices', 'POST') && isPermitted('/tenants/<tenant_name>/accounts/<account_id>/appliances/<applianceid>', 'PUT') && isPermitted('/tenants/<tenant_name>/accounts/<account_id>/devices/<device_id>', 'PUT')"
          offset-y
        >
          <template #activator="{on}">
            <v-btn
              text
              color="primary"
              dark
              v-on="on"
            >
              <v-icon class="mr-2">
                add_box
              </v-icon>Create Device
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="hasSBC()"
              @click="addSBC"
            >
              <v-list-item-title>SBC</v-list-item-title>
            </v-list-item>
            <v-list-item @click="addVPN">
              <v-list-item-title>VPN</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-layout class="mt-2" />
        <v-expansion-panels
          v-if="appliance.Devices.length>0"
          v-model="panel"
          multiple
          focusable
          class="my-3 mx-2"
        >
          <v-expansion-panel
            v-for="(d,i) in appliance.Devices"
            :key="i"
          >
            <v-expansion-panel-header>
              <div
                v-if="d.DeviceType==='VPN'"
                class="subtitle-1"
              >
                <v-icon class="mr-3">
                  vpn_lock
                </v-icon>VPN - {{ d.Description }}
              </div>
              <div
                v-if="d.DeviceType==='SBC'"
                class="subtitle-1"
              >
                <v-icon class="mr-3">
                  developer_board
                </v-icon>SBC - {{ d.Description }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card
                v-if="d.DeviceType==='VPN'"
                class="px-5 mb-3"
              >
                <v-layout>
                  <v-flex
                    xs6
                    class="pr-2"
                  >
                    <v-text-field
                      v-model="d.Description"
                      label="Name"
                      :rules="nameRules"
                      :counter="60"
                      required
                    />
                    <v-text-field
                      v-model="d.LANIP"
                      label="LAN IP"
                      :rules="ipRules"
                      required
                    />
                    <v-text-field
                      v-model="d.LanIface"
                      label="LAN Interface"
                    />
                    <v-text-field
                      v-model="d.VPNIP"
                      label="VPN IP"
                      readonly
                      disabled
                    />
                    <v-text-field
                      v-model="d.Version"
                      label="VPN Version"
                      readonly
                      disabled
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs5>
                    <v-select
                      v-if="sites.length>1"
                      v-model="d.SiteId"
                      flat
                      :items="sites"
                      item-text="SiteName"
                      item-value="SiteId"
                      label="Site"
                    />
                  </v-flex>
                </v-layout>
                <v-card-actions>
                  <v-layout
                    align-center
                    justify-end
                  >
                    <v-btn
                      v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/devices/<device_id>', 'DELETE')"
                      icon
                      @click="closePanel(i)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-layout>
                </v-card-actions>
              </v-card>
              <v-card
                v-if="d.DeviceType==='SBC' && !d.deleted"
                class="px-5 mb-3"
              >
                <v-layout v-if="trunks.length>0">
                  <v-flex
                    xs6
                    class="pr-2"
                  >
                    <v-text-field
                      v-model="d.Description"
                      label="Name"
                      :rules="nameRules"
                      :counter="80"
                    />
                    <v-select
                      v-model="d.TrunkId"
                      flat
                      :items="trunks"
                      item-text="Description"
                      item-value="TrunkID"
                      label="Trunk"
                    />
                    <v-text-field
                      v-model="d.Version"
                      label="SBC Version"
                      readonly
                      disabled
                    />
                    <v-select
                      v-model="d.SBCType"
                      flat
                      :items="sbcTypes"
                      :rules="sbcTypeRules"
                      label="Type"
                    />
                    <v-text-field
                      v-if="d.SBCType === 'DualLAN'"
                      v-model="d.LANIP"
                      label="External IP"
                      :counter="15"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-if="d.SBCType === 'DualLAN'"
                      v-model="d.DualLANIP"
                      label="Internal IP"
                      :counter="15"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-model="d.LIMIT_SPS"
                      label="Max Sessions Per Second"
                    />
                    <v-text-field
                      v-model="d.LIMIT_MAX_SESSIONS"
                      label="Max Concurrent Sessions"
                    />
                    <v-switch
                      v-model="d.SUPPORT_SEPARATE_DTMF_SSRC"
                      label="Support Separate DTMF SSRC"
                    />
                    <v-switch
                      v-model="d.ATMOSO"
                      label="Is Atmoso"
                    />
                    <v-switch
                      v-model="d.DISABLE_T38"
                      label="Disable T38"
                    />
                    <v-text-field
                      v-if="d.ATMOSO"
                      v-model="d.RPS_DEVICE_IP"
                      label="RPS DEVICE IP"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-if="d.ATMOSO"
                      v-model="d.RPS_GATEWAY_IP"
                      label="RPS GATEWAY IP"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-if="d.ATMOSO"
                      v-model="d.CCS_DEVICE_IP"
                      label="CCS DEVICE IP"
                      :rules="ipRules"
                    />
                    <v-text-field
                      v-if="d.ATMOSO"
                      v-model="d.CCS_GATEWAY_IP"
                      label="CCS GATEWAY IP"
                      :rules="ipRules"
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs5>
                    <v-select
                      v-model="d.SiteId"
                      flat
                      :items="sites"
                      item-text="SiteName"
                      item-value="SiteId"
                      label="Site"
                    />
                  </v-flex>
                </v-layout>
                <v-btn
                        text
                        color="blue"
                        @click="toggleDevInfoDialog(d)"
                      >
                        Show/Hide Developer Info
                      </v-btn>
                    <v-dialog v-model="devInfoDialog" max-width="800">
                      <v-card class="developer-box">
                        <v-card-title class="headline">
                          Developer Information
                        </v-card-title>
                        <v-container>
                          <v-layout class="px-2">
                            <v-flex xs12>
                              <!-- Display formatted previous data -->
                              <v-textarea
                                v-if="d.PreviousData"
                                readonly
                                label="Previous Data"
                                v-model="formattedPreviousData"
                                class="dev-info-textarea"
                                rows="22"
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn text color="grey" @click="devInfoDialog=false">Close</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                <v-card-actions>
                  <v-layout
                    align-center
                    justify-end
                  >
                    <v-btn
                      v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/devices/<device_id>', 'DELETE')"
                      icon
                      @click="closePanel(i)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-layout>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-layout class="mt-2">
          <v-btn
            v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/appliances/<applianceid>', 'PUT') && isPermitted('/tenants/<tenant_name>/accounts/<account_id>/devices/<device_id>', 'PUT')"
            primary
            color="primary"
            dark
            @click="updateAppliance"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
      <v-dialog
        v-model="delDialog"
        persistent
        max-width="300"
      >
        <v-card>
          <v-card-title class="headline">
            Delete Appliance
          </v-card-title>
          <v-container>
            <v-layout class="px-2">
              <v-flex xs12>
                <p class="subtitle-1">
                  Do you really want to delete this appliance?
                </p>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              primary
              color="primary"
              dark
              @click="deleteAppliance"
            >
              Delete
            </v-btn>
            <v-btn
              text
              color="grey"
              @click="delDialog=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="provDialog"
        persistent
        max-width="600"
      >
        <v-card>
          <v-card-title class="headline">
            Provisioning Information
          </v-card-title>
          <v-container>
            <v-layout class="px-2">
              <v-flex xs12>
                <v-textarea
                  ref="provCommand"
                  v-model="appliance.ProvisionCommand"
                  outline
                  readonly
                  label="Command"
                  append-outer-icon="content_copy"
                  @click:append-outer="copy"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="grey"
              @click="provDialog=false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';

import config from '@/config.js';
import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'Appliance',
  data() {
    return {
      selectedDeviceParsed: null,
      devInfoDialog: false, // New property to control the developer information dialog
      valid: false,
      applianceId: this.$route.params.id,
      delDialog: false,
      provDialog: false,
      sites: [],
      trunks: [],
      panel: [],
      deletedDevices: [],
      SiteId: undefined,
      appliance: {
        Name: '',
        Description: '',
        Devices: [],
      },
      siteTypes: [
        { value: 'OFFICE', text: 'Office' },
        { value: 'HOME', text: 'Home' },
      ],
      sbcTypes: [
        { value: 'Standard', text: 'Standard' },
        { value: 'DualLAN', text: 'Dual LAN' },
      ],
      timeZones: [
        'Europe/London',
        'US/Pacific',
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      descrRules: [
        (v) => (v && v.length <= 80) || 'Description must not have more than 80 characters',
      ],
      sbcTypeRules: [
        (v) => !!v || 'SBC Type is required',
      ],
      ipRules: [
        (v) => (v && /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v)) || 'Not a valid IP address format',
      ],
    };
  },
  computed: {
    formattedPreviousData() {
      if (this.selectedDeviceParsed) {
        return JSON.stringify(this.selectedDeviceParsed, null, 2);
      }
      return '';
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  created() {
    this.loadSites();
    this.loadTrunks();
    this.loadAppliance();
  },
  methods: {
    toggleDevInfoDialog(d) {
      this.devInfoDialog = !this.devInfoDialog;
      this.selectedDeviceParsed = d.PreviousData ? JSON.parse(d.PreviousData) : null;
      // Format PreviousData directly
      if (this.selectedDeviceParsed) {
        this.selectedDeviceParsed.PreviousData = this.formatPreviousData(this.selectedDeviceParsed.PreviousData);
      }
    },
    // Method to format previous data using regular expression
    formatPreviousData(data) {
      // Remove outer curly braces
      const trimmedData = data.trim();
      if (trimmedData.startsWith('{') && trimmedData.endsWith('}')) {
        return trimmedData.slice(1, -1);
      }
      // Define regular expression pattern to add indentation
      const regex = /(^|\n)(\s*)(\S.*?)(?=\n|$)/g;
      // Add line breaks and indentation
      return trimmedData.replace(regex, (match, p1, p2, p3) => {
        // If it's the first line, return it as is
        if (p1 === '') return match;
        // Otherwise, add line break and indentation
        return `\n  ${p3}`;
      });
    },
    closePanel(i) {
      if (this.appliance.Devices[i].DeviceId) {
        this.deletedDevices.push(this.appliance.Devices[i]);
      }
      this.appliance.Devices.splice(i, 1);
    },
    loadAppliance() {
      const self = this;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${self.selectedAccountId}/appliances/${this.applianceId}`)
        .then((response) => {
          self.appliance = response.data;
          self.appliance.Devices = self.appliance.Devices.map((device) => ({
            ...device,
            ...JSON.parse(device.Options),
          }));
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    updateAppliance() {
      if (!this.$refs.form.validate()) {
        this.$emit('snack', 'Please fix validation errors before continuing', true);
        return;
      }

      const aPayload = { Name: this.appliance.Name };
      axios.put(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/appliances/${this.applianceId}`, aPayload)
        .then(() => {
        // STEP 1: Remove deleted devices
          const promiseArray = [];
          this.deletedDevices.forEach((dd) => {
            promiseArray.push(axios.delete(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/devices/${dd.DeviceId}`));
          });
          // STEP 2: Create or Update Devices
          this.appliance.Devices.forEach((d) => {
            d.ApplianceId = this.appliance.ApplianceId;

            let options = {
              LIMIT_SPS: d.LIMIT_SPS,
              LIMIT_MAX_SESSIONS: d.LIMIT_MAX_SESSIONS,
            };

            if (d.ATMOSO) {
              const {
                RPS_DEVICE_IP,
                RPS_GATEWAY_IP,
                CCS_DEVICE_IP,
                CCS_GATEWAY_IP,
              } = d;

              options = {
                ...options,
                ATMOSO: true,
                RPS_DEVICE_IP,
                RPS_GATEWAY_IP,
                CCS_DEVICE_IP,
                CCS_GATEWAY_IP,
              };
            }

            if (d.SUPPORT_SEPARATE_DTMF_SSRC) {
              options = {
                ...options,
                SUPPORT_SEPARATE_DTMF_SSRC: true,
              };
            }

            if (d.DISABLE_T38) {
              options = {
                ...options,
                DISABLE_T38: true,
              };
            }

            d.Options = JSON.stringify(options);

            delete d.SUPPORT_SEPARATE_DTMF_SSRC;
            delete d.ATMOSO;
            delete d.RPS_DEVICE_IP;
            delete d.RPS_GATEWAY_IP;
            delete d.CCS_DEVICE_IP;
            delete d.CCS_GATEWAY_IP;
            delete d.DISABLE_T38;
            delete d.LIMIT_SPS;
            delete d.LIMIT_MAX_SESSIONS;

            if (d.DeviceId) {
            // Update existing Device
              promiseArray.push(axios.put(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/devices/${d.DeviceId}`, d));
            } else {
            // Create new Device
              promiseArray.push(axios.post(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/devices`, d));
            }
          });
          // Run update requests
          Promise.all(promiseArray)
            .then(() => {
              this.$emit('snack', 'Appliance successfully updated!');
              this.$router.back();
            })
            .catch((error) => {
              this.$emit('snack', 'Appliance update failed', true);
              console.error('ERROR', error.response);
            });
        })
        .catch((error) => {
          if (error.response) {
            console.error('ERROR', error);
            this.$emit('snack', 'Appliance update failed', true);
          }
        });
    },
    deleteAppliance() {
      this.delDialog = false;

      axios.delete(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/appliances/${this.applianceId}`)
        .then(() => {
          this.$emit('snack', 'Appliance successfully deleted!');
          this.$router.back();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    addSBC() {
      const device = {
        DeviceType: 'SBC',
        Description: this.appliance.Name,
        SiteId: undefined,
        TrunkId: undefined,
        ApplianceId: undefined,
        SBCType: 'Standard',
        DualLANIP: null,
        LANIP: null,
      };
      this.panel.push(this.appliance.Devices.length);
      this.appliance.Devices.push(device);
    },
    addVPN() {
      const device = {
        DeviceType: 'VPN',
        Description: '',
        LANIP: '',
        SiteId: undefined,
        ApplianceId: undefined,
      };
      this.panel.push(this.appliance.Devices.length);
      this.appliance.Devices.push(device);
    },
    loadSites() {
      const self = this;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${self.selectedAccountId}/sites`)
        .then((response) => {
          self.sites = response.data;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    loadTrunks() {
      const self = this;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${self.selectedAccountId}/trunks`)
        .then((response) => {
          self.trunks = sortBy(response.data, (trunk) => trunk.Description.toLowerCase());
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    hasSBC() {
      return (this.appliance.Devices.filter((d) => d.DeviceType === 'SBC').length > 0);
    },
    hasVPN() {
      return (this.appliance.Devices.filter((d) => d.DeviceType === 'VPN').length > 0);
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    copy() {
      const input = this.$refs.provCommand;
      input.focus();
      document.execCommand('selectAll');
      document.execCommand('copy');
    },
  },
};
</script>

<style scoped>

.dev-info-textarea textarea {
  resize: vertical; /* Allow vertical resizing if needed */
  padding-bottom: 10em;
  min-height: 500px;
}
</style>
