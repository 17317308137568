<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Analytics
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <query @snack="snack" />
    </v-container>
  </div>
</template>

<script>
import query from '@/components/query/QueryBuilder';

export default {
  name: 'QueryBuilderView',
  components: {
    query,
  },
  methods: {
    snack(message, error) {
      this.$emit('snack', message, error);
    },
  },
};
</script>
