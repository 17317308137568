<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card>
        <v-spacer class="pt-8" />
        <v-card-text>
          <!-- Options laid out in a vertical stack -->
          <v-container>
            <v-row>
              <v-col cols="12" v-for="option in items" :key="option.value">
                <v-btn
                  block
                  :color="noneGeo === option ? 'primary' : 'default'"
                  @click="noneGeo = option"
                >
                  {{ option.text }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'QueryBuilderNoneGeo',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      noneGeo: null,
      items: [
        { value: 'EXCLUDED', text: 'NONGEO Excluded' },
        { value: 'ONLY', text: 'NONGEO Only' },
      ],
    };
  },
  mounted() {
    if (this.editMode) {
      this.onShow();
    }
    if (this.item && this.item.defaultValue) {
      this.noneGeo = this.items.find((option) => option.value === this.item.defaultValue);
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'noneGeo';
      item.defaultValue = this.noneGeo ? this.noneGeo.value : null;
      const text = this.noneGeo ? this.noneGeo.text : null;
      const noneGeo = this.noneGeo ? this.noneGeo.value : null;

      let query = {
        query: {
          bool: {
            must_not: [
              {
                match: {
                  phone_type: 'NONGEO',
                },
              },
            ],
          },
        },
      };
      if (noneGeo === 'ONLY') {
        query = {
          query: {
            bool: {
              must: [
                {
                  match: {
                    phone_type: 'NONGEO',
                  },
                },
              ],
            },
          },
        };
      }

      this.$emit('submit', {
        item,
        query,
        text,
      });
      this.dialog = false;
      this.noneGeo = null;
    },
  },
};
</script>
