<template>
  <v-autocomplete
    v-model="timeZone"
    :items="timeZones"
    label="Time Zone"
    @change="$emit('update', timeZone)"
  >
    <template #item="{ item }">
      <small class="font-weight-light">({{ item.offset }})</small> &nbsp; {{ item.name }} <v-spacer />  <small class="font-weight-light">({{ item.value }})</small>
    </template>
    <template #selection="{ item }">
      <div><small class="font-weight-light">({{ item.value }} {{ item.offset }})</small> &nbsp; {{ item.name }}</div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TimeZonePicker',
  props: {
    initZone: String,
  },
  data() {
    return {
      timeZone: null,
    };
  },
  computed: {
    ...mapGetters([
      'timeZones',
    ]),
  },
  created() {
    if (this.initZone) {
      this.timeZone = this.initZone;
    }
  },
};
</script>
