<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Rates
      </v-toolbar-title>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-container fluid>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="table in tablesWithData"
          :key="table.id"
        >
          <v-expansion-panel-header>{{ table.name }} Rates</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="table.headers"
              :items="table.rates"
              :loading="loading"
              :search="$store.getters.searchQuery"
              :item-key="table.itemKey"
              :items-per-page="100"
              :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
              fixed-header
              :custom-sort="customSort"
              height="60vh"
            >
              <template
                v-if="permittedInternationalCodes"
                #item.CountryName="{ item }"
              >
                <v-tooltip
                  bottom
                  open-delay="500"
                >
                  <template #activator="{on}">
                    <span
                      class="link"
                      @click="showCodes({
                        CountryId: item.CountryId,
                        name: item.CountryName
                      })"
                      v-on="on"
                    >{{ item.CountryName }}</span>
                  </template>
                  <span>Show Codes</span>
                </v-tooltip>
              </template>
              <template
                v-if="permittedNongeoCodes"
                #item.Name="{ item }"
              >
                <v-tooltip
                  bottom
                  open-delay="500"
                >
                  <template #activator="{on}">
                    <span
                      class="link"
                      @click="showCodes({
                        CBId: item.CBId,
                        name: item.Name
                      })"
                      v-on="on"
                    >{{ item.Name }}</span>
                  </template>
                  <span>Show Codes</span>
                </v-tooltip>
              </template>
              <template #item.SellDayRate="{ item }">
                <span>{{ item.SellDayRate | formatMoney }}</span>
              </template>
              <template #item.SellEveRate="{ item }">
                <span>{{ item.SellEveRate | formatMoney }}</span>
              </template>
              <template #item.SellWendRate="{ item }">
                <span>{{ item.SellWendRate | formatMoney }}</span>
              </template>
              <template #item.SellConFee="{ item }">
                <span>{{ item.SellConFee | formatMoney }}</span>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-dialog
        v-model="codesDialog"
        max-width="430"
      >
        <v-card>
          <v-card-title>{{ dialogHeader }}</v-card-title>
          <v-data-table
            :headers="[{ text: 'Code', value: 'Code' }]"
            :items="codes"
          />
          <v-card-actions class="mr-4">
            <v-spacer />
            <v-btn
              color="primary"
              @click="codesDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import customSort from '@/utils/customSort';
import permittedFunction from '@/permittedFunction.js';

import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'Rates',
  filters: {
    formatMoney(value) {
      return currencyFormatter(value);
    },
  },
  data() {
    const tables = [
      {
        id: uuidv4(),
        name: 'National',
        endpoint: 'nationals',
        itemKey: 'NRId',
        headers: [
          { text: 'Carrier', value: 'Carrier' },
          { text: 'Cost Day Rate', value: 'CostDayRate' },
          { text: 'Day Rate Per Minute', value: 'SellDayRate' },
          { text: 'Evening Rate per Minute', value: 'SellEveRate' },
          { text: 'Weekend Rate per Minute', value: 'SellWendRate' },
          { text: 'Connection Fee', value: 'SellConFee' },
        ],
      },
      {
        id: uuidv4(),
        name: 'International',
        endpoint: 'countries',
        itemKey: 'CountryId',
        headers: [
          { text: 'Country', value: 'CountryName' },
          { text: 'Carrier', value: 'Carrier' },
          { text: 'Day Rate per Minute', value: 'SellDayRate' },
          { text: 'Evening Rate per Minute', value: 'SellEveRate' },
          { text: 'Weekend Rate per Minute', value: 'SellWendRate' },
        ],
      },
      {
        id: uuidv4(),
        name: 'Nongeo',
        endpoint: 'chargebands',
        itemKey: 'CBId',
        headers: [
          { text: 'Name', value: 'Name' },
          { text: 'Carrier', value: 'Carrier' },
          { text: 'Description', value: 'Description' },
          { text: 'Day Rate per min', value: 'SellDayRate' },
          { text: 'Evening Rate per Minute', value: 'SellEveRate' },
          { text: 'Weekend Rate per Minute', value: 'SellWendRate' },
          { text: 'Connection Fee', value: 'SellConFee' },
        ],
      },
    ];
    return {
      config,
      loading: false,
      tables,
      permittedInternationalCodes: false,
      permittedNongeoCodes: false,
      codesDialog: false,
      dialogHeader: '',
      codes: [],
    };
  },
  computed: {
    tablesWithData() {
      return this.tables.filter((table) => table.rates && table.rates.length);
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    accountFilter() {
      this.loadData();
    },
    selectedAccountId() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        await this.loadRates();
        this.permittedInternationalCodes = this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/countries/<countryid>/codes', 'GET');
        this.permittedNongeoCodes = this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/chargebands/<cb_id>/codes', 'GET');
      } catch (error) {
        console.error('Could not load data: ', error.message);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadRates() {
      this.tables.forEach(async (entry) => {
        try {
          if (this.isPermitted(`/tenants/<tenant_name>/accounts/<account_id>/${entry.endpoint}`, 'GET')) {
            entry.rates = (await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/${entry.endpoint}`)).data;
            this.tables.push('a dummy value that will get filtered out anyway but needs to be pushed to trigger the refresh');
          }
        } catch (error) {
          console.error(`Failed to load ${entry.name} rates`, error);
          this.$emit('snack', `Failed to load ${entry.name} rates`, true);
        }
      });
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    async showCodes(params) {
      let path;
      if (params.CountryId) {
        path = `${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/countries/${params.CountryId}/codes`;
      } else if (params.CBId) {
        path = `${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/chargebands/${params.CBId}/codes`;
      } else {
        return;
      }

      try {
        this.codes = (await axios.get(path)).data;
        this.codesDialog = true;
        this.dialogHeader = `Codes for ${params.name}`;
      } catch (error) {
        console.error('Failed to retrieve codes', error);
        this.$emit('snack', 'Failed to retrieve codes.', true);
      }
    },
    customSort: customSort(),
  },
};
</script>

<style>
  .link {
    text-decoration: underline;
    color: #1976d2;
    cursor: pointer;
  }
</style>
