<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
      @click:outside="onCancel"
    >
      <v-card>
        <v-spacer class="pt-8" />
        <v-card-text>
          <!-- Options laid out as buttons -->
          <v-row>
            <v-col cols="12" sm="6" v-for="option in items" :key="option.text">
              <v-btn
                block
                :color="selected.type === option.type ? 'primary' : 'default'"
                @click="selected = option"
              >
                {{ option.text }}
              </v-btn>
            </v-col>
          </v-row>

          <!-- Dynamically loaded component -->
          <component
            :is="selected.type"
            @cancel="onCancel"
            @submit="onSubmit"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import customRange from './customRange';
import pastDays from './pastDays';
import today from './today';
import lastMonth from './LastMonth';
import currentMonth from './CurrentMonth';

export default {
  name: 'QueryBuilderNumber',
  components: {
    customRange,
    pastDays,
    today,
    lastMonth,
    currentMonth,
  },
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      items: [
        { text: 'Today', type: 'today' },
        { text: 'Past [...] days', type: 'pastDays' },
        { text: 'Current Month', type: 'currentMonth' },
        { text: 'Last Month', type: 'lastMonth' },
        { text: 'From / To', type: 'customRange' },
      ],
      selected: { text: 'Today', type: 'today' },
    };
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.selected = { ...this.item.defaultValue };
    }
  },
  methods: {
    onShow() {
      this.dialog = true;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit({ query, text, parameters }) {
      const item = { ...this.item };
      item.type = 'datetime';
      item.defaultValue = this.selected;

      this.$emit('submit', {
        item,
        query,
        text,
        parameters,
      });
      this.dialog = false;
    },
  },
};
</script>
