<script>
import { Doughnut, mixins } from 'vue-chartjs';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

export default {
  name: 'Dashndoughnutchart',
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: {
    labels: Array,
    data: Array,
    options: Object,
    chartData: Object,
  },
  data() {
    return {
    };
  },
  mounted() {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        colorschemes: {
          scheme: Tableau20,
        },
      },
      ...this.options,
    };

    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          label: 'Data One',
          data: this.data,
        },
      ],
    }, chartOptions);
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
