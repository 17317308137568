<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} HCD
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && !HCD.DefaultHCD && isPermitted('/highcostdestinations/<hcdid>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="HCD.HCDCode"
                label="Code"
                :rules="rules.HCDCode"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="HCD.HCDDescription"
                label="Description"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="HCD.HCDCost"
                label="Cost (£0.00)"
                :rules="rules.HCDCost"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="HCD.HCDDuration"
                label="Duration (Minutes)"
                :rules="rules.HCDDuration"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="HCD.HCDCount"
                label="Count"
                :rules="rules.HCDCount"
              />
            </v-layout>
            <v-layout>
              <v-switch
                v-model="HCD.AutoUpdate"
                label="Auto Update"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="HCD.HCDToleranceByHour"
                type="number"
                label="Tolerance By Hour (%)"
                :rules="rules.HCDToleranceByHour"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="HCD.HCDToleranceBySegment"
                type="number"
                label="Tolerance By Segment (%)"
                :rules="rules.HCDToleranceBySegment"
              />
            </v-layout>
            <v-layout>
              <v-switch
                v-model="HCD.HCDIsOutOfHours"
                label="Is Out Of Hours"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-layout>
          <v-flex class="pa-2">
            <v-row>
              <v-btn
                v-if="createMode ? isPermitted('/highcostdestinations', 'POST') : isPermitted('/highcostdestinations/<hcdid>', 'PUT')"
                primary
                color="primary"
                dark
                @click="updateHCD"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this HCD?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteHCD"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'HighCostDestination',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      HCDId: this.$route.params.hcdid,
      HCD: {
        HCDIsOutOfHours: false, // required so need to default to false
        AutoUpdate: true,
      },
      rules: {
        HCDCode: [
          (v) => (v != null && String(v).length > 0) || 'HDC Code is required',
        ],
        HCDCost: [
          (v) => (v != null && String(v).length > 0) || 'HCD Cost is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        HCDDuration: [
          (v) => (v != null && String(v).length > 0) || 'HCD Duration is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        HCDCount: [
          (v) => (v != null && String(v).length > 0) || 'HCD Count is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        HCDToleranceByHour: [
          (v) => !v || !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        HCDToleranceBySegment: [
          (v) => !v || !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
      },
    };
  },
  created() {
    if (this.HCDId === 'new') {
      this.createMode = true;
    } else {
      this.loadHCD();
    }
  },
  methods: {
    async updateHCD() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'HCD successfully updated.';

        // Britannic ORM can't interpret empty strings as null so gotta convert them to nulls ourselves
        this.HCD.HCDToleranceByHour = this.HCD.HCDToleranceByHour === '' ? null : this.HCD.HCDToleranceByHour;
        this.HCD.HCDToleranceBySegment = this.HCD.HCDToleranceBySegment === '' ? null : this.HCD.HCDToleranceBySegment;

        if (this.createMode) {
          await axios.post(`${config.BTL_API_TENANT_URL}/highcostdestinations`, this.HCD);
          snackMessage = 'HCD successfully created.';
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/highcostdestinations/${this.HCDId}`, this.HCD);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating HCD', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} HCD.`, true);
      }
    },
    async loadHCD() {
      try {
        const { data: HCD } = await axios.get(`${config.BTL_API_TENANT_URL}/highcostdestinations/${this.HCDId}`);
        this.HCD = HCD;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve HCD.', true);
        console.error('Failed to retrieve HCD', error);
      }
    },
    async deleteHCD() {
      try {
        await axios.delete(`${config.BTL_API_TENANT_URL}/highcostdestinations/${this.HCDId}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete HCD.', true);
        console.error('Failed to delete HCD', error);
      }
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
