<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="isAuthenticated()"
      v-model="feedbackDrawer"
      :width="750"
      clipped
      right
      temporary
      floating
      app
    >
      <general-feedback
        @closeFeedback="closeFeedback"
      />
    </v-navigation-drawer>

    <v-navigation-drawer
      v-if="isAuthenticated()"
      permanent
      clipped
      fixed
      app
    >
      <v-toolbar flat>
        <v-autocomplete
          v-if="accounts.length > 0"
          style="padding-top: 20px;"
          dense
          flat
          :items="accounts"
          item-text="name"
          item-value="account_id"
          label="Account"
          :value="selectedAccountId"
          @input="updateAccountId"
        />
        <v-chip
          v-else
          color="primary"
          style="width: 100%;"
          outlined
        >
          <v-avatar left>
            <v-icon>domain</v-icon>
          </v-avatar>
          {{ authentication.userAccountName }}
        </v-chip>
      </v-toolbar>
      <v-divider />
      <v-list dense>
        <node-list
          :nodes="items"
        />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <a
                class="caption"
                @click="$router.push('/changelog')"
              >Build version: {{ portalVersion }}</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="isAuthenticated()"
      clipped-left
      color="blue darken-3"
      dark
      app
      fixed
    >
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-3"
      >
        <router-link to="/dashboard">
          <img
            class="logo"
            src="/logo.png"
            alt="logo"
          >
        </router-link>
      </v-toolbar-title>

      <v-text-field
        v-if="isAuthenticated()"
        id="searchBar"
        :value="searchQuery"
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="search"
        label="Search"
        @input="setSearchQuery"
      />

      <v-spacer />

      <v-btn
        v-if="isAuthenticated()"
        fab
        color="blue darken-3"
        dark
        @click="feedbackDrawer = true"
        class="feedback-btn"
      >
        <img src="/feedback.png" alt="Feedback Icon" class="feedback-icon"/>
      </v-btn>

      <btl-alert
        @snack="snack"
      />

      <v-btn
        v-if="authentication.userName"
        icon
        @click="userDialog = true"
      >
        <v-icon>account_circle</v-icon>
      </v-btn>

      <v-dialog
        v-model="userDialog"
        max-width="1000"
      >
        <user-context
          :dialogue-open="userDialog"
          :accounts="accounts"
          @close="userDialog = false"
          @snack="snack"
        />
      </v-dialog>

      <p
        v-if="isAuthenticated()"
        class="user-name subtitle-1"
      >
        {{ authentication.userName }}
      </p>
      <v-btn
        v-if="isAuthenticated()"
        icon
        @click="logoff"
      >
        <v-icon>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view
        v-if="selectedAccountId || $router.currentRoute.name ==='callback'"
        :key="$route.path"
        @snack="snack"
        @newAccount="fetchAccounts"
      />
    </v-main>

    <v-snackbar
      v-for="message in sbMessages"
      :key="message.id"
      v-model="message.snackbar"
      :color="message.sbError ? 'error' : 'info'"
      :absolute="true"
      :style="`bottom: ${message.offset}px;`"
    >
      {{ message.sbText }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="closeSnack(message);"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <ChangelogBanner />
  </v-app>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { isAuthenticated, logout } from '@/auth/AuthService';
import permittedFunction from '@/permittedFunction.js';

import UserContext from '@/components/user/UserContext';
import BtlAlert from '@/components/alert/BtlAlert';
import GeneralFeedback from '@/components/feedback/GeneralFeedback';
import ChangelogBanner from '@/components/ChangelogBanner';
import NodeList from '@/components/NodeList';
import Snacks from './utils/snacks';
import changelog from '../changelog.json';

const { BTL_ENV, BTL_API_ACCOUNT_URL, feature_billing_menu } = require('@/config');

export default {
  name: 'BritannicPortal',
  components: {
    UserContext,
    BtlAlert,
    GeneralFeedback,
    ChangelogBanner,
    NodeList,
  },
  props: {
    source: String,
  },
  data: () => ({
    btlEnv: BTL_ENV,
    userDialog: false,
    sbMessages: [],
    sysToggle: false,
    prevAccount: null,
    accounts: [],
    feedbackDrawer: false,
    items: [
      {
        icon: 'dashboard', text: 'Dashboard', route: '/dashboard', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/analytics', verb: 'POST',
      },
      {
        icon: 'settings',
        text: 'Tenant Settings',
        endpoint: 'TenantSettingsMainMenu',
        verb: 'GET',
        children: [
          {
            icon: 'domain', text: 'Accounts', route: '/accounts', endpoint: '/tenants/<tenant_name>/accounts', verb: 'GET',
          },
          {
            icon: 'call', text: 'Number Management', route: '/numbermanagement', endpoint: '/tenants/<tenant_name>', verb: 'GET',
          },
          {
            icon: 'filter_list', text: 'Number Filters', route: '/tenant-filters', endpoint: '/tenants/<tenant_name>/whitelists', verb: 'GET',
          },
          {
            icon: 'shuffle', text: 'Trunk Management', route: '/trunkmanagement', endpoint: '/tenants/<tenant_name>', verb: 'GET',
          },
          {
            icon: 'devices', text: 'Devices', route: '/devices', endpoint: '/tenants/<tenant_name>/devices', verb: 'GET',
          },
          {
            icon: 'show_chart', text: 'Concurrent Calls', route: '/concurrentcalls', endpoint: '/tenants/<tenant_name>', verb: 'GET',
          },
          {
            icon: 'vpn_key',
            text: 'Security',
            endpoint: 'TenantSecurity',
            verb: 'GET',
            children: [
              {
                icon: 'details', text: 'Functions', route: '/functions', endpoint: '/functions', verb: 'GET',
              },
              {
                icon: 'supervised_user_circle', text: 'Roles', route: '/roles', endpoint: '/tenants/<tenant_name>/roles', verb: 'GET',
              },
            ],
          },
          {
            icon: 'attach_money',
            text: 'Billing',
            endpoint: 'TenantBillingMenu',
            verb: 'GET',
            children: [
              {
                icon: 'attach_money', text: 'National Rates', route: '/nationalrates', endpoint: '/tenants/<tenant_name>/nationals', verb: 'GET',
              },
              {
                icon: 'label_important', text: 'International Dial Plan', route: '/internationalcodes', endpoint: '/tenants/<tenant_name>/internationals', verb: 'GET',
              },
              {
                icon: 'attach_money', text: 'International Rates', route: '/internationalrates', endpoint: '/tenants/<tenant_name>/countries', verb: 'GET',
              },
              {
                icon: 'label_important', text: 'NGN Dial Plan', route: '/nongeocodes', endpoint: '/tenants/<tenant_name>/nongeos', verb: 'GET',
              },
              {
                icon: 'attach_money', text: 'NGN Rates', route: '/nongeorates', endpoint: '/tenants/<tenant_name>/chargebands', verb: 'GET',
              },
              {
                icon: 'attach_money', text: 'Call Rounding', route: '/callrounding', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/roundups', verb: 'GET',
              },
              {
                icon: 'attach_money', text: 'Minimum Call Charge', route: '/minimumcallcharges', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/minimumcharges', verb: 'GET',
              },
              {
                icon: 'attach_money', text: 'High Cost Destinations', route: '/hcds', endpoint: '/tenants/<tenant_name>/highcostdestinations', verb: 'GET',
              },
              {
                icon: 'attach_money', text: 'High Cost Destinations Exceptions', route: '/hcdexceptions', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/hcdexceptions', verb: 'GET',
              },
            ],
          },
          {
            icon: 'miscellaneous_services',
            text: 'Services',
            endpoint: 'TenantServicesMenu',
            verb: 'GET',
            children: [
              {
                icon: 'miscellaneous_services', text: 'Tenant Services', route: '/tenantservices', endpoint: '/tenants/<tenant_name>/services', verb: 'GET',
              },
              {
                icon: 'attach_money', text: 'Service Charges', route: '/tenantservicecharges', endpoint: '/tenants/<tenant_name>/servicecharges', verb: 'GET',
              },
            ],
          },
          {
            icon: 'alt_route',
            text: 'LCR',
            endpoint: 'LCRMenu',
            verb: 'GET',
            children: [
              {
                icon: 'miscellaneous_services', text: 'Providers', route: '/providers', endpoint: '/tenants/<tenant_name>/providers', verb: 'GET',
              },
              {
                icon: 'miscellaneous_services', text: 'Provider Gateways', route: '/providergateways', endpoint: '/tenants/<tenant_name>/providergateways', verb: 'GET',
              },
              {
                icon: 'miscellaneous_services', text: 'Prefixes', route: '/prefixes', endpoint: '/tenants/<tenant_name>/lcrprefixes', verb: 'GET',
              },
            ],
          },
          {
            icon: 'list_alt',
            text: 'Bundles',
            endpoint: 'TenantBundlesMenu',
            verb: 'GET',
            children: [
              {
                icon: 'call', text: 'Call Bundles', route: '/callbundles', endpoint: '/tenants/<tenant_name>/callbundles', verb: 'GET',
              },
              {
                icon: 'list_alt', text: 'Bundle Chargebands', route: '/bundlechargebands', endpoint: '/tenants/<tenant_name>/bundlechargebands', verb: 'GET',
              },
            ],
          },
          {
            icon: 'credit_card',
            text: 'NetPCI',
            endpoint: 'NetPCIMenu',
            verb: 'GET',
            children: [
              {
                icon: 'attach_money', text: 'Payment Service Providers', route: '/paymentserviceproviders', endpoint: '/tenants/<tenant_name>/paymentserviceproviders', verb: 'GET',
              },
              {
                icon: 'credit_card', text: 'Capture Templates', route: '/capturetemplates', endpoint: '/tenants/<tenant_name>/capturetemplates', verb: 'GET',
              },
            ],
          },
          {
            icon: 'message',
            text: 'SMS',
            endpoint: 'SMSMenu',
            verb: 'GET',
            children: [
              {
                icon: 'add_ic_call', text: 'Numbers', route: '/sms/number-management', endpoint: '/tenants/<tenant_name>/sms/numbers', verb: 'GET',
              },
            ],
          },
          {
            icon: 'cloud_download', text: 'Exports', route: '/exports', endpoint: '/tenants/<tenant_name>/sites/export/emergency', verb: 'GET',
          },
        ],
      },
      {
        icon: 'settings',
        text: 'Account Settings',
        endpoint: 'AccountSettingsMainMenu',
        verb: 'GET',
        children: [
          {
            icon: 'miscellaneous_services', text: 'Services', route: '/accountservices', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/accountservices', verb: 'GET',
          },
          {
            icon: 'list_alt', text: 'Bundles', route: '/accountbundles', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/accountbundles', verb: 'GET',
          },
          {
            icon: 'calendar_today', text: 'Calendar', route: '/calendar', endpoint: '/tenants/<tenant_name>/accounts/<account_id>', verb: 'GET',
          },
          {
            icon: 'person', text: 'Users', route: '/users', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/users', verb: 'GET',
          },
          {
            icon: 'group', text: 'Groups', route: '/groups', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/groups', verb: 'GET',
          },
          {
            icon: 'app_registration', text: 'Applications', route: '/applications', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/applications', verb: 'GET',
          },
          {
            icon: 'import_export', text: 'API endpoints', route: '/apiendpoints', endpoint: '/tenant/<tenant_name>/account/<account_id>/endpoint', verb: 'GET',
          },
          {
            icon: 'print', text: 'Fax', route: '/fax', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/fax', verb: 'GET',
          },
          {
            icon: 'credit_card', text: 'NetPCI', route: '/netpci', endpoint: '/tenants/<tenant_name>/merchants/<account_id>', verb: 'GET',
          },
        ],
      },
      {
        icon: 'receipt', text: 'Billing', route: '/billing', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/sites', verb: 'GET', disabled: feature_billing_menu,
      },
      {
        icon: 'attach_money', text: 'Rates', route: '/rates', endpoint: 'AccountRates', verb: 'GET', disabled: feature_billing_menu,
      },
      {
        icon: 'store', text: 'Sites', route: '/sites', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/sites', verb: 'GET',
      },
      {
        icon: 'dns', text: 'Appliances', route: '/appliances', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/appliances', verb: 'GET',
      },
      {
        icon: 'shuffle', text: 'Trunks', route: '/trunks', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/trunks', verb: 'GET',
      },
      {
        icon: 'call_split', text: 'CallFlows', route: '/callflows', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/callflows', verb: 'GET',
      },
      {
        icon: 'call', text: 'Allocated Numbers', route: '/numbers', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/phonenumbers', verb: 'GET',
      },
      {
        icon: 'filter_list', text: 'Number Filters', route: '/account-filters', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/whitelists', verb: 'GET',
      },
      {
        icon: 'timeline', text: 'Analytics', route: '/analytics', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/analytics', verb: 'POST',
      },
      {
        icon: 'message',
        text: 'SMS',
        endpoint: 'SMSMenu',
        verb: 'GET',
        children: [
          {
            icon: 'call', text: 'Numbers', route: '/sms/numbers', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/sms/numbers', verb: 'GET',
          },
          {
            icon: 'person', text: 'Users', route: '/sms/users', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/sms/users', verb: 'GET',
          },
          {
            icon: 'sms', text: 'Send', route: '/sms/send', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/sms/users/<user_id>/messages', verb: 'POST',
          },
          {
            icon: 'forum', text: 'Messages', route: '/sms/messages', endpoint: '/tenants/<tenant_name>/accounts/<account_id>/sms/messages', verb: 'GET',
          },
        ],
      },
      {
        icon: 'help', text: 'Help', route: '/help', endpoint: 'Help', verb: 'POST',
      },
    ],
    renewAuthTokenInterval: null,
    portalVersion: changelog.releases[0].version,
  }),
  computed: {
    ...mapGetters([
      'searchQuery',
      'authentication',
      'selectedAccountId',
    ]),
  },
  watch: {
    authentication() {
      if (isAuthenticated()) {
        this.loadUserPreferences();
        this.fetchAccounts();
      }
    },
    selectedAccountId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reloadPage();
      }
    },
  },
  created() {
    this.loadAuthentication();
    this.loadTimezones();
  },
  mounted() {
    Snacks.$on('error', (message) => {
      this.snack(message, true);
    });
    Snacks.$on('info', (message) => {
      this.snack(message);
    });
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    isAuthenticated,
    ...mapMutations([
      'setSearchQuery',
    ]),
    ...mapActions([
      'updateAccountId',
      'loadUserPreferences',
      'loadAuthentication',
      'loadTimezones',
    ]),
    logoff() {
      logout();
    },
    snack(text, isError = false) {
      const margin = (
        document.documentElement.scrollHeight - document.documentElement.clientHeight
        - window.scrollY
      ) + 48;
      // Not right as we have multiline and single line snacks so this makes spacing disjointed
      const snackheight = 88;

      let snacks = [...this.sbMessages.filter((snack) => snack.snackbar)];

      if (Array.isArray(text)) {
        snacks = [...snacks, ...text.map((t) => ({
          sbText: t.text,
          sbError: t.isError,
          snackbar: true,
        }))];
      } else {
        snacks.push({
          sbText: text,
          sbError: isError,
          snackbar: true,
        });
      }

      this.sbMessages = snacks.map((snack, i) => ({
        id: uuidv4(),
        ...snack,
        offset: ((i * (snackheight + 8)) + margin),
      }));

      this.$forceUpdate();
    },
    closeSnack(snack) {
      // eslint-disable-next-line
      snack.snackbar = false;
      this.$forceUpdate();
    },
    async fetchAccounts() {
      if (!this.isPermitted('/tenants/<tenant_name>/accounts', 'GET')) {
        return;
      }

      try {
        const response = await axios.get(BTL_API_ACCOUNT_URL);
        this.accounts = response.data.sort((a, b) => {
          const nameA = a.name.toLowerCase(); const
            nameB = b.name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      } catch (e) {
        console.error('Fetch account error', e);
      }
    },
    closeFeedback() {
      this.feedbackDrawer = false;
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style>
.logo {
  height: 50px;
  margin-top: 5px;
}
.user-name {
  margin-top: 17px;
  margin-right: 10px;
}

h1, h2, h3, .v-card__title {
  font-weight: 400 !important;
}

#searchBar {
  caret-color: black;
}

.v-snack__wrapper {
  bottom: inherit !important;
  position: inherit !important;
}
.v-snack__wrapper > div[role=status] {
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedback-btn {
  background-color: transparent; /* Make sure the button background is transparent */
  box-shadow: none; /* Remove any button shadow */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px !important; /* Set the button width (adjust as needed) */
  height: 24px !important; /* Set the button height (adjust as needed) */
  padding: 0; /* Remove any padding */
  margin-right: 15px;
  box-shadow: none !important;
}

.feedback-icon {
  max-width: 38px;
  max-height: 38px;
  object-fit: contain;
}
</style>
