<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Non-geographic Dial Plan Management
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/nongeos', 'POST')"
        text
        color="blue"
        @click="$router.push('/nongeocode/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/nongeos/load', 'POST')"
        text
        color="blue"
        @click="importDialog = true"
      >
        <v-icon class="mr-2">
          cloud_upload
        </v-icon>Import
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        color="primary"
        :headers="exportHeaders"
        :download-u-r-l="baseURL"
        report-name="Non-geographic Dial Plans"
      >
        Download
      </download>
      <v-spacer />
    </v-toolbar>

    <v-container
      fluid
    >
      <v-row class="mx-1">
        <v-col cols="4">
          <v-autocomplete
            v-model="chargeBandFilter"
            flat
            :items="chargebands"
            item-text="Name"
            item-value="CBId"
            label="Chargeband Filter"
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="codeFilter"
            label="Code Filter"
          />
        </v-col>
      </v-row>

      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="codes"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            :custom-sort="customSort"
            height="calc(100vh - 315px)"
          >
            <template #item.chargeband.Name="{ item }">
              <router-link :to="item.itemRoute">
                {{ item.chargeband.Name }}
              </router-link>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <code-importer
        :show="importDialog"
        headings="Name &amp; Code"
        example="/examplenongeocode.csv"
        :url="importURL"
        @close="importDialog = false"
        @snack="handleSnack"
      />
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { debounce, sortBy } from 'lodash';
import config from '@/config.js';

import download from '@/components/download/tableDownload';

import CodeImporter from '@/components/billing/CodeImporter';
import customSort from '@/utils/customSort';
import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'NongeoCodesManagement',
  components: {
    download,
    CodeImporter,
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  data() {
    const headers = [
      { text: 'Charge Band Name', value: 'chargeband.Name' },
      { text: 'Code', value: 'Code' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];

    const baseURL = `${config.BTL_API_TENANT_URL}/nongeos`;

    return {
      codes: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      chargebands: [],
      loadingChargebands: false,
      chargeBandFilter: null,
      codeFilter: null,
      loading: false,
      importDialog: false,
      baseURL,
      importURL: `${baseURL}/load`,
    };
  },
  watch: {
    chargeBandFilter() {
      this.loadData();
      this.$router.push({ name: 'nongeocodesbycountry', params: { CBId: this.chargeBandFilter } });
    },
    codeFilter: debounce(function () {
      this.loadData();
    }, 500),
  },
  async mounted() {
    await this.loadChargeBands();
    const { CBId } = this.$route.params;
    if (CBId) {
      this.chargeBandFilter = CBId;
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        if (this.chargeBandFilter || this.codeFilter) {
          await this.loadCodes();
        } else {
          this.$emit('snack', 'Supply either a chargeband or code filter to execute the search.');
        }
      } catch (error) {
        console.error('Could not load data: ', error);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadCodes() {
      let url = this.baseURL;
      const paramsObj = {
        filters: [],
      };

      if (this.chargeBandFilter) {
        paramsObj.filters.push({
          name: 'CBId',
          op: 'eq',
          val: this.chargeBandFilter,
        });
      }

      if (this.codeFilter) {
        paramsObj.filters.push({
          name: 'Code',
          op: 'like',
          val: `${this.codeFilter}%`,
        });
      }

      if (paramsObj.filters.length) {
        url += `?q=${JSON.stringify(paramsObj)}`;
      }

      const { CancelToken } = axios;
      this.cancelToken = CancelToken.source();

      const response = await axios.get(url);

      this.codes = response.data.map((row) => ({
        ...row,
        itemRoute: `/nongeocode/${row.Id}`,
      }));
    },
    async loadChargeBands() {
      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/chargebands`);
        this.loadingChargebands = true;
        const chargebands = response.data.filter((item) => !item.AccountId);
        this.chargebands = sortBy(chargebands, [(chargeband) => chargeband.Name.toLowerCase()]);
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve chargebands.', true);
        console.error('Failed to retrieve chargebands', error);
      }
      this.loadingChargebands = false;
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    handleSnack(message, isError = false) {
      this.$emit('snack', message, isError);
    },
    customSort: customSort('ModifiedOn'),
  },
};
</script>
