import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/';

import { login, isAuthenticated } from '@/auth/AuthService';

import NetPCI from '@/views/AccountSetting/NetPCI';

import SmsNumbers from '@/views/Sms/SmsNumbers';
import SmsUsers from '@/views/Sms/SmsUsers';
import SmsNumberManagement from '@/views/Tenant/Sms/SmsNumberManagement';
import SmsSend from '@/views/Sms/SmsSend';
import SmsMessages from '@/views/Sms/SmsMessages';
import Applications from '@/views/AccountSetting/Applications.vue';
import Application from '@/views/AccountSetting/Application.vue';
import Callback from './views/Callback.vue';

import Dashboard from './views/Dashboard.vue';

import Accounts from './views/Account/Accounts.vue';
import NewAccount from './views/Account/NewAccount.vue';
import Account from './views/Account/Account.vue';

import AccountBundles from './views/AccountSetting/Bundles.vue';
import AccountBundle from './views/AccountSetting/Bundle.vue';

import AccountServices from './views/AccountSetting/Services.vue';

import Users from './views/AccountSetting/Users.vue';
import User from './views/AccountSetting/User.vue';
import NewUser from './views/AccountSetting/NewUser.vue';
import Groups from './views/AccountSetting/Groups.vue';
import Group from './views/AccountSetting/Group.vue';
import NewGroup from './views/AccountSetting/NewGroup.vue';

import Roles from './views/Tenant/Roles/Roles.vue';
import Role from './views/Tenant/Roles/Role.vue';

import Functions from './views/Tenant/Functions.vue';

import CallFlows from './views/CallFlow/CallFlows.vue';
import NewCallFlow from './views/CallFlow/NewCallFlow.vue';
import CloneCallFlow from './views/CallFlow/CloneCallFlow.vue';
import BrowseCallFlow from './views/CallFlow/BrowseCallFlow.vue';

import Sites from './views/Site/Sites.vue';
import NewSite from './views/Site/NewSite.vue';
import BrowseSite from './views/Site/Site.vue';

import Calendar from './views/AccountSetting/Calendar.vue';

import AllocatedNumbers from './views/Number/AllocatedNumbers.vue';
import NumberManagement from './views/Number/NumberManagement.vue';
import PhoneNumber from './views/Number/Number.vue';
import ManageNumber from './views/Number/ManageNumber.vue';
import NewNumber from './views/Number/NewNumber.vue';

import Trunks from './views/Trunk/Trunks.vue';
import Trunk from './views/Trunk/Trunk.vue';
import NewTrunk from './views/Trunk/NewTrunk.vue';
import ManageTrunks from './views/Trunk/TrunkManagement.vue';

import Appliances from './views/Appliance/Appliances.vue';
import NewAppliance from './views/Appliance/NewAppliance.vue';
import Appliance from './views/Appliance/Appliance.vue';

import Filters from './views/Filter/Filters.vue';
import NewFilter from './views/Filter/NewFilter.vue';

import ApiEndpoints from './views/AccountSetting/ApiEndpoints.vue';
import NewApiEndpoint from './views/AccountSetting/NewApiEndpoint.vue';
import ApiEndpoint from './views/AccountSetting/ApiEndpoint.vue';

import FaxAccount from './views/AccountSetting/FaxAccount.vue';

import Billing from './views/Billing/Billing';

import Analytics from './views/Analytics/Query.vue';

import AnayticsDashboard from './views/Analytics/Dashboard.vue';

import Report from './views/Report';

import CallDetails from './views/CallDetails/CallDetails.vue';

import Changelog from './views/Changelog.vue';

import ConcurrentCalls from './views/Tenant/ConcurrentCalls.vue';

import NationalRates from './views/Tenant/Billing/NationalRates.vue';
import NationalRate from './views/Tenant/Billing/NationalRate.vue';

import InternationalRates from './views/Tenant/Billing/InternationalRates.vue';
import InternationalRate from './views/Tenant/Billing/InternationalRate.vue';

import InternationalCodes from './views/Tenant/Billing/InternationalCodes.vue';
import InternationalCode from './views/Tenant/Billing/InternationalCode.vue';

import NonGeoRates from './views/Tenant/Billing/NonGeoRates.vue';
import NonGeoRate from './views/Tenant/Billing/NonGeoRate.vue';

import NonGeoCodes from './views/Tenant/Billing/NonGeoCodes.vue';
import NonGeoCode from './views/Tenant/Billing/NonGeoCode.vue';

import CallRoundingManagement from './views/Tenant/Billing/CallRounding.vue';

import MinimumCallChargeManagement from './views/Tenant/Billing/MinimumCallCharge.vue';
import MinimumCallCharge from './views/Tenant/Billing/CallCharge.vue';

import HighCostDestinations from './views/Tenant/Billing/HighCostDestinations.vue';
import HighCostDestination from './views/Tenant/Billing/HighCostDestination.vue';

import HighCostDestinationsExceptions from './views/Tenant/Billing/HighCostDestinationsExceptions.vue';
import HighCostDestinationException from './views/Tenant/Billing/HighCostDestinationException.vue';

import AnalyticsRates from './views/Tenant/Billing/AnalyticsRates.vue';

import TenantServices from './views/Tenant/Services/TenantServices.vue';
import TenantService from './views/Tenant/Services/TenantService.vue';

import TenantServiceCharges from './views/Tenant/Services/TenantServiceCharges.vue';
import TenantServiceCharge from './views/Tenant/Services/TenantServiceCharge.vue';

import CallBundles from './views/Tenant/Bundles/Bundles.vue';
import CallBundle from './views/Tenant/Bundles/Bundle.vue';

import BundleChargebands from './views/Tenant/Bundles/BundleChargebands.vue';
import BundleChargeband from './views/Tenant/Bundles/BundleChargeband.vue';

import PaymentServiceProviders from './views/Tenant/NetPCI/PaymentServiceProviders';
import PaymentServiceProvider from './views/Tenant/NetPCI/PaymentServiceProvider';

import CaptureTemplates from './views/Tenant/NetPCI/CaptureTemplates';
import CaptureTemplate from './views/Tenant/NetPCI/CaptureTemplate';

import Exports from './views/Tenant/Exports';

import Rates from './views/Rates/Rates';

import Devices from './views/Tenant/Devices';

import Prefixes from './views/Tenant/LCR/Prefixes';
import Prefix from './views/Tenant/LCR/Prefix';
import ProviderGateways from './views/Tenant/LCR/ProviderGateways';
import ProviderGateway from './views/Tenant/LCR/ProviderGateway';
import Providers from './views/Tenant/LCR/Providers';
import Provider from './views/Tenant/LCR/Provider';
/* eslint-disable */
Vue.use(Router);

// eslint-disable-next-line consistent-return
const support_email = 'uifeedback@btlnet.co.uk';
const checkLogin = (to, from, next) => {
  if ((isAuthenticated()) || to.path === '/callback') {
    return next();
  }
  login({ path: '/dashboard', query: to.query });
};

const clearSearch = (to, from, next) => {
  store.commit('setSearchQuery', '');
  next();
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/callback',
      name: 'callback',
      component: Callback,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
    },
    {
      path: '/user/:userId',
      name: 'user',
      component: User,
    },
    {
      path: '/group/:groupId',
      name: 'group',
      component: Group,
    },
    {
      path: '/newuser',
      name: 'newuser',
      component: NewUser,
    },
    {
      path: '/newgroup',
      name: 'newgroup',
      component: NewGroup,
    },
    {
      path: '/groups',
      name: 'groups',
      component: Groups,
    },
    {
      path: '/roles',
      name: 'roles',
      component: Roles,
    },
    {
      path: '/role/:id',
      name: 'role',
      component: Role,
    },
    {
      path: '/newrole',
      name: 'newrole',
      component: Role,
    },
    {
      path: '/functions',
      name: 'functions',
      component: Functions,
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: Accounts,
    },
    {
      path: '/newaccount',
      name: 'newaccount',
      component: NewAccount,
    },
    {
      path: '/account/:id',
      name: 'account',
      component: Account,
    },
    {
      path: '/accountbundles',
      name: 'AccountBundles',
      component: AccountBundles,
    },
    {
      path: '/accountbundles/:accountBundleId',
      name: 'AccountBundle',
      component: AccountBundle,
    },
    {
      path: '/accountservices',
      name: 'AccountServices',
      component: AccountServices,
    },
    {
      path: '/callflows',
      name: 'callflows',
      component: CallFlows,
    },
    {
      path: '/newcallflow/:templateId',
      name: 'newcallflow',
      component: NewCallFlow,
    },
    {
      path: '/clonecallflow/:callflowId',
      name: 'clonecallflow',
      component: CloneCallFlow,
    },
    {
      path: '/callflow/:callflowid',
      name: 'browsecallflow',
      component: BrowseCallFlow,
    },
    {
      path: '/sites',
      name: 'sites',
      component: Sites,
    },
    {
      path: '/newsite',
      name: 'newsite',
      component: NewSite,
    },
    {
      path: '/site/:id',
      name: 'browsesite',
      component: BrowseSite,
    },
    {
      path: '/numbers',
      name: 'numbers',
      component: AllocatedNumbers,
    },
    {
      path: '/numbermanagement',
      name: 'numbermanagement',
      component: NumberManagement,
    },
    {
      path: '/number/:number',
      name: 'number',
      component: PhoneNumber,
    },
    {
      path: '/managenumber/:number',
      name: 'managenumber',
      component: ManageNumber,
    },
    {
      path: '/newnumber',
      name: 'newnumber',
      component: NewNumber,
    },
    {
      path: '/trunks',
      name: 'trunks',
      component: Trunks,
    },
    {
      path: '/trunk/:id',
      name: 'trunk',
      component: Trunk,
    },
    {
      path: '/newtrunk',
      name: 'newtrunk',
      component: NewTrunk,
    },
    {
      path: '/trunkmanagement',
      name: 'trunkmanagement',
      component: ManageTrunks,
    },
    {
      path: '/appliances',
      name: 'appliances',
      component: Appliances,
    },
    {
      path: '/newappliance',
      name: 'newappliance',
      component: NewAppliance,
    },
    {
      path: '/appliance/:id',
      name: 'appliance',
      component: Appliance,
    },
    {
      path: '/account-filters',
      name: 'accountfilters',
      component: Filters,
      props: {
        level: 'account',
      },
    },
    {
      path: '/tenant-filters',
      name: 'tenantfilters',
      component: Filters,
      props: {
        level: 'tenant',
      },
    },
    {
      path: '/newfilter/:level/:type',
      name: 'newfilter',
      component: NewFilter,
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: Analytics,
    },
    {
      path: '/analytics/dashboard',
      name: 'analytics-dashboard',
      component: AnayticsDashboard,
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar,
    },
    {
      path: '/calldetails/:callId/:accountId',
      name: 'calldetails',
      component: CallDetails,
      props: true,
    },
    {
      path: '/concurrentcalls',
      name: 'concurrentcalls',
      component: ConcurrentCalls,
    },
    {
      path: '/nationalrates',
      name: 'nationalrates',
      component: NationalRates,
    },
    {
      path: '/nationalrate/:rateID',
      name: 'nationalrate',
      component: NationalRate,
    },
    {
      path: '/internationalrates',
      name: 'internationalrates',
      component: InternationalRates,
    },
    {
      path: '/internationalrate/:countryID',
      name: 'internationalratetenant',
      component: InternationalRate,
    },
    {
      path: '/internationalrate/:countryID/:accountID',
      name: 'internationalrateaccount',
      component: InternationalRate,
    },
    {
      path: '/internationalcodes',
      name: 'internationalcodes',
      component: InternationalCodes,
    },
    {
      path: '/internationalcodes/:countryID',
      name: 'internationalcodesbycountry',
      component: InternationalCodes,
    },
    {
      path: '/internationalcode/:codeID',
      name: 'internationalcode',
      component: InternationalCode,
    },
    {
      path: '/nongeorates',
      name: 'nongeorates',
      component: NonGeoRates,
    },
    {
      path: '/nongeorate/:CBId',
      name: 'nongeoratetenant',
      component: NonGeoRate,
    },
    {
      path: '/nongeorate/:CBId/:accountID',
      name: 'nongeorateaccount',
      component: NonGeoRate,
    },
    {
      path: '/nongeocodes',
      name: 'nongeocodes',
      component: NonGeoCodes,
    },
    {
      path: '/nongeocodes/:CBId',
      name: 'nongeocodesbycountry',
      component: NonGeoCodes,
    },
    {
      path: '/nongeocode/:codeID',
      name: 'nongeocode',
      component: NonGeoCode,
    },
    {
      path: '/changelog',
      name: 'changelog',
      component: Changelog,
    },
    {
      path: '/help',
      beforeEnter: () => {
        // Redirect to the email link
        window.location.href = `mailto:${support_email}?Subject=Britannic%20portal%20support%20query`;
      },
    },
    {
      path: '/newapiendpoint',
      name: 'newapiendpoint',
      component: NewApiEndpoint,
    },
    {
      path: '/apiendpoints',
      name: 'apiendpoints',
      component: ApiEndpoints,
    },
    {
      path: '/fax',
      name: 'fax',
      component: FaxAccount,
    },
    {
      path: '/netpci',
      name: 'NetPCI',
      component: NetPCI,
    },
    {
      path: '/apiendpoint/:endpointId',
      name: 'apiendpoint',
      component: ApiEndpoint,
    },
    {
      path: '/billing',
      name: 'billing',
      component: Billing,
    },
    {
      path: '/callrounding',
      name: 'callrounding',
      component: CallRoundingManagement,
    },
    {
      path: '/minimumcallcharges',
      name: 'minimumcallcharges',
      component: MinimumCallChargeManagement,
    },
    {
      path: '/minimumcallcharge/:mcid',
      name: 'newminimumcallcharge',
      component: MinimumCallCharge,
    },
    {
      path: '/minimumcallcharge/:accountId/:mcid',
      name: 'editminimumcallcharge',
      component: MinimumCallCharge,
    },
    {
      path: '/hcds',
      name: 'HighCostDestinations',
      component: HighCostDestinations,
    },
    {
      path: '/hcd/:hcdid',
      name: 'HighCostDestination',
      component: HighCostDestination,
    },
    {
      path: '/hcdexceptions',
      name: 'HighCostDestinationsExceptions',
      component: HighCostDestinationsExceptions,
    },
    {
      path: '/hcdexception/:hcdexceptionid',
      name: 'HighCostDestinationException',
      component: HighCostDestinationException,
    },
    {
      path: '/hcdexception/:accountid/:hcdexceptionid',
      name: 'HighCostDestinationException',
      component: HighCostDestinationException,
    },
    {
      path: '/analyticsrates',
      name: 'AnalyticsRates',
      component: AnalyticsRates,
    },
    {
      path: '/tenantservices',
      name: 'TenantServices',
      component: TenantServices,
    },
    {
      path: '/tenantservices/:serviceId',
      name: 'TenantService',
      component: TenantService,
    },
    {
      path: '/tenantservicecharges',
      name: 'TenantServiceCharges',
      component: TenantServiceCharges,
    },
    {
      path: '/tenantservicecharges/:chargeId',
      name: 'TenantServiceCharge',
      component: TenantServiceCharge,
    },
    {
      path: '/callbundles',
      name: 'CallBundles',
      component: CallBundles,
    },
    {
      path: '/callbundles/:bundleId',
      name: 'CallBundle',
      component: CallBundle,
    },
    {
      path: '/bundlechargebands',
      name: 'BundleChargebands',
      component: BundleChargebands,
    },
    {
      path: '/bundlechargebands/:bundleChargebandId',
      name: 'BundleChargeband',
      component: BundleChargeband,
    },
    {
      path: '/paymentserviceproviders',
      name: 'paymentserviceproviders',
      component: PaymentServiceProviders,
    },
    {
      path: '/paymentserviceprovider/:id',
      name: 'paymentserviceprovider',
      component: PaymentServiceProvider,
    },
    {
      path: '/capturetemplates',
      name: 'capturetemplates',
      component: CaptureTemplates,
    },
    {
      path: '/capturetemplate/:id',
      name: 'capturetemplate',
      component: CaptureTemplate,
    },
    {
      path: '/exports',
      name: 'exports',
      component: Exports,
    },
    {
      path: '/rates',
      name: 'rates',
      component: Rates,
    },
    {
      path: '/report',
      name: 'report',
      component: Report,
    },
    {
      path: '/devices',
      name: 'devices',
      component: Devices,
    },
    {
      path: '/prefixes',
      name: 'prefixes',
      component: Prefixes,
    },
    {
      path: '/prefix/:prefixID',
      name: 'prefix',
      component: Prefix,
    },
    {
      path: '/providergateways',
      name: 'providergateways',
      component: ProviderGateways,
    },
    {
      path: '/providergateway/:shortCode',
      name: 'providergateway',
      component: ProviderGateway,
    },
    {
      path: '/providers',
      name: 'providers',
      component: Providers,
    },
    {
      path: '/provider/:providerID',
      name: 'provider',
      component: Provider,
    },
    {
      path: '/sms/numbers',
      name: 'smsNumbers',
      component: SmsNumbers,
    },
    {
      path: '/sms/users',
      name: 'smsUsers',
      component: SmsUsers,
    },
    {
      path: '/sms/number-management',
      name: 'smsNumberManagement',
      component: SmsNumberManagement,
    },
    {
      path: '/sms/send',
      name: 'smsSend',
      component: SmsSend,
    },
    {
      path: '/sms/messages',
      name: 'smsMessages',
      component: SmsMessages,
    },
    {
      path: '/application',
      name: 'application',
      component: Application,
    },
    {
      path: '/application/:clientId',
      name: 'application',
      component: Application,
    },
    {
      path: '/applications',
      name: 'applications',
      component: Applications,
    },
    {
      path: '*',
      redirect: '/dashboard',
    },
  ],
});

router.beforeEach(checkLogin);
router.beforeEach(clearSearch);

export default router;
