<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="400"
  >
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">
          Order Fields
        </v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-list
          rounded
          class="ma-1"
        >
          <draggable v-model="orderedFields">
            <v-list-item
              v-for="(item, i) in orderedFields"
              :key="i"
              style="border: 1px solid rgba(0, 0, 0, 0.12)"
              dense
            >
              <v-list-item-content>
                {{ fieldNames[item].text }}
              </v-list-item-content>

              <v-list-item-avatar>
                <v-icon>swap_vert</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </draggable>
        </v-list>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            primary
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'FieldSorter',
  components: {
    draggable,
  },
  props: {
    fields: Array,
    fieldNames: Object,
    show: Boolean,
  },
  data() {
    return {
      orderedFields: this.fields,
    };
  },
  watch: {
    fields() {
      this.orderedFields = this.fields;
    },
  },
  methods: {
    save() {
      this.$emit('save', this.orderedFields);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
