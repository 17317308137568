<template>
  <div>
    <v-tooltip
      v-if="allTotal >= 0"
      bottom
    >
      <template #activator="{ on }">
        <v-chip
          color="primary"
          outlined
          small
          v-on="on"
        >
          <v-avatar left>
            <v-icon>import_export</v-icon>
          </v-avatar>
          {{ allTotal }}
        </v-chip>
      </template>
      <span>All Calls</span>
    </v-tooltip>
    <v-tooltip
      v-if="allIn >= 0"
      bottom
    >
      <template #activator="{ on }">
        <v-chip
          class="ml-1"
          color="success"
          outlined
          small
          v-on="on"
        >
          <v-avatar left>
            <v-icon>call_received</v-icon>
          </v-avatar>
          {{ allIn }}
        </v-chip>
      </template>
      <span>Calls In</span>
    </v-tooltip>
    <v-tooltip
      v-if="allOut >= 0"
      bottom
    >
      <template #activator="{ on }">
        <v-chip
          class="ml-1"
          color="error"
          outlined
          small
          v-on="on"
        >
          <v-avatar left>
            <v-icon>call_made</v-icon>
          </v-avatar>
          {{ allOut }}
        </v-chip>
      </template>
      <span>Calls Out</span>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: 'CallCounts',
  props: {
    allTotal: Number,
    allIn: Number,
    allOut: Number,
  },
};
</script>
