<template>
  <v-btn
    v-if="!downloading"
    :disabled="!query"
    :color="color"
    icon
    @click="handleDownload"
  >
    <v-icon>cloud_download</v-icon>
  </v-btn>
  <v-progress-circular
    v-else
    :class="progressClass"
    indeterminate
    :color="color"
    size="24"
    width="2"
  />
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { bizvu_analytics_url } from '@/config';

export default {
  name: 'QueryDownloader',
  props: {
    query: Object,
    headers: Array,
    reportName: {
      type: String,
      default: 'Export',
    },
    color: {
      type: String,
    },
    progressClass: String,
  },
  data() {
    return {
      downloading: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'timeZone',
    ]),
  },
  methods: {
    async handleDownload() {
      this.downloading = true;
      try {
        const userquery = JSON.stringify({
          ...this.query,
          size: 10000,
        });

        const { data } = await axios.get(`${bizvu_analytics_url}/account/${this.selectedAccountId}/downloadReport`, {
          params: {
            userquery,
            headerFields: JSON.stringify(this.headers),
            timeZone: this.timeZone,
            reportType: 'querybuilder_download',
          },
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.reportName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error('Failed to download report', err);
      }
      this.downloading = false;
    },
  },
};
</script>
