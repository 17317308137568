<template>
  <v-dialog
    v-model="show"
    max-width="600"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="headline">
        Import Numbers
        <v-spacer />
        <v-btn
          href="/example.csv"
          color="info"
          download="example.csv"
        >
          Download Example File
        </v-btn>
      </v-card-title>
      <v-card-text v-if="!importRun">
        <v-autocomplete
          v-model="account"
          :items="accounts"
          item-text="name"
          item-value="account_id"
          label="Account"
          flat
          prepend-icon="domain"
          required
        />

        <v-autocomplete
          v-if="account"
          v-model="callflowId"
          :items="callflows"
          :loading="loadingCallflows"
          item-text="Name"
          item-value="CallflowId"
          label="CallFlow"
          flat
          prepend-icon="call_split"
          clearable
        />

        <v-switch
          v-if="account"
          v-model="enableNumbers"
          label="Enable numbers"
        />

        <v-subheader v-if="account">
          CVS should have 5 columns with headings: DDI, Name, Notes, Maintainer &amp; Masking CLI
        </v-subheader>
        <v-file-input
          v-if="account"
          v-model="file"
          show-size
          accept=".csv"
          label="File input"
        />

        <v-sheet
          v-if="csvError"
          class="red lighten-2 pa-2"
        >
          <v-subheader class="white--text">
            Errors in CSV
          </v-subheader>
          <v-simple-table
            dense
            fixed-header
            :height="200"
            class="ma-2"
          >
            <thead>
              <tr>
                <th class="text-left">
                  Line Number
                </th>
                <th class="text-left">
                  Error
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in csvErrors"
                :key="item.row"
              >
                <td>{{ item.row }}</td>
                <td>{{ item.message }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-sheet>

        <v-sheet
          v-if="showValidationErrors"
          class="red lighten-2 pa-2"
        >
          <v-subheader class="white--text">
            Validation errors
          </v-subheader>
          <v-simple-table
            dense
            fixed-header
            :height="200"
            class="ma-2"
          >
            <thead>
              <tr>
                <th class="text-left">
                  Line
                </th>
                <th class="text-left">
                  Error
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in validationErrors"
                :key="item.rowNumber"
              >
                <td>{{ item.rowNumber }}</td>
                <td>{{ item.errorMessages.join('. ') }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-sheet>

        <v-subheader v-if="showParsedNumbers">
          Parsed {{ numbers.length }} Numbers
        </v-subheader>
        <v-simple-table
          v-if="showParsedNumbers"
          dense
          fixed-header
          :height="200"
        >
          <thead>
            <tr>
              <th class="text-left">
                Number
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Notes
              </th>
              <th class="text-left">
                Maintainer
              </th>
              <th class="text-left">
                Masking
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in numbers"
              :key="item.PhoneNumber"
            >
              <td>{{ item.PhoneNumber }}</td>
              <td>{{ item.Label }}</td>
              <td>{{ item.Notes }}</td>
              <td>{{ item.Maintainer }}</td>
              <td>{{ item.Masking }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-btn
          v-if="showParsedNumbers"
          :loading="importRunning"
          color="primary"
          width="100%"
          @click="runImport"
        >
          Import
        </v-btn>
      </v-card-text>

      <v-card-text v-if="importRun">
        <v-sheet
          v-if="!importSuccess"
          class="red lighten-2 pa-2"
        >
          <v-subheader class="white--text">
            Errors creating numbers
          </v-subheader>
          <v-simple-table
            v-if="importErrors.length > 0"
            dense
            fixed-header
            :height="200"
            class="ma-2"
          >
            <thead>
              <tr>
                <th class="text-left">
                  Number
                </th>
                <th class="text-left">
                  Error
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in importErrors"
                :key="item.PhoneNumber"
              >
                <td>{{ item.line }}</td>
                <td>{{ item.errors.join(' ') }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-sheet>

        <v-sheet
          v-if="importSuccess"
          class="green lighten-2 pa-2"
        >
          <v-subheader class="white--text">
            Imported {{ numbers.length }} Numbers
          </v-subheader>
          <v-simple-table
            dense
            fixed-header
            :height="200"
            class="ma-2"
          >
            <thead>
              <tr>
                <th class="text-left">
                  Number
                </th>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Notes
                </th>
                <th class="text-left">
                  Maintainer
                </th>
                <th class="text-left">
                  Masking
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in numbers"
                :key="item.PhoneNumber"
              >
                <td>{{ item.PhoneNumber }}</td>
                <td>{{ item.Label }}</td>
                <td>{{ item.Notes }}</td>
                <td>{{ item.Maintainer }}</td>
                <td>{{ item.Masking }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-sheet>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          @click="reset"
        >
          Reset
        </v-btn>
        <v-btn
          text
          color="grey"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import papa from 'papaparse';
import { pick, sortBy } from 'lodash';

import config from '@/config';

export default {
  name: 'NumberImporter',
  props: {
    show: Boolean,
    accounts: Array,
  },
  data() {
    return {
      account: null,
      callflows: [],
      callflowId: null,
      file: null,
      enableNumbers: false,

      numbers: [],

      importErrors: [],

      csvErrors: [],
      csvError: false,

      importRunning: false,
      importRun: false,
      importSuccess: true,

      loadingCallflows: false,

      numberRegex: /\+[0-9]{3,13}/,
    };
  },
  computed: {
    showValidationErrors() {
      return !this.csvError && this.validationErrors.length > 0;
    },
    showParsedNumbers() {
      return !this.showValidationErrors && this.numbers.length > 0;
    },
    validationErrors() {
      return this.numbers.reduce((acc, num) => {
        if (!num.valid) {
          acc.push(num);
        }
        return acc;
      }, []);
    },
  },
  watch: {
    file(file) {
      this.numbers = [];
      this.csvErrors = [];
      this.csvError = false;
      if (file) {
        papa.parse(file, {
          skipEmptyLines: true,
          complete: (results) => {
            if (results.errors.length > 0) {
              this.csvError = true;
              this.csvErrors = results.errors;
              return;
            }
            this.numbers = results.data.slice(1).map(this.validateNumber);
          },
        });
      }
    },
    async account(accountId) {
      this.loadingCallflows = true;

      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${accountId}/callflows`);

        this.callflows = sortBy(response.data, 'Name');
      } catch (error) {
        console.error('Failed to retrieve CallFlows', error);
      }

      this.loadingCallflows = false;
    },
  },
  methods: {
    validateNumber(row, rowNumber) {
      const number = {
        PhoneNumber: row[0],
        Label: row[1],
        Notes: row[2],
        Maintainer: row[3],
        Masking: row[4],
        valid: true,
        rowNumber: rowNumber + 2, // +2 corrects for header and array start at 0
        errorMessages: [],
      };

      if (number.PhoneNumber.length && number.PhoneNumber[0] !== '+') number.PhoneNumber = `+${number.PhoneNumber}`;
      if (number.Masking.length && Number(number.Masking) && number.Masking[0] !== '+') number.Masking = `+${number.Masking}`;

      if (!this.numberRegex.test(number.PhoneNumber)) {
        number.errorMessages.push('DDI invalid');
        number.valid = false;
      }

      if (number.Label && number.Label.length > 60) {
        number.errorMessages.push('Name too long.');
        number.valid = false;
      }

      if (number.Notes && number.Notes.length > 80) {
        number.errorMessages.push('Notes too big.');
        number.valid = false;
      }

      if (number.Maintainer && number.Maintainer.length > 60) {
        number.errorMessages.push('Maintainer too big.');
        number.valid = false;
      }

      if (number.Masking && (number.Masking !== '' && number.Masking !== 'Anonymous' && !this.numberRegex.test(number.Masking))) {
        number.errorMessages.push('Masking must be either Anonymous, a e164 number or left empty for none.');
        number.valid = false;
      }

      return number;
    },
    async runImport() {
      this.importRunning = true;

      const numbersToCreate = this.numbers.map((num) => ({
        ...pick(num, ['PhoneNumber', 'Label', 'Notes', 'Maintainer', 'Masking']),
        Enabled: this.enableNumbers,
        PhoneType: 'GEO',
        AccountId: this.account,
        CallflowId: this.callflowId ? this.callflowId : undefined,
      }));

      try {
        await axios.post(`${config.BTL_API_TENANT_URL}/phonenumbers/batch`, numbersToCreate);
      } catch (error) {
        this.importSuccess = false;

        if (error.response && error.response.status === 400) {
          const { errors } = error.response.data;

          if (errors) {
            this.importErrors = Object.keys(errors).map((errorPosition) => {
              // eslint-disable-next-line no-shadow
              const error = {
                line: Number(errorPosition) + 2, // +2 corrects for header and array start at 0
                errors: [],
              };

              const fieldsInError = errors[errorPosition];

              Object.keys(fieldsInError).forEach((field) => {
                fieldsInError[field].forEach((fieldError) => {
                  error.errors.push(`${field} - ${fieldError}`);
                });
              });

              return error;
            });
          } else {
            this.importErrors.push({ line: null, errors: [error.response.data.message] });
          }
        }
      }

      this.importRunning = false;
      this.importRun = true;
      this.$emit('importRun');
    },
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    close() {
      this.reset();
      this.$emit('close');
    },
  },
};
</script>
