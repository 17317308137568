<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$emit('closeFeedback')"
      >
        <v-icon>chevron_right</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Your feedback
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-layout class="mb-5">
        <p class="headline">
          Please help us with your feedback to improve the Portal:
        </p>
      </v-layout>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout>
          <v-flex xs12>
            <v-layout>
              <v-flex xs6>
                <p>Overall I find the portal intuitive to use</p>
              </v-flex>
              <v-flex xs6>
                <div class="text-center">
                  <v-rating v-model="feedback.intuitive" />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-layout>
              <v-flex xs6>
                <p>The portal helps me to manage my platform more efficiently</p>
              </v-flex>
              <v-flex xs6>
                <div class="text-center">
                  <v-rating v-model="feedback.efficiency" />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout class="mt-5">
          <v-flex xs12>
            <v-textarea v-model="feedback.missingFeatures">
              <template #label>
                <div>
                  What additional functionality, or improvements would you like to see and why? <small>(optional)</small>
                </div>
              </template>
            </v-textarea>
          </v-flex>
        </v-layout>
        <v-layout class="mt-5">
          <v-btn
            primary
            color="primary"
            dark
            @click="submit"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$emit('closeFeedback')"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */ 
import { mapGetters } from 'vuex';
export default {
  name: 'Filterfeedback',
  data() {
    return {
      valid: false,
      feedback: {
        intuitive: 0,
        efficiency: 0,
        missingFeatures: '',
      },
    };
  },
  computed: {
  ...mapGetters(['authentication']),
  emailLink() {
    const subject = encodeURIComponent("Feedback for the Portal");
    const starsIntuitive = '★'.repeat(this.feedback.intuitive) + '☆'.repeat(5 - this.feedback.intuitive);
    const starsEfficiency = '★'.repeat(this.feedback.efficiency) + '☆'.repeat(5 - this.feedback.efficiency);
    // Added two line breaks between sections for better readability
    const body = `Overall I find the portal intuitive to use: ${starsIntuitive}\n\n` +
                `The portal helps me to manage my platform more efficiently: ${starsEfficiency}\n\n` +
                `What additional functionality, or improvements would you like to see and why? (optional):\n${this.feedback.missingFeatures}`;
    console.log("Email body before encoding:", body); // Log the unencoded body
    const encodedBody = encodeURIComponent(body);
    console.log('Generated Email Link:', `mailto:uifeedback@btlnet.co.uk?subject=${subject}&body=${encodedBody}`);
    return `mailto:uifeedback@btlnet.co.uk?subject=${subject}&body=${encodedBody}`;
  }
},

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        if (this.emailLink.length > 2000) {
          console.error('Email content too long for some email clients.');
        } else {
          const emailWindow = window.open(this.emailLink);
          if (emailWindow) {
            this.$emit('snack', 'Feedback successfully submitted!');
            this.$emit('closeFeedback');
          } else {
            console.error('Popup blocked');
          }
        }
      } else {
        console.error('Validation failed');
      }
    },
  },
};
</script>

<style scoped>
</style>
