<script>
import { Line, mixins } from 'vue-chartjs';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

export default {
  name: 'Linechart',
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: Object,
    chartTitle: String,
    options: Object,
  },
  data() {
    return {
    };
  },
  mounted() {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        colorschemes: {
          scheme: Tableau20,
        },
      },
      elements: {
        line: {
          borderWidth: 1,
          fill: false,
        },
      },
      ...this.options,
    };
    this.renderChart(this.chartData, chartOptions);
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
};
</script>

<style scoped>
</style>
