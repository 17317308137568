<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Tenant Service Charges
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/servicecharges', 'POST')"
        color="blue"
        text
        @click="$router.push('/tenantservicecharges/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="serviceCharges"
        :headers="exportHeaders"
        report-name="Tenant Service Charges"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="serviceCharges"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 220px)"
          >
            <template #item.Name="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/servicecharges/<servicecharge_id>', 'GET')"
                :to="`tenantservicecharges/${item.servicecharge_id}`"
              >
                {{ item.Name }}
              </router-link>
              <div v-else>
                {{ item.Name }}
              </div>
            </template>

            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
            <template #item.UnitCost="{ item }">
              <span>{{ item.UnitCost | formatMoney }}</span>
            </template>
            <template #item.TotalCost="{ item }">
              <span>{{ item.TotalCost | formatMoney }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import config, { BTL_API_TENANT_URL } from '@/config.js';

import permittedFunction from '@/permittedFunction.js';
import download from '@/components/download/tableDownload';
import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'TenantServiceCharges',
  components: {
    download,
  },
  filters: {
    formatDate(date) {
      return moment(date).format(config.date_format);
    },
    formatMoney(value) {
      return currencyFormatter(value);
    },
  },
  data() {
    const headers = [
      { text: 'Name', value: 'Name' },
      { text: 'Service', value: 'service.Name' },
      { text: 'Description', value: 'Description' },
      { text: 'Unit Type', value: 'UnitType' },
      { text: 'Units', value: 'Units' },
      { text: 'Unit Cost', value: 'UnitCost' },
      { text: 'Total Cost', value: 'TotalCost' },
      { text: 'Charge Frequency', value: 'ChargeFrequency' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      serviceCharges: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      accounts: {},
      loading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        await this.loadServiceCharges();
        this.loadAccounts();
      } finally {
        this.loading = false;
      }
    },
    async loadServiceCharges() {
      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/servicecharges`);
        this.serviceCharges = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve service charges.', true);
      }
    },
    async loadAccounts() {
      try {
        if (this.isPermitted('/tenants/<tenant_name>/accounts', 'GET')) {
          const response = await axios.get(config.BTL_API_ACCOUNT_URL);
          this.accounts = response.data.reduce((acc, cv) => {
            const returnValue = { ...acc };
            returnValue[cv.account_id] = cv;
            return returnValue;
          }, {});
        }
      } catch (error) {
        console.error('Failed to retrieve accounts', error);
        this.$emit('snack', 'Failed to retrieve accounts', true);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
