<template>
  <v-container>
    <node-header
      icon="call_split"
      :title="name"
      :detail="threshold"
      :color-a="routeConfig.trueAction ==='none'"
      :color-b="routeConfig.falseAction ==='none'"
      chip-a="NEXT"
      chip-b="OVERFLOW"
    />

    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        :close-on-content-click="false"
        :close-on-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="primary"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              edit
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Configure name</v-subheader>
          <v-list-item>
            <v-text-field
              v-model="name"
              label="Node Name"
            />
          </v-list-item>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-subheader>CallFlow Limit</v-subheader>
            <v-list-item>
              <v-text-field
                v-model="threshold"
                label="Threshold"
                type="number"
                :rules="thresholdRules"
                required
              />
            </v-list-item>
            <v-subheader>Add next step</v-subheader>
            <v-list-item class="mb-2">
              <v-select
                v-model="nextAction"
                label="NEXT"
                :items="actions"
              />
            </v-list-item>
            <v-list-item class="mb-2">
              <v-select
                v-model="overflowAction"
                label="OVERFLOW"
                :items="actions"
              />
            </v-list-item>
          </v-form>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="handleCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="menu_delete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleDelete"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>
import NodeHeader from './partials/Header';

import { typesForSelect } from './nodeTypes';

export default {
  name: 'CallflowLimit',
  components: {
    NodeHeader,
  },
  props: {
    node: Object,
    dialplan: Array,
    canedit: Boolean,
  },
  data: () => ({
    valid: false,
    menu: false,
    menu_delete: false,
    width: 0,
    height: 0,

    nextAction: 'none',
    overflowAction: 'none',

    threshold: 0,
    name: null,

    actions: typesForSelect.filter((item) => item.value !== 'CheckDCL'),
    routeConfig: {
      threshold: 0,
      nextAction: 'none',
      overflowAction: 'none',
    },

    thresholdRules: [
      (v) => !!v || 'Threshold is required',
      (v) => (v && Number(v) > 0) || 'Threshold must be greater than 0',
    ],
  }),
  mounted() {
    this.name = this.node.name;
    this.threshold = this.node.parameters.threshold;
    this.nextAction = this.getLabel(this.node.conditions.CheckDCL.next);
    this.routeConfig.nextAction = this.getLabel(this.node.conditions.CheckDCL.next);
    this.overflowAction = this.getLabel(this.node.conditions.CheckDCL.overflow);
    this.routeConfig.overflowAction = this.getLabel(this.node.conditions.CheckDCL.overflow);
  },
  methods: {
    getLabel(nodeId) {
      const node = this.dialplan.find((n) => n.id === nodeId);
      if (node) {
        return node.action;
      }
      return 'none';
    },
    handleDelete() {
      this.$emit('deletenode');
    },
    handleSave() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }

      this.menu = false;

      this.node.name = this.name;
      this.node.parameters.threshold = Number(this.threshold);
      this.node.routeConfig = this.routeConfig;
      if (this.routeConfig.nextAction !== this.nextAction) {
        this.routeConfig.nextAction = this.nextAction;
        if (this.nextAction === 'none') {
          this.node.conditions.CheckDCL.next = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.nextAction, 'CheckDCLNext');
        }
      }
      if (this.routeConfig.overflowAction !== this.overflowAction) {
        this.routeConfig.overflowAction = this.overflowAction;
        if (this.overflowAction === 'none') {
          this.node.conditions.CheckDCL.overflow = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.overflowAction, 'CheckDCLOverflow');
        }
      }
    },
    handleCancel() {
      this.menu = false;
      this.name = this.node.name;
      this.threshold = this.node.parameters.threshold;
    },
  },
};
</script>
