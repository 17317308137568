<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        High Cost Destinations
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/highcostdestinations', 'POST')"
        text
        color="blue"
        @click="$router.push('/hcd/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        v-if="isPermitted('/highcostdestinations/load', 'POST')"
        text
        color="blue"
        @click="importDialog = true"
      >
        <v-icon class="mr-2">
          cloud_upload
        </v-icon>Import
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="hcds"
        :headers="exportHeaders"
        report-name="Hight Cost Destinations"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="hcds"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="key"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            :custom-sort="customSort"
            sort-by="HCDCode"
            height="calc(100vh - 220px)"
          >
            <template #item.HCDCode="{ item }">
              <router-link
                v-if="isPermitted('/highcostdestinations/<hcdid>', 'GET')"
                :to="item.itemRoute"
              >
                {{ item.HCDCode }}
              </router-link>
              <span v-else>{{ item.HCDCode }}</span>
            </template>
            <template #item.HCDCost="{ item }">
              <span>{{ item.HCDCost | formatMoney }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <csv-importer
      :show="importDialog"
      headings="HCDCode, HCDDescription, HCDCost, HCDDuration, HCDCount, HCDToleranceByHour, HCDToleranceBySegment, &amp; HCDIsOutOfHours"
      example="/examplehcd.csv"
      :url="importURL"
      @close="importDialog = false"
      @snack="handleSnack"
    />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import config from '@/config.js';

import download from '@/components/download/tableDownload';
import csvImporter from '@/components/billing/csvImporter.vue';
import customSort from '@/utils/customSort';
import permittedFunction from '@/permittedFunction.js';

import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'HighCostDestinations',
  components: {
    download,
    csvImporter,
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatMoney(value) {
      return value != null ? currencyFormatter(value) : '';
    },
  },
  data() {
    const headers = [
      { text: 'Code', value: 'HCDCode' },
      { text: 'Description', value: 'HCDDescription' },
      { text: 'Cost (£0.00)', value: 'HCDCost' },
      { text: 'Duration (Minutes)', value: 'HCDDuration' },
      { text: 'Count', value: 'HCDCount' },
      { text: 'Tolerance By Hour (%)', value: 'HCDToleranceByHour' },
      { text: 'Tolerance By Segment (%)', value: 'HCDToleranceBySegment' },
      { text: 'Is Out Of Hours', value: 'HCDIsOutOfHours' },
      { text: 'Is Default', value: 'DefaultHCD' },
      { text: 'Auto Update', value: 'AutoUpdate' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];
    return {
      hcds: [],
      headers,
      exportHeaders: [
        { text: 'ID', value: 'HCDId' },
        ...headers.map((header) => ({
          ...header,
          text: header.text.replace(/ /g, ''),
        })),
      ],
      loading: false,
      importDialog: false,
      importURL: `${config.BTL_API_TENANT_URL}/highcostdestinations/load`,
    };
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        await this.loadHCDs();
      } catch (error) {
        console.error('Could not load data: ', error.message);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
      }
    },
    async loadHCDs() {
      const response = await axios.get(`${config.BTL_API_TENANT_URL}/highcostdestinations`);

      this.hcds = response.data
        .map((row) => ({
          ...row,
          itemRoute: `/hcd/${row.HCDId}`,
          key: row.HCDId,
        }));
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
    handleSnack(message, isError = false) {
      this.$emit('snack', message, isError);
    },
    customSort: customSort('ModifiedOn'),
  },
};
</script>
