<script>/* eslint-disable */</script>
<template>
<resize-container
  :id="id"
  :layout-props="layoutProps"
  @saveLayout="bubbleLayout"
  @resizing="onResize"
>
    <v-layout
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            Call Count
          </v-toolbar-title>
          <v-spacer />
          <v-select
            v-if="!hasInsufficientPermissions"
            v-model="countType"
            class="body-2 widget__select"
            dense
            required
            :items="countTypes"
            style="width: 100px;"
          />
          <v-spacer />
          <v-btn
            v-if="!hasInsufficientPermissions"
            icon
            @click="loadCounts"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
      <v-container style="height: 100%; overflow-y: scroll; background-color: #FFF;">
        <v-layout
          v-if="countType==='TRUNK'"
          class="ma-1"
        >
          <v-select
            v-model="trunk"
            flat
            :items="trunks"
            item-text="Description"
            item-value="TrunkID"
            label="Trunk"
            class="body-2"
          />
        </v-layout>

        <v-layout
          v-if="countType==='DDI'"
          class="ma-1"
        >
          <v-flex xs11>
            <v-text-field
              v-model="ddi"
              clearable
              label="DDI"
              :counter="15"
            />
          </v-flex>
          <v-flex xs1>
            <v-tooltip bottom>
              <template #activator="{on}">
                <v-btn
                  icon
                  class="mt-3"
                  @click="loadCounts"
                  v-on="on"
                >
                  <v-icon>forward</v-icon>
                </v-btn>
              </template>
              <span>Update counters for DDI</span>
            </v-tooltip>
          </v-flex>
        </v-layout>

        <v-alert
          :value="hasInsufficientPermissions"
          type="warning"
        >
          You have insufficient permissions to view call counts
        </v-alert>

        <v-alert
          :value="callCountsWarning"
          type="warning"
          dismissible
        >
          Call Counts might not be accurate
        </v-alert>

        <v-alert
          :value="callCountsError"
          type="error"
        >
          Call Counts system in error and cant be relied upon
        </v-alert>

        <v-data-table
        :headers="headers"
        :items="counts"
        dense
        hide-default-footer
        disable-pagination
        item-key="name"
        @update:sort-by="updateSort"
        @update:sort-desc="updateSortDesc"
        class="container-padding"
      >
        <template #header="{ props }">
          <thead>
            <tr>
              <th
              v-for="header in props.headers"
              :key="header.text.text || header.text"
              :class="['clickable-header', header.class, header.value === 'name' ? 'call-type-header' : 'standard-header']"
              @click="handleHeaderClick(header.value)"
              :style="{ cursor: header.sortable ? 'pointer' : 'default', 'white-space': 'nowrap' }"
            >
              <div class="header-container">
                <span class="header-text">
                  {{ typeof header.text === 'object' ? header.text.text : header.text }}
                </span>
                <svg
                  class="sort-arrow"
                  v-if="localSortBy === header.value"
                  :class="{
                    'rotate-down': !localSortDesc,
                    'rotate-up': localSortDesc
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  width="12"
                  height="12"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m26.71 10.29-10-10a1 1 0 0 0-1.41 0l-10 10 1.41 1.41L15 3.41V32h2V3.41l8.29 8.29z" />
                </svg>
              </div>
            </th>
            </tr>
          </thead>
        </template>
      </v-data-table>

      </v-container>
    </v-layout>
  </resize-container>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import { mapGetters } from 'vuex';
import { BTL_API_TENANT_URL, BTL_API_ACCOUNT_URL } from '@/config.js';
import ResizeContainer from '@/components/widgets/layout/ResizeContainer';
import permittedFunction from '../../permittedFunction';

export default {
  name: 'CallcountWidget',
  components: {
    'resize-container': ResizeContainer,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    layoutProps: {
      type: Object,
      default: () => ({
        h: 150,
        w: 150,
        x: 50,
        y: 50,
      }),
    },
  },
  data() {
    return {
      loading: true,
      callCount: null,
      trunks: [],
      trunk: null,
      ddi: '',
      refresherFunction: null,
      localSortBy: '',
      localSortDesc: false,
      headers: [
        { text: 'Call Type', value: 'name', class: 'pl-1', sortable: true },
        { text: 'In', value: 'in', align: 'end', sortable: true },
        { text: 'Out', value: 'out', align: 'end', sortable: true },
        { text: 'Total', value: 'total', align: 'end', sortable: true },
      ],
      counts: [],
      callCountCategories: [
        { name: 'All', prefix: 'all' },
        { name: 'National', prefix: 'nat' },
        { name: 'Non-Geographic', prefix: 'ngn' },
        { name: 'Mobile', prefix: 'mob' },
        { name: 'Premium', prefix: 'prem' },
        { name: 'International', prefix: 'intl' },
        { name: 'International Premium', prefix: 'intlPrem' },
        { name: 'Premium Mobile', prefix: 'premMob' },
        { name: 'International Mobile', prefix: 'intlMob' },
        { name: 'International Premium Mobile', prefix: 'intlPremMob' },
        { name: 'IntraAccount', prefix: 'intraAcc' },
        { name: 'Other', prefix: 'other' },
        { name: 'Emergency', prefix: 'emrg' },
      ],
    };
  },
  computed: {
  ...mapGetters(['userPreferences']),
  
  countType: {
    get() {
      // Check if the Vuex store has user preferences for this specific widget ID
      return this.$store.state.userPreferences?.[this.id]?.countType || 'ACCOUNT'; // default to 'ACCOUNT'
    },
    set(value) {
      // Save the updated countType in Vuex under this widget's specific ID
      this.$store.commit('setCountType', { id: this.id, countType: value });
      this.$store.dispatch('saveUserPreferences', {
        ...this.$store.state.userPreferences,
        [this.id]: {
          ...this.$store.state.userPreferences?.[this.id],
          countType: value,
        },
      });
    },
  },
    hasInsufficientPermissions() {
      return this.countTypes.length === 0;
    },
    callCountsError() {
      return this.callCount && this.callCount.serviceStatus !== 'OK';
    },
    callCountsWarning() {
      return this.callCount && this.callCount.nonAuthoritativeNetxGateways.length > 0;
    },
    countTypes() {
      const countType = [];

      if (this.isPermitted('/tenants/<tenant_name>/callcount/system', 'GET')) {
        countType.push({ text: 'System', value: 'SYSTEM' });
      }
      if (this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/callcount', 'GET')) {
        countType.push({ text: 'Account', value: 'ACCOUNT' });
      }
      if (this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/trunks/<trunk_id>/callcount', 'GET')) {
        countType.push({ text: 'Trunk', value: 'TRUNK' });
      }
      if (this.isPermitted('/tenants/<tenant_name>/accounts/<account_id>/phonenumbers/<phonenumber>/callcount', 'GET')) {
        countType.push({ text: 'DDI', value: 'DDI' });
      }
      return countType;
    },
    ...mapGetters([
      'selectedAccountId',
      'authentication',
    ]),
  },
  watch: {
    countType(newValue) {
    // Reload counts whenever countType changes
    this.loadCounts();
  },
    authentication: {
      handler() {
        this.loadCounts();
      },
      deep: true,
    },
    trunk() {
      this.loadCounts();
    },
  },
  mounted() {
    this.$store.dispatch('loadUserPreferences').then(() => {
    this.loadCounts();  // Load counts based on the saved countType
  });

    this.loadCounts();
    this.loadTrunks();

    if (!this.refresherFunction) {
      this.refresherFunction = setInterval(this.loadCounts, 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.refresherFunction);
  },
  methods: {
    async loadCounts() {
      if (this.hasInsufficientPermissions) {
        return;
      }

      this.loading = true;

      let getURL = `${BTL_API_TENANT_URL}/callcount/system`;
      let includeAllFowarded = false;

      if (this.countType === 'DDI' && this.ddi !== '') {
        getURL = `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/phonenumbers/${this.ddi}/callcount`;
      } else if (this.countType === 'ACCOUNT') {
        includeAllFowarded = true;
        getURL = `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/callcount`;
      } else if (this.countType === 'TRUNK') {
        if (!this.trunk) {
          return;
        }
        includeAllFowarded = true;
        getURL = `${BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/trunks/${this.trunk}/callcount`;
      }

      try {
        const { data: callCounts } = await axios.get(getURL);
        this.callCount = callCounts;

        const { count: counts } = callCounts;
        this.counts = this.callCountCategories.map(({ name, prefix }) => this.createTableRow(counts, name, prefix));
        if (includeAllFowarded) {
          this.counts.push({
            name: 'All Forwarded', in: '-', out: '-', total: counts.allFwd || 0,
          });
        }
      } catch (err) {
        console.error('ERROR', err);
      }
      this.loading = false;
    },
    createTableRow(counts, name, prefix) {
      return {
        name,
        in: counts[`${prefix}In`] || 0,
        out: counts[`${prefix}Out`] || 0,
        total: counts[`${prefix}Total`] || 0,
      };
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
    async loadTrunks() {
      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/trunks`);
        this.trunks = sortBy(response.data, (trunk) => trunk.Description.toLowerCase());
      } catch (error) {
        console.error('ERROR', error);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    handleHeaderClick(column) {
      if (this.localSortBy === column) {
        this.localSortDesc = !this.localSortDesc;
      } else {
        this.localSortBy = column;
        this.localSortDesc = false;
      }
      this.sortTable();
    },
    sortTable() {
      this.counts = this.counts.slice().sort((a, b) => {
        if (this.localSortDesc) {
          return a[this.localSortBy] < b[this.localSortBy] ? 1 : -1;
        } else {
          return a[this.localSortBy] > b[this.localSortBy] ? 1 : -1;
        }
      });
    },
  },
};
</script>

<style scoped>
:deep(.v-data-table-header) {
  display: none;
}
/* Adjust the header spacing */
.clickable-header {
  font-weight: bold;
  text-align: center;
  padding: 0 !important;
  padding-left: 1px; /* Add more space between columns */
  padding-right: 1px; /* Add more space between columns */
}

/* Adjust the arrow positioning */
.clickable-header svg {
  margin-left: 8px; /* Increase margin between header text and arrow */
}

/* Adjust specific column alignment */
.left-align {
  text-align: left !important;
}

.right-align {
  text-align: right !important; /* Ensure right alignment for 'In', 'Out', and 'Total' */
}
.container-padding {
  padding: 16px; /* Adjust padding as needed */
}

:deep(.text-end) {
  text-align: right !important;
}
.feather {
  transition: transform 0.3s ease;
}

/* Rotate up arrow (opposite to default) */
.rotate-up {
  transform: rotate(-180deg); /* Rotate arrow 180 degrees */
}

/* Rotate down arrow (opposite to rotated) */
.rotate-down {
  transform: rotate(0deg); /* No rotation for down state */
}
/* General header cell width */
.clickable-header {
  font-weight: bold;
  text-align: center;
  padding: 0 !important;
  padding-left: 1px;
  padding-right: 1px;
  white-space: nowrap;
  box-sizing: border-box; /* Include padding and borders in width calculation */
  overflow: hidden; /* Prevent overflow issues */
}

/* Wider width for the Call Type column */
.call-type-header {
  width: 200px; /* Adjust width as needed for Call Type column */
}

/* Standard width for other columns */
.standard-header {
  width: 150px; /* Standard width for other columns */
}

.header-container {
  align-items: center; /* Center items vertically */
  width: 100%; /* Full width of the header cell */
  text-align: right;
}

.header-text {
  margin-right: 4px; /* Space between text and arrow */
}

.sort-arrow {
  display: inline-block;
  vertical-align: middle; /* Align with text */
}

.rotate-up {
  transform: rotate(-180deg); /* Rotate arrow 180 degrees */
}

.rotate-down {
  transform: rotate(0deg); /* No rotation for down state */
}

thead th:first-of-type .header-container {
  text-align: left !important;
}
.header-text{
  margin-right: 0px  !important;
}
</style>