<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        API Endpoints
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('POST')"
        text
        color="blue"
        @click="$router.push('/newapiendpoint')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create API endpoint
      </v-btn>
      <tableDownload
        color="primary"
        :table-data="endpoints"
        report-name="API Endpoints"
      />
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="endpoints"
        :search="$store.getters.searchQuery"
        :loading="loading"
        sort-by="url"
        :items-per-page="15"
      >
        <template #item.actions="props">
          <v-tooltip
            v-if="isPermitted('PUT')"
            bottom
          >
            <template #activator="{on}">
              <v-btn
                icon
                @click="$router.push(`/apiendpoint/${props.item.id}`)"
                v-on="on"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>Edit Endpoint</span>
          </v-tooltip>

          <v-tooltip
            v-if="isPermitted('DELETE')"
            bottom
          >
            <template #activator="{on}">
              <v-btn
                icon
                @click="deleteEndpoint(props.item.id)"
                v-on="on"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Endpoint</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import permittedFunction from '@/permittedFunction.js';
import config from '../../config.js';

import tableDownload from '../../components/download/tableDownload';

export default {
  name: 'ApiEndpoints',
  components: {
    tableDownload,
  },
  data() {
    return {
      endpoints: [],
      headers: [
        {
          text: 'Url',
          value: 'url',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Authentication',
          value: 'authType',
        },
        {
          text: 'Timeout Period',
          value: 'timeoutPeriod',
        },
        {
          text: 'Version',
          value: 'version',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.loadEndpoints();
    },
  },
  mounted() {
    this.loadEndpoints();
  },
  methods: {
    isPermitted(verb, resources) {
      const endpointRoot = '/tenant/<tenant_name>/account/<account_id>/endpoint';
      const endpoint = verb === 'PUT' || verb === 'DELETE' ? `${endpointRoot}/<endpointId>` : endpointRoot;
      return permittedFunction(endpoint, verb, resources);
    },
    loadEndpoints() {
      this.loading = true;
      axios.get(`${config.bizvu_3rd_party_endpoint_url}/account/${this.selectedAccountId}/endpoint`)
        .then((response) => {
          this.endpoints = response.data;
        })
        .catch((error) => {
          console.error('Couldnt fetch api endpoints', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteEndpoint(endpointId) {
      axios.delete(`${config.bizvu_3rd_party_endpoint_url}/account/${this.selectedAccountId}/endpoint/${endpointId}`)
        .then(() => {
          this.$emit('snack', 'Api Endpoint Deleted!');
          this.loadEndpoints();
        })
        .catch((error) => {
          console.error('Error deleting api endpoint', error);
        });
    },
  },
};
</script>
