<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Changelog
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-layout
        row
        class="mt-1 px-2"
      >
        <v-card>
          <v-list>
            <template v-for="release in releases">
              <v-subheader :key="release.release_id">
                {{ release.version }} - {{ formatDate(release.timestamp) }}
              </v-subheader>

              <v-spacer :key="release.version" />

              <v-list-item
                v-for="change in release.changes"
                :key="change.id"
              >
                <v-list-item-avatar>
                  <v-icon>{{ icon(change.type) }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="py-0">
                  <v-list-item-title>{{ change.title }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span style="white-space: pre-wrap;">{{ change.description }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';

import config from '../config.js';
import changelog from '../../changelog.json';

export default {
  name: 'Changelog',
  data() {
    return {
      releases: null,
    };
  },
  mounted() {
    const { releases } = changelog;

    releases.forEach((release) => {
      const bugFixes = release.changes.filter((change) => change.type === 'bugfix');
      const improvements = release.changes.filter((change) => change.type === 'improvement');
      const features = release.changes.filter((change) => change.type === 'feature');
      const debt = release.changes.filter((change) => change.type === 'debt');

      release.changes = [...features, ...improvements, ...bugFixes, ...debt];
    });

    this.releases = releases;
  },
  methods: {
    icon(changeType) {
      switch (changeType) {
        case 'improvement':
          return 'arrow_upward';
        case 'bugfix':
          return 'bug_report';
        case 'debt':
          return 'attach_money';
        default:
          return 'add_circle_outline';
      }
    },
    formatDate(timestamp) {
      return moment.unix(timestamp).format(config.long_date_format);
    },
  },
};
</script>

<style scoped>

</style>
