<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
  >
    <v-layout
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            All Traffic
          </v-toolbar-title>
          <v-spacer />
          <v-select
            v-model="days"
            dense
            required
            :items="timePeriods"
            style="width: 100px;"
            class="body-2 widget__select"
          />
          <v-spacer />
          <v-btn
            icon
            @click="loadCalls"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
      <v-container
        ref="chartContainer"
        style="height: 100%; overflow-y: scroll; background-color: #FFF;"
      >
        <v-layout
          v-if="!dataLoaded"
          align-center
          justify-center
          row
          fill-height
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-layout>
        <linechart
          v-if="dataLoaded"
          ref="chart"
          chart-title="All Traffic"
          :height="height"
          :chart-data="callChart"
          :options="chartOptions"
        />
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { range } from 'lodash';
import { mapGetters } from 'vuex';

import { bizvu_analytics_url } from '@/config.js';

import linechart from '@/components/widgets/charts/linechart';
import ResizeContainer from '@/components/widgets/layout/ResizeContainer';

export default {
  name: 'Alltraffic',
  components: {
    linechart,
    'resize-container': ResizeContainer,
  },
  props: {
    id: String,
    layoutProps: Object,
    data: {
      type: Object,
      default() {
        return {
          days: 1,
        };
      },
    },
  },
  data() {
    return {
      days: this.data.days,
      dataLoaded: false,
      callChart: {
        labels: [],
      },
      timePeriods: [
        { text: 'Today', value: 1 },
        { text: 'Last 7 Days', value: 7 },
        { text: 'Last 30 Days', value: 30 },
      ],
      height: 252,
    };
  },
  computed: {
    interval() {
      return this.days === 1 ? 'hour' : 'day';
    },
    chartOptions() {
      return {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
          }],
          xAxes: [{
            type: 'time',
            time: {
              unit: this.interval,
              displayFormats: {
                hour: 'hh',
                day: 'D',
              },
            },
            ticks: {
              source: 'labels',
            },
          }],
        },
      };
    },
    ...mapGetters([
      'selectedAccountId',
      'authentication',
      'timeZone',
    ]),
  },
  watch: {
    days() {
      this.loadCalls();
      this.$emit('saveWidgetData', this.id, { days: this.days });
    },
    authentication: {
      handler() {
        this.dataLoaded = false;
        this.loadCalls();
      },
      deep: true,
    },
  },
  mounted() {
    this.height = this.layoutProps.h - 36;
    this.loadCalls();
  },
  methods: {
    async loadCalls() {
      this.dataLoaded = false;

      const startDate = moment().tz(this.timeZone).startOf('day').subtract(this.days - 1, 'days')
        .toISOString();
      const endDate = moment().tz(this.timeZone).endOf('day').toISOString();

      if (this.interval === 'hour') {
        this.callChart.labels = range(24).map((hour) => moment(startDate).add(hour, 'hour'));
      } else {
        this.callChart.labels = range(this.days).map((day) => moment(startDate).add(day, 'day'));
      }

      try {
        const { data } = await axios.get(`${bizvu_analytics_url}/account/${this.selectedAccountId}/alltraffic?startdate=${startDate}&enddate=${endDate}&interval=${this.interval}&timeZone=${this.timeZone}`);

        const seriesLabels = {
          OUTGOING: 'Out',
          INCOMING: 'In',
        };

        const dataSets = [];
        Object.keys(data).forEach((callDirection) => {
          dataSets.push({
            label: `${seriesLabels[callDirection]} Total`,
            data: this.addZeroEntriesWhereNoData(data[callDirection].totals),
            fill: false,
            spanGaps: true,
          });

          Object.keys(data[callDirection]).filter((key) => key !== 'totals').forEach((callType) => {
            dataSets.push({
              label: `${seriesLabels[callDirection]} ${callType}`,
              data: this.addZeroEntriesWhereNoData(data[callDirection][callType]),
              fill: false,
              spanGaps: true,
            });
          });
        });

        this.callChart.datasets = dataSets;
      } catch (error) {
        console.error('ERROR', error);
      }
      this.dataLoaded = true;
    },
    addZeroEntriesWhereNoData(dataSeries) {
      const dataPoints = this.callChart.labels.map((time) => ({ x: time, y: 0 }));
      dataSeries.forEach((timeValue) => {
        const point = dataPoints.find((p) => p.x.isSame(timeValue.key));
        if (point) {
          point.y = timeValue.doc_count;
        }
      });
      return dataPoints;
    },
    bubbleLayout(layout) {
      if (layout.h !== this.layoutProps.h || layout.w !== this.layoutProps.w) { // Trick to get chart to re render on resize
        this.height = this.$refs.chartContainer.clientHeight;
        this.dataLoaded = false;
        this.$nextTick(() => {
          this.dataLoaded = true;
        });
      }
      this.$emit('saveLayout', layout);
    },
  },
};
</script>
