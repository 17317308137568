<template>
  <v-data-table
    :items="data"
    :headers="headers"
    :footer-props="{
      itemsPerPageOptions: 50,
      itemsPerPageOptions: [50, 100, 250, -1]
    }"
  >
    <template #item.name="{ item }">
      <router-link
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>', 'GET')"
        :to="`account/${item.accountId}`"
      >
        {{ item.name }}
      </router-link>
      <div v-else>
        {{ item.name }}
      </div>
    </template>
  </v-data-table>
</template>
<script>

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'ServiceServiceChargesLookup',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const headers = [
      { text: 'Account Name', value: 'name' },
    ];

    return {
      headers,
    };
  },
  methods: {
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>

.v-data-table {
  height: 90%;
}

::v-deep .v-data-table__wrapper {
  height: calc(100% - 60px);
  overflow-x: auto;
  overflow-y: auto;
}

</style>
