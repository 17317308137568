import moment from 'moment';

const label = '24 hours - 1 hour interval';
const limit = 12;
const text = '24 Hours';

const createFilter = () => {
  const now = moment(); // Get the current date and time
  const startOfToday = now.clone().startOf('day'); // Get the start of the current day
  const endOfToday = now.clone().endOf('day'); // Get the end of the current day

  const timeRangeFilter = {
    range: {
      call_datetime_iso: {
        gte: startOfToday.toISOString(),
        lte: endOfToday.toISOString(),
      },
    },
  };
  return [timeRangeFilter];
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  fixed_interval: '1h',
  min_doc_count: 0,
});

const dateFormat = (date) => moment(date).format('HH:mm');

export default {
  label,
  limit,
  text,
  createFilter,
  dateHistogram,
  dateFormat,
};
