<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Rate
      </v-toolbar-title>
      <codes-lookup
        v-if="!createMode && !rateAccountID"
        rate-name="chargeband.Name"
        header="name"
        api-path="nongeos"
        filter-name="CBId"
        :filter-value="CBId"
        redirect-path="nongeocodes"
      />
      <v-btn
        v-if="!createMode && isPermitted('/chargebands/<cb_id>', 'DELETE', true)"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col cols="6">
            <v-layout v-if="rateAccountID && !createMode">
              <v-text-field
                readonly
                label="Account"
                :value="rate.account"
              />
            </v-layout>
            <v-layout v-if="createMode">
              <v-select
                v-model="rate.AccountId"
                flat
                :items="accounts"
                item-text="name"
                item-value="account_id"
                label="Account"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-layout v-if="createMode && rate.AccountId">
              <v-select
                v-model="rate.Name"
                flat
                :items="chargebands"
                item-text="Name"
                item-value="Name"
                :rules="NameRules"
                label="Chargeband"
              />
            </v-layout>
            <v-layout v-if="!createMode || createMode && !rate.AccountId">
              <v-text-field
                v-model="rate.Name"
                label="Name"
                :rules="NameRules"
                :readonly="!createMode"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout v-if="!createMode">
              <v-flex>
                <v-text-field
                  v-model="rate.CodeCount"
                  readonly
                  label="Destination Count"
                />
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-layout>
              <v-select
                v-model="rate.Carrier"
                flat
                :items="carriers"
                :rules="CarrierRules"
                item-text="name"
                item-value="name"
                label="Carrier"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="rate.Description"
                label="Description"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="rate.CostDayRate"
                label="Cost Day Rate"
                :rules="CostDayRateRules
                "
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="rate.CostEveRate"
                label="Cost Evening Rate"
                :rules="CostEveRateRules
                "
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="rate.CostWendRate"
                label="Cost Weekend Rate"
                :rules="CostWendRateRules
                "
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="rate.CostConFee"
                label="Cost Connection Fee"
                :rules="CostConFeeRules
                "
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="rate.SellDayRate"
                label="Sell Day Rate"
                :rules="SellDayRateRules
                "
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="rate.SellEveRate"
                label="Sell Evening Rate"
                :rules="SellEveRateRules
                "
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="rate.SellWendRate"
                label="Sell Weekend Rate"
                :rules="SellWendRateRules
                "
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="rate.SellConFee"
                label="Sell Connection Fee"
                :rules="SellConFeeRules
                "
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-layout>
          <v-flex class="pa-2">
            <v-row>
              <v-btn
                v-if="isPermitted('/chargebands/<cb_id>', 'PUT', true)"
                primary
                color="primary"
                dark
                @click="updateRate"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this rate?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteRate"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';

import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';
import CodesLookup from '@/components/billing/CodesLookup';

export default {
  name: 'Nongeorate',
  components: {
    CodesLookup,
  },
  data() {
    const numberValidator = (v) => !Number.isNaN(Number(v)) || 'Must be a valid number';
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      CBId: this.$route.params.CBId,
      rateAccountID: this.$route.params.accountID,
      rate: {},
      accounts: [],
      chargebands: [],
      carriers: [],
      NameRules: [
        (v) => !!v || 'Name is required',
      ],
      CarrierRules: [
        (v) => !!v || 'Carrier is required',
      ],
      CostDayRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Cost Day Rate is required',
        numberValidator,
      ],
      CostEveRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Cost Evening Rate is required',
        numberValidator,
      ],
      CostWendRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Cost Weekend Rate is required',
        numberValidator,
      ],
      CostConFeeRules: [
        (v) => (v != null && String(v).length > 0) || 'Cost Connection Fee is required',
        numberValidator,
      ],
      SellDayRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Sell Day Rate is required',
        numberValidator,
      ],
      SellEveRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Sell Evening Rate is required',
        numberValidator,
      ],
      SellWendRateRules: [
        (v) => (v != null && String(v).length > 0) || 'Sell Weekend Rate is required',
        numberValidator,
      ],
      SellConFeeRules: [
        (v) => (v != null && String(v).length > 0) || 'Sell Connection Fee is required',
        numberValidator,
      ],
    };
  },
  created() {
    this.loadCarriers();
    if (this.CBId === 'new') {
      this.createMode = true;
      this.loadAccounts();
      this.loadChargebands();
    } else {
      this.loadRate();
    }
  },
  methods: {
    async updateRate() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Rate successfully updated.';
        if (this.createMode) {
          if (this.rate.AccountId) {
            this.rate.Carrier = this.chargebands.find((cb) => cb.Name === this.rate.Name).Carrier;
            await axios.post(`${config.BTL_API_ACCOUNT_URL}/${this.rate.AccountId}/chargebands`, this.rate);
          } else {
            await axios.post(`${config.BTL_API_TENANT_URL}/chargebands`, this.rate);
          }
          snackMessage = 'Rate successfully created.';
        } else if (this.rateAccountID) {
          await axios.put(`${config.BTL_API_ACCOUNT_URL}/${this.rateAccountID}/chargebands/${this.CBId}`, this.rate);
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/chargebands/${this.CBId}`, this.rate);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating rate', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} rate.`, true);
      }
    },
    async loadRate() {
      try {
        if (this.rateAccountID) {
          const account = (await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.rateAccountID}`)).data;
          const rate = (await axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.rateAccountID}/chargebands/${this.CBId}`)).data;
          rate.account = account.name;
          this.rate = rate;
        } else {
          const rate = (await axios.get(`${config.BTL_API_TENANT_URL}/chargebands/${this.CBId}`)).data;
          this.rate = rate;
        }
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve rate.', true);
        console.error('Failed to retrieve rate', error);
      }
    },
    async loadChargebands() {
      try {
        const { data } = await axios.get(`${config.BTL_API_TENANT_URL}/chargebands`);
        this.chargebands = sortBy(data, [(cb) => cb.Name.toLowerCase()]);
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve chargebands.', true);
        console.error('Failed to retrieve chargebands', error);
      }
    },
    async deleteRate() {
      try {
        if (this.rateAccountID) {
          await axios.delete(`${config.BTL_API_ACCOUNT_URL}/${this.rateAccountID}/chargebands/${this.CBId}`);
        } else {
          await axios.delete(`${config.BTL_API_TENANT_URL}/chargebands/${this.CBId}`);
        }
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete rate.', true);
        console.error('Failed to delete rate', error);
      }
    },
    async loadAccounts() {
      try {
        const { data } = await axios.get(config.BTL_API_ACCOUNT_URL);
        this.accounts = sortBy(data, (account) => account.name.toLowerCase());
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve accounts.', true);
        console.error('Failed to retrieve accounts', error);
      }
    },
    async loadCarriers() {
      try {
        const { data } = await axios.get(`${config.bizvu_billing_catalog_url}/carriers`);
        this.carriers = data;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve carriers.', true);
        console.error('Failed to retrieve carriers', error);
      }
    },
    isPermitted(endpoint, verb, accountOrTenant = false) {
      const prefixedEndpoint = ((accountOrTenant && this.rateAccountID) ? '/tenants/<tenant_name>/accounts/<account_id>' : '/tenants/<tenant_name>') + endpoint;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
