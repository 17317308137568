import moment from 'moment';
import dotNotation from './dotNotation';

const customSort = (dateIndexName) => (items, sortBy, isDesc) => {
  if (sortBy[0] && items.length) {
    // this customSort doesn't work with multi-sort, sortBy and isDesc are array and we're taking just the first entry from each
    const indexName = sortBy[0];
    const descending = isDesc[0];
    const isDateComparison = Array.isArray(dateIndexName) ? dateIndexName.includes(indexName) : dateIndexName === indexName;
    if (isDateComparison) {
      items.sort((a, b) => {
        const aCompare = (dotNotation(indexName, a));
        const bCompare = (dotNotation(indexName, b));
        if (moment(aCompare).isSame(bCompare)) {
          return 0;
        }
        if (descending) {
          return moment(aCompare).isAfter(bCompare) ? -1 : 1;
        }
        return moment(aCompare).isBefore(bCompare) ? -1 : 1;
      });
    } else if (typeof dotNotation(indexName, items[0]) === 'string') {
      items.sort((a, b) => {
        const aCompare = (dotNotation(indexName, a) || '').toLowerCase();
        const bCompare = (dotNotation(indexName, b) || '').toLowerCase();
        if (aCompare === bCompare) {
          return 0;
        }
        if (descending) {
          return bCompare < aCompare ? -1 : 1;
        }
        return aCompare < bCompare ? -1 : 1;
      });
    } else {
      items.sort((a, b) => {
        const aCompare = dotNotation(indexName, a);
        const bCompare = dotNotation(indexName, b);
        if (aCompare === bCompare) {
          return 0;
        }
        if (descending) {
          return bCompare < aCompare ? -1 : 1;
        }
        return aCompare < bCompare ? -1 : 1;
      });
    }
  }
  return items;
};

export default customSort;
