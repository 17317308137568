<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
  >
    <v-layout
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          flat
          height="36"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            Outgoing Calls
          </v-toolbar-title>
          <v-spacer />
          <v-select
            v-model="period"
            class="body-2 widget__select"
            dense
            required
            :items="timePeriods"
            style="width: 100px;"
          />
          <v-spacer />
          <v-btn
            icon
            @click="loadCalls"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
      <v-container
        pa-0
        style="height: 100%; overflow-y: hidden; background-color: #FFF"
      >
        <v-layout
          v-if="!dataLoaded"
          align-center
          justify-center
          row
          fill-height
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-layout>
        <dashdoughnutchart
          v-if="dataLoaded"
          :labels="callChart.labels"
          :data="callChart.data"
        />
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import dashdoughnutchart from '@/components/widgets/charts/dashdoughnutchart';
import ResizeContainer from '@/components/widgets/layout/ResizeContainer';

export default {
  name: 'OutgoingCalls',
  components: {
    dashdoughnutchart,
    'resize-container': ResizeContainer,
  },
  props: {
    id: String,
    layoutProps: Object,
    data: {
      type: Object,
      default() {
        return {
          period: 'TODAY',
        };
      },
    },
  },
  data() {
    return {
      period: this.data.period,
      dataLoaded: false,
      callChart: {
        labels: [],
        data: [],
      },
      timePeriods: [
        { text: 'Today', value: 'TODAY' },
        { text: 'last 10 days', value: '10DAYS' },
        { text: 'last 30 days', value: '30DAYS' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'authentication',
      'timeZone',
    ]),
  },
  watch: {
    period() {
      this.loadCalls();
      this.$emit('saveWidgetData', this.id, { period: this.period });
    },
    authentication: {
      handler() {
        this.dataLoaded = false;
        this.loadCalls();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadCalls();
  },
  methods: {
    async loadCalls() {
      this.callChart.labels = [];
      this.callChart.data = [];
      let startDate = moment().tz(this.timeZone).startOf('day');
      const endDate = moment().tz(this.timeZone).endOf('day').toISOString();
      if (this.period === 'TODAY') {
        startDate = startDate.toISOString();
      } else if (this.period === '10DAYS') {
        startDate = startDate.subtract(10, 'days').toISOString();
      } else if (this.period === '30DAYS') {
        startDate = startDate.subtract(30, 'days').toISOString();
      }

      try {
        this.dataLoaded = false;
        const response = await axios.get(`${config.bizvu_analytics_url}/account/${this.selectedAccountId}/outgoingcalls?startdate=${startDate}&enddate=${endDate}`);

        response.data.forEach((datum) => {
          this.callChart.labels.push(datum.callType);
          this.callChart.data.push(datum.count);
        });

        this.dataLoaded = true;
      } catch (error) {
        console.error('ERROR', error.response);
      }
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
  },
};
</script>

<style>
</style>
