<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Payment Service Provider
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/tenants/<tenant_name>/paymentserviceproviders/<pspIdOrName>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
      v-model="valid"
      class="pa-2"
      lazy-validation
    >
      <v-container fluid>
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              v-model="psp.Name"
              label="Name"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="psp.DefaultURL"
              label="Default Url"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            lg="1"
            md="2"
            sm="4"
            xs="6"
          >
            <v-text-field
              v-model="psp.DefaultPort"
              label="Default Port"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              v-model="psp.Comment"
              label="Comment"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              v-model="psp.PspSpecificData"
              label="Specific Data"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              primary
              color="primary"
              dark
              @click="createMode ? createPSP() : updatePSP()"
            >
              Save
            </v-btn>
            <v-btn
              class="ml-2"
              @click="$router.back()"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this payment service provider?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deletePSP"
          >
            Confirm
          </v-btn>
          <v-btn
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

import { BTL_API_TENANT_URL } from '@/config';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'PaymentServiceProvider',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,

      pspId: this.$route.params.id,
      psp: {},
    };
  },
  mounted() {
    if (this.pspId === 'new') {
      this.createMode = true;
    } else {
      this.loadPSP();
    }
  },
  methods: {
    async loadPSP() {
      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/paymentserviceproviders/${this.pspId}`);
        this.psp = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to load payment service provider', true);
        console.error(e);
      }
    },
    async createPSP() {
      try {
        await axios.post(`${BTL_API_TENANT_URL}/paymentserviceproviders`, this.psp);
        this.$emit('snack', 'Payment service provider created');
        this.$router.back();
      } catch (e) {
        this.$emit('snack', 'Failed to update payment service provider', true);
        console.error(e);
      }
    },
    async updatePSP() {
      try {
        await axios.put(`${BTL_API_TENANT_URL}/paymentserviceproviders/${this.pspId}`, this.psp);
        this.$emit('snack', 'Payment service provider updated');
        this.$router.back();
      } catch (e) {
        this.$emit('snack', 'Failed to update payment service provider', true);
        console.error(e);
      }
    },
    async deletePSP() {
      try {
        await axios.delete(`${BTL_API_TENANT_URL}/paymentserviceproviders/${this.pspId}`);
        this.$emit('snack', 'Payment service provider deleted');
        this.$router.back();
      } catch (e) {
        this.$emit('snack', 'Failed to delete payment service provider', true);
        console.error(e);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
