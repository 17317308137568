<template>
  <v-list-item @click.stop="onShow">
    <span v-if="!editMode">
      {{ item.text }}
    </span>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-spacer class="pt-8" />
        <p class="px-6 pb-4">
          Please type in one or more values and press enter to confirm
        </p>
        <v-card-text>
          <v-combobox
            v-model="numbers"
            :label="item.text"
            multiple
            autofocus
            chips
            deletable-chips
            dense
            @change="change"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'QueryBuilderSourceNumber',
  props: {
    item: Object,
    query: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      numbers: [],
    };
  },
  mounted() {
    if (this.editMode) {
      this.onShow();
      this.numbers = this.query.query.bool.filter[0].bool.should.map((filter) => (filter.prefix ? filter.prefix.source_e164.replace(/\*/g, '') : filter.prefix.source_e164));
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'sourceNumber';

      const query = {
        query: {
          bool: {
            filter: [
              {
                bool: {
                  should: this.numbers.map((number) => (({ // to account for 'anonymous'
                    prefix: {
                      source_e164: `${number}`,
                    },
                  }))),
                },
              },
            ],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        text: this.numbers.join(', '),
      });

      this.dialog = false;
      this.number = [];
    },
    change(numbers) {
      this.numbers = numbers.map((number) => {
        const trimmed = number.trim();
        if (trimmed.toLowerCase() === 'anonymous') return 'Anonymous';
        if (trimmed[0] !== '+') return `+${trimmed}`;
        return trimmed;
      });
    },
  },
};
</script>

<style scoped>
 ::v-deep div[role=combobox] .v-input__append-inner {
   display: none;
 }
</style>
