<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}

    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <!-- Subheading for Account(s) -->
        <v-card-title class="subheading-title">
          Account(s)
        </v-card-title>

        <!-- Search Bar -->
        <v-card-text class="pb-1">
          <v-container class="search" fluid>
            <v-row no-gutters>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  label="Search Accounts"
                  dense
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Scrollable Content -->
        <v-card-text class="pb-0 scrollable-content">
          <v-container fluid>
            <v-row no-gutters>
              <v-col
                v-for="account in filteredAccounts"
                :key="account.account_id"
                cols="12"
              >
                <v-checkbox
                  :value="account.account_id"
                  :label="account.name"
                  v-model="selected"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Sticky Footer -->
        <v-card-actions class="sticky-footer">
          <v-btn text @click="selectAll">
            Select All
          </v-btn>
          <v-btn text @click="unselectAll">
            Unselect All
          </v-btn>
          <v-spacer />
          <v-btn text @click="onCancel">
            Cancel
          </v-btn>
          <v-btn :disabled="!!!selected.length" color="primary" text @click="onSubmit">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
/* eslint-disable */ 
import axios from 'axios';
import { sortBy } from 'lodash';
import config from '@/config.js';

export default {
  name: 'QueryBuilderAccount',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      accounts: [],
      selected: [],
      search: '', // Search term for filtering accounts
    };
  },
  created() {
    this.loadAccounts();
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.selected = [...this.item.defaultValue];
    }
  },
  computed: {
    filteredAccounts() {
      const searchTerm = this.search.toLowerCase();
      return this.accounts.filter((account) =>
        account.name.toLowerCase().includes(searchTerm)
      );
    },
  },
  methods: {
    async loadAccounts() {
      try {
        const response = await axios.get(config.BTL_API_ACCOUNT_URL);
        this.accounts = sortBy(response.data, [(account) => account.name.toLowerCase()]);
      } catch (err) {
        this.$emit('snack', 'Failed to load accounts', err);
        console.error('Failed to load accounts', err);
      }
    },
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'account';
      item.defaultValue = [...this.selected];

      const query = {
        query: {
          bool: {
            filter: [
              {
                terms: {
                  accountid: this.selected,
                },
              },
            ],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        modet: [...this.selected],
        text:
          this.accounts.length === this.selected.length
            ? 'All accounts'
            : this.accounts
                .filter((account) =>
                  this.selected.find((selected) => selected === account.account_id)
                )
                .map((account) => account.name)
                .join(', '),
      });
      this.dialog = false;
      this.selected = [];
    },
    selectAll() {
      this.selected = this.accounts.map((account) => account.account_id);
    },
    unselectAll() {
      this.selected = [];
    },
  },
};
</script>

<style scoped>
.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
}

.subheading-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.v-row {
  margin: 0;
}

.v-col {
  padding: 0;
}

/* Adjusted padding below search bar */
.search {
  padding-bottom: 1px !important; /* Less padding under search */
}
</style>
