<template>
  <v-container
    fluid
    grid-list-sm
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        v-for="(value, key) in callDetails"
        :key="key"
        xs4
      >
        <v-text-field
          :label="key"
          :value="value"
          readonly
          style="max-width: 300px;"
        />
        <v-spacer />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'CallDetails',
  components: {
  },
  props: {
    callDetails: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    orderedData() {
      const unordered = this.callDetails;
      const ordered = {};
      Object.keys(unordered).sort().forEach((key) => {
        ordered[key] = unordered[key];
      });
      return ordered;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
</style>
