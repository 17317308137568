<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Provider
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/providers/<provider_prefix>', 'DELETE', true)"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="provider.ProviderPrefix"
                  label="Prefix"
                  :rules="PrefixRules
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="provider.Name"
                  label="Name"
                  :rules="NameRules
                  "
                />
              </v-col>
              <v-col
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="provider.Priority"
                  label="Priority"
                  type="number"
                  :rules="PriorityRules
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="provider.Description"
                  label="Description"
                  :rules="DescriptionRules
                  "
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-2">
            <v-btn
              v-if="isPermitted('/providers/<provider_prefix>', 'PUT', true)"
              primary
              color="primary"
              dark
              @click="updateProvider"
            >
              Save
            </v-btn>
            <v-btn
              class="ml-2"
              @click="$router.back()"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this provider?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="error"
            @click="deleteProvider"
          >
            <v-icon left>
              delete
            </v-icon>
            Confirm
          </v-btn>
          <v-btn
            color="secondary"
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'Provider',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      provider: {
        Name: null,
        ProviderPrefix: null,
        Description: null,
        Priority: null,
      },
      valid: false,
      providerID: this.$route.params.providerID,
      accounts: [],
      PrefixRules: [
        (v) => !!v || 'Prefix is required',
      ],
      NameRules: [
        (v) => !!v || 'Name is required',
      ],
      DescriptionRules: [
        (v) => !!v || 'Description is required',
      ],
      PriorityRules: [
        (v) => !!v || 'Priority is required',
      ],
    };
  },
  created() {
    if (this.providerID === 'new') {
      this.createMode = true;
    } else {
      this.loadProvider();
    }
  },
  methods: {
    async updateProvider() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Provider successfully updated.';

        if (this.createMode) {
          await axios.post(`${config.BTL_API_TENANT_URL}/providers`, this.provider);
          snackMessage = 'Provider successfully created.';
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/providers/${this.providerID}`, this.provider);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating provider', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} provider.`, true);
      }
    },
    async loadProvider() {
      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/providers/${this.providerID}`);
        const provider = response.data;
        this.provider = provider;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve provider.', true);
        console.error('Failed to retrieve provider', error);
      }
    },
    async deleteProvider() {
      try {
        await axios.delete(`${config.BTL_API_TENANT_URL}/providers/${this.providerID}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete provider.', true);
        console.error('Failed to delete provider', error);
      }
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
