import moment from 'moment';
import interval12hours from './interval/12hours';
import interval24hours from './interval/24hours';
import interval3days from './interval/3days';
import interval1week from './interval/1week';
import interval2weeks from './interval/2weeks';
import interval1month from './interval/1month';
import interval6months from './interval/6months';
import interval1year from './interval/1year';
import interval2years from './interval/2years';

const intervals = {
  interval12hours,
  interval24hours,
  interval3days,
  interval1week,
  interval2weeks,
  interval1month,
  interval6months,
  interval1year,
  interval2years,
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  calendar_interval: 'month',
  min_doc_count: 1,
});

const dateFormat = (date, grid) => (grid.interval && intervals[grid.interval] ? intervals[grid.interval].dateFormat(date) : moment(date).format('MMM'));

const getData = (grid, data, level1, level2) => {
  const result = {
    labels: [],
    datasets: [],
  };
  const { aggregations } = data;
  const level1Data = aggregations[level1];
  const summary = level1Data.buckets.map((item) => {
    const sumResult = {};
    sumResult.label = item.key;

    sumResult.datasets = item[level2].buckets.map((item2) => {
      const date = new Date(item2.key); // 2009-11-10
      const x = dateFormat(date, grid);

      return { label: item.key, x, y: item2.doc_count };
    });
    return sumResult;
  });

  result.labels = summary[0].datasets.map((item) => item.x);
  result.datasets = summary.map((item) => {
    const r = {};
    r.label = item.label;
    r.data = item.datasets.map((item2) => item2.y);
    return r;
  });
  // ..by_call_direction
  return result;
};

const getPieData = (grid, data, level1) => {
  const result = {
    labels: [],
    datasets: [],
  };
  const { aggregations } = data;

  result.labels = aggregations[level1].buckets.map((item) => item.key);
  result.datasets[0] = {
    label: '',
    data: aggregations[level1].buckets.map((item) => item.doc_count),
  };
  return result;
};
const build = (grid) => {
  const filter = grid.interval && intervals[grid.interval] ? intervals[grid.interval].createFilter() : [];
  const date_histogram = grid.interval && intervals[grid.interval] ? intervals[grid.interval].dateHistogram(grid) : dateHistogram(grid);
  const query = {
    query: {
      bool: {
        filter,
      },
    },
    aggs: {
      label: {
        terms: {
          field: grid.labelField,
        },
        aggs: {
          y: {
            date_histogram,
            aggs: {
              call_count_agg: {
                value_count: {
                  field: '_id',
                },
              },
            },
          },
        },
      },
    },
  };
  query.sort = [
    {
      call_datetime_iso: 'desc',
    },
  ];
  return query;
};

const fields = [
  {
    value: 'call_status.keyword',
    text: 'Call Status',
  },
  {
    value: 'call_direction.keyword',
    text: 'Call Direction',
  },
  {
    value: 'call_type.keyword',
    text: 'Call Type',
  },
];

export {
  build,
  dateFormat,
  getData,
  getPieData,
  fields,
  intervals,
};
