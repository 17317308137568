<template>
  <div class="simpletimeperiodpicker">
    <v-container class="pa-0">
      <v-row
        no-gutters
        dense
      >
        <v-col>
          <v-icon class="float-right mt-1 mr-2">
            schedule
          </v-icon>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="shrs"
            class="pt-0 mt-0"
            dense
            flat
            :item-value="shrs"
            :items="hrsArray"
          />
        </v-col>
        <v-col>
          <p class="subtitle-1 text-center mt-1">
            :
          </p>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="smin"
            class="pt-0 mt-0"
            dense
            flat
            :item-value="smin"
            :items="minArray"
          />
        </v-col>

        <v-col>
          <p class="subtitle-1 text-center mt-1">
            to
          </p>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="ehrs"
            class="pt-0 mt-0"
            dense
            flat
            :item-value="ehrs"
            :items="hrsArray"
          />
        </v-col>
        <v-col>
          <p class="subtitle-1 text-center mt-1">
            :
          </p>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="emin"
            class="pt-0 mt-0"
            dense
            flat
            :item-value="emin"
            :items="minArray"
          />
        </v-col>
        <v-col>
          <v-btn
            icon
            small
            primary
            class="float-right mt-1 ml-2"
            @click="removeWeekDay"
          >
            <v-icon>cancel</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Simpletimeperiodpicker',
  props: {
    value: Array,
    index: Number,
    timePeriod: Object,
  },
  data: () => ({
    smin: '00',
    shrs: '00',
    emin: '00',
    ehrs: '00',
    hrsArray: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
      '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minArray: ['00', '15', '30', '45'],
  }),
  watch: {
    timePeriod: {
      handler(after, before) {
        if (this.timePeriod.finish !== before.finish) {
          this.ehrs = this.timePeriod.finish.split(':')[0];
          this.emin = this.timePeriod.finish.split(':')[1];
        }
        if (this.timePeriod.start !== before.start) {
          this.shrs = this.timePeriod.start.split(':')[0];
          this.smin = this.timePeriod.start.split(':')[1];
        }
      },
      deep: true,
    },
    emin() {
      this.timePeriod.finish = `${this.ehrs}:${this.emin}`;
    },
    smin() {
      this.timePeriod.start = `${this.shrs}:${this.smin}`;
    },
    ehrs() {
      this.timePeriod.finish = `${this.ehrs}:${this.emin}`;
    },
    shrs() {
      this.timePeriod.start = `${this.shrs}:${this.smin}`;
    },
  },
  created() {
    const sArray = this.timePeriod.start.split(':');
    const eArray = this.timePeriod.finish.split(':');
    this.shrs = sArray[0];
    this.smin = sArray[1];
    this.ehrs = eArray[0];
    this.emin = eArray[1];
  },
  methods: {
    removeWeekDay() {
      this.value.splice(this.index, 1);
    },
  },
};
</script>
<style>
</style>
