<template>
  <div>
    <v-icon color="green">
      call
    </v-icon>
    <v-menu>
      <template #activator="{ on }">
        <v-btn
          v-if="canedit"
          class=" cornerbtn"
          fab
          dark
          color="primary"
          height="24"
          width="24"
          v-on="on"
        >
          <v-icon size="12">
            add
          </v-icon>
        </v-btn>
      </template>

      <v-list v-if="canedit">
        <v-subheader>Add next step</v-subheader>
        <v-list-item
          v-for="type in types"
          :key="type.value"
          @click="$emit('newnode',type.value, 'NEXT')"
        >
          <v-list-item-title>{{ type.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { typesForSelect } from './nodeTypes';

export default {
  props: {
    canedit: Boolean,
  },
  data: () => ({
    types: typesForSelect.filter((item) => item.value !== 'none'),
  }),
};
</script>
