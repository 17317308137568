<template>
  <v-list-item @click.stop="onShow">
    {{ item.text }}
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-spacer class="pt-8" />
        <v-card-text>
          <!-- Options laid out in a vertical stack -->
          <v-container>
            <v-row>
              <v-col cols="12" v-for="option in items" :key="option.text">
                <v-btn
                  block
                  :color="selected === option ? 'primary' : 'default'"
                  @click="selected = option"
                >
                  {{ option.text }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'DCLOverflow',
  props: {
    item: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          text: 'True - Resulted in overflow',
          value: true,
        },
        {
          text: 'False - Did not result in overflow',
          value: false,
        },
      ],
      selected: null,
    };
  },
  mounted() {
    if (this.editMode) {
      this.dialog = true;
    }
    if (this.item && this.item.defaultValue) {
      this.selected = { ...this.item.defaultValue };
    }
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'DCLOverflow';
      item.defaultValue = this.selected;

      const query = {
        query: {
          bool: {
            filter: [
              {
                term: {
                  dcl_overflow: this.selected.value,
                },
              },
            ],
          },
        },
      };

      this.$emit('submit', {
        item,
        query,
        text: this.selected.text,
      });
      this.dialog = false;
      this.selected = null;
    },
  },
};
</script>
