<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        API endpoint
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-container>
      <v-progress-linear
        v-if="loading"
        :indeterminate="true"
      />
      <v-form
        v-if="!loading"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs4>
            <v-select
              v-model="apiEndpoint.type"
              flat
              :items="apiTypes"
              label="Api Type"
              required
              disabled
            />
          </v-flex>
        </v-layout>

        <v-layout class="px-2">
          <v-flex xs4>
            <v-text-field
              v-model="apiEndpoint.name"
              flat
              required
              :rules="nameRules"
              label="Name"
            />
          </v-flex>
        </v-layout>

        <v-layout class="px-2">
          <v-flex xs12>
            <v-text-field
              ref="url"
              v-model="apiEndpoint.url"
              label="Url"
              :rules="urlRules"
              :counter="1024"
              required
            />
          </v-flex>
        </v-layout>

        <v-layout class="px-2">
          <v-flex xs4>
            <v-select
              v-model="apiEndpoint.authType"
              flat
              :items="authenticationType"
              label="Authentication Mechanism"
              :rules="authTypeRules"
              required
            />
          </v-flex>
        </v-layout>

        <v-layout
          v-if="apiEndpoint.authType === 'basic'"
          class="px-2"
        >
          <v-flex xs8>
            <v-text-field
              v-model="apiEndpoint.username"
              label="User Name"
              :rules="usernameRules"
              :counter="60"
              required
            />
            <v-text-field
              v-model="apiEndpoint.password"
              label="Password"
              :rules="passwordRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>

        <v-layout
          v-if="apiEndpoint.authType === 'key'"
          class="px-2"
        >
          <v-flex xs10>
            <v-textarea
              v-model="apiEndpoint.apiKey"
              label="API Key"
              :rules="apiKeyRules"
              :counter="8192"
              required
            />
          </v-flex>
        </v-layout>

        <v-layout class="px-2">
          <v-flex xs2>
            <v-text-field
              v-model="apiEndpoint.timeoutPeriod"
              type="number"
              label="Timeout (s)"
              :rules="timePeriodRules"
              :counter="2"
              required
            />
          </v-flex>
        </v-layout>

        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="saveApiEndpoint"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '../../config.js';

export default {
  name: 'ApiEndpoint',
  data() {
    return {
      valid: false,
      apiEndpoint: {},
      loading: false,
      authenticationType: [{ text: 'API Key', value: 'key' }, { text: 'Basic', value: 'basic' }],
      apiTypes: [{ text: 'CallFlow Action', value: 'callflowAction' }],
      endpointId: null,
      nameRules: [
        (v) => !!v || 'Name is required',
      ],
      urlRules: [
        (v) => !!v || 'Url is required',
        (v) => (v && v.length <= 1024) || 'Url must not have more than 1024 characters',
      ],
      authTypeRules: [
        (v) => !!v || 'Authentication Type is required',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length <= 60) || 'Password must not have more than 60 characters',
      ],
      usernameRules: [
        (v) => !!v || 'Username is required',
        (v) => (v && v.length <= 60) || 'Username must not have more than 60 characters',
      ],
      apiKeyRules: [
        (v) => !!v || 'API key is required',
        (v) => (v && v.length <= 8192) || 'API key must not have more than 512 characters',
      ],
      timePeriodRules: [
        (v) => !!v || 'Timeout is required',
        (v) => (v && v < 31) || 'Timeout must be less or equal to 30',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  mounted() {
    this.endpointId = this.$route.params.endpointId;
    this.loadEndpoint();
  },
  methods: {
    loadEndpoint() {
      this.loading = true;
      axios.get(`${config.bizvu_3rd_party_endpoint_url}/account/${this.selectedAccountId}/endpoint/${this.endpointId}`)
        .then((response) => {
          this.apiEndpoint = response.data;
        })
        .catch((error) => {
          console.error('Something bad happened retrieving endpoint', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveApiEndpoint() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }

      this.apiEndpoint.timeoutPeriod = Number(this.apiEndpoint.timeoutPeriod);

      axios.patch(`${config.bizvu_3rd_party_endpoint_url}/account/${this.selectedAccountId}/endpoint/${this.endpointId}`, this.apiEndpoint)
        .then(() => {
          this.$emit('snack', 'Api Endpoint Updated');
          this.$router.back();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
  },
};
</script>
