<template>
  <v-card>
    <v-card-title>
      <span class="headline">New Revision</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          class="px-2"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="newRevision.lines"
                label="Line Count"
                :rules="rules.lines"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newRevision.cost"
                label="Cost (pence)"
                :rules="rules.cost"
              />
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="newRevision.dateFromMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="newRevision.effectiveFromDate"
                    label="Effective From"
                    prepend-icon="event"
                    :rules="rules.startDate"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="newRevision.effectiveFromDate"
                  no-title
                  :min="newRevision.minEffectiveFromDate"
                  @input="newRevision.dateFromMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col
              v-if="parentRate.accountId && hasDefaultForComplexity"
              cols="6"
            >
              <v-menu
                v-model="newRevision.dateUntilMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="newRevision.effectiveUntilDate"
                    label="Effective Until (optional)"
                    prepend-icon="event"
                    :disabled="!newRevision.effectiveFromDate"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="newRevision.effectiveUntilDate"
                  no-title
                  :disabled="!newRevision.effectiveFromDate"
                  :min="newRevision.minEffectiveUntilDate"
                  @input="newRevision.dateUntilMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="createRevision"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'NewRevision',
  props: {
    rules: Object,
    rates: Array,
    parentRate: Object,
  },
  data() {
    return {
      newRevision: {},
      valid: false,
      today: moment().startOf('day').format(),
      hasDefaultForComplexity: false,
    };
  },
  watch: {
    'newRevision.effectiveFromDate': {
      deep: true,
      handler() {
        this.newRevision.minEffectiveUntilDate = moment(this.newRevision.effectiveFromDate).endOf(1, 'day').format();
      },
    },
  },
  mounted() {
    const { parentRate } = this;
    this.newRevision = {
      rate: parentRate,
      minEffectiveFromDate:
      // eslint-disable-next-line no-nested-ternary
        parentRate.revisions.length
          ? parentRate.revisions[parentRate.revisions.length - 1].effectiveUntilDate
            ? moment(parentRate.revisions[parentRate.revisions.length - 1].effectiveUntilDate).format()
            : this.today
          : this.today,
    };

    this.hasDefaultForComplexity = !!this.rates.find((r) => !r.accountId && r.complexity === parentRate.complexity);
  },
  methods: {
    async createRevision() {
      const rate = this.parentRate;
      this.newRevision.effectiveFromDate = moment(moment.max([this.newRevision.effectiveFromDate, moment()])).format();
      const revisionsLength = rate.revisions.length;
      if (revisionsLength && !rate.revisions[revisionsLength - 1].effectiveUntilDate) {
        rate.revisions[revisionsLength - 1].effectiveUntilDate = moment(this.newRevision.effectiveFromDate).valueOf() - 1;
      }
      rate.revisions.push({
        id: uuidv4(),
        lines: this.newRevision.lines,
        cost: this.newRevision.cost,
        effectiveFromDate: moment(moment.max([moment(this.newRevision.effectiveFromDate).startOf('day'), moment()])).valueOf(),
        effectiveUntilDate: this.newRevision.effectiveUntilDate ? moment(this.newRevision.effectiveUntilDate).endOf('day').valueOf() : null,
      });

      this.$emit('createRevision', { ...rate });
      this.newRevision = {};
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.newRevision = {};
    },
  },
};
</script>
