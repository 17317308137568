<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="computedDateFormatted"
        :label="label"
        :hint="dateFormat"
        :prepend-icon="!noIcon ? 'date_range' : ''"
        clearable
        readonly
        v-on="on"
        @focus="on"
        @click:clear="clean"
      />
    </template>
    <v-date-picker
      v-model="dates"
      no-title
      range
    />
  </v-menu>
</template>

<script>
import moment from 'moment';

const DATE_PICKER_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'DateRange',
  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: 'Filter by Date Range',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: String,
      default: 'DD-MM-YYYY',
    },
  },
  data() {
    return {
      dates: [],
      menuOpen: false,
    };
  },
  computed: {
    computedDateFormatted() {
      if (this.dates.length < 1) return '';
      const start = this.dates[0] ? moment(this.dates[0], DATE_PICKER_FORMAT).format(this.dateFormat) : '';
      const end = this.dates[1] ? moment(this.dates[1], DATE_PICKER_FORMAT).format(this.dateFormat) : '';
      return `${start} - ${end}`;
    },
  },
  watch: {
    dates: {
      handler(newVal) {
        if (this.menuOpen && newVal[1]) {
          this.menuOpen = false;
          this.filterByRangeDate();
        }
      },
      deep: true,
    },
    value() {
      this.handleValueChange();
    },
  },
  mounted() {
    this.handleValueChange();
  },
  methods: {
    handleValueChange() {
      if (this.value && this.value.start) {
        this.dates = [
          moment(this.value.start).isValid() ? moment(this.value.start).format(DATE_PICKER_FORMAT) : moment(this.value.start, 'x').format(DATE_PICKER_FORMAT),
          moment(this.value.start).isValid() ? moment(this.value.end).format(DATE_PICKER_FORMAT) : moment(this.value.end, 'x').format(DATE_PICKER_FORMAT),
        ];
      }
    },
    clean() {
      this.dates = [];
      const date = { start: null, end: null };
      this.$emit('input', date);
    },
    filterByRangeDate() {
      const date = {
        start: moment(this.dates[0], DATE_PICKER_FORMAT).startOf('day').valueOf(),
        end: moment(this.dates[1], DATE_PICKER_FORMAT).endOf('day').valueOf(),
      };
      this.$emit('input', date);
    },
  },
};
</script>
