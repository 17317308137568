<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        International Dial Plan Management
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/internationals', 'POST')"
        text
        color="blue"
        @click="$router.push('/internationalcode/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/internationals/load', 'POST')"
        text
        color="blue"
        @click="importDialog = true"
      >
        <v-icon class="mr-2">
          cloud_upload
        </v-icon>Import
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadData"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>

      <download
        color="primary"
        :headers="exportHeaders"
        :download-u-r-l="baseURL"
        report-name="International Dial Plans"
      >
        Download
      </download>
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-row
        dense
        class="mx-2"
      >
        <v-col cols="4">
          <v-autocomplete
            v-model="countryFilter"
            flat
            :items="countries"
            item-text="CountryName"
            item-value="CountryId"
            label="Country Filter"
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="codeFilter"
            label="Code Filter"
            clearable
            hide-details
          />
        </v-col>
      </v-row>

      <v-row class="mx-1">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="codes"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            :custom-sort="customSort"
            height="calc(100vh - 290px)"
          >
            <template #item.country.CountryName="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/internationals/<internationalid>', 'GET')"
                :to="item.itemRoute"
              >
                {{ item.country.CountryName }}
              </router-link>
              <span v-else>{{ item.country.CountryName }}</span>
            </template>
            <template #item.ModifiedOn="{ item }">
              <span>{{ item.ModifiedOn | formatDate }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <code-importer
        :show="importDialog"
        headings="CountryName &amp; Code"
        example="/examplecode.csv"
        :url="importURL"
        @close="importDialog = false"
        @snack="handleSnack"
      />
    </v-container>
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { debounce, sortBy } from 'lodash';
import config from '@/config.js';

import download from '@/components/download/tableDownload';
import CodeImporter from '@/components/billing/CodeImporter.vue';
import customSort from '@/utils/customSort';
import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'InternationalCodesManagement',
  components: {
    download,
    CodeImporter,
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  data() {
    const headers = [
      { text: 'Country Name', value: 'country.CountryName' },
      { text: 'Code', value: 'Code' },
      { text: 'Modified By', value: 'ModifiedBy' },
      { text: 'Modified On', value: 'ModifiedOn' },
    ];

    const baseURL = `${config.BTL_API_TENANT_URL}/internationals`;

    return {
      codes: [],
      headers,
      exportHeaders: headers.map((header) => ({
        ...header,
        text: header.text.replace(/ /g, ''),
      })),
      countries: [],
      countryFilter: null,
      codeFilter: null,
      loading: false,
      importDialog: false,
      baseURL,
      importURL: `${baseURL}/load`,
      cancelToken: null,
    };
  },
  watch: {
    countryFilter() {
      this.loadData();
      this.$router.push({ name: 'internationalcodesbycountry', params: { countryID: this.countryFilter } });
    },
    codeFilter: debounce(function () {
      this.loadData();
    }, 500),
  },
  async mounted() {
    await this.loadCountries();
    const { countryID } = this.$route.params;
    if (countryID) {
      this.countryFilter = countryID;
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        if (this.countryFilter || this.codeFilter) {
          await this.loadCodes();
        } else {
          this.$emit('snack', 'Supply either a country or code filter to execute the search.');
        }
      } catch (error) {
        console.error('Could not load data: ', error);
        this.$emit('snack', 'Failed to retrieve data.', true);
      } finally {
        this.loading = false;
        this.cancelToken = null;
      }
    },
    async loadCodes() {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }

      let url = this.baseURL;
      const paramsObj = {
        filters: [],
      };

      if (this.codeFilter) {
        paramsObj.filters.push({
          name: 'Code',
          op: 'like',
          val: `${this.codeFilter}%`,
        });
      }

      if (this.countryFilter) {
        paramsObj.filters.push({
          name: 'CountryId',
          op: 'eq',
          val: this.countryFilter,
        });
      }

      if (paramsObj.filters.length) {
        url += `?q=${JSON.stringify(paramsObj)}`;
      }

      const { CancelToken } = axios;
      this.cancelToken = CancelToken.source();

      const response = await axios.get(url, {
        cancelToken: this.cancelToken.token,
      });

      this.codes = response.data.map((row) => ({
        ...row,
        itemRoute: `/internationalcode/${row.Id}`,
      }));
    },
    async loadCountries() {
      try {
        const paramsObj = {
          filters: [{
            name: 'AccountId',
            op: 'is_null',
          }],
        };
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/countries?q=${JSON.stringify(paramsObj)}`);
        this.countries = sortBy(response.data, [(country) => country.CountryName.toLowerCase()]);
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve countries.', true);
        console.error('Failed to retrieve countries', error);
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    handleSnack(message, isError = false) {
      this.$emit('snack', message, isError);
    },
    customSort: customSort('ModifiedOn'),
  },
};
</script>
