<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Edit number
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs4>
            <v-text-field
              v-model="number.PhoneNumber"
              readonly
              label="Number"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs4>
            <v-select
              v-model="number.CallflowId"
              flat
              :items="callflows"
              item-text="Name"
              item-value="CallflowId"
              label="CallFlow"
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs3>
            <v-switch
              v-model="number.Enabled"
              :readonly="number.AccountId==='00000000-0000-0000-0000-000000000000'"
              label="Enabled"
            />
          </v-flex>
          <v-flex xs8>
            <p
              v-if="number.AccountId==='00000000-0000-0000-0000-000000000000'"
              class="subtitle-1"
              style="margin-top: 20px;"
            >
              Number is not allocated to an account.
            </p>
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs6>
            <v-text-field
              v-model="number.Label"
              label="Name"
              :counter="60"
              :rules="nameRules"
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs6>
            <v-text-field
              v-model="number.Notes"
              label="Notes"
              :counter="80"
              :rules="notesRules"
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs3>
            <v-select
              v-model="number.PhoneType"
              flat
              :items="numberTypes"
              item-text="text"
              item-value="value"
              label="Number type"
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs6>
            <v-text-field
              v-model="number.Maintainer"
              label="Maintainer"
              :rules="maintainerRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs5>
            <v-select
              v-model="maskingType"
              flat
              :items="maskingTypes"
              label="Masking CLI"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="maskingType === 'e164'"
          class="px-2"
        >
          <v-flex xs5>
            <v-text-field
              v-model="maskingNumber"
              label="Masking Number"
              :rules="numberRules"
              :counter="13"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="updateNumber"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';

import { BTL_API_TENANT_URL } from '@/config.js';

export default {
  name: 'Number',
  components: {
  },
  data() {
    return {
      phoneNumber: this.$route.params.number,
      valid: false,
      callflows: [],
      number: {
      },
      maskingType: '',
      maskingNumber: '',
      numberTypes: [
        { value: 'GEO', text: 'Geographic' },
        { value: 'NONGEO', text: 'Non-Geographic' },
      ],
      maskingTypes: [
        { value: '', text: 'None' },
        { value: 'Anonymous', text: 'Anonymous' },
        { value: 'e164', text: 'E164 Phone Number' },
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      maintainerRules: [
        (v) => !!v || 'Maintainer is required',
        (v) => (v && v.length <= 60) || 'Maintainer must not have more than 60 characters',
      ],
      notesRules: [
        (v) => (!v || v.length <= 80) || 'Notes must not have more than 80 characters',
      ],
      numberRules: [
        (v) => !!v || 'Phone number is required',
        (v) => (v && v.length <= 13) || 'Phone number must not have more than 12 characters',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    maskingType(selectedMaskingType) {
      if (selectedMaskingType !== 'e164') {
        this.number.Masking = selectedMaskingType;
      } else {
        this.number.Masking = this.maskingNumber;
      }
    },
    maskingNumber(maskingNumber) {
      this.number.Masking = maskingNumber;
    },
    selectedAccountId() {
      this.$router.back();
    },
  },
  created() {
    this.loadNumber();
  },
  methods: {
    updateNumber() {
      if (!this.$refs.form.validate()) {
        return;
      }
      delete this.number.AllocatedDate; // [FIXME] - to be done in BFTF
      axios.put(`${BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/phonenumbers/${this.phoneNumber}`, this.number)
        .then(() => {
          this.$emit('snack', 'Number successfully updated!');
          this.$router.back();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    async loadNumber() {
      try {
        const { data: callflows } = await axios.get(`${BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/callflows`);
        this.callflows = sortBy(callflows, [(callflow) => callflow.Name.toLowerCase()]);

        const { data: number } = await axios.get(`${BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/phonenumbers/${this.phoneNumber}`);
        this.number = number;

        if (!this.number.Label) {
          this.number.Label = '';
        }

        switch (this.number.Masking) {
          case '':
            this.maskingType = '';
            break;
          case 'Anonymous':
            this.maskingType = 'Anonymous';
            break;
          default:
            this.maskingNumber = this.number.Masking;
            this.maskingType = 'e164';
        }
      } catch (err) {
        console.error('ERROR', err);
        this.$emit('snack', 'Failed to load number', true);
      }
    },
  },
};
</script>

<style scoped>
</style>
