<template>
  <v-list-item @click.stop="onShow">
    <span v-if="!editMode">
      {{ item.text }}
    </span>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <!-- Subheading -->
        <v-card-title class="subheading-title">
          Additional filters (optional)
        </v-card-title>

        <!-- Search Bar -->
        <v-card-text class="pb-1">
          <v-container fluid class="search">
            <v-row no-gutters>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  label="Search Filters"
                  dense
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Scrollable List of Filters -->
        <v-card-text class="pb-0 scrollable-content">
          <v-container fluid>
            <v-row no-gutters>
              <v-col
                v-for="filter in filteredOptions"
                :key="filter.text"
                cols="12"
              >
                <v-checkbox
                  :value="filter"
                  :label="filter.text"
                  v-model="selected"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Sticky Footer with Buttons -->
        <v-card-actions class="sticky-footer">
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!selected.length"
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
export default {
  name: 'DynamicallyRoutedCallFilter',
  props: {
    item: Object,
    query: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      search: '', // Search term for filtering filters
      options: [
        {
          text: 'Alternative Destination - Answered',
          value: {
            bool: {
              must: [
                {
                  term: {
                    dynamic_routing_alternative_dst_answered: true,
                  },
                },
                {
                  range: {
                    billsec: {
                      gt: 0,
                    },
                  },
                },
              ],
            },
          },
        },
        {
          text: 'Alternative Destination - Not Answered',
          value: {
            bool: {
              must: [
                {
                  term: {
                    dynamic_routing_alternative_dst_answered: true,
                  },
                },
                {
                  term: {
                    billsec: 0,
                  },
                },
              ],
            },
          },
        },
        {
          text: 'Original Destination - Answered',
          value: {
            bool: {
              must: [
                {
                  term: {
                    dynamic_routing_alternative_dst_answered: false,
                  },
                },
                {
                  range: {
                    billsec: {
                      gt: 0,
                    },
                  },
                },
              ],
            },
          },
        },
        {
          text: 'Original Destination - Not Answered',
          value: {
            bool: {
              must: [
                {
                  term: {
                    dynamic_routing_alternative_dst_answered: false,
                  },
                },
                {
                  term: {
                    billsec: 0,
                  },
                },
              ],
            },
          },
        },
        {
          text: 'All DCR Calls - Answered',
          value: {
            range: {
              billsec: {
                gt: 0,
              },
            },
          },
        },
        {
          text: 'All DCR Calls - Not Answered',
          value: {
            term: {
              billsec: 0,
            },
          },
        },
      ],
      selected: [],
    };
  },
  mounted() {
    if (this.editMode) {
      this.onShow();
      this.selected = this.options.filter(({ value: optionValue }) => {
        const key = Object.keys(optionValue)[0];
        if (key === 'bool') {
          return this.query.query.bool.must[1].bool.should.filter((filter) => !!filter.bool).find((filter) => {
            const areTheSameDSTAnswered = filter.bool.must[0].term.dynamic_routing_alternative_dst_answered === optionValue.bool.must[0].term.dynamic_routing_alternative_dst_answered;

            const isFilterBillsec0 = !!filter.bool.must[1].term;
            const isOptionBillsec0 = !!optionValue.bool.must[1].term;

            const areTheSameBillsec = isFilterBillsec0 === isOptionBillsec0;
            return areTheSameDSTAnswered && areTheSameBillsec;
          });
        }
        return this.query.query.bool.must[1].bool.should.find((filter) => Object.keys(filter)[0] === key);
      });
    }
  },
  computed: {
    filteredOptions() {
      const searchTerm = this.search.toLowerCase();
      return this.options.filter((filter) => filter.text.toLowerCase().includes(searchTerm));
    },
  },
  methods: {
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const query = {
        query: {
          bool: {
            must: [
              {
                term: {
                  dynamic_routing: true,
                },
              },
              {
                bool: {
                  should: [],
                },
              },
            ],
          },
        },
      };

      this.selected.forEach((filter) => query.query.bool.must[1].bool.should.push(filter.value));

      const item = { ...this.item };
      item.type = 'DynamicallyRoutedCallFilter';

      this.$emit('submit', {
        item,
        query,
        text: this.selected.map((filter) => filter.text).join(', '),
      });
      this.dialog = false;
      this.selected = [];
    },
  },
};
</script>

<style scoped>
.scrollable-content {
  max-height: 300px;
  overflow-y: auto;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
}

.subheading-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

/* Adjusted padding below search bar */
.search {
  padding-bottom: 1px !important;
}
</style>
