<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Provider Gateway
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/providergateways/<short_code>', 'DELETE', true)"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="payload.ShortCode"
                  label="Short Code"
                  :rules="ShortCodeRules
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="12"
                md="6"
              >
                <v-select
                  v-model="payload.ProviderPrefix"
                  flat
                  :items="providers"
                  item-value="ProviderPrefix"
                  item-text="ProviderPrefix"
                  label="Provider"
                  :rules="ProviderPrefixRules"
                />
              </v-col>
              <v-col
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="payload.Priority"
                  label="Priority"
                  type="number"
                  :rules="PriorityRules
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="payload.Notes"
                  label="Notes"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-6 pt-4">
            <v-row>
              <v-btn
                v-if="isPermitted('/providergateways/<short_code>', 'PUT', true)"
                primary
                color="primary"
                dark
                @click="updateProvider"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="550+"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this provider gateway?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="error"
            @click="deleteProviderGateway"
          >
            <v-icon left>
              delete
            </v-icon>
            Confirm
          </v-btn>
          <v-btn
            color="secondary"
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'ProviderGateway',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      payload: {
        ShortCode: null,
        ProviderPrefix: null,
        Notes: null,
        Priority: null,
      },
      providers: [],
      valid: false,
      shortCode: this.$route.params.shortCode,
      accounts: [],
      ProviderPrefixRules: [
        (v) => !!v || 'Provider prefix is required',
      ],
      ShortCodeRules: [
        (v) => !!v || 'Short code is required',
      ],
      NotesRules: [
        (v) => !!v || 'Notes is required',
      ],
      PriorityRules: [
        (v) => !!v || 'Priority is required',
      ],
    };
  },
  created() {
    this.loadPoviders();
    if (this.shortCode === 'new') {
      this.createMode = true;
    } else {
      this.loadProviderGateway();
    }
  },
  methods: {
    async updateProvider() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Provider gateway successfully updated.';

        if (this.createMode) {
          await axios.post(`${config.BTL_API_TENANT_URL}/providergateways`, this.payload);
          snackMessage = 'Provider successfully created.';
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/providergateways/${this.shortCode}`, this.payload);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating provider gateway', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} provider gateway.`, true);
      }
    },
    async loadPoviders() {
      const response = await axios.get(
        `${config.BTL_API_TENANT_URL}/providers`,
      );
      this.providers = response.data;
    },
    async loadProviderGateway() {
      try {
        const response = await axios.get(`${config.BTL_API_TENANT_URL}/providergateways/${this.shortCode}`);
        const { data } = response;
        this.payload.ShortCode = data.ShortCode;
        this.payload.ProviderPrefix = data.ProviderPrefix;
        this.payload.Notes = data.Notes;
        this.payload.Priority = data.Priority;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve provider gateway.', true);
        console.error('Failed to retrieve provider gateway', error);
      }
    },
    async deleteProviderGateway() {
      try {
        await axios.delete(`${config.BTL_API_TENANT_URL}/providergateways/${this.shortCode}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete provider gateway.', true);
        console.error('Failed to delete provider gateway', error);
      }
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
