<template>
  <div class="flex-wrapper">
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        CallFlow Details
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/callflows/<callflowid>', 'DELETE')"
        text
        color="blue"
        @click="delDialog=true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/callflows', 'POST')"
        text
        color="blue"
        @click="triggerClone"
      >
        <v-icon class="mr-2">
          content_copy
        </v-icon>Clone Call Flow
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="savePDF"
      >
        Export as PDF
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container
      v-if="callflow"
      fluid
    >
      <v-layout class="px-2">
        <v-flex xs5>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="callflow.Name"
              :disabled="!isPermitted('/tenants/<tenant_name>/accounts/<account_id>/callflows/<callflowid>', 'PUT')"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
            <v-switch
              v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/callflows/<callflowid>', 'PUT')"
              v-model="callflow.Enabled"
              label="Enabled"
            />
          </v-form>
        </v-flex>
        <v-flex
          offset-xs1
          xs5
        >
          <v-card v-if="version">
            <v-card-title>
              <span class="headline">Description: {{ version.title }}</span>
            </v-card-title>
            <v-card-text>
              <p class="ml-2">
                Created: {{ formatDate(version.created) }}
              </p>
              <p class="ml-2">
                {{ version.description }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="versionDialog=true"
              >
                Browse Versions
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog
        v-model="delDialog"
        persistent
        max-width="300"
      >
        <v-card>
          <v-card-title class="headline">
            Delete CallFlow
          </v-card-title>
          <v-container>
            <v-layout class="px-2">
              <v-flex xs12>
                <p class="subtitle-1">
                  Do you really want to delete this CallFlow?
                </p>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              primary
              color="primary"
              dark
              @click="deleteCallflow"
            >
              Delete
            </v-btn>
            <v-btn
              text
              color="grey"
              @click="delDialog=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="versionDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">CallFlow Versions</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <table style="width: 100%">
                  <tr>
                    <th
                      style="width: 39%"
                      align="left"
                    >
                      Description
                    </th>
                    <th
                      style="width: 31%"
                      align="left"
                    >
                      Creation date
                    </th>
                    <th style="width: 30%" />
                  </tr>
                </table>
                <div style="width: 100%; height: 200px; overflow-y: scroll;">
                  <table style="width: 100%; ">
                    <tr
                      v-for="(v,i) in versions"
                      :key="i"
                    >
                      <td
                        style="width: 40%"
                        align="left"
                      >
                        {{ v.title }}
                      </td>
                      <td
                        style="width: 30%"
                        align="left"
                      >
                        {{ formatDate(v.created) }}
                      </td>
                      <td style="width: 30%">
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="loadVersion(i)"
                        >
                          Load
                        </v-btn>
                      </td>
                    </tr>
                  </table>
                </div>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="versionDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-layout class="px-2 mt-3">
        <p class="subtitle-1 mt-2 mr-5">
          Dial plan
        </p>
        <v-layout class="mt-2">
          <v-dialog
            v-model="saveDialog"
            persistent
            max-width="600px"
          >
            <template
              v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/callflows/<callflowid>', 'PUT')"
              #activator="{on}"
            >
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                Save & Deploy
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Save callflow</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="newCallflowVersion.title"
                        label="Description"
                        required
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        v-model="newCallflowVersion.description"
                        label="Notes"
                      />
                    </v-flex>
                  <!-- <v-flex xs12>
                    <v-switch label="Deploy new version to NetX platform" v-model="newCallflowVersion.deployed"></v-switch>
                  </v-flex> -->
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveDialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveCallflow"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-layout>
      <v-layout class="growme">
        <CallFlowEditor
          v-if="callflow.Dialplan"
          id="callFlowComponent"
          :callflow="callflow.Dialplan"
          :version="versionId"
        />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import * as html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import moment from 'moment';
import { mapGetters } from 'vuex';

import config from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import CallFlowEditor from '@/components/callfloweditor2/CallFlowEditor';

export default {
  name: 'Browsecallflow',
  components: {
    CallFlowEditor,
  },
  data() {
    return {
      callflowId: this.$route.params.callflowid,
      saveDialog: false,
      delDialog: false,
      versionDialog: false,
      valid: false,
      callflow: null,
      version: {},
      versionId: null,
      newCallflowVersion: {
        title: '',
        description: '',
        callflow: {},
        deployed: false,
      },
      versions: [],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'timeZone',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  mounted() {
    this.loadCallflow();
  },
  methods: {
    deleteCallflow() {
      this.delDialog = false;
      axios.delete(`${config.bizvu_callflow_url}/account/${this.selectedAccountId}/callflow/${this.callflowId}`)
        .then(() => {
          this.$emit('snack', 'CallFlow successfully deleted!');
          this.$router.back();
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    triggerClone() {
      this.$router.push(`/clonecallflow/${this.callflowId}`);
    },
    loadVersion(i) {
      axios.get(`${config.bizvu_callflow_url}/account/${this.selectedAccountId}/callflow/${this.callflowId}/version/${this.versions[i].id}`)
        .then((response) => {
          this.callflow = response.data.callflow;
          this.version = this.versions[i];
          this.versionId = this.versions[i].id;
          this.versionDialog = false;
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    saveCallflow() {
      this.newCallflowVersion.callflow = this.callflow;
      axios.post(`${config.bizvu_callflow_url}/account/${this.selectedAccountId}/callflow/${this.callflowId}/version`, this.newCallflowVersion)
        .then(() => {
          this.$emit('snack', 'New version of CallFlow successfully created!');
          this.$router.push('/callflows');
        })
        .catch((error) => {
          console.error('Failed to save callflow', error);
          if (error.response.data.ValidationReport) {
            this.processValidationErrors(error.response.data.ValidationReport);
          } else {
            this.$emit('snack', 'Failed to save CallFlow', true);
          }
        });
    },
    savePDF() {
      const doc = new JsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [1200, 800],
      });
      const el = document.getElementById('callFlowComponent');
      const _self = this;
      html2canvas(el, {
        scale: 1.0,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const filename = `${_self.callflow.Name}-${new Date().toJSON().split('T')[0]}.pdf`;
        doc.addImage(imgData, 'PNG', 15, 20);
        doc.text(filename.split('.')[0], 15, 10);
        doc.autoPrint(); // this works when pdf is opened in viewer
        doc.save(filename);
      });
    },
    loadCallflow() {
      const self = this;
      axios.get(`${config.BTL_API_ACCOUNT_URL}/${this.selectedAccountId}/callflows/${this.callflowId}`)
        .then((response) => {
          self.callflow = response.data;
          axios.get(`${config.bizvu_callflow_url}/account/${this.selectedAccountId}/callflow/${this.callflowId}/version`)
            .then(({ data }) => {
              self.versions = data;
              self.version = self.versions[0];
            })
            .catch((error) => {
              console.error('ERROR', error.response);
            });
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    processValidationErrors(ValidationReport) {
      const errors = [
        ...ValidationReport.Error,
        ...ValidationReport.Critical,
        ...ValidationReport.Warning,
      ].map((e) => ({
        text: e.text,
        isError: true,
      }));

      this.$emit('snack', errors, true);
    },
    formatDate(d) {
      return moment(d).tz(this.timeZone).format(config.date_time_format);
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};

</script>

<style scoped>
  .flex-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .flex-wrapper > header {
    flex-grow: 0;
  }

  .flex-wrapper > .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .flex-wrapper > .container > .layout {
    flex-grow: 0;
  }

  .flex-wrapper > .container > .growme {
    flex-grow: 1;
  }
</style>
