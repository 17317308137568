<template>
  <v-card>
    <v-card-title>
      <span class="headline">Edit Revision</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          class="px-2"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedRevision.lines"
                label="Line Count"
                :rules="rules.lines"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedRevision.cost"
                label="Cost (pence)"
                :rules="rules.cost"
              />
            </v-col>
            <v-col v-if="editedRevision && editedRevision.allowEffectiveUntil">
              <v-row>
                <v-col cols="7">
                  <v-menu
                    v-model="editedRevision.dateUntilMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="editedRevision.effectiveUntilDate"
                        label="Effective Until (optional)"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="editedRevision.effectiveUntilDate"
                      no-title
                      :min="today"
                      @input="editedRevision.dateUntilMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  v-if="editedRevision.effectiveUntilDate"
                  class="pt-6"
                >
                  <v-tooltip bottom>
                    <template #activator="{on}">
                      <v-btn
                        icon
                        v-on="on"
                        @click="editedRevision.effectiveUntilDate = null"
                      >
                        <v-icon>
                          close
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Make the revision active indefinitely</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="updateRevisions"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'EditRevision',
  props: {
    rules: Object,
    rates: Array,
    revision: Object,
    parentRate: Object,
  },
  data() {
    return {
      editedRevision: {},
      valid: false,
      today: moment().startOf('day').format(),
      hasDefaultForComplexity: false,
    };
  },
  mounted() {
    const originalIndex = this.parentRate.revisions.findIndex((r) => r.id === this.revision.id);
    this.hasDefaultForComplexity = !!this.rates.find((r) => !r.accountId && r.complexity === this.parentRate.complexity);

    const allowEffectiveUntil = originalIndex === this.parentRate.revisions.length - 1 && (this.parentRate.accountId && this.hasDefaultForComplexity);
    this.editedRevisionDialog = true;
    this.editedRevision = {
      ...this.revision,
      allowEffectiveUntil,
    };

    if (allowEffectiveUntil && this.editedRevision.effectiveUntilDate) {
      this.editedRevision.effectiveUntilDate = moment(this.editedRevision.effectiveUntilDate).format('YYYY-MM-DD');
    }
  },
  methods: {
    async updateRevisions() {
      const { parentRate } = this;
      const er = this.editedRevision;
      const originalRevision = parentRate.revisions.find((r) => r.id === er.id);

      if (er.effectiveUntilDate) {
        er.effectiveUntilDate = moment(er.effectiveUntilDate).endOf('day').valueOf();
      }

      delete er.allowEffectiveUntil;
      delete er.dateUntilMenu;

      if (er.isActiveRevision) {
        const originalRevisionIndex = parentRate.revisions.findIndex((r) => r.id === er.id);
        const now = moment().valueOf();
        parentRate.revisions.splice(originalRevisionIndex + 1, 0, {
          id: uuidv4(),
          lines: er.lines,
          cost: er.cost,
          effectiveFromDate: now,
          effectiveUntilDate: originalRevision.effectiveUntilDate,
        });
        originalRevision.effectiveUntilDate = now;
      } else if (er.isFutureRevision) {
        originalRevision.lines = er.lines;
        originalRevision.cost = er.cost;
        originalRevision.effectiveUntilDate = er.effectiveUntilDate;
      } else {
        this.$emit('snack', 'Cannot edit past revisions', true);
        return;
      }

      this.$emit('updateRevisions', { ...parentRate });
      this.editedRevision = {};
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
};
</script>
