<template>
  <v-snackbar
    v-model="showBar"
    :timeout="-1"
    top
  >
    <span>A new update has been released version: {{ latestVersion.version }}.
      Click <a @click="viewChangelog">Here</a> for details.</span>
    <v-btn
      color="primary"
      text
      @click="acknowledgeChange"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import changelog from '../../changelog.json';

export default {
  name: 'ChangelogBanner',
  data() {
    return {
      showBar: false,
      acknowledgedVersion: null,
      latestVersion: changelog.releases[0], // presuming releases[0] is latest!!!
    };
  },
  mounted() {
    this.acknowledgedVersion = localStorage.getItem('changelog_ack_version');
    if (this.acknowledgedVersion !== this.latestVersion.version) {
      this.showBar = true;
    }
  },
  methods: {
    acknowledgeChange() {
      this.showBar = false;
      this.acknowledgedVersion = this.latestVersion.version;
      localStorage.setItem('changelog_ack_version', this.acknowledgedVersion);
    },
    viewChangelog() {
      this.acknowledgeChange();
      this.$router.push('/changelog');
    },
  },
};
</script>

<style scoped>
</style>
