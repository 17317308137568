<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Edit Settings
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout>
          <v-flex xs6 />
        </v-layout>

        <v-expansion-panels
          focusable
          class="my-3 mx-2"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  calendar_today
                </v-icon>Working hours
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs12>
                    <OpeningHoursEditor
                      v-if="settings.Hours && Array.isArray(settings.Hours.WeekDay1)"
                      v-model="settings.Hours"
                      :hours="settings.Hours"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  calendar_today
                </v-icon>Working hours (Bank Holiday)
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <OpeningHourBankHoliday
                  :key="bankHolidaIndex"
                  v-model="settings.bankHoliday"
                  @update="onUpdateBankHolidays"
                />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="updateSettings"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import OpeningHourBankHoliday from '@/components/openinghourseditor/OpeningHourBankHolidayEditor';
import OpeningHoursEditor from '@/components/openinghourseditor/OpeningHoursEditor';

export default {
  name: 'WorkingHours',
  components: {
    OpeningHoursEditor,
    OpeningHourBankHoliday,
  },
  data() {
    return {
      bankHolidaIndex: 0,
      isGod: false,
      settingsId: this.$route.params.id,
      valid: false,
      settings: {},
      menu: false,
      geo: null,
      SettingsTypes: [
        { value: 'OFFICE', text: 'Office' },
        { value: 'HOME', text: 'Home' },
      ],
      timeZones: [
        'Europe/London',
        'US/Pacific',
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.loadSettings();
    },
  },
  created() {
    this.loadSettings();
  },
  methods: {
    onUpdateBankHolidays(data) {
      this.settings.bankHoliday = data;
    },
    updateSettings() {
      const self = this;
      axios.put(`${config.bizvu_account_url}/${this.selectedAccountId}/workinghours`, this.settings.Hours)
        .then(() => {
          self.updateBankHolidays();
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
    updateBankHolidays() {
      const self = this;
      axios.put(`${config.bizvu_account_url}/${self.selectedAccountId}/bankholidays`, self.settings.bankHoliday)
        .then(() => {
          self.$emit('snack', 'Settings successfully updated!');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    loadBankHolidays() {
      const self = this;
      axios.get(`${config.bizvu_account_url}/${this.selectedAccountId}/bankholidays`)
        .then((response) => {
          self.settings.bankHoliday = response.data.Hours;
          self.bankHolidaIndex += 1;
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
    loadSettings() {
      this.loading = true;
      const self = this;
      axios.get(`${config.bizvu_account_url}/${this.selectedAccountId}/workinghours`)
        .then((response) => {
          self.settings = response.data;
          self.loadBankHolidays();
        })
        .catch((error) => {
          console.error('ERROR', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.custom-list .v-list__tile{
  min-height: 48px!important ;
  height: auto !important;
  padding: 20px 16px !important;
}
</style>
