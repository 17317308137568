<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Appliances
      </v-toolbar-title>
      <v-btn
        v-if="permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/appliances', 'POST')"
        text
        color="blue"
        @click="$router.push('/newappliance')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create Appliance
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadAppliances"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <tableDownload
        color="primary"
        :table-data="appliances"
        report-name="Appliances"
      />
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-data-table
        :headers="headers"
        :options="tableOptions"
        :items="appliances"
        :loading="loading"
        :search="$store.getters.searchQuery"
        fixed-header
        height="calc(100vh - 220px)"
        @update:options="handleTableOptionsUpdate"
      >
        <template #item="props">
          <tr>
            <td>
              <router-link :to="props.item.detailsRoute">
                {{ props.item.Name }}
              </router-link>
            </td>
            <td>{{ props.item.numVPN }}</td>
            <td>{{ props.item.vpnVersion }}</td>
            <td>{{ props.item.numSBC }}</td>
            <td>{{ props.item.sbcVersion }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import {
  reactive, computed, watch, toRefs, onMounted,
} from 'vue';

import config from '@/config.js';
import { useStore } from '@/utils/vue';
import permittedFunction from '@/permittedFunction.js';
import usePagination from '../../composables/tablePaginationViaQueryString';
import tableDownload from '../../components/download/tableDownload';

export default {
  name: 'Appliances',
  components: {
    tableDownload,
  },
  setup(props, context) {
    const store = useStore();

    // composables
    // for some reason pagination on appliances and sites doesn't seem to be working, looks like a vuetify issue but we're keeping the code in hope they fix it in future releases
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 15, sortBy: 'Name' });

    const state = reactive({
      selectedAccountId: computed(() => store.getters.selectedAccountId),

      headers: [
        { text: 'Name', value: 'Name' },
        { text: 'Number of VPNs', value: 'numVPN' },
        { text: 'VPN version', value: 'vpnVersion' },
        { text: 'Number of SBCs', value: 'numSBC' },
        { text: 'SBC version', value: 'sbcVersion' },
      ],
      appliances: [],
      loading: false,
    });

    const loadAppliances = async () => {
      state.loading = true;
      try {
        const { data } = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/appliances`);

        state.appliances = data;
        state.appliances.forEach((row) => {
          row.detailsRoute = `/appliance/${row.ApplianceId}`;
          // Count devices
          row.numVPN = 0;
          row.numSBC = 0;
          row.Devices.forEach((d) => {
            if (d.DeviceType === 'VPN') row.numVPN += 1;
            if (!row.vpnVersion && d.DeviceType === 'VPN') row.vpnVersion = d.Version;
            if (d.DeviceType === 'SBC') row.numSBC += 1;
            if (!row.sbcVersion && d.DeviceType === 'SBC') row.sbcVersion = d.Version;
          });
        });
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        state.loading = false;
      }
    };

    watch(() => state.selectedAccountId, loadAppliances);

    saveQueryParams();
    onMounted(async () => {
      await loadAppliances();
      processQueryParams();
    });

    return {
      ...toRefs(state),
      loadAppliances,
      tableOptions,
      handleTableOptionsUpdate,
      permittedFunction,
    };
  },
};
</script>

<style scoped>
</style>
