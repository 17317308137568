<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ editMode ? '' : 'New' }} Role
      </v-toolbar-title>
      <v-btn
        v-if="canDeleteRole"
        :loading="deleting"
        text
        color="blue"
        @click="deleteRole"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete Role
      </v-btn>
    </v-toolbar>

    <v-container
      ref="page"
      v-resize="onResize"
    >
      <v-form>
        <v-row>
          <v-col>
            <v-text-field
              v-model="role.name"
              label="Name"
              :readonly="!canSaveRole"
            />
            <v-text-field
              v-model="role.description"
              label="Description"
              :readonly="!canSaveRole"
            />
          </v-col>
        </v-row>

        <v-row
          ref="table"
          style="height: calc(100vh - 350px);"
        >
          <v-col>
            <v-card>
              <v-card-subtitle>Granted Functions</v-card-subtitle>
              <v-card-text>
                <v-data-table
                  :headers="functionHeaders"
                  :items="role.functions"
                  :search="$store.getters.searchQuery"
                  dense
                  sort-by="name"
                  disable-pagination
                  hide-default-footer
                  fixed-header
                  :height="tableHeight"
                >
                  <template #item.action="{ item: func }">
                    <v-tooltip bottom>
                      <template #activator="{on}">
                        <v-btn
                          icon
                          @click="removeFunction(func)"
                          v-on="on"
                        >
                          <v-icon>clear</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove Function</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="canSaveRole">
            <v-card>
              <v-card-subtitle>Available Functions</v-card-subtitle>
              <v-card-text>
                <v-data-table
                  :headers="functionHeaders"
                  :items="availableFunctions"
                  :loading="loadingFunctions"
                  :search="$store.getters.searchQuery"
                  dense
                  sort-by="name"
                  disable-pagination
                  hide-default-footer
                  fixed-header
                  :height="tableHeight"
                >
                  <template #item.action="{ item: func }">
                    <v-tooltip bottom>
                      <template #activator="{on}">
                        <v-btn
                          icon
                          @click="addFunction(func)"
                          v-on="on"
                        >
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Function</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div v-if="canSaveRole">
              <v-btn
                v-if="canSaveRole"
                :loading="saving"
                class="mr-2"
                color="primary"
                @click="save"
              >
                Save
              </v-btn>
              <v-btn
                text
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { remove, differenceWith, isEqual } from 'lodash';

import { BTL_API_TENANT_URL, BTL_API_URL } from '../../../config';
import permittedFunction from '../../../permittedFunction';

export default {
  name: 'Role',
  data() {
    return {
      editMode: !!this.$route.params.id,
      roleId: this.$route.params.id,

      functions: [],
      loadingFunctions: false,

      role: {
        functions: [],
      },
      roleLoading: false,
      saving: false,
      deleting: false,
      tableHeight: 0,
    };
  },
  computed: {
    canDeleteRole() {
      return this.isPermitted('/tenants/<tenant_name>/roles/<role_id>', 'DELETE') && this.editMode;
    },
    canSaveRole() {
      if (this.editMode) {
        return this.isPermitted('/tenants/<tenant_name>/roles/<role_id>', 'PUT');
      }
      return this.isPermitted('/tenants/<tenant_name>/roles', 'POST');
    },
    availableFunctions() {
      return differenceWith(this.functions, this.role.functions, isEqual);
    },
    functionHeaders() {
      const functionHeaders = [
        { text: 'Function', value: 'name' },
      ];

      if (this.canSaveRole) {
        functionHeaders.push({ text: '', value: 'action', align: 'right' });
      }

      return functionHeaders;
    },
  },
  mounted() {
    this.loadFunctions();
    if (this.editMode) {
      this.loadRole();
    }
  },
  methods: {
    async loadRole() {
      this.roleLoading = true;
      try {
        const { data: role } = await axios.get(`${BTL_API_TENANT_URL}/roles/${this.roleId}`);
        this.role = role;
      } catch (err) {
        this.$emit('snack', 'Failed to load role', true);
      }
      this.roleLoading = false;
    },
    async loadFunctions() {
      this.loadingFunctions = true;
      try {
        const { data: functions } = await axios.get(`${BTL_API_URL}/functions`);
        this.functions = functions;
      } catch (err) {
        this.$emit('snack', 'Failed to load functions', true);
      }
      this.loadingFunctions = false;
    },
    async save() {
      this.saving = true;
      const roleToSave = {
        name: this.role.name,
        description: this.role.description,
        functions: this.role.functions.map((func) => func.function_id),
      };

      if (this.editMode) {
        try {
          await axios.put(`${BTL_API_TENANT_URL}/roles/${this.roleId}`, roleToSave);
          this.$emit('snack', 'Role updated');
          this.$router.back();
        } catch (err) {
          this.$emit('snack', 'Failed to update role', true);
        }
      } else {
        try {
          await axios.post(`${BTL_API_TENANT_URL}/roles`, roleToSave);
          this.$emit('snack', 'Role created');
          this.$router.back();
        } catch (err) {
          this.$emit('snack', 'Failed to save role', true);
        }
      }
      this.saving = false;
    },
    async deleteRole() {
      this.deleting = true;
      try {
        await axios.delete(`${BTL_API_TENANT_URL}/roles/${this.roleId}`);
        this.$emit('snack', 'Role Deleted');
        this.$router.back();
      } catch (err) {
        this.$emit('snack', 'Failed to delete role', true);
      }
      this.deleting = false;
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    onResize() {
      this.tableHeight = this.$refs.table.clientHeight - 100;
    },
    addFunction(func) {
      this.role.functions.push(func);
    },
    removeFunction(func) {
      this.role.functions = remove(this.role.functions, (f) => f.function_id !== func.function_id);
    },
  },
};
</script>
