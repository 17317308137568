import moment from 'moment';

const label = '12 hours - 30 minute interval';
const limit = 12;
const text = '12 Hours';

const createFilter = () => {
  const now = moment(); // Get the current date and time
  const twelveHoursAgo = now.clone().subtract(12, 'hours'); // Get the time 12 hours ago

  const timeRangeFilter = {
    range: {
      call_datetime_iso: {
        gte: twelveHoursAgo.toISOString(),
        lte: now.toISOString(),
      },
    },
  };
  return [timeRangeFilter];
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  fixed_interval: '30m',
  min_doc_count: 0,
});

const dateFormat = (date) => moment(date).format('HH:mm');

export default {
  label,
  limit,
  text,
  createFilter,
  dateHistogram,
  dateFormat,
};
