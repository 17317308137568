<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ createMode ? 'Create' : 'Manage' }} Call Bundle
      </v-toolbar-title>
      <v-btn
        v-if="!createMode && isPermitted('/callbundles/<bundleid>', 'DELETE')"
        text
        color="primary"
        @click="deleteDialog = true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        class="px-2"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-layout>
              <v-text-field
                v-model="callBundle.BundleName"
                label="Name"
                :rules="rules.BundleName"
              />
            </v-layout>
          </v-col>
          <v-col>
            <v-layout>
              <v-select
                v-model="callBundle.BundleType"
                flat
                :items="bundleTypes"
                label="Bundle Type"
                :rules="rules.BundleType"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="callBundle.BundleCost"
                label="Cost"
                :rules="rules.BundleCost"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="callBundle.InclusiveMinutes"
                label="Inclusive Minutes"
                :rules="rules.InclusiveMinutes"
              />
            </v-layout>
          </v-col>
        </v-row>
        <v-layout>
          <v-flex class="pa-2">
            <v-row>
              <v-btn
                v-if="createMode ? isPermitted('/callbundles', 'POST') : isPermitted('/callbundles/<bundleid>', 'PUT')"
                primary
                color="primary"
                dark
                @click="updateBundle"
              >
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this Bundle?</v-card-title>
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteBundle"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';

import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'CallBundle',
  data() {
    return {
      createMode: false,
      deleteDialog: false,
      valid: false,
      bundleId: this.$route.params.bundleId,
      callBundle: {},
      bundleTypes: [
        {
          text: 'National',
          value: 'NATIONAL',
        },
        {
          text: 'International',
          value: 'INTERNATIONAL',
        },
        {
          text: 'Non Geographic',
          value: 'NONGEO',
        },
      ],
      rules: {
        BundleName: [
          (v) => (v != null && String(v).length > 0) || 'Name is required',
        ],
        BundleType: [
          (v) => (v != null && String(v).length > 0) || 'Type is required',
        ],
        BundleCost: [
          (v) => (v != null && String(v).length > 0) || 'Cost is required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
        InclusiveMinutes: [
          (v) => (v != null && String(v).length > 0) || 'Inclusive Minutes are required',
          (v) => !Number.isNaN(Number(v)) || 'Must be a valid number',
        ],
      },
    };
  },
  created() {
    if (this.bundleId === 'new') {
      this.createMode = true;
    } else {
      this.loadBundle();
    }
  },
  methods: {
    async updateBundle() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$emit('snack', 'Form contains invalid values.', true);
        return;
      }
      try {
        let snackMessage = 'Bundle successfully updated.';

        if (this.createMode) {
          await axios.post(`${config.BTL_API_TENANT_URL}/callbundles`, this.callBundle);
          snackMessage = 'Budnle successfully created.';
        } else {
          await axios.put(`${config.BTL_API_TENANT_URL}/callbundles/${this.bundleId}`, this.callBundle);
        }
        this.$emit('snack', snackMessage);
        this.$router.back();
      } catch (error) {
        console.error('Error updating bundle', error);
        this.$emit('snack', `Failed to ${this.createMode ? 'create' : 'update'} bundle.`, true);
      }
    },
    async loadBundle() {
      try {
        const { data: callBundle } = await axios.get(`${config.BTL_API_TENANT_URL}/callbundles/${this.bundleId}`);
        this.callBundle = callBundle;
      } catch (error) {
        this.$emit('snack', 'Failed to retrieve bundle.', true);
        console.error('Failed to retrieve bundle', error);
      }
    },
    async deleteBundle() {
      try {
        await axios.delete(`${config.BTL_API_TENANT_URL}/callbundles/${this.bundleId}`);
        this.$emit('snack', 'Deleted successfully.');
        this.$router.back();
      } catch (error) {
        this.$emit('snack', 'Failed to delete bundle.', true);
        console.error('Failed to delete bundle', error);
      }
    },
    isPermitted(endpoint, verb) {
      const prefixedEndpoint = `/tenants/<tenant_name>${endpoint}`;
      return permittedFunction(prefixedEndpoint, verb);
    },
  },
};
</script>
