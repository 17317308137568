<template>
  <v-container>
    <v-row>
      <v-col>
        <Schedules
          :dialogue-open="dialogueOpen"
          :account-names="accountNames"
          @close="$emit('close')"
          @snack="$emit('snack', $event)"
          @scheduleRun="$refs.reports.loadReports()"
          @schedulesLoaded="schedules = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Reports
          ref="reports"
          :dialogue-open="dialogueOpen"
          :account-names="accountNames"
          :schedules="schedules"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Schedules from './Schedules.vue';
import Reports from './Reports';

export default {
  name: 'SchedulesAndReports',
  components: {
    Schedules,
    Reports,
  },
  props: {
    dialogueOpen: Boolean,
    accountNames: Object,
  },
  data() {
    return {
      schedules: [],
    };
  },
  computed: {
    userHasAccessToMultipleAccounts() {
      return Object.keys(this.accountNames).length > 1;
    },
  },
};
</script>
