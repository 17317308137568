<template>
  <div>
    <page-toolbar title="SMS Numbers">
      <v-btn
        text
        color="blue"
        :loading="loadingNumbers"
        @click="loadNumbers"
      >
        <v-icon left>
          refresh
        </v-icon>Refresh
      </v-btn>
    </page-toolbar>

    <v-container fluid>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="numbers"
            :loading="loadingNumbers"
            item-key="SourceNumber"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 205px)"
            :search="$store.getters.searchQuery"
          >
            <template #item.ModifiedOn="{item}">
              {{ formatDateTime(item.ModifiedOn) }}
            </template>
            <template #item.actions="props">
              <div>
                <v-tooltip
                  bottom
                >
                  <template #activator="{on}">
                    <v-btn
                      icon
                      @click="openEditDialogue({ number: props.item })"
                      v-on="on"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Number</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="editNumberDialogue"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Edit Number
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="numberToEdit.Notes"
              label="Notes"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            primary
            color="primary"
            dark
            @click="editNumber"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="secondary"
            @click="editNumberDialogue = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import {
  onMounted, ref, computed, watch,
} from 'vue';
import axios from 'axios';

import PageToolbar from '@/components/PageToolbar';

import { BTL_API_ACCOUNT_URL } from '@/config';
import { useStore } from '@/utils/vue';
import { formatDateTime } from '@/utils/DateTimeFormatter';

const headers = [
  { text: 'Number', value: 'SourceNumber' },
  { text: 'Notes', value: 'Notes' },
  { text: 'Modified On', value: 'ModifiedOn' },
  { text: 'Modified By', value: 'ModifiedBy' },
  { text: '', value: 'actions', align: 'right' },
];

const emit = defineEmits(['snack']);
const store = useStore();

const selectAccountId = computed(() => store.getters.selectedAccountId);

const loadingNumbers = ref(false);
const numbers = ref([]);

const loadNumbers = async () => {
  try {
    loadingNumbers.value = true;
    const { data } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/numbers`);
    numbers.value = data;
  } catch (err) {
    console.error('ERROR', err.response);
  } finally {
    loadingNumbers.value = false;
  }
};

const editNumberDialogue = ref(false);
const numberToEdit = ref({});
const editingNumber = ref(false);

const openEditDialogue = ({ number }) => {
  numberToEdit.value = number;
  editNumberDialogue.value = true;
};

const editNumber = async () => {
  editingNumber.value = true;
  try {
    await axios.put(`${BTL_API_ACCOUNT_URL}/${selectAccountId.value}/sms/numbers/${numberToEdit.value.SourceNumber}`, numberToEdit.value);
    editNumberDialogue.value = false;
    emit('snack', 'Number Updated');
    await loadNumbers();
  } catch (err) {
    console.error('ERROR', err);
  } finally {
    editingNumber.value = false;
  }
};

watch(selectAccountId, loadNumbers);

onMounted(async () => {
  await Promise.all([
    await loadNumbers(),
  ]);
});

</script>
