<template>
  <resize-container
    :id="id"
    :layout-props="layoutProps"
    @saveLayout="bubbleLayout"
  >
    <v-layout
      elevation-6
      align-center
      justify-center
      column
      fill-height
    >
      <v-container>
        <v-toolbar
          color="blue darken-3"
          dark
          dense
          height="36"
        >
          <v-btn
            icon
            @click="$emit('deleteWidget', id)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title class="body-2">
            Recent Changes
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="loadHistory"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
      <v-container style="height: 100%; overflow-y: scroll; background-color: #FFF;">
        <v-layout
          v-if="!dataLoaded"
          align-center
          justify-center
          row
          fill-height
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-layout>
        <timeline v-if="dataLoaded">
          <timeline-title>History</timeline-title>
          <timeline-item
            v-for="(t,i) in timeline"
            :key="i"
          >
            <span style="color: grey;font-style: italic;">{{ t.date }} ({{ t.user }})</span><br>
            <span>{{ t.description }}</span>
            <template #others>
              <v-icon>{{ t.icon }}</v-icon>
            </template>
          </timeline-item>
        </timeline>
      </v-container>
    </v-layout>
  </resize-container>
</template>
<script>
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline';

import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import ResizeContainer from '@/components/widgets/layout/ResizeContainer';

import config from '@/config.js';

export default {
  name: 'Recentchanges',
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle,
    'resize-container': ResizeContainer,
  },
  props: {
    id: String,
    layoutProps: Object,
  },
  data() {
    return {
      history: [],
      timeline: [],
      dataLoaded: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
      'authentication',
      'timeZone',
    ]),
  },
  watch: {
    authentication: {
      handler() {
        this.loadHistory();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadHistory();
  },
  methods: {
    loadHistory() {
      this.dataLoaded = false;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/history?pagenum=1&pagesize=50`)
        .then((response) => {
          this.timeline.splice(0, this.timeline.length);
          this.history = response.data;
          this.history.forEach((h) => {
            const t = {
              user: h.UserFullname,
              description: h.Comment,
              date: moment(h.CreatedDate).tz(this.timeZone).format(config.long_date_time_format),
            };
            if (h.EntityType === 'Whitelist' || h.EntityType === 'Blacklist') {
              t.icon = 'filter_list';
            } else if (h.EntityType === 'Device') {
              t.icon = 'dns';
            } else if (h.EntityType === 'Subscription') {
              t.icon = 'settings';
            } else if (h.EntityType === 'Trunk') {
              t.icon = 'shuffle';
            } else if (h.EntityType === 'Callflow') {
              t.icon = 'call_split';
            } else if (h.EntityType === 'User') {
              t.icon = 'account_circle';
            } else if (h.EntityType === 'PhoneNumber') {
              t.icon = 'call';
            } else if (h.EntityType === 'Account') {
              t.icon = 'supervisor_account';
            } else if (h.EntityType === 'Group') {
              t.icon = 'people';
            } else if (h.EntityType === 'Site') {
              t.icon = 'business';
            } else if (h.EntityType === 'National') {
              t.icon = 'local_atm';
            } else if (h.EntityType === 'Toll Fraud') {
              t.icon = 'warning';
            }
            this.timeline.push(t);
          });
          this.dataLoaded = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    bubbleLayout(data) {
      this.$emit('saveLayout', data);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
