<template>
  <v-data-table
    :items="serviceCharges"
    :headers="headers"
    :footer-props="{
      itemsPerPageOptions: 50,
      itemsPerPageOptions: [50, 100, 250, -1]
    }"
  >
    <template #item.Name="{ item }">
      <router-link
        v-if="isPermitted('/tenants/<tenant_name>/servicecharges/<servicecharge_id>', 'GET')"
        :to="`tenantservicecharges/${item.servicecharge_id}`"
      >
        {{ item.Name }}
      </router-link>
      <div v-else>
        {{ item.Name }}
      </div>
    </template>
  </v-data-table>
</template>
<script>

import moment from 'moment';

import config from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import formatter from '@/utils/currencyFormatter';

const currencyFormatter = formatter(4);

export default {
  name: 'ServiceServiceChargesLookup',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'Name' },
        { text: 'Service', value: 'service.Name' },
        { text: 'Description', value: 'Description' },
        { text: 'Unit Type', value: 'UnitType' },
        { text: 'Units', value: 'Units' },
        { text: 'Unit Cost', value: 'UnitCost' },
        { text: 'Total Cost', value: 'TotalCost' },
        { text: 'Charge Frequency', value: 'ChargeFrequency' },
        { text: 'Modified By', value: 'ModifiedBy' },
        { text: 'Modified On', value: 'ModifiedOn' },
      ],
    };
  },
  computed: {
    serviceCharges() {
      return (this.data || []).map((charge) => ({
        ...charge,
        UnitCost: currencyFormatter(charge.UnitCost),
        TotalCost: currencyFormatter(charge.TotalCost),
        ModifiedOn: moment(charge.ModifiedOn).format(config.date_format),
      }));
    },
  },
  methods: {
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>

.v-data-table {
  height: 90%;
}

::v-deep .v-data-table__wrapper {
  height: calc(100% - 60px);
  overflow-x: auto;
  overflow-y: auto;
}

</style>
