import { render, staticRenderFns } from "./InboundTraffic.vue?vue&type=template&id=e0a9ee80&scoped=true"
import script from "./InboundTraffic.vue?vue&type=script&lang=js"
export * from "./InboundTraffic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0a9ee80",
  null
  
)

export default component.exports