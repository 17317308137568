<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="subtitle-1">
        <v-icon class="mr-3">
          build
        </v-icon>Functions

        ({{ selectedFunctions.length }} / {{ functions.length }} assigned)
        <v-tooltip

          right
        >
          <template #activator="{ on }">
            <v-btn

              class="ml-2"
              icon
              @click.stop="assignedFunctionsDialog = true"
              v-on="on"
            >
              <v-icon small>
                launch
              </v-icon>
            </v-btn>
          </template>
          View assigned functions
        </v-tooltip>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <v-card flat>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title />
          <v-spacer />
          <v-btn
            v-if="canChangeEntityType"
            color="primary"
            :disabled="selectedFunctions.length < 1"
            text
            @click="showDialog"
          >
            Assign entity to selected functions
          </v-btn>
        </v-toolbar>

        <v-data-table
          ref="functions"
          v-model="selectedFunctions"
          :headers="functionsHeaders"
          :items="functions"
          :search="$store.getters.searchQuery"
          :loading="loading"
          sort-by="name"
          item-key="function_id"
          show-select
          :items-per-page="25"
          :footer-props="{itemsPerPageOptions: [10, 25, 50]}"
          @input="updateParentModel"
          @toggle-select-all="selectAll"
        >
          <template #item="props">
            <tr :class=" props.isSelected ? 'v-data-table__selected' : ''">
              <td>
                <v-simple-checkbox
                  :value="props.isSelected"
                  color="blue"
                  hide-details
                  @input="toggleFunction(props, $event)"
                />
              </td>
              <td>{{ props.item.name }}</td>
              <td v-if="canChangeEntityType">
                <v-select
                  v-if="props.isSelected"
                  v-model="props.item.entity_type"
                  :items="entityTypes"
                  item-text="name"
                  item-value="key"
                  label="Type"
                  @change="changeDetect++"
                />
              </td>
              <td
                v-if="canChangeEntityType"
                :key="changeDetect"
              >
                <v-autocomplete
                  v-if="props.isSelected && props.item.entity_type==='tenant'"
                  v-model="props.item.entity_id"
                  label="Tenant"
                  :items="tenantEntityIds"
                  auto-select-first
                />
                <v-autocomplete
                  v-if="props.isSelected && props.item.entity_type==='account'"
                  v-model="props.item.entity_id"
                  label="Account"
                  :items="sortedAccounts"
                  item-text="name"
                  item-value="account_id"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog
        v-model="selectAllDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title class="headline">
            Apply changes to all functions?
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="setAllEntityType"
                  :items="entityTypes"
                  item-text="name"
                  item-value="key"
                  label="Type"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  v-if="setAllEntityType === 'tenant'"
                  v-model="setAllTenant"
                  :items="tenantEntityIds"
                  label="Tenant"
                  auto-select-first
                />
                <v-autocomplete
                  v-if="setAllEntityType === 'account'"
                  v-model="setAllAccount"
                  :items="sortedAccounts"
                  label="Account"
                  item-text="name"
                  item-value="account_id"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="selectAllDialog=false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="applySetAllChanges"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="assignedFunctionsDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title class="headline">
            Assigned functions
          </v-card-title>
          <v-container>
            <v-data-table
              height="600px"
              :headers="functionsHeaders"
              :items="selectedFunctions"
              sort-by="name"
              fixed-header
              dense
              disable-pagination
              hide-default-footer
            >
              <template #item="props">
                <tr disabled>
                  <td>{{ props.item.name }}</td>
                  <td v-if="canChangeEntityType">
                    <v-select
                      :value="props.item.entity_type"
                      disabled
                      :items="entityTypes"
                      item-text="name"
                      item-value="key"
                      label="Type"
                      @change="changeDetect++"
                    />
                  </td>
                  <td
                    v-if="canChangeEntityType"
                    :key="changeDetect"
                  >
                    <v-select
                      v-if="props.item.entity_type==='tenant'"
                      :value="props.item.entity_id"
                      disabled
                      label="Tenant"
                      :items="tenantEntityIds"
                      auto-select-first
                    />
                    <v-select
                      v-if="props.item.entity_type==='account'"
                      :value="props.item.entity_id"
                      disabled
                      label="Account"
                      :items="sortedAccounts"
                      item-text="name"
                      item-value="account_id"
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="assignedFunctionsDialog=false"
            >
              close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import { mapGetters } from 'vuex';

import { BTL_API_URL } from '@/config.js';

export default {
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      functions: [],
      selectedFunctions: [],
      loading: false,
      selectAllDialog: false,
      assignedFunctionsDialog: false,
      setAllEntityType: 'account',
      setAllTenant: 'btlcloud',
      setAllAccount: '',
      entityTypes: [
        { name: 'Account', key: 'account' },
        { name: 'Tenant', key: 'tenant' },
      ],
      tenantEntityIds: ['btlcloud'],
      changeDetect: 1,
    };
  },
  computed: {
    filteredFunctions() {
      // THIS MAY BREAK IF VUETIFY CHANGES DATATABLE INTERNAL STRUCTURE!
      return this.$refs.functions.$children[0].filteredItems;
    },
    sortedAccounts() {
      return sortBy(this.accounts, (account) => account.name.toLowerCase());
    },
    ...mapGetters([
      'selectedAccountId',
    ]),
    canChangeEntityType() {
      if (this.user) {
        return this.user?.max_entitlement_level === 'Tenant' && this.$store.getters.authentication.maxEntitlementLevel === 'Tenant';
      }
      if (this.group) {
        return this.$store.getters.authentication.maxEntitlementLevel === 'Tenant';
      }
      return false;
    },
    functionsHeaders() {
      const headers = [
        { text: 'Name', value: 'name' },
      ];
      if (this.canChangeEntityType) {
        headers.push({ text: 'Entity Type', value: 'entity_type', filterable: false }, { text: 'Entity', value: 'entity_id', filterable: false });
      }
      return headers;
    },
  },
  watch: {
    changeDetect() {
      this.updateParentModel();
    },
  },
  mounted() {
    this.loadFunctions();
    this.setAllAccount = this.selectedAccountId;
  },
  methods: {
    loadFunctions() {
      axios.get(`${BTL_API_URL}/functions`)
        .then((response) => {
          this.loading = false;
          this.functions = response.data;
          const entitlement = this.user ? this.user.entitlement : this.group.entitlements;
          if (entitlement) this.updateFunctionsWithEntitlementInfo(entitlement);
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    },
    updateFunctionsWithEntitlementInfo(entitlement) {
      entitlement.forEach((e) => {
        if (e.functions) {
          e.functions.forEach((f) => {
            f.entity_type = e.resource.entity_type;
            f.entity_id = e.resource.entity_id;
            this.selectedFunctions.push(f);
            const func = this.functions.filter((ff) => ff.function_id === f.function_id);
            if (func && func.length === 1) {
              func[0].entity_type = f.entity_type;
              func[0].entity_id = f.entity_id;
            }
          });
          this.updateParentModel();
        }
      });
    },
    showDialog() {
      this.selectAllDialog = true;
    },
    applySetAllChanges() {
      this.filteredFunctions.forEach((prop) => {
        prop.entity_type = this.setAllEntityType;
        const entityIdSelector = `setAll${this.uppercaseFirst(prop.entity_type)}`;
        prop.entity_id = this[entityIdSelector];
      });

      this.selectAllDialog = false;
      this.updateParentModel();
    },
    updateParentModel() {
      this.$emit('selectedFunctionsChanged', this.functions.filter((f) => this.selectedFunctions.find((sf) => f.function_id === sf.function_id)));
    },
    uppercaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    changeSort(column, pagination) {
      if (pagination.sortBy === column) {
        pagination.descending = !pagination.descending;
      } else {
        pagination.sortBy = column;
        pagination.descending = false;
      }
    },
    selectAll(selectAllEvent) {
      if (selectAllEvent.value) {
        this.filteredFunctions.forEach((func) => {
          const index = this.selectedFunctions.indexOf(func);
          if (index === -1) {
            func.entity_type = 'account';
            func.entity_id = this.selectedAccountId;
            this.selectedFunctions.push(func);
          }
        });
        if (this.canChangeEntityType) {
          this.showDialog();
        }
      } else {
        this.filteredFunctions.forEach((func) => {
          const index = this.selectedFunctions.indexOf(func);
          if (index !== -1) {
            this.$delete(this.selectedFunctions, index);
          }
        });
      }
    },
    toggleFunction(tableRow, selected) {
      if (selected) {
        tableRow.item.entity_type = 'account';
        tableRow.item.entity_id = this.selectedAccountId;
      } else {
        tableRow.item.entity_type = null;
        tableRow.item.entity_id = null;
      }

      tableRow.select(selected);
    },
  },
};

</script>
