<script>/* eslint-disable */</script>
<template>
  <v-container>
    <node-header
      icon="settings"
      badge="variables"
      :title="name"
      :detail="leg"
      chip-a="NEXT"
      :color-a="defaultAction === 'none'"
    />

    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn fab dark color="primary" height="24" width="24" v-on="on">
            <v-icon size="12">edit</v-icon>
          </v-btn>
        </template>
        <v-form ref="form">
          <v-card flat>
            <v-card-text>
              <v-text-field v-model="name" label="Step Name" />
              <v-select
                v-model="leg"
                label="Leg"
                :items="legs"
                required
              />
              <v-subheader class="px-0">Channel Variables</v-subheader>
              <div class="variables-container">
                <div v-for="(variable, index) in channelVariables" :key="index" class="d-flex align-center mb-2">
                  <v-text-field
                    v-model="variable.name"
                    label="Variable Name"
                    required
                    class="mr-2"
                  />
                  <v-text-field
                    v-model="variable.value"
                    label="Variable Value"
                    required
                    class="mr-2"
                  />
                  <v-btn icon @click="deleteVariable(index)" color="red">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-btn color="primary" @click="addVariable">
                Add Variable
              </v-btn>

              <v-subheader class="px-0">Add next step</v-subheader>
                <div class="next-step-container mb-2">
                <v-select
                    v-model="defaultAction"
                    label="NEXT"
                    :items="actions"
                />
                </div>

            </v-card-text>
          </v-card>
        </v-form>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="handleCancel">Cancel</v-btn>
            <v-btn color="primary" text @click="handleSave">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn fab dark color="red" height="24" width="24" v-on="on">
            <v-icon size="12">delete</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="menu_delete = false">Cancel</v-btn>
            <v-btn color="primary" text @click="handleDelete">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import NodeHeader from './partials/Header';
import { typesForSelect } from './nodeTypes';

export default {
  components: {
    NodeHeader,
  },
  props: {
    node: Object,
    dialplan: Array,
    canedit: Boolean,
  },
  data: () => ({
    menu: false,
    menu_delete: false,
    name: null,
    leg: 'both',
    defaultAction: 'none',
    actions: typesForSelect,
    channelVariables: [{ name: '', value: '' }],
    legs: ['aleg', 'bleg', 'both'],
    ruleRequired: (v) => !!v || 'Field is required',
  }),
  computed: {
    ...mapGetters(['selectedAccountId']),
  },
  mounted() {
    if (this.node) {
      this.loadNodeData();
    }
  },
  methods: {
    loadNodeData() {
      this.name = this.node.name || '';
      this.leg = this.node.parameters?.leg || 'both';
      this.defaultAction = this.getLabel(this.node.next);
      
      this.channelVariables = this.node.parameters?.channelVariables?.map(variable => {
        const name = Object.keys(variable)[0];
        const value = variable[name];
        return { name, value };
      }) || [{ name: '', value: '' }];
    },
    getLabel(nodeId) {
      const node = this.dialplan?.find((n) => n.id === nodeId);
      return node ? node.action : 'none';
    },
    handleSave() {
      if (!this.$refs.form.validate()) return;
      this.menu = false;

      this.node.name = this.name;
      this.node.parameters = {
        leg: this.leg,
        channelVariables: this.channelVariables
          .filter(variable => variable.name && variable.value)
          .map(variable => ({ [variable.name]: variable.value })),
      };

      if (this.defaultAction !== this.getLabel(this.node.next)) {
        if (this.defaultAction === 'none') {
          this.node.next = 99;
        } else {
          this.$emit('newnode', this.defaultAction, 'NEXT');
        }
      }
      this.$emit('updateNode', this.node);
    },
    handleCancel() {
      this.menu = false;
      this.loadNodeData();
    },
    handleDelete() {
      this.$emit('deletenode');
    },
    addVariable() {
      this.channelVariables.push({ name: '', value: '' });
    },
    deleteVariable(index) {
      this.channelVariables.splice(index, 1);
    },
  },
};
</script>

<style>
.variables-container {
  max-height: 200px;
  overflow-y: auto;
}
.next-step-container .v-select {
  padding-left: 0 !important; /* Remove any default padding */
  margin-left: 0 !important;  /* Adjust margin if needed */
}
</style>
