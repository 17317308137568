<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Capture Templates
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/capturetemplates', 'POST')"
        color="blue"
        text
        @click="$router.push('/capturetemplate/new')"
      >
        <v-icon class="mr-2">
          add_box
        </v-icon>Create
      </v-btn>
      <v-btn
        text
        color="blue"
        @click="loadCaptureTemplates"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <download
        :table-data="captureTemplates"
        :headers="exportHeaders"
        report-name="Payment Capture Templates"
        color="primary"
      />
      <v-spacer />
    </v-toolbar>

    <v-container fluid>
      <v-row class="mx-1">
        <v-col cols="6">
          <div class="headline mb-2">
            Card Templates
          </div>
          <v-divider class="mb-2" />
          <v-data-table
            :headers="headers"
            :items="cardTemplates"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 260px)"
            sort-by="Issuer"
          >
            <template #item.Issuer="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/capturetemplates/<pcIssuerOrId>', 'GET')"
                :to="`capturetemplate/${item.Id}`"
              >
                {{ item.Issuer }}
              </router-link>
              <div v-else>
                {{ item.Issuer }}
              </div>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="6">
          <div class="headline mb-2">
            Bank Account Templates
          </div>
          <v-divider class="mb-2" />
          <v-data-table
            :headers="headers"
            :items="accountTemplates"
            :loading="loading"
            :search="$store.getters.searchQuery"
            item-key="Id"
            :items-per-page="100"
            :footer-props="{itemsPerPageOptions: [100, 250, 500]}"
            fixed-header
            height="calc(100vh - 260px)"
            sort-by="Issuer"
          >
            <template #item.Issuer="{ item }">
              <router-link
                v-if="isPermitted('/tenants/<tenant_name>/capturetemplates/<pcIssuerOrId>', 'GET')"
                :to="`capturetemplate/${item.Id}`"
              >
                {{ item.Issuer }}
              </router-link>
              <div v-else>
                {{ item.Issuer }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

import { BTL_API_TENANT_URL } from '@/config.js';

import permittedFunction from '@/permittedFunction.js';
import download from '@/components/download/tableDownload';

export default {
  name: 'CaptureTemplates',
  components: {
    download,
  },
  data() {
    return {
      captureTemplates: [],
      headers: [
        { text: 'Name', value: 'Issuer' },
      ],
      exportHeaders: [
        { text: 'Id', value: 'Id' },
        { text: 'TransactionType', value: 'TransactionType' },
        { text: 'Issuer', value: 'Issuer' },
        { text: 'Pan', value: 'Pan' },
        { text: 'PanRegex', value: 'PanRegex' },
        { text: 'IinDescriptor', value: 'IinDescriptor' },
        { text: 'IinRegex', value: 'IinRegex' },
        { text: 'ExpiryMonth', value: 'ExpiryMonth' },
        { text: 'ExpiryYear', value: 'ExpiryYear' },
        { text: 'StartMonth', value: 'StartMonth' },
        { text: 'StartYear', value: 'StartYear' },
        { text: 'IssueNumber', value: 'IssueNumber' },
        { text: 'Csc', value: 'Csc' },
        { text: 'AccountNumber', value: 'AccountNumber' },
        { text: 'SortCode', value: 'SortCode' },
      ],
      loading: false,
    };
  },
  computed: {
    cardTemplates() {
      return this.captureTemplates.filter((pct) => pct.TransactionType === 'CardPayment');
    },
    accountTemplates() {
      return this.captureTemplates.filter((pct) => pct.TransactionType === 'AccountCapture');
    },
  },
  mounted() {
    this.loadCaptureTemplates();
  },
  methods: {
    async loadCaptureTemplates() {
      this.loading = true;

      try {
        const response = await axios.get(`${BTL_API_TENANT_URL}/capturetemplates`);
        this.captureTemplates = response.data;
      } catch (e) {
        this.$emit('snack', 'Failed to retrieve payment capture templates', true);
      } finally {
        this.loading = false;
      }
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>
