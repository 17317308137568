<template>
  <div class="text-center">
    <v-progress-circular
      :size="250"
      color="primary"
      indeterminate
    />
  </div>
</template>

<script>
import { handleAuthentication } from '@/auth/AuthService';

export default {
  name: 'Callback',
  data() {
    return {
    };
  },
  created() {
    handleAuthentication()
      .then(({ appState = {} }) => {
        const path = appState.path ? appState.path : '/';
        const { query = {} } = appState;
        this.$router.push({ path, query });
      });
  },
};
</script>
