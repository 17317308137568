<template>
  <v-container>
    <node-header
      icon="call"
      :title="name"
      :detail="routeConfig.phoneNumber"
      :color-a="routeConfig.defaultAction === 'none'"
      :color-b="routeConfig.failoverAction === 'none'"
      chip-a="NEXT"
      chip-b="FAILOVER"
    />

    <v-layout align-content-space-between>
      <v-menu
        v-if="canedit"
        v-model="menu"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="primary"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              edit
            </v-icon>
          </v-btn>
        </template>
        <v-form ref="form">
          <v-card flat>
            <v-card-text>
              <v-text-field
                v-model="name"
                label="Step Name"
              />
              <v-subheader class="px-0">
                Configure dial number
              </v-subheader>
              <v-text-field
                v-model="phoneNumber"
                label="Number"
                :rules="numberRules"
                required
              />
              <v-select
                v-model="trunkId"
                label="Trunk"
                required
                :items="trunks"
                item-text="Description"
                item-value="Username"
              />
              <v-text-field
                v-model.number="ringTimeout"
                label="Ring Timeout (Seconds)"
                :rules="ringTimeoutRules"
              />
              <v-subheader class="px-0">
                Add next step
              </v-subheader>
              <v-select
                v-model="defaultAction"
                label="NEXT"
                :items="actions"
              />
              <v-select
                v-model="failoverAction"
                label="On FAILOVER"
                :items="actions"
              />
            </v-card-text>
          </v-card>
        </v-form>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="handleCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-menu
        v-if="canedit"
        v-model="menu_delete"
        :close-on-content-click="false"
        class="callflow-node__menu"
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            height="24"
            width="24"
            v-on="on"
          >
            <v-icon size="12">
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Confirm delete</v-subheader>
          <v-subheader>Are you sure you wish to delete this node and its children?</v-subheader>
        </v-list>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="menu_delete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="handleDelete"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';

import config from '@/config.js';

import NodeHeader from './partials/Header';

import { typesForSelect } from './nodeTypes';

export default {
  components: {
    NodeHeader,
  },
  props: {
    node: Object,
    dialplan: Array,
    canedit: Boolean,
  },
  data: () => ({
    menu: false,
    menu_delete: false,
    width: 0,
    height: 0,
    trunks: null,
    name: null,
    trunkName: 'none',
    trunkId: null,
    phoneNumber: '',
    ringTimeout: null,
    defaultAction: 'none',
    failoverAction: 'none',
    actions: typesForSelect,
    routeConfig: {
      trunkId: '',
      phoneNumber: '',
      defaultAction: 'none',
      failoverAction: 'none',
    },
    numberRules: [
      (v) => !!v || 'Phone number is required',
      (v) => (v && v.length <= 20) || 'Phone number must not have more than 20 characters',
    ],
    ringTimeoutRules: [
      (v) => ((!v || v?.length === 0) || Number.isInteger(v)) || 'Must Be Integer',
      (v) => ((!v || v?.length === 0) || v >= 0) || 'Must be 0 or greater',
    ],
  }),
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    trunkId() {
      const trunk = this.trunks.find((t) => t.Username === this.trunkId);
      if (trunk) {
        this.trunkName = trunk.Description;
      } else {
        this.trunkName = 'none';
      }
    },
  },
  mounted() {
    this.loadTrunks();
    this.name = this.node.name;
    this.failoverAction = this.getLabel(this.node.conditions.DIALSTATUS.FAILOVER);
    this.routeConfig.failoverAction = this.getLabel(this.node.conditions.DIALSTATUS.FAILOVER);
    this.defaultAction = this.getLabel(this.node.next);
    this.routeConfig.defaultAction = this.getLabel(this.node.next);
  },
  methods: {
    getLabel(nodeId) {
      const node = this.dialplan.find((n) => n.id === nodeId);
      if (node) {
        return node.action;
      }
      return 'none';
    },
    handleDelete() {
      this.$emit('deletenode');
    },
    handleSave() {
      if (!this.$refs.form.validate()) return;
      this.menu = false;
      this.routeConfig.phoneNumber = this.phoneNumber;
      this.node.name = this.name;
      this.node.parameters.trunkid = this.trunkId;
      this.node.parameters.phonenumber = this.phoneNumber;
      this.node.parameters.ringTimeout = this.ringTimeout;
      this.node.routeConfig = this.routeConfig;
      if (this.routeConfig.defaultAction !== this.defaultAction) {
        this.routeConfig.defaultAction = this.defaultAction;
        if (this.defaultAction === 'none') {
          this.node.next = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.defaultAction, 'NEXT');
        }
      }
      if (this.routeConfig.failoverAction !== this.failoverAction) {
        this.routeConfig.failoverAction = this.failoverAction;
        if (this.failoverAction === 'none') {
          this.node.conditions.DIALSTATUS.FAILOVER = 99;
          this.$emit('drawconnectors');
        } else {
          this.$emit('newnode', this.routeConfig.failoverAction, 'FAILOVER');
        }
      }
    },
    handleCancel() {
      this.menu = false;
      this.name = this.node.name;
      this.trunkId = this.node.parameters.trunkid;
      this.phoneNumber = this.node.parameters.phonenumber;
      this.ringTimeout = this.node.parameters.ringTimeout;
    },
    async loadTrunks() {
      try {
        const { data } = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/trunks`);
        this.trunks = sortBy(data, (trunk) => trunk.Description.toLowerCase());
        this.trunkId = this.node?.parameters?.trunkid;
        this.routeConfig.phoneNumber = this.node.parameters.phonenumber;
        this.phoneNumber = this.node.parameters.phonenumber;
        this.ringTimeout = this.node?.parameters?.ringTimeout;
      } catch (error) {
        console.error('ERROR', error);
      }
    },
  },
};
</script>
