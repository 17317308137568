<template>
  <div
    ref="containerRef"
    v-resize="onResize"
    style="height: 100%;"
  >
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        CallFlows
      </v-toolbar-title>
      <v-menu>
        <template #activator="{on}">
          <v-btn
            v-if="permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/callflows', 'POST')"
            text
            color="blue"
            v-on="on"
          >
            <v-icon class="mr-2">
              add_box
            </v-icon>Create CallFlow
          </v-btn>
        </template>
        <v-list>
          <v-subheader>Select CallFlow Template</v-subheader>
          <v-list-item @click="$router.push('/newcallflow/default')">
            <v-list-item-title>Default</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/newcallflow/template1')">
            <v-list-item-title>Dial a number</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/newcallflow/template2')">
            <v-list-item-title>DR non-resilient</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/newcallflow/template3')">
            <v-list-item-title>DR resilient</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/newcallflow/template4')">
            <v-list-item-title>Non-resilient</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/newcallflow/template5')">
            <v-list-item-title>Non-resilient working hours</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/newcallflow/template6')">
            <v-list-item-title>Resilient</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/newcallflow/template7')">
            <v-list-item-title>Resilient working hours</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        text
        color="blue"
        @click="loadCallFlows"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <tableDownload
        color="primary"
        :table-data="callflows"
        report-name="CallFlows"
      />
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-layout class="mt-1 px-2">
        <v-flex>
          <v-data-table
            :headers="headers"
            :options="tableOptions"
            :items="callflows"
            :loading="loading"
            :search="$store.getters.searchQuery"
            :footer-props="footerOptions"
            fixed-header
            :height="tableHeight"
            @update:options="handleTableOptionsUpdate"
          >
            <template #item="props">
              <tr>
                <td v-if="permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/callflows/<callflowid>', 'GET')">
                  <router-link :to="props.item.detailsRoute">
                    {{ props.item.Name }}
                  </router-link>
                </td>
                <td>{{ props.item.number_count }}</td>
                <td>
                  <v-icon
                    v-if="props.item.Enabled"
                    color="#1565C0"
                  >
                    toggle_on
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                  >
                    toggle_off
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import {
  reactive, computed, watch, ref, toRefs, onMounted, unref, nextTick,
} from 'vue';

import config from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import { useStore } from '@/utils/vue';
import usePagination from '../../composables/tablePaginationViaQueryString';
import tableDownload from '../../components/download/tableDownload';

export default {
  name: 'Callflows',
  components: {
    tableDownload,
  },
  setup(props, context) {
    const store = useStore();
    // refs
    const containerRef = ref(null);

    // composables
    const {
      tableOptions, saveQueryParams, processQueryParams, handleTableOptionsUpdate,
    } = usePagination(context, { itemsPerPage: 100, sortBy: 'Name' });

    const state = reactive({
      selectedAccountId: computed(() => store.getters.selectedAccountId),

      footerOptions: config.tableFooterOptions,
      headers: [
        { text: 'Name', value: 'Name' },
        { text: 'Numbers', value: 'number_count' },
        { text: 'Enabled', value: 'Enabled' },
      ],
      callflows: [],
      loading: false,
      tableHeight: 0,
    });

    const loadCallFlows = async () => {
      state.loading = true;
      try {
        const { data } = await axios.get(`${config.BTL_API_TENANT_URL}/accounts/${state.selectedAccountId}/callflows`);
        state.callflows = data;
        state.callflows.forEach((row) => {
          row.detailsRoute = `/callflow/${row.CallflowId}`;
        });
      } catch (error) {
        console.error('ERROR', error.response);
      } finally {
        state.loading = false;
      }
    };

    const onResize = () => {
      nextTick(() => {
        const oldRef = unref(containerRef);
        if (oldRef) {
          state.tableHeight = oldRef.clientHeight - 175;
        }
      });
    };

    watch(() => state.selectedAccountId, loadCallFlows);

    saveQueryParams();
    onMounted(async () => {
      await loadCallFlows();
      processQueryParams();
    });

    return {
      ...toRefs(state),
      containerRef,
      loadCallFlows,
      tableOptions,
      handleTableOptionsUpdate,
      permittedFunction,
      onResize,
    };
  },
};
</script>

<style scoped>
</style>
