<template>
  <div v-resize="onResize">
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Functions
      </v-toolbar-title>
      <v-btn
        text
        color="blue"
        @click="loadFunctions"
      >
        <v-icon class="mr-2">
          refresh
        </v-icon>Refresh
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container
      ref="table"
      style="height: calc(100vh - 150px)"
    >
      <v-data-table
        :headers="headers"
        :items="functions"
        :loading="loading"
        :search="$store.getters.searchQuery"
        sort-by="name"
        fixed-header
        :height="tableHeight"
        dense
        disable-pagination
        hide-default-footer
      />
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

import { BTL_API_URL } from '../../config';

export default {
  name: 'Functions',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Verb', value: 'verb' },
        { text: 'Endpoint', value: 'endpoint' },
      ],
      functions: [],
      loading: false,
      tableHeight: 300,
    };
  },
  mounted() {
    this.loadFunctions();
    this.onResize();
  },
  methods: {
    async loadFunctions() {
      this.loading = true;
      try {
        const { data: functions } = await axios.get(`${BTL_API_URL}/functions`);
        this.functions = functions;
      } catch (err) {
        this.$emit('snack', 'Failed to load functions', true);
      }
      this.loading = false;
    },
    onResize() {
      this.tableHeight = this.$refs.table.clientHeight;
    },
  },
};
</script>
