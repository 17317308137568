<template>
  <div class="lookup">
    <v-tooltip
      v-if="small"
      bottom
    >
      <template #activator="{on}">
        <v-btn
          icon
          @click="showDialPlans"
          v-on="on"
        >
          <v-icon size="16">
            open_in_new
          </v-icon>
        </v-btn>
      </template>
      <span>Look Up Codes</span>
    </v-tooltip>

    <v-btn
      v-else
      text
      color="primary"
      @click="showDialPlans"
    >
      <v-icon class="mr-2">
        open_in_browser
      </v-icon>View Dial Plans
    </v-btn>

    <v-dialog
      v-model="dialPlansDialog"
      max-width="430"
    >
      <v-card>
        <v-card-title>
          <span>Dial Plans</span>
          <v-spacer />
          <v-btn
            v-if="!Array.isArray(filterValue)"
            text
            link
            :href="`/${redirectPath}/${filterValue}`"
            target="_blank"
            color="primary"
          >
            <v-icon class="mr-2">
              open_in_new
            </v-icon>Open in New Tab
          </v-btn>
        </v-card-title>
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="search"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="dialPlansHeaders"
          :items="dialPlans"
          :search="search"
        />
        <v-card-actions class="mr-4">
          <v-spacer />
          <v-btn
            color="error"
            @click="dialPlansDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import axios from 'axios';
import config from '@/config.js';
import dotNotation from '@/utils/dotNotation';

export default {
  name: 'CodesLookup',
  props: {
    rateName: String,
    header: String,
    apiPath: String,
    filterName: String,
    filterValue: [String, Array],
    redirectPath: String,
    small: Boolean,
  },
  data() {
    return {
      search: null,
      dialPlansDialog: false,
      dialPlansHeaders: [
        { text: '', value: 'name' },
        { text: 'Code', value: 'code' },
      ],
      dialPlans: [],
    };
  },
  mounted() {
    this.dialPlansHeaders[0].text = this.header[0].toUpperCase() + this.header.slice(1);
  },
  methods: {
    async loadDialPlans() {
      let url = `${config.BTL_API_TENANT_URL}/${this.apiPath}`;
      const paramsObj = {
        filters: [{
          name: this.filterName,
          op: Array.isArray(this.filterValue) ? 'in' : 'eq',
          val: this.filterValue,
        }],
      };

      url += `?q=${JSON.stringify(paramsObj)}`;
      const { data } = await axios.get(url);
      this.dialPlans = data.map((row) => ({
        name: dotNotation(this.rateName, row),
        code: row.Code,
      }));
    },
    async showDialPlans() {
      if (!this.dialPlans.length) {
        await this.loadDialPlans();
      }
      this.dialPlansDialog = true;
    },
  },
};
</script>

<style scoped>
  .lookup {
    display: inline-block;
  }
</style>
