import moment from 'moment';

const label = '6 months - weekly';
const limit = 12;
const text = '6 Months';

const createFilter = () => {
  const now = moment(); // Get the current date and time
  const sixMonthsAgo = now.clone().subtract(6, 'months'); // Get the date 6 months ago

  const timeRangeFilter = {
    range: {
      call_datetime_iso: {
        gte: sixMonthsAgo.toISOString(),
        lte: now.toISOString(),
      },
    },
  };
  return [timeRangeFilter];
};

const dateHistogram = (grid) => ({
  field: grid.yField,
  calendar_interval: 'week',
  min_doc_count: 0,
});

const dateFormat = (date) => moment(date).format('W MMM YY');

export default {
  label,
  limit,
  text,
  createFilter,
  dateHistogram,
  dateFormat,
};
