<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        {{ isEditing ? 'Edit' : 'Create' }} Application
      </v-toolbar-title>
      <v-btn
        v-if="isEditing && canDeleteApplication"
        text
        color="red"
        @click="deleteDialogue=true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form ref="form">
        <v-card
          flat
          :loading="loading || loadingRoles"
        >
          <v-card-text>
            <v-text-field
              v-model="application.name"
              label="Name"
              :rules="nameRules"
            />
            <v-text-field
              v-if="isEditing"
              v-model="application.client_id"
              label="Client Id"
              disabled
              readonly
              append-outer-icon="content_copy"
            >
              <template #append-outer>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="copy('client_id', 'Client Id')"
                    >
                      <v-icon>
                        content_copy
                      </v-icon>
                    </v-btn>
                  </template>
                  Copy
                </v-tooltip>
              </template>
            </v-text-field>
            <v-text-field
              v-if="isEditing"
              v-model="application.client_secret"
              label="Client Secret"
              disabled
              outlined
              :type="showSecret ? 'text': 'password'"
            >
              <template #append>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="showSecret = !showSecret"
                    >
                      {{ showSecret ? 'visibility_off': 'visibility' }}
                    </v-icon>
                  </template>
                  {{ showSecret ? 'Hide': 'Show' }}
                </v-tooltip>
              </template>
              <template #append-outer>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="copy('client_secret', 'Client Secret')"
                    >
                      content_copy
                    </v-icon>
                  </template>
                  Copy
                </v-tooltip>
              </template>
            </v-text-field>
            <v-switch
              v-model="application.active"
              label="Active"
              class="mt-0"
            />
            <v-card>
              <v-card-title>Roles</v-card-title>
              <v-card-text>
                <v-data-table
                  v-model="selectedRoles"
                  :headers="roleHeaders"
                  dense
                  :items="roles"
                  show-select
                  item-key="role_id"
                  :items-per-page="25"
                  :footer-props="{itemsPerPageOptions: [10, 25, 50]}"
                  :search="searchQuery"
                />
              </v-card-text>
            </v-card>

            <v-card
              v-if="isEditing"
              outlined
              class="mt-3"
            >
              <v-card-title>Danger Zone</v-card-title>
              <v-card-actions>
                <v-btn
                  block
                  color="error lighten-1"
                  @click="rotateDialogue = true"
                >
                  Rotate Secret
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-btn
              primary
              color="primary"
              @click="saveApp"
            >
              Save
            </v-btn>
            <v-btn
              class="ml-2"
              text
              @click.stop="$router.back()"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>

    <DeleteDialogue
      v-model="deleteDialogue"
      :loading="deleting"
      title="Delete Application?"
      @delete="deleteApp"
    />

    <DeleteDialogue
      v-model="rotateDialogue"
      title="Rotate Secret?"
      confirm-label="Rotate"
      :loading="rotatingSecrete"
      description="This will prevent all authentication attempts with the existing secret"
      @delete="rotateSecret"
    />
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref,
} from 'vue';
import axios from 'axios';

import { useStore, useRoute, useRouter } from '@/utils/vue';
import DeleteDialogue from '@/components/DeleteDialogue.vue';
import { BTL_API_ACCOUNT_URL } from '@/config';
import permittedFunction from '@/permittedFunction';

const store = useStore();
const route = useRoute();
const router = useRouter();
const emit = defineEmits(['snack']);

const canDeleteApplication = computed(() => permittedFunction('/tenants/<tenant_name>/accounts/<account_id>/applications/<client_id>', 'DELETE'));
const searchQuery = computed(() => store.getters.searchQuery);

const roles = ref([]);
const roleHeaders = [
  { text: 'Name', value: 'name' },
  { text: 'Description', value: 'description' },
];
const selectedRoles = ref([]);

const selectedAccountId = computed(() => store.getters.selectedAccountId);

const application = ref({});

const nameRules = [(v) => !!v || 'Name is required'];

const clientId = computed(() => route.params.clientId);
const isEditing = computed(() => !!clientId.value);
const showSecret = ref(false);

const loadingRoles = ref(false);
const loadRoles = async () => {
  try {
    loadingRoles.value = true;
    const { data } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectedAccountId.value}/roles`);
    roles.value = data;
  } catch (e) {
    console.error('failed to load roles', e);
  }
  loadingRoles.value = false;
};

const loading = ref(false);
const loadApplication = async () => {
  try {
    loading.value = true;
    const { data: app } = await axios.get(`${BTL_API_ACCOUNT_URL}/${selectedAccountId.value}/applications/${clientId.value}`);
    application.value = app;
    selectedRoles.value = roles.value.filter((role) => app?.entitlements?.[0]?.roles?.includes(role?.name));
  } catch (err) {
    console.error('failed to load app', err);
  }
  loading.value = false;
};

const deleteDialogue = ref(false);
const deleting = ref(false);
const deleteApp = async () => {
  try {
    deleting.value = true;
    await axios.delete(`${BTL_API_ACCOUNT_URL}/${selectedAccountId.value}/applications/${clientId.value}`);
    emit('snack', 'Application Deleted');
    await router.replace('/applications');
  } catch (err) {
    console.error('failed to delete app', err);
  }
  deleting.value = false;
};

const rotateDialogue = ref(false);
const rotatingSecrete = ref(false);
const rotateSecret = async () => {
  try {
    rotatingSecrete.value = true;
    await axios.put(`${BTL_API_ACCOUNT_URL}/${selectedAccountId.value}/applications/${clientId.value}`, { client_secret: null });
    await loadApplication();
    emit('snack', 'Secret Rotated');
    rotateDialogue.value = false;
  } catch (err) {
    console.error('failed to rotate secret', err);
  }
  rotatingSecrete.value = false;
};

const form = ref(null);
const saving = ref(false);
const saveApp = async () => {
  try {
    if (!form.value.validate()) return;
    saving.value = true;
    application.value.entitlements = [{
      resource: {
        entity_id: selectedAccountId.value,
        entity_type: 'Account',
      },
      roles: selectedRoles.value.map((role) => role.name),
    }];
    if (isEditing.value) {
      await axios.put(`${BTL_API_ACCOUNT_URL}/${selectedAccountId.value}/applications/${clientId.value}`, application.value);
      emit('snack', 'Application Updated');
    } else {
      const { data: app } = await axios.post(`${BTL_API_ACCOUNT_URL}/${selectedAccountId.value}/applications`, application.value);
      application.value = app;
      emit('snack', 'Application Created');
      await router.replace(`/application/${app.client_id}`);
    }
  } catch (err) {
    console.error('failed to save app', err);
  }
  saving.value = false;
};

const copy = (field, label) => {
  navigator.clipboard.writeText(application.value[field]);
  emit('snack', `Copied ${label}`);
};

onMounted(async () => {
  await loadRoles();
  if (isEditing.value) await loadApplication();
});

</script>

<style scoped>

</style>
